import React, { useContext, useEffect, useState } from "react";
import { uploadFile } from "api";
import { LanguageCodeEnum, useGetAllTermsQuery } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { SelectPicker, Checkbox } from "rsuite";
import InputSign from "user/component/InputSign";
import { generateTermImage } from "user/util";
import { InputFile } from "../input";
import {
  ArrowAnchor,
  Contents,
  Divider,
  Flex,
  FlexColumn,
  FlexItem,
  FlexRow,
  InputTitle,
  SectionTitle,
  TermBox,
  Text,
  TextBox,
} from "../style";
import "./4.scss";
import { useRecoilState } from "recoil";
import {
  chapterState,
  companyNameState,
  contactState,
  countryState,
  nameState,
  rrnState,
  signDataUrlState,
  signLangState,
  signState,
  userTypeState,
} from "signup/SignUp.atoms";
import Navigator from "signup/Navigator";
import { SignupChapterEnum } from "signup/type";
import { AppContext } from "app";
import useTerm from "signup/useTerm";
import { Blob } from "buffer";
import { Stack, Typography } from "@mui/material";

export default function ContractSigningForm() {
  // 번역 도구
  const { t, i18n } = useTranslation();
  const { loading, data } = useGetAllTermsQuery();
  const { completeCreateUser } = useTerm();

  const { showConfirmMessage } = useContext(AppContext);

  const [termSrc, setTermSrc] = useState("");
  const [type] = useRecoilState(userTypeState);
  const [country] = useRecoilState(countryState);
  const [rrn] = useRecoilState(rrnState);
  const [contact] = useRecoilState(contactState);
  const [name] = useRecoilState(nameState);
  const [companyName] = useRecoilState(companyNameState);
  const [signaturePath, setSign] = useRecoilState(signState);
  const [signDataUrl, setSignDataUrl] = useRecoilState(signDataUrlState);
  const [, setSignLang] = useRecoilState(signLangState);

  const [isAgreeTerm, setIsAgreeTerm] = useState(false);
  const [isAgreeSign, setIsAgreeSign] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [isShowServiceTerm, setIsShowServiceTerm] = useState(false);
  const [isShowDataTerm, setIsShowDataTerm] = useState(false);
  const [termLang, setTermLang] = useState<LanguageCodeEnum>(
  i18n.language === "ko"
      ? LanguageCodeEnum.Ko
      : LanguageCodeEnum.En
  );
  const [, setChapterValid] = useRecoilState(chapterState);

  const onChangeSign = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.onload = () => {
      uploadFile(file, true)
        .then((res) => {
          setSign(res.key);
          setSignDataUrl(fileReader.result as string);
          setIsSigned(true);
        })
        .catch(() => {
          alert("파일 업로드 실패");
        });
    };
    fileReader.readAsDataURL(file);
  };

  useEffect(() => {
    generateTermImage(
      {
        name: companyName || name,
        contact,
        nationalIdNumber: rrn,
        lang: termLang,
      },
      true,
      false
    ).then((termSrc: string) => {
      setTermSrc(termSrc);
      setSignLang(termLang);
    });
  }, [termLang, companyName, name, rrn, setTermSrc, setSignLang]);

  const languages = [
    {
      label: "English",
      value: "en",
      role: "Master",
    },
    {
      label: "中文",
      value: "zh",
      role: "Master",
    },
  ];

  const setCurrentChapterValid = (valid: boolean) => {
    setChapterValid((prev) => {
      return {
        ...prev,
        [SignupChapterEnum.Contract]: valid,
      };
    });
  };

  useEffect(() => {
    setCurrentChapterValid(isAgreeSign && isAgreeTerm && isSigned);
  }, [isAgreeSign, isAgreeTerm, isSigned]);

  const uploadSign: () => Promise<{
    key: string;
    dataUrl: string;
  }> = async () => {
    const signCanvas = document.getElementById("sign") as HTMLCanvasElement;
    const dataUrl = signCanvas.toDataURL();
    const blob = await (await fetch(dataUrl)).blob();
    const key = (await uploadFile(blob)).key;
    return { key, dataUrl };
  };

  const handleNext: () => Promise<string> = async () => {
    if (i18n.language === LanguageCodeEnum.Ko) {
      return new Promise((resolve, reject) => {
        showConfirmMessage(
          "알림",
          "회원가입에 필요한 모든 정보를 제대로 입력하셨나요?",
          async () => {
            // 사업자가 아닐경우, 사인을 직접 그려서 파일을 저장함
            if (type === "0") {
              const { key, dataUrl } = await uploadSign();
              await completeCreateUser(key);
            } else {
              await completeCreateUser(signaturePath);
            }
            resolve("");
          }
        );
      });
    } else {
      const { key, dataUrl } = await uploadSign();
      setSign(key);
      setSignDataUrl(dataUrl);
    }
  };

  return (
    <>
      <Contents>
        <SectionTitle style={{ marginBottom: 30 }}>
          {t("signupPage.signAgreement")}
        </SectionTitle>
        <FlexColumn align="center">
          {i18n.language === "ko" && (
            <>
              <FlexColumn align="center" marginBottom="50">
                {!loading && (
                  <TextBox height="600">
                    <img
                      id="test"
                      width="100%"
                      onContextMenu={(e) => e.preventDefault()}
                      src={termSrc}
                    />
                  </TextBox>
                )}
              </FlexColumn>
              <FlexColumn align="center" marginBottom="50">
                {type === "0" ? (
                  <div className="mt-30">
                    <InputTitle>서명</InputTitle>
                    <InputSign
                      id="sign"
                      summary="네모 칸 속에 서명을 그려주세요"
                      setIsDrawn={setIsSigned}
                    />
                  </div>
                ) : (
                  <div className="mt-30" style={{ width: "100%" }}>
                    <InputTitle>인감/서명</InputTitle>
                    <InputFile onChange={onChangeSign} />
                  </div>
                )}
              </FlexColumn>
              <FlexColumn>
                <InputTitle
                  onClick={() => setIsShowServiceTerm(!isShowServiceTerm)}
                >
                  서비스 이용약관
                  {isShowServiceTerm ? "▼" : "▲"}
                </InputTitle>
                {!loading && isShowServiceTerm && (
                  <TextBox>
                    {
                      data.getTermAll.find(
                        (term) => term.type === "서비스 이용약관"
                      ).contents
                    }
                  </TextBox>
                )}
              </FlexColumn>
              <FlexColumn>
                <InputTitle onClick={() => setIsShowDataTerm(!isShowDataTerm)}>
                  개인정보 수집 및 이용
                  {isShowDataTerm ? "▼" : "▲"}
                </InputTitle>
                {!loading && isShowDataTerm && (
                  <TextBox>
                    {
                      data.getTermAll.find(
                        (term) => term.type === "개인정보 수집 및 이용"
                      ).contents
                    }
                  </TextBox>
                )}
              </FlexColumn>
              <FlexColumn>
                <Flex
                  style={{
                    justifyContent: "space-between",
                    marginBottom: "12px",
                  }}
                >
                  <span>[필수]</span>
                  <FlexItem>
                    &nbsp;본인은 위 에이콘3D 파트너십 및 입점 계약서, 서비스
                    이용약관 및 개인정보처리방침의 내용을 모두 확인하였으며,
                    에이콘의 파트너로서 계약 및 약관에 따라 성실히 활동할 것에
                    동의합니다.
                  </FlexItem>
                  <span>
                    <label>
                      <Checkbox
                        inline
                        value={isAgreeTerm}
                        onChange={(a, b) => setIsAgreeTerm(b)}
                      />{" "}
                      동의함
                    </label>
                  </span>
                </Flex>
                <Flex
                  style={{
                    justifyContent: "space-between",
                    marginBottom: "12px",
                  }}
                >
                  <span>[필수]</span>
                  <FlexItem>
                    &nbsp;본인은 본 계약서에 서명/인감을 등록함으로써 종이문서의
                    서명과 동일한 효력을 갖는데 동의합니다.
                  </FlexItem>
                  <span>
                    <label>
                      <Checkbox
                        inline
                        value={isAgreeSign}
                        onChange={(a, b) => setIsAgreeSign(b)}
                      />{" "}
                      동의함
                    </label>
                  </span>
                </Flex>
              </FlexColumn>
            </>
          )}
          {i18n.language !== "ko" && (
            <>
              {/*<FlexColumn align="flex-end" marginBottom="10">*/}
              {/*  <SelectPicker*/}
              {/*    size="lg"*/}
              {/*    data={languages}*/}
              {/*    style={{ width: 100, height: 40 }}*/}
              {/*    value={termLang}*/}
              {/*    searchable={false}*/}
              {/*    cleanable={false}*/}
              {/*    onChange={(value, e) => setTermLang(value)}*/}
              {/*  />*/}
              {/*</FlexColumn>*/}
              <FlexColumn align="center" marginBottom="50">
                {!loading && (
                  <TermBox height="600">
                    <img
                      id="test"
                      width="100%"
                      onContextMenu={(e) => e.preventDefault()}
                      src={termSrc}
                    />
                  </TermBox>
                )}
              </FlexColumn>
              <FlexColumn align="center" marginBottom="20">
                <InputSign
                  className={"oversea"}
                  id="sign"
                  summary={
                    <Stack spacing={0.5} textAlign={"center"}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          color: "#aaa",
                          lineHeight: "30px",
                        }}
                      >
                        Please write your signature here
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#aaa",
                          lineHeight: "24px",
                        }}
                      >
                        (If you do not sign here, you may need to rewrite the
                        contract.)
                      </Typography>
                    </Stack>
                  }
                  setIsDrawn={setIsSigned}
                />
              </FlexColumn>
              <FlexRow justifyContent="space-between" marginBottom="12">
                <span>Terms of Service</span>
                <ArrowAnchor
                  href="https://acon3d.notion.site/ACON-PARTNER-HUB-Terms-Conditions-26c339cef33442178d343502cfc8c1f2"
                  target="_blank"
                >
                  View all &gt;
                </ArrowAnchor>
              </FlexRow>
              <FlexRow justifyContent="space-between" marginBottom="20">
                <span>Personal Infomation Privacy Policy</span>
                <ArrowAnchor
                  href="https://www.acon3d.com/en/policy/privacy"
                  target="_blank"
                >
                  View all &gt;
                </ArrowAnchor>
              </FlexRow>
              <Divider marginBottom="20" />
              <FlexRow
                justifyContent="space-between"
                marginBottom="12"
                style={{ alignItems: "flex-start" }}
              >
                <FlexRow>
                  <Checkbox
                    inline
                    checked={isAgreeTerm}
                    value={isAgreeTerm}
                    onChange={(a, b) => setIsAgreeTerm(b)}
                  />
                  <Text
                    style={{ cursor: "pointer", lineHeight: "130%" }}
                    onClick={(e) => setIsAgreeTerm(!isAgreeTerm)}
                  >
                    I have read all contents of the above ACON3D Partnership and
                    Entry Agreement, Terms of Service and Personal Information
                    Privacy Policy, and agree to act faithfully in accordance
                    with the agreement, terms and conditions.
                  </Text>
                </FlexRow>
                <Text color="#F300BA" fontSize="12">
                  (Required)
                </Text>
              </FlexRow>
              <FlexRow
                justifyContent="space-between"
                style={{ alignItems: "flex-start" }}
              >
                <FlexRow>
                  <Checkbox
                    inline
                    checked={isAgreeSign}
                    value={isAgreeSign}
                    onChange={(a, b) => setIsAgreeSign(b)}
                  />
                  <Text
                    style={{ cursor: "pointer", lineHeight: "130%" }}
                    onClick={(e) => setIsAgreeSign(!isAgreeSign)}
                  >
                    By registering my signature/seal in this agreement, I agree
                    that it has the same effect as signature on paper documents.
                  </Text>
                </FlexRow>
                <Text color="#F300BA" fontSize="12">
                  (Required)
                </Text>
              </FlexRow>
            </>
          )}
        </FlexColumn>
      </Contents>
      <Navigator onNext={handleNext} />
    </>
  );
}

// Contract.validation = (state) => {
//   const { isBothAgree, isSigned } = state;

//   if (isBothAgree && isSigned) {
//     return true;
//   }

//   return false;
// };
