import { SmallWhiteButton } from 'common/style';
import React, { useEffect, useState } from 'react';
import { Flex } from 'signup/style';
import { Select } from 'user/User/style';
import { Field } from '../common/field';
import { Input, InputField } from '../common/input';

const Component = (props) => {
    // variables
    const { user } = props;
    const [ reference, setReference ] = useState(user.reference.map(a => ({url: a.url})));
    
    const referencePush = () => {
        if (reference.length >= 3) {
            return false;
        }
        reference.push({ url: '' });
        setReference([...reference]);
    };

    const referencePop = () => {
        reference.pop();
        setReference([...reference]);
    };

    const onChangeReference = (e) => {
        const { target } = e;
        const key = target.dataset.key;
        const value = target.value;
        let newReference = [...reference];

        newReference[+key].url = value;

        setReference(newReference);
    };

    const onChangeData = (e) => {
        const element = e.target;
        const key = element.dataset.key;
        let value = element.value;

        if (element.dataset.allowNum) {
            value = element.value.replace(/[^0-9\-]/g, '');
            element.value = value;
        }

        props.onChangeData(key, value);
    };

    useEffect(() => {
        console.log(reference);
        props.onChangeData('reference', reference);
    }, [reference]);

    const brands = user.brand.item.slice().sort((a, b) => {
        return a.language.id - b.language.id;
    });

    return (
        <>
            <InputField readOnly label="작가 승인상태" value={user.accept.statusString} />
            <Field label="반려사유">
                <textarea readOnly defaultValue={user.accept.comment} />
            </Field>
            <InputField readOnly label="아이디(이메일)" defaultValue={user.account} />
            <InputField label="필명" data-key="brand.name" defaultValue={user.brand.name} onChange={onChangeData} />
            <Field label={'필명'}>
                {brands.map((b, i, arr) => {
                    return (
                        <Input
                            readOnly
                            key={i}
                            style={arr.length - 1 > i ? { marginBottom: 10 } : {}}
                            value={b?.name}
                        />
                    );
                })}
            </Field>
            <InputField label="연락처" data-key="contact" data-allow-num defaultValue={user.contact} onChange={onChangeData} />
            <InputField label="추가 이메일" data-key="contactAdditional" defaultValue={user.contactAdditional || ''} onChange={onChangeData} />
            <Field label="입점 검토 자료">
                { reference.map((document, i) => 
                    <Flex>
                        <Input key={i} data-key={i} value={document.url} onChange={onChangeReference}/>
                        { i === 0 ? (
                            <SmallWhiteButton onClick={referencePush}>+</SmallWhiteButton>
                        ) : i !== 2 &&(
                            <SmallWhiteButton onClick={referencePop}>-</SmallWhiteButton>
                        )}
                    </Flex>
                )}
            </Field>
        </>
    );
};

export default Component;