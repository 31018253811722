import React from "react";
import { TextField, Typography } from '@mui/material';

interface Props {
  text: React.ReactNode;
  defaultValue: string;
  placeholder: string;
  disabled?: boolean;
}  

const QuestionInput = React.forwardRef(({
  text,
  defaultValue,
  placeholder,
  disabled,
}: Props, ref: React.MutableRefObject<HTMLInputElement>) => {
  return (
    <>
      <Typography
        color="#000000DE"
        fontWeight="500"
        fontSize="14px"
        lineHeight="22px"
        letterSpacing="0.1px"
        sx={{ '& span': { fontWeight: '700' } }}
      >
        {text}
      </Typography>
      <TextField
        inputRef={ref}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        size="small"
        fullWidth
        sx={{ marginTop: '12px' }}
      />
    </>
  );
});

export default QuestionInput
