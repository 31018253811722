import { ApolloClient, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createHttpLink } from "apollo-link-http";
import { AbortController } from "@azure/abort-controller";
import { BlockBlobClient } from "@azure/storage-blob";
import axios from "axios";
import { saveAs } from "file-saver";
import {SERVICE_NAME} from "../constants";

const graphqlProtocol = process.env.NETWORK_PROTOCOL_GRAPHQL;
const graphqlHost = process.env.NETWORK_HOST_GRAPHQL;

const authProtocol = process.env.NETWORK_PROTOCOL_AUTH;
const authHost = process.env.NETWORK_HOST_AUTH;

export const graphqlOrigin = `${graphqlProtocol}://${graphqlHost}`;
export const graphqlUrl = `${graphqlOrigin}/graphql`;
export const authOrigin = `${authProtocol}://${authHost}`;

const httpLink = createHttpLink({
  uri: graphqlUrl,
  credentials: "include",
});

let callback = () => {};
export const setCallback = (cb) => {
  callback = cb;
};

export const errorHandler = ({ forward, graphQLErrors, networkError }) => {
  if (networkError?.statusCode === 401) {
    // 로그인 오류
    if (networkError?.result?.message === "jwt expired") {
      // 토큰 만료
      callback("로그인 세션이 만료되었습니다.", () => {
        window.location.href = "/logout";
      });
    }
  }
};

const errorLink = onError(errorHandler);

export const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache(),
  name: SERVICE_NAME,
});

/**
 * s3에 파일 업로드를 위한 인증키를 통신으로 받아옴
 * @returns Promise<axios result>
 */
export const getFileCredentials = (args) => {
  return axios.post(authOrigin + "/auth/file", args);
};

// 파일 컨트롤러
export const fileController = (() => {
  let controller = null;
  function thisClass() {
    controller = new AbortController();
  }

  // 업로드 취소하기 메소드
  thisClass.prototype.cancel = () => {
    controller.abort();
  };
  thisClass.prototype.upload = async (file, progressCallback) => {
    // source.cancel();
    const options = { contentType: file.type };

    if (file.name) {
      options.ext = file.name.split(".").pop();
    }
    const { data: url } = await getFileCredentials(options);
    return new Promise(async (resolve, reject) => {
      const client = new BlockBlobClient(url);
      await client.uploadData(file, {
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
        onProgress: (ev) => {
          var percentCompleted = Math.round((100 * ev.loadedBytes) / file.size);
          progressCallback && progressCallback(percentCompleted);
        },
      });
      resolve({ key: client.containerName + "/" + client.name });
    });
  };

  return thisClass;
})();

export const uploadFile = async (file, arg, progressCallback) => {
  const config = progressCallback && {
    onProgress: (ev) => {
      var percentCompleted = Math.round((100 * ev.loadedBytes) / file.size);
      progressCallback && progressCallback(percentCompleted);
    },
  };
  // source.cancel();
  const options = {};

  if (file.type) {
    options.contentType = file.type;
  }

  if (file.name) {
    options.ext = file.name.split(".").pop();
  }

  // second argument is isPublic
  if (typeof arg === "boolean") {
    options.isPublic = arg;
  }

  // second argument is directory
  if (typeof arg === "string") {
    options.fileName = arg;
  }

  if (typeof arg === "object") {
    for (const key in arg) {
      options[key] = arg[key];
    }
  }

  const { data: url } = await getFileCredentials(options);

  return new Promise(async (resolve) => {
    const client = new BlockBlobClient(url);
    await client.uploadData(file, {
      blobHTTPHeaders: { blobContentType: file.type },
      ...config,
    });
    resolve({ key: client.containerName + "/" + client.name });
  });
};

export const downloadFile = (uploadFileId, fileName) => {
  return new Promise((resolve, reject) => {
    axios
      .post(graphqlOrigin + "/download/hub/model/" + `${uploadFileId}`, {
        id: uploadFileId,
      })

      .then(async (res) => {
        const client = new BlockBlobClient(res.data.split(" ")[3]);
        const response = await client.download();

        saveAs(await response.blobBody, fileName);
        resolve();
      })
      .catch(reject);
  });
};

export const getFile = (uploadFileId, fileName) => {
  return new Promise((resolve, reject) => {
    axios
      .post(graphqlOrigin + "/download/hub/model/" + `${uploadFileId}`, {
        id: uploadFileId,
      })

      .then(async (res) => {
        const client = new BlockBlobClient(res.data.split(" ")[3]);
        const response = await client.download();

        const blob = await response.blobBody;
        const file = new File([blob], fileName);
        resolve(file);
      })
      .catch(reject);
  });
};

export const getSignImg = async (signId) => {
  const url = await axios.post(authOrigin + "/auth/download", { id: signId });

  const img = await new Promise((resolve, reject) => {
    let img = document.createElement("img");
    img.crossOrigin = "anonymous";
    img.src = url.data;

    img.onload = () => {
      resolve(img);
    };
  });

  return img;
};
