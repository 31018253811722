export const color = {
  primary: '#4E4EFF',
  text: {
    primary: '#212B36',
    secondary: '#637381'
  },
  gray: {
    border: 'rgba(145, 158, 171, 0.32)'
  }
};

export const inputStyle = {
  '& .MuiInput-underline:after': {
    borderBottomColor: color.primary,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: color.primary,
    },
  },
};
