import React from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { LanguageCodeEnum } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { brandNameState } from "./atom";
import { languageCodeToCountry } from "lib/multiLocale";

const penNameGroup = [
  [
    {
      language: LanguageCodeEnum.En,
      label: "ENGLISH",
    },
    {
      language: LanguageCodeEnum.Zh,
      label: "CHINESE",
    },
  ],
  [
    {
      language: LanguageCodeEnum.Ko,
      label: "KOREAN",
    },
    {
      language: LanguageCodeEnum.Ja,
      label: "JAPANESE",
    },
  ],
];

export default function PenNameField() {
  const { t, i18n } = useTranslation();
  const [brandNames, setBrandNames] = useRecoilState(brandNameState);

  const getHandleChange = (language: LanguageCodeEnum) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setBrandNames((prev) => {
        return {
          ...prev,
          [language]: e.target.value,
        };
      });
    };
  };

  return (
    <Stack spacing={0.5}>
      <Typography variant="subtitle2">{t("creatorName")}</Typography>
      <Stack spacing={1}>
        {penNameGroup.map((x, i) => (
          <Stack key={`penname_${i}`} direction="row" spacing={1}>
            {x.map((y, j) => (
              <Stack spacing={0.5} key={`penname_${i}_${j}`} width={"50%"}>
                <Typography
                  variant="overline"
                  fontWeight={700}
                  color="text.secondary"
                >
                  {`${y.label}${
                    i18n.language === y.language ? `(${t("require")})` : ``
                  }`}
                </Typography>
                <Typography variant="body2">
                  <TextField
                    size="small"
                    value={brandNames[y.language]}
                    onChange={getHandleChange(y.language)}
                  />
                </Typography>
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
