import { useQuery } from "@apollo/client";
import { NOTIFICATIONS } from "api/quries";
import { useReadNotificationMutation } from "generated/graphql";
import { useEffect, useState } from "react";

export default function useNotification(type) {
  const { data } = useQuery(NOTIFICATIONS);

  const [readNotification] = useReadNotificationMutation();
  const [result, setResult] = useState([]);

  useEffect(() => {
    if (data && data.notifications) {
      let tmp = data.notifications;

      if (type) {
        tmp = tmp.filter(({ type: _type }) => type === _type);
      }

      setResult(tmp);
    }
  }, [data]);

  const read = async () => {
    const { data } = await readNotification({
      variables: { type },
      refetchQueries: [{ query: NOTIFICATIONS }],
    });

    return data.readNotification;
  };

  return { notifications: result, readNotification: read };
}
