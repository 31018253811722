import React from 'react';
import styled from 'styled-components';

export default styled(Component)`
	margin-top: 30px;
	font-size: 14px;
	line-height: 2;

	.bigger {
		font-size: 16px;
	}

	ul {
		padding-left: 40px;
		list-style: disc;
	}
`;

function Component({ className }) {
	return (
		<p className={className}>
			안녕하세요 작가님!
			<br />
			<span className="bigger">
				에이콘에서의 더욱 원활한 작품 등록과 판매를 위해 입점 계약서의
				조항이 더 쉽고 정확하게 개정되었습니다.
			</span>
			<br />
			<br />
			<span className="bigger">주요 변경사항은 다음과 같습니다.</span>
			<br />
			2조
			<ul>
				<li>판매 정책 협의 조건 구체화</li>
				<li>전속 계약 의미 명확화 </li>
			</ul>
			3조
			<ul>
				<li>파트너십 선택 주체 명확화</li>
				<li>파트너십 하향 조건 명시</li>
			</ul>
			6조
			<ul>
				<li>문제 발생 조건 구체화</li>
				<li>문제 발생 시 조치 및 책임 의무 설명 구체화</li>
			</ul>
			<br />
			<span className="bigger">
				계약서 내용을 확인 후 서명 또는 인감 파일 업로드를
				부탁드립니다.
			</span>
			<br />
			<span className="bigger">
				작성된 계약서는 마이페이지 화면에서 언제든지 다운로드 받으실
				수 있습니다.
			</span>
			<br />
			문의 : contact@acon3d.com
		</p>
	);
}
