import React, { useEffect } from "react";
import { LanguageCodeEnum, useGetUserStoreQuery } from "generated/graphql";
import { Skeleton, Stack, Typography, Box, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import NameField from "./NameField";
import ContactField from "./ContactField";
import PenNameField from "./PenNameField";
import DocumentsField from "./DocumentsField";
import NationalNumberIdField from "./NationalNumberField";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { pageState, userStoreState, userTypeState } from "./atom";
import CountryField from "./CountryField";
import { UserTypeEnum } from "signup/type";
import TermField from "./TermField";
import AgreeField from "./AgreeField";
import SignField from "./SignField";
import Navigator from "./Navigator";
import { languageCodeToCountry } from "lib/multiLocale";
import Next from "./Next";
import { getFile } from "api/index";
import CompanyNameField from "./CompanyNameField";

export default function StoreModify() {
  const page = useRecoilValue(pageState);
  const userType = useRecoilValue(userTypeState);
  const { t, i18n } = useTranslation();

  const setState = useSetRecoilState(userStoreState);

  const { data, loading } = useGetUserStoreQuery({
    fetchPolicy: "no-cache",
  });
  const userData = data?.userV2;

  const init = async () => {
    const signUploadFileId = userData.settle.sign.id;
    const signFile = await getFile(signUploadFileId, "sign.png");

    setState({
      signFile,
      companyName: userData.companyName,
      termLanguage: userData.settle.signLang as LanguageCodeEnum,
      userType: userData.type as UserTypeEnum,
      name: userData.name,
      brandNames: {
        ko: userData.brand.item.find(
          (x) => x.language.code === LanguageCodeEnum.Ko
        )?.name,
        en: userData.brand.item.find(
          (x) => x.language.code === LanguageCodeEnum.En
        )?.name,
        zh: userData.brand.item.find(
          (x) => x.language.code === languageCodeToCountry(LanguageCodeEnum.Zh)
        )?.name,
        ja: userData.brand.item.find(
          (x) => x.language.code === languageCodeToCountry(LanguageCodeEnum.Ja)
        )?.name,
      },
      contact: userData.contact,
      country: userData.settle.country,
      documents: userData.reference.map((x) => x.url),
      nationalIdNumber: userData.settle.nationalIdNumber,
    });
  };

  useEffect(() => {
    if (!userData) return;

    init();
  }, [userData]);

  if (loading || !data) {
    return <Skeleton />;
  }

  return (
    <Box maxWidth={page === 1 ? "576px" : "776px"} width={"100%"}>
      <Stack direction={"column"} alignItems={"center"} textAlign={"center"}>
        <Typography
          fontWeight={700}
          fontSize={24}
          lineHeight={"36px"}
          color={"grey.800"}
        >
          {t("user.store.modifyTitle")}
        </Typography>

        <Box sx={{ mt: 1 }} />

        <Typography
          fontWeight={500}
          fontSize={14}
          lineHeight={"22px"}
          color={"grey.800"}
        >
          {parse(t("user.store.modify.description"))}
        </Typography>
      </Stack>

      <Box sx={{ mt: 3 }} />

      {page === 1 && (
        <>
          <Card sx={{ p: 3 }}>
            <Stack spacing={2}>
              {userType === UserTypeEnum.Business && <CompanyNameField />}
              <NameField />
              <PenNameField />
              {i18n.language === LanguageCodeEnum.Ko && <ContactField />}
              {i18n.language !== LanguageCodeEnum.Ko && <CountryField />}
              <DocumentsField />
              {i18n.language === LanguageCodeEnum.Ko && (
                <NationalNumberIdField />
              )}
            </Stack>
          </Card>

          <Box sx={{ mt: 5 }} />

          <Box textAlign={"center"}>
            <Next />
          </Box>
        </>
      )}
      {page === 2 && (
        <Stack spacing={5}>
          <TermField />
          <SignField />
          <AgreeField />
          <Navigator />
        </Stack>
      )}
    </Box>
  );
}
