import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import ArrowIcon from "assets/icon_arrow";
import { LanguageCodeEnum, useGetAllTermsQuery } from "generated/graphql";
import { ScrollBox } from "components";
import { useTranslation } from "react-i18next";
import {
  PersonalInformationProcessingPolicy,
  TermsConditionsText,
} from "termOfService";
import { useSetRecoilState } from "recoil";
import { isAgreeState } from "./atom";

export default function AgreeField() {
  const { t, i18n, ready } = useTranslation();
  const setIsAgree = useSetRecoilState(isAgreeState);
  const { data } = useGetAllTermsQuery({
    skip: !ready || i18n.language !== LanguageCodeEnum.Ko,
  });
  const [isPartnerAgree, setIsPartnerAgree] = useState<boolean>(false);
  const [isSignAgree, setIsSignAgree] = useState<boolean>(false);

  const [isShowServiceTerm, setIsShowServiceTerm] = useState<boolean>(false);
  const [isShowPrivateTerm, setIsShowPrivateTerm] = useState<boolean>(false);

  useEffect(() => {
    setIsAgree(isPartnerAgree && isSignAgree);
  }, [isPartnerAgree, isSignAgree]);

  return (
    <Stack spacing={2.5}>
      <Stack spacing={1}>
        <Stack spacing={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="body2">{t("term.service")}</Typography>
            <button
              onClick={() => setIsShowServiceTerm((prev) => !prev)}
              style={{ padding: 0, background: "none" }}
            >
              <Stack direction="row" alignItems={"center"}>
                <Typography variant="caption">{t("term.viewAll")}</Typography>
                <ArrowIcon
                  direction={isShowServiceTerm ? "up" : "down"}
                  customSize={12}
                />
              </Stack>
            </button>
          </Stack>
          {isShowServiceTerm && (
            <ScrollBox
              height={140}
              text={
                i18n.language === LanguageCodeEnum.Ko
                  ? data.getTermAll.find(
                      (term) => term.type === "서비스 이용약관"
                    ).contents
                  : TermsConditionsText
              }
            />
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="body2">{t("term.private")}</Typography>
          <button
            onClick={() => setIsShowPrivateTerm((prev) => !prev)}
            style={{ padding: 0, background: "none" }}
          >
            <Stack direction="row" alignItems={"center"}>
              <Typography variant="caption">{t("term.viewAll")}</Typography>
              <ArrowIcon
                direction={isShowServiceTerm ? "up" : "down"}
                customSize={12}
              />
            </Stack>
          </button>
        </Stack>
        {isShowPrivateTerm && (
          <ScrollBox
            height={140}
            text={
              i18n.language === LanguageCodeEnum.Ko
                ? data.getTermAll.find(
                    (term) => term.type === "개인정보 수집 및 이용"
                  ).contents
                : PersonalInformationProcessingPolicy
            }
          />
        )}
      </Stack>
      <Box sx={{ height: "1px", background: "#f0f0f0" }} />
      {i18n.language === LanguageCodeEnum.Ko && (
        <Stack spacing={1}>
          <Stack direction="row" justifyContent={"space-between"}>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" sx={{ color: "primary.main" }}>
                [필수]
              </Typography>
              <Typography variant="body2" color={"#333"}>
                본인은 위 에이콘3D 파트너십 및 입점 계약서, 서비스 이용약관 및
                개인정보처리방침의 내용을 모두 확인하였으며,
                <br />
                에이콘의 파트너로서 계약 및 약관에 따라 성실히 활동할 것에
                동의합니다.
              </Typography>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  value={isPartnerAgree}
                  onChange={(e, checked) => setIsPartnerAgree(checked)}
                />
              }
              label="동의함"
            />
          </Stack>
          <Stack direction="row" justifyContent={"space-between"}>
            <Stack direction="row" spacing={1} alignItems={"baseline"}>
              <Typography variant="body2" sx={{ color: "primary.main" }}>
                [필수]
              </Typography>
              <Typography variant="body2" color={"#333"}>
                본인은 본 계약서에 서명/인감을 등록함으로써 종이문서의 서명과
                동일한 효력을 갖는데 동의합니다.
              </Typography>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  value={isSignAgree}
                  onChange={(e, checked) => setIsSignAgree(checked)}
                />
              }
              label="동의함"
            />
          </Stack>
        </Stack>
      )}
      {i18n.language !== LanguageCodeEnum.Ko && (
        <Stack spacing={1}>
          <Stack direction="row" justifyContent={"space-between"}>
            <Stack direction="row" spacing={1}>
              <Checkbox
                size="small"
                value={isPartnerAgree}
                onChange={(e, checked) => setIsPartnerAgree(checked)}
              />
              <Typography variant="body2" color={"#333"}>
                I have read all contents of the above ACON3D Partnership and
                Entry Agreement, Terms of Service
                <br />
                and Personal Information Privacy Policy, and agree to act
                faithfully in accordance with the
                <br />
                agreement, terms and conditions.
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{ color: "primary.main" }}>
              (Mandatory)
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent={"space-between"}>
            <Stack direction="row" spacing={1}>
              <Checkbox
                size="small"
                value={isSignAgree}
                onChange={(e, checked) => setIsSignAgree(checked)}
              />
              <Typography variant="body2" color={"#333"}>
                By registering my signature/seal in this agreement, I agree that
                it has the same effect as signature
                <br />
                on paper documents.
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{ color: "primary.main" }}>
              (Mandatory)
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
