import React, { useState } from "react";
import {
  LanguageCodeEnum,
  useGetUserQuery,
  UserSettleStatusEnum,
} from "generated/graphql";
import { Skeleton, Stack, Typography, Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FieldsBox, FieldName, FieldValue, FieldGroup } from "./styled";
import { UserFormType } from "./type";
import { downloadFile } from "api/index";
import parse from "html-react-parser";
import { CommonDialog } from "components/dialog";
import { PATH_USER } from "routes/paths";
import { useHistory } from "react-router-dom";

type Props = UserFormType & {
  onClickShowDialog: () => void;
};
export default function SettleForm({ userId, onClickShowDialog }: Props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  const { data, loading } = useGetUserQuery({
    variables: {
      id: userId,
    },
  });

  if (loading || !data) {
    return <Skeleton />;
  }
  const handleTaxAppDownload = () => {
    downloadFile(
      data.userV2.settle.limitedTaxApl.id,
      data.userV2.settle.limitedTaxApl.fileName
    );
  };

  const handleIdentificationCardDownload = () => {
    downloadFile(
      data.userV2.settle.idCard.id,
      data.userV2.settle.idCard.fileName
    );
  };

  const handleClickLink = () => {
    setIsShowDialog(true);
  };

  const handleClickEditSettle = () => {
    history.push(PATH_USER.settle);
  };

  return (
    <>
      <FieldsBox>
        {data.userV2.settle.status === UserSettleStatusEnum.None && (
          <Stack spacing={3} textAlign={"center"}>
            <Stack spacing={1.5}>
              <Typography variant="body2" fontWeight={500}>
                {parse(t("user.settle.pleaseInputInfo"))}
              </Typography>
              <Link
                onClick={handleClickLink}
                variant="body2"
                sx={{ textDecoration: "underline" }}
              >
                {parse(t("user.settle.addDocument"))}
              </Link>
            </Stack>
            <Stack>
              <Button
                size="large"
                variant="contained"
                onClick={handleClickEditSettle}
              >
                {t("user.settle.inputInfo")}
              </Button>
            </Stack>
          </Stack>
        )}
        {[
          UserSettleStatusEnum.Request,
          UserSettleStatusEnum.Rerequest,
        ].includes(data.userV2.settle.status) && (
          <Stack textAlign={"center"}>
            <Typography variant="body2" fontWeight={500}>
              {parse(t("user.settle.inprogress"))}
            </Typography>
          </Stack>
        )}

        {data.userV2.settle.status === UserSettleStatusEnum.Reject && (
          <Stack spacing={3} textAlign={"center"}>
            <Stack>
              <Typography variant="body2" fontWeight={500}>
                {parse(t("user.settle.editInfo"))}
              </Typography>
            </Stack>
            <Stack>
              <Button
                size="large"
                variant="contained"
                onClick={handleClickEditSettle}
              >
                {t("user.settle.edit")}
              </Button>
            </Stack>
          </Stack>
        )}

        {data.userV2.settle.status === UserSettleStatusEnum.Approve && (
          <>
            {i18n.language === LanguageCodeEnum.Ko && (
              <>
                <FieldGroup>
                  <FieldName>{t("user.bank")}</FieldName>
                  <FieldValue>{data.userV2.settle.bank.name}</FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>{t("user.paymentAccount")}</FieldName>
                  <FieldValue>{data.userV2.settle.bankAccountOwner}</FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>{t("user.paymentAccountNumber")}</FieldName>
                  <FieldValue>
                    {data.userV2.settle.bankAccountNumber}
                  </FieldValue>
                </FieldGroup>
              </>
            )}
            {i18n.language !== LanguageCodeEnum.Ko && (
              <>
                <FieldGroup>
                  <FieldName>Payment Method</FieldName>
                  <FieldValue>
                    {(data.userV2.settle.bankName.length > 0 && !data.userV2.settle.bankName.includes("Paypal")) ? data.userV2.settle.bankName : "Paypal"}
                  </FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>Payment account</FieldName>
                  <FieldValue>
                    {data.userV2.settle.bankAccountNumber}
                  </FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>Name(English)</FieldName>
                  <FieldValue>{data.userV2.name}</FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>Application of Limited Tax Rate</FieldName>
                  <FieldValue>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={handleTaxAppDownload}
                    >
                      {t("download")}
                    </Button>
                  </FieldValue>
                </FieldGroup>
                <FieldGroup>
                  <FieldName>Personal Identification Document</FieldName>
                  <FieldValue>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={handleIdentificationCardDownload}
                    >
                      {t("download")}
                    </Button>
                  </FieldValue>
                </FieldGroup>
              </>
            )}
          </>
        )}
      </FieldsBox>
      {data.userV2.settle.status === UserSettleStatusEnum.Approve && (
        <Stack alignItems={"end"} mt={1.5}>
          <Button variant="text" onClick={onClickShowDialog}>
            {t("user.editInfo")}
          </Button>
        </Stack>
      )}
      <CommonDialog
        title={t("user.settle.dialogTitle")}
        contents={
          i18n.language === LanguageCodeEnum.Ko &&
          "개인 : 신분증 사본, 통장 사본<br />사업자 : 사업자등록증 사본, 통장 사본"
        }
        isOpen={isShowDialog}
        onClose={() => setIsShowDialog(false)}
      >
        {i18n.language !== LanguageCodeEnum.Ko && (
          <Stack textAlign={"left"}>
            <ol style={{ marginBottom: "0", paddingLeft: "15px" }}>
              <li
                style={{
                  marginBottom: "24px",
                }}
              >
                <Typography variant="body2">
                  Application of Limited Tax Rate&nbsp;
                  <strong>
                    <a
                      href="https://acon3d.notion.site/ACON-FWT-Form-Guide-0fffee8b20274b19a99ace79fee01122"
                      target="blank"
                      style={{ color: "inherit", textDecoration: "underline" }}
                    >
                      (View Guide &gt;)
                    </a>
                  </strong>
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Copy of your ID, passport or TIN document
                  <br /> (Your full name and ID number must be included)
                </Typography>
              </li>
            </ol>
          </Stack>
        )}
      </CommonDialog>
    </>
  );
}
