import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { DialogType } from "./type";

type Props = DialogType & {
  loading?: boolean;
  onSubmit: () => void;
};

export default function ConfirmRequestDialog({
  isOpen,
  loading = false,
  onClose,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "400px",
          width: "100%",
          p: 3,
        },
      }}
    >
      <Stack direction={"column"} alignItems={"center"}>
        <Typography
          fontWeight={700}
          fontSize={18}
          lineHeight={"28px"}
          textAlign={"center"}
        >
          {parse(t("user.settle.modify.modal.title"))}
        </Typography>

        <Box sx={{ pb: 2 }} />

        <Typography fontSize={14} lineHeight={"22px"} textAlign={"center"}>
          {parse(t("user.settle.modify.modal.description"))}
        </Typography>

        <Box sx={{ pb: 3 }} />

        <Stack direction={"row"} justifyContent={"space-between"} spacing={1.5}>
          <Button
            sx={{ width: 170 }}
            variant={"outlined"}
            size={"large"}
            onClick={onClose}
          >
            {t("user.settle.modify.modal.backButton")}
          </Button>
          <LoadingButton
            sx={{ width: 170 }}
            variant={"contained"}
            size={"large"}
            loading={loading}
            onClick={onSubmit}
          >
            {t("user.settle.modify.modal.requestButton")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
