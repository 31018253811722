import React from 'react';
import './SignUp.scss';

export default (({
    title,
    value,
    className,
    validationMsg,
    type = 'text',
    name,
    onChange,
    onBlur,
    readOnly,
    childRef,
    strLimit = 255
}) => {
    /**
     * 
     * @param {Event} e 
     */

    const onChangeInputTag = (e) => {
        // 변경 이벤트 실행 
        onChange && onChange(name, e.currentTarget.value);
    };
    return (
        <>
            <div className="signup__field">
                {/* 폼 제목 */}
                <span className="signup__field__title">
                    {title}
                </span>
                <input
                    type="text"
                    name={name || ''}
                    value={value || ''}
                    onChange={onChangeInputTag}
                    readOnly={readOnly || false}
                    onBlur={onBlur}
                    ref={childRef}
                    className={"underline " + className}
                />
                <span className="guide-msg">
                    {validationMsg}
                </span>
            </div>
        </>
    );
});