import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { AppContext } from '../app'
import { CREATE_PRODUCT_BADGE, UPDATE_PRODUCT_BADGE, DELETE_PRODUCT_BADGE } from '../api/mutations';
import { GET_PRODUCT_BADGE_WRITING_MATERIALS } from '../api/quries';
import DateSetting from '../common/DateSetting';
import CheckProduct from '../common/CheckProduct';
import './BannerWrite.scss';

export default ((props) => {
    const { userInfo } = useContext(AppContext);

    // 관리자 권한이 없을 경우 홈 화면으로 돌려보냅니다.
    const { isAdmin } = userInfo;
    if (!isAdmin) {
        props.history.push("/");
        return <></>;
    }

    // 배너 DB id
    const docId = +props.match.params.docId;

    // 모달 표시 메소드 불러오기
    const {
        showAlertMessage,
    } = useContext(AppContext);

    // 번역기 불러오기
    const { t } = props;

    /* State 설정 */
    // 상품 배지 문구
    const [badgeText, setBadgeText] = useState([]);
    // 배지 표시 시작일 yyyy-mm-dd
    const [startDate, setStartDate] = useState(null);
    // 배지 표시 종료일 yyyy-mm-dd
    const [endDate, setEndDate] = useState(null);
    // 노출 상품 설정 값 배열
    const [godoGoodsNos, setGodoGoodsNos] = useState([]);
    // 언어 값 조회 응답 여부
    const [isGetlanguages, setIsGetlanguages] = useState(false);
    /* State 설정 끝 */

    /* 필수 항목 체크 */
    const [isBadgeText, setIsBadgeText] = useState(false);
    const [isGodoGoodsNos, setIsGodoGoodsNos] = useState(false);
    const [isViewDate, setIsViewDate] = useState(false);
    /* 필수 항목 체크 */

    // 배너타입, 몰(국가) 설정을 위한 선택지 불러오기, 배너 아이디가 있을 경우 내용 가져오기
    const { data } = useQuery(GET_PRODUCT_BADGE_WRITING_MATERIALS, {
        fetchPolicy: "no-cache",
        variables: { id: docId || 0 }
    });
    const languages = data?.getLanguages;
    const bannerData = data?.getBadge;

    useEffect(() => {
        if (languages) {
            // 언어 값을 받은 후 상품 배지 문구 값 초기 세팅
            const badgeTextArr = [];
            const badgeTextData = bannerData?.texts || [];
            languages.forEach(x => {
                const findData = badgeTextData.find(j => j.lang === x.code);
                badgeTextArr.push({
                    lang: x.code,
                    text: findData?.text || ''
                });
            });

            // 해당 배너 ID에 대한 데이터가 있을 경우 값 세팅
            if (bannerData) {
                setGodoGoodsNos(bannerData.godoGoodsNos);
                setStartDate(bannerData.startDate);
                setEndDate(bannerData.endDate);
            }

            setBadgeText(badgeTextArr);
            // 언어 값을 받은 후 배지 문구 입력을 허용하기 위해 플래그 상태 변경
            setIsGetlanguages(true);
        }
    }, [languages, bannerData]);

    // 생성 쿼리
    const [createProductBadgeMutation] = useMutation(CREATE_PRODUCT_BADGE);
    // 수정 쿼리
    const [updateProductBadgeMutation] = useMutation(UPDATE_PRODUCT_BADGE);
    // 삭제 쿼리
    const [deleteProductBadgeMutation] = useMutation(DELETE_PRODUCT_BADGE);

    // 저장 쿼리 파라미터
    const queryVariables = {
        id: docId || 0,
        texts: badgeText.filter(x => x.text),
        startDate: startDate || new Date(),
        endDate: endDate || new Date("2099-01-01T12:00Z"),
        godoGoodsNos: godoGoodsNos,
    }

    // 저장 버튼 클릭 이벤트
    const onClickSaveButton = async () => {
        const checkBadgeText = badgeText.filter(x => x.text).length === 0;
        const checkGoodsNos = godoGoodsNos.length === 0;
        const checkViewDate = queryVariables.startDate > queryVariables.endDate;
        // 배너 문구가 한개도 입력되지 않은 경우
        setIsBadgeText(checkBadgeText);
        // 배너 문구가 한개도 입력되지 않은 경우
        setIsGodoGoodsNos(checkGoodsNos);
        // 종료일자가 시작일자 보다 이전일 경우
        setIsViewDate(checkViewDate);

        if(! checkBadgeText && ! checkGoodsNos && ! checkViewDate) {
            // 생성 및 저장 쿼리
            let mutation = docId ? updateProductBadgeMutation : createProductBadgeMutation;

            const { data } = await mutation({
                variables: queryVariables
            });
            const isSucceed = data?.updateBadge || data?.createBadge;

            if (isSucceed) {
                showAlertMessage(t('saved'), t('saved'))
                props.history.push("/banner/productBadgePage");
            } else {
                throw new Error("graphql query failed");
            }
        }
    };

    // 상품 배지 삭제하는 함수
    const deleteDoc = async () => {
        if (docId) {
            const { data } = await deleteProductBadgeMutation({
                variables: { id: docId }
            });

            if (data?.deleteBadge) {
                showAlertMessage(t("ProductBadge.delete.title"), t("ProductBadge.delete.content"));
            } else {
                showAlertMessage(t("ProductBadge.delete.title"), t("ProductBadge.deleteFail.content"));
            }

            props.history.goBack();
        }
    };

    // 목록으로 이동
    const onClickListPage = () => {
        props.history.push("/banner/productBadgePage");
    };

    // 버튼 그룹 (미리보기, 저장, 삭제) 
    const btnGroup = (
        <div style={{ 'display': 'flex', 'justify-content': 'space-between', 'margin-top': '30px' }} className="banner-write_buttonGroup">
            <div></div>
            <div>
                <button
                    className="black-button"
                    type="button"
                    onClick={onClickListPage}
                >
                    {t("List.label")}
                </button>
                { ! bannerData?.isAutoCreate &&
                    <button
                        className="black-button"
                        type="button"
                        onClick={onClickSaveButton}
                    >
                        {t("Save.label")}
                    </button>
                }
                {
                    (!!docId) &&
                    <button
                        className="white-button"
                        type="button"
                        onClick={deleteDoc}
                    >
                        {t("Remove.label")}
                    </button>
                }
            </div>
        </div>
    );

    // 뱃지 문구 변경 감지
    const onChangeBadgeText = (e, langInfo) => {
        const replaceBadgeText = badgeText.map(x => {
            if (x.lang === langInfo.code) {
                x.text = e.target.value;
            }
            return x;
        });

        setBadgeText(replaceBadgeText);
    };

    return (
        <>
            <div className={'banner-write product-badge-write'}>
                { /* 버튼 영역 */}
                {btnGroup}

                { /* 배너 등록 */}
                <div className={'fieldContainer'}>
                    <div className={'fieldTitle'}>
                        {t('ProductBadge.write.badgeText')}
                    </div>
                    <div className={'fieldProductBadgeText'}>
                        <table className={'fieldProductBadgeTable'}>
                            <thead>
                                <th></th>
                                {languages?.map((e, i) => {
                                    const langCode = (() => {
                                        return e.code === 'jp' ? 'ja' : e.code === 'cn' ? 'zh' : e.code;
                                    })();

                                    return (
                                        <th key={i}>{langCode.toUpperCase()}</th>
                                    )
                                })}
                            </thead>
                            <tbody>
                                <td>배지 문구</td>
                                {languages?.map((langInfo, i) => {
                                    const rowValue = badgeText.find(x => x.lang === langInfo.code)?.text;
                                    return (
                                        <td key={i}>
                                            <input 
                                                type="text" 
                                                placeholder={'입력하세요'} 
                                                value={rowValue} 
                                                readOnly={!isGetlanguages} 
                                                onChange={(e) => { onChangeBadgeText(e, langInfo) }} 
                                                className={'productBadgeInput'} 
                                            />
                                        </td>
                                    )
                                })}
                            </tbody>
                        </table>
                        { // 문구가 한개라도 입력되지 않을 경우 알림 문구 표시
                          isBadgeText &&
                            <div className={'fieldValidation'}>{t('ProductBadge.write.validation')}</div>
                        }
                    </div>
                </div>

                { /* 미리보기 */}
                <div className={'fieldContainer'}>
                    <div className={'fieldTitle'}>
                        {t('Banner.writer.preview.title')}
                        <div className={'fieldSubTitle'}>{t('Banner.writer.preview.subTitle')}</div>
                    </div>
                    <div className={'fieldBadgePreview'}>
                        {(badgeText || []).map(x => (
                            <div className={'previewProductCard'}>
                                { /* 문구가 있는 배지만 미리보기에 표시합니다. */ }
                                { x.text &&
                                    <div className={'previewProductCardBadge'}>{x.text}</div>
                                }
                            </div>
                        ))}
                    </div>
                </div>

                { /* 노출 기간 */}
                <div className={'fieldContainer'}>
                    <div className={'fieldTitle'}>
                        {t('Banner.writer.date')}
                    </div>
                    <DateSetting
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />
                    { // 종료일자가 시작일자보다 이전일 경우 문구 표시
                        isViewDate && 
                        <div className={'fieldValidation'}>{t('ProductBadge.write.validationDate')}</div>
                    }
                </div>

                { /* 노출 상품 설정 */ }
                <div className={'fieldContainer'}>
                    <div className={'fieldTitle'}>
                        {t('Banner.writer.viewProduct')}
                    </div>
                    <CheckProduct 
                        onChangeValue={setGodoGoodsNos}
                        value={godoGoodsNos}
                        lang={'ko'}
                    />
                    { // 상품 번호가 한개라도 입력되지 않을 경우 알림 문구 표시
                      isGodoGoodsNos &&
                        <div className={'fieldValidation'}>{t('ProductBadge.write.validation')}</div>
                    }
                </div>
            </div>
        </>
    );
});