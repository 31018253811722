import React from "react";
import { Button, Link, Stack, Typography } from "@mui/material";
import UploadField from "./UploadField";
import { useRecoilState } from "recoil";
import { idCardState, limitedTaxAplState } from "user/User.atom";
import AttachFileIcon from "assets/icon_attach_file";

export default function PaymentDocuments() {
  const [limitedTaxApl, setLimitedTaxApl] = useRecoilState(limitedTaxAplState);
  const [idCard, setIdCard] = useRecoilState(idCardState);

  const handleLimitedTaxUploadFile = (fileName: string, key: string) => {
    setLimitedTaxApl({
      key: key,
      name: fileName,
      size: null,
    });
  };

  const handleRemoveLimitedTaxUploadFile = () => {
    setLimitedTaxApl(null);
  };

  const handleIdCardUploadFile = (fileName: string, key: string) => {
    setIdCard({
      key: key,
      name: fileName,
      size: null,
    });
  };

  const handleRemoveIdCardUploadFile = () => {
    setIdCard(null);
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <Typography variant={"body2"} color={"grey.600"}>
        Payment Documents
      </Typography>

      <Typography
        fontSize={12}
        lineHeight={"18px"}
        color={"grey.600"}
        className={"ml-0.5"}
      >
        1. Please attach a signed copy of your
        <Link
          href={
            "https://acon3d.notion.site/ACON-FWT-Form-Guide-0fffee8b20274b19a99ace79fee01122"
          }
          target="_blank"
          underline={"always"}
          color={"grey.600"}
        >
          <Typography
            className={"ml-1"}
            fontSize={12}
            fontWeight={700}
            lineHeight={"18px"}
            color={"grey.600"}
            display={"inline"}
          >
            application of limited tax rate
          </Typography>
        </Link>
      </Typography>

      {!limitedTaxApl && <UploadField onChange={handleLimitedTaxUploadFile} />}

      {limitedTaxApl && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <AttachFileIcon />
            <Typography fontSize={14} lineHeight={"22px"}>
              {limitedTaxApl.name}
            </Typography>
          </Stack>

          <Button
            variant={"outlined"}
            onClick={handleRemoveLimitedTaxUploadFile}
          >
            Delete
          </Button>
        </Stack>
      )}

      <Typography
        fontSize={12}
        lineHeight={"18px"}
        color={"grey.600"}
        className={"ml-0.5"}
      >
        2. Please attach a copy of your ID, passport or TIN document to receive
        payments. Your full name and ID number must be included.
      </Typography>

      {!idCard && <UploadField onChange={handleIdCardUploadFile} />}

      {idCard && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <AttachFileIcon />
            <Typography fontSize={14} lineHeight={"22px"}>
              {idCard.name}
            </Typography>
          </Stack>

          <Button variant={"outlined"} onClick={handleRemoveIdCardUploadFile}>
            Delete
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
