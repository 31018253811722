import React from 'react';
import styled from 'styled-components';
import { Modal as _Modal } from 'react-bootstrap';
import { BANK_LIST } from '../constants';
import { Flex, FlexItem, SectionTitle } from '../style';

const Modal = styled(_Modal)`
    .modal-dialog {
        max-width: 545px;
    }

    .modal-content {
        padding: 50px;
    }

    ${Flex} {
        justify-content: space-between;
    }

    ${FlexItem} {
        flex: none;
        width: 135px;
        height: 70px;
        margin: 10px 0;
        line-height: 70px;
        background: #EEE;
        box-sizing: border-box;
        border-radius: 3px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;

        &.selected {
            border: 2px solid #FF007B;
            line-height: 66px;
        }
    }

    ${SectionTitle} {
        font-weight: normal;
        margin-bottom: 17px;
    }
`;

export default React.memo(function ({ onHide, isShow, onSelect, selected, bankList }) {
    const rowNum = Math.ceil(bankList.length / 3);
    const lastRowLength = bankList.length % 3;

    return (
        <Modal 
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
            show={isShow} 
            onHide={onHide}
        >
            <SectionTitle>은행을 선택하세요</SectionTitle>
            {Array.from(Array(rowNum), (v, y) => {
                return <Flex key={"bankRow" + y}>
                    {Array.from(Array(y === rowNum - 1 ? lastRowLength : 3), (v, x) => {
                        const bank = bankList[y * 3 + x];
                        const { id, name } = bank;
                        const isSelected = name === selected;

                        return <FlexItem key={name} data-value={id} className={isSelected && 'selected'} onClick={onSelect}>{name}</FlexItem>
                    })}
                </Flex>
            })}
        </Modal>
    )
})