import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { downloadFile } from "api";
import * as Styled from "../../style";
import { GET_USER_SETTLE } from "api/quries";
import downloadIcon from "../../assets/download_icon.svg";
import countryData from "common/country/country.json";
import { downloadImageFile } from "../../util";
import deleteIcon from "../../assets/delete_icon.svg";

const Component = (props) => {
  const { data, id, onOpenModal } = props;
  // 사업자등록증 사본
  const [idCardFile, setIdCardFile] = useState();
  // 제한세율적용신청서
  const [limitedTaxApl, setLimitedTaxApl] = useState();
  // 정산 정보 수정 여부
  const isModifyPayment = data.user.accept?.isModifyPayment || false;
  // 정산 정보
  const [getUserSettle, { data: settleData, loading, error }] =
    useLazyQuery(GET_USER_SETTLE);
  const [password, setPassword] = useState("");
  const settleUserData = !loading && !error && settleData && settleData.user;

  const signIn = () => {
    getUserSettle({ variables: { id, password } });
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      onOpenModal("관리자 비밀번호가 맞지 않습니다", false);
      return;
    }

    if (settleData) {
      props.onChangeData("data.user.settle", {
        ...data.user.settle,
        ...settleData.user.settle,
      });

      setIdCardFile(settleData?.user.settle.idCard);
      setLimitedTaxApl(settleData?.user.settle.limitedTaxApl);
    }
  }, [settleData, loading, error]);

  // 페이팔 계정 변경 이벤트
  const onChangePaypalAccount = (value) => {
    props.onChangeData("data.user.settle.bankAccountNumber", value);
  };

  const convertFileSize = (size) => {
    return size ? (size / 1024 / 1024).toFixed(2) : null;
  };

  // 신분증 사본
  const onChangeIdCard = async (e) => {
    const file = e.target.files[0];
    const result = {
      id: null,
      path: file.name,
      fileName: file.name,
      size: file.size,
      file,
    };

    setIdCardFile(result);
    props.onChangeData("data.file.idCard", result);
  };

  // 신분증 이미지 제거
  const onDeleteIdCard = () => {
    const fileData = data.file;

    if (fileData?.idCard) {
      delete fileData.idCard;
      props.onChangeData("data.file", fileData);
    }
    setIdCardFile({});
  };

  // 제한 세율 적용 신청서 변경 이벤트
  const onChangeLimitedTaxApl = (e) => {
    const file = e.target.files[0];
    const result = {
      id: null,
      path: file.name,
      fileName: file.name,
      size: file.size,
      file,
    };

    setLimitedTaxApl(result);
    props.onChangeData("data.file.limitedTaxApl", result);
  };

  // 제한 세율 적용 신청서 이미지 제거
  const onDeleteLimitedTaxApl = () => {
    const fileData = data.file;

    if (fileData?.limitedTaxApl) {
      delete fileData.limitedTaxApl;
      props.onChangeData("data.file", fileData);
    }
    setLimitedTaxApl({});
  };

  // 정산 문서 컴포넌트
  const FileItemComponent = (props) => {
    const { file, onClickDelete } = props;

    return (
      <Styled.FileItem>
        {file?.path ? (
          <>
            <div>{file.path}</div>
            <div>
              <span>{convertFileSize(file?.size)} MB</span>
              <span>
                <Styled.FileIcon
                  src={downloadIcon}
                  onClick={() => {
                    file?.file
                      ? downloadImageFile(file?.file)
                      : downloadFile(file?.id, file?.path);
                  }}
                />
              </span>
              <span>
                <Styled.FileIcon src={deleteIcon} onClick={onClickDelete} />
              </span>
            </div>
          </>
        ) : (
          <Styled.FileNotFound>No Files</Styled.FileNotFound>
        )}
      </Styled.FileItem>
    );
  };

  return (
    <section>
      {/* 제목 */}
      <Styled.TitleBar>
        <Styled.Title isNotice={isModifyPayment}>정산 정보</Styled.Title>
      </Styled.TitleBar>

      {/* 영역 */}
      <Styled.Contents>
        {!settleUserData ? (
          <Styled.Field label="비밀번호 인증">
            <Styled.FlexWrap>
              <Styled.FlexItem>
                <Styled.Input
                  type="password"
                  placeholder="비밀번호를 입력 후 확인 가능합니다."
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Styled.FlexItem>
              <Styled.CustomButton onClick={signIn}>인증</Styled.CustomButton>
            </Styled.FlexWrap>
          </Styled.Field>
        ) : (
          <>
            {/* 이름 */}
            <Styled.InputField
              label={"이름"}
              defaultValue={data.user.name}
              disabled={true}
            />

            <Styled.InputField
              label={"국가"}
              defaultValue={
                countryData.find((x) => x.code === data.user.settle?.country)
                  ?.name
              }
              disabled={true}
            />

            <Styled.InputField
              label={"페이팔 계정"}
              data-key="settle.bankAccountNumber"
              defaultValue={data.user.settle?.bankAccountNumber}
              onChange={(e) => {
                onChangePaypalAccount(e.target.value);
              }}
              disabled={false}
            />

            <Styled.Field label={"해외원천세 제한세율 신청서"}>
              <div>
                <Styled.FileListInputComponent
                  value={limitedTaxApl?.path}
                  onChange={onChangeLimitedTaxApl}
                  isGlobal={true}
                />

                {/* 해외원천세 제한세율 신청서 - 파일 정보 */}
                <FileItemComponent
                  file={limitedTaxApl}
                  onClickDelete={onDeleteLimitedTaxApl}
                />
              </div>
            </Styled.Field>
            <Styled.Field label={"신분 증빙 서류"}>
              <div>
                <Styled.FileListInputComponent
                  value={idCardFile?.path}
                  onChange={onChangeIdCard}
                  isGlobal={true}
                />

                {/* 신분 증빙 서류 - 파일 정보 */}
                <FileItemComponent
                  file={idCardFile}
                  onClickDelete={onDeleteIdCard}
                />
              </div>
            </Styled.Field>
          </>
        )}
      </Styled.Contents>
    </section>
  );
};

export default Component;
