import React, { useState, useRef, useEffect } from 'react';
import { Item, Value } from '../../style';
import { useTranslation } from 'react-i18next';
import { Message } from './style';
import DetailImage from './detail';
import { useRecoilState } from 'recoil';
import { mainImageSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import palette from 'theme/palette';

export default ({ isChecked }) => {
  const { t } = useTranslation();
  const [mainImage, setMainImage] = useRecoilState(mainImageSelector);

  // 메세지
  const [message, setMessage] = useState({
    type: false,
    txt: '',
  });
  // 이미지 유형, true 인 경우 상세페이지 / false 인 경우 썸네일
  const [imageType, setImageType] = useState(true);

  // 이미지
  const [image, setImage] = useState('');

  // 상세페이지 크로퍼
  const mainCropperTag = useRef(null);

  useEffect(() => {
    if (!mainImage.isComplete && mainImage.cropInfo) setMainImage({ isComplete: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainImage.cropInfo]);

  // 메세지 표시하기 메소드
  const showMessage = (txt, type) => {
    // type이 정의되지 않았을 경우
    if (type === undefined) type = false;

    // 메세지 표시
    setMessage({
      type,
      txt,
    });

    setTimeout(() => {
      // 2초 뒤 메세지 초기화
      setMessage({
        ...message,
        txt: '',
      });
    }, 2000);
  };

  const setCompleteCallback = () => {
    // 이미지 타입이 썸네일 이미지이고, 메인 이미지가 완료되지 않은경우
    if (!imageType && !mainImage.isComplete) setImageType(true);
  };
  const setCallback = () => {
    mainCropperTag && mainCropperTag.current && mainCropperTag.current.cropper && mainCropperTag.current.cropper.enable && mainCropperTag.current.cropper.enable();
  };
  const setFileCallback = () => {
    mainCropperTag && mainCropperTag.current && mainCropperTag.current.cropper && mainCropperTag.current.cropper.enable && mainCropperTag.current.cropper.enable();
    setMainImage({
      // 크롭된 메인 이미지 블롭 초기화
      imageBlob: null,
      // 크롭된 메인 이미지 데이터 url 초기화
      croppedImageDataUrl: '',
      // 메인 이미지 크롭정보 초기화
      cropInfo: null,
    });

    // 디테일 완료 취소
    setMainImage({ isComplete: false });
  };

  return (
    <>
      <Box mb={1.5} display="flex" color="#000000DE">
        <Typography fontWeight="500" fontSize="14px" lineHeight="22px" letterSpacing="0.1px">
          {t('representativeImage')}
        </Typography>
        <Typography ml={1} padding="3px 10px" bgcolor="#00000014" borderRadius="99px" color="#000000DE" fontSize="13px" lineHeight="18px" letterSpacing="0.16px">
          {t('document.image.mainImageBallonDescription')}
        </Typography>
      </Box>
      <Item>
        {
          <Value>
            {message.txt && <Message type={message.type}>{message.txt}</Message>}
            {isChecked ? (
              <DetailImage
                setCallback={setCallback}
                setCroppedImageBlob={(value) => {
                  setMainImage({
                    imageBlob: value,
                  });
                }}
                setCroppedImageDataUrl={(value) => {
                  setMainImage({
                    croppedImageDataUrl: value,
                  });
                }}
                cropperTag={mainCropperTag}
                disabled={false}
                image={image}
                setImage={setImage}
                isComplete={mainImage?.isComplete || false}
                setComplete={(val) => setMainImage({ isComplete: val })}
                isShow={imageType}
                setCompleteCallback={setCompleteCallback}
                setFileCallback={setFileCallback}
                message={message}
                showMessage={showMessage}
                onDeleteImage={() => {
                  setImage('');
                  setMainImage({
                    path: '',
                    name: '',
                    cropInfo: null,
                  });
                }}
              />
            ) : (
              <Box
                component="img"
                alt={mainImage.name || '메인 이미지'}
                src={mainImage.croppedImageDataUrl || mainImage.path}
                width="100%"
                height="auto"
                border={`1px solid ${palette.dark.divider}`}
              />
            )}
          </Value>
        }
      </Item>
    </>
  );
};
