import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { uploadFile } from "api/index";

type Props = {
  onChange: (fileName: string, key: string) => void;
};

export default function UploadField({ onChange }: Props) {
  const { t } = useTranslation();
  const fileRef = useRef(null);
  const handleChange = async (e) => {
    const file = e.target.files[0];
    const fileResult = await uploadFile(file);
    onChange(file.name, fileResult.key);
  };

  const handleUploadButtonClick = () => {
    fileRef.current.click();
  };

  return (
    <Box>
      <input ref={fileRef} hidden type="file" onChange={handleChange} />
      <Button
        variant="outlined"
        size={"medium"}
        onClick={handleUploadButtonClick}
      >
        {t("user.settle.modify.upload.button")}
      </Button>
    </Box>
  );
}
