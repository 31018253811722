import {useCreateUserMutation} from "generated/graphql";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {signinState, signState} from "./SignUp.atoms";
import {createContract} from "api/contract";
import {UserTypeEnum} from "./type";

export default function useTerm() {
  const { i18n } = useTranslation();
  const [createUser] = useCreateUserMutation();
  const sign = useRecoilValue(signState);
  const state = useRecoilValue(signinState);

  const completeCreateUser = async (signatureAzureStoragePath: string) => {
    const contractAzurePath = await createContract({
      signatureAzureStoragePath,
      locale: state.signLang,
      userName: state.userType === UserTypeEnum.Personal ? state.name : state.companyName,
      userContact: state.contact,
      userPin: state.rrn,
    });

    // const signedTermKey = await uploadSignedTerm(dataUrl);

    await createUser({
      variables: {
        user: {
          type: state.userType,
          subType: state.businessType ? state.businessType : "",
          joinLanguage: i18n.language,
          account: state.userEmail,
          companyName: state.userType === "0" ? null : state.companyName,
          password: state.password,
          name: state.name,
          contact: state.contact,
          contactAdditional: state.contactAdditional,
          settle: {
            bank: 0,
            nationalIdNumber: state.rrn,
            country: i18n.language === "ko" ? "KR" : state.country,
            signLang: state.signLang,
            bankAccountNumber: "",
            bankName: "",
            bankAccountOwner: "",
          },
          reference: state.documents,
          snsUrl: i18n.language === "ko" ? null : state.snsUrl,
        },
        brand: {
          koName: state.brandNames.koBrandName,
          enName: state.brandNames.enBrandName,
          cnName: state.brandNames.zhBrandName,
          jpName: state.brandNames.jaBrandName,
        },
        file: {
          idCard: i18n.language === "ko" ? state.idCard : null,
          bankBook: i18n.language === "ko" ? state.bankBook : null,
          sign: {
            key: signatureAzureStoragePath ?? sign,
            name: "sign",
          },
          signedTerm: {
            key: contractAzurePath,
            name: contractAzurePath.split("/").pop(),
          },
        },
      },
    });
  };

  return { completeCreateUser };
}
