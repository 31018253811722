import React from 'react';
import { Masking } from 'user/util';
import { InputField } from '../common/input';

const Component = (props) => {
    const { data: { user }, isAccept } = props;

    console.log(isAccept);

    return (
        <>
            <InputField readOnly label="사업자 유형" value={user.subTypeString} />
            <InputField readOnly label="상호" value={user.companyName} />
            <InputField readOnly label="대표자 이름" value={user.name} />
            <InputField readOnly label="사업자등록번호" value={Masking(user.settle.nationalIdNumber)} />
            { !isAccept &&
                <InputField readOnly label="사업자등록증 사본" value={user.settle.idCard?.path || ''} />
            }
            <InputField readOnly label="은행" value={user.settle.bank.name} />
            <InputField readOnly label="예금주" value={user.settle.bankAccountOwner} />
            <InputField readOnly label="계좌번호" value={Masking(user.settle.bankAccountNumber)} />
            { !isAccept &&
                <InputField readOnly label="통장사본" value={user.settle.bankBook?.path || ''} />
            }
        </>
    );
};

export default Component;