import React, { useMemo, useState } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Field, InformationCard, Title } from 'acon-mui/components/Board';
import { Container } from 'acon-mui/components';
import MainImage from './mainImage';
import SubImage from './subImage';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { displayState, mainImageState, originalDocumentData, selectedFieldsState, statusState, subImageState } from '../recoil';
import { STATUS_DRAFT_ON_OPEN } from '../constants';

export default function Image() {
  const { t } = useTranslation();
  const display = useRecoilValue(displayState);
  const originalData = useRecoilValue(originalDocumentData);
  const [selectedFields, setSelectedFields] = useRecoilState(selectedFieldsState);
  const status = useRecoilValue(statusState);
  const setMainImage = useSetRecoilState(mainImageState);
  const setSubImage = useSetRecoilState(subImageState);
  const [isChecked, setIsChecked] = useState(false);

  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || display.isDisp, [isCheckboxVisible, display.isDisp, isChecked]);

  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setMainImage(originalData?.mainImage);
      setSubImage(originalData?.subImage);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };
  return (
    <Field
      className="hubWrite_field_image"
      informationCards={
        <>
          <InformationCard>{t('document.image.information')}</InformationCard>

          <InformationCard type="notice" marginTop="20px">
            {parse(t('document.image.notice'))}
          </InformationCard>
        </>
      }
    >
      <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('thumbnail'))}>
        {t('thumbnail')}
      </Title>
      <Container marginTop="12px" {...(disabled && { pointerEvents: 'none' })}>
        {/* 메인 이미지 */}
        <MainImage isChecked={!isCheckboxVisible || isChecked} />
        {/* 서브 이미지 */}
        <SubImage isChecked={!isCheckboxVisible || isChecked} />
      </Container>
    </Field>
  );
}
