import React from "react";
import { Box } from "@mui/material";
import ScrollWrapper from "./ScrollWrapper";

type Props = {
  height: number;
  text: string;
};

export default function ScrollBox({ text, height }: Props) {
  return (
    <Box
      p={1.5}
      height={height}
      overflow={"auto"}
      border={`1px solid rgba(145, 158, 171, 0.32)`}
      borderRadius={"8px"}
    >
      <ScrollWrapper trigger={text.length}>
        <pre
          style={{
            color: "#000",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          {text}
        </pre>
      </ScrollWrapper>
    </Box>
  );
}
