import React, { useState, useContext, useEffect } from 'react';
import { Flex, FlexBox } from 'components/style';
import { WhiteButton } from 'components/button';
import { useTranslation } from 'react-i18next';
import { GET_MAINGROUP_ALL } from 'api/quries';
import { useMutation, useQuery } from '@apollo/client';
import { AppContext } from 'app';
import SelectView from './selected';
import { List, ListItem, Input } from './style';
import { SYNC_MAINGROUP, SET_MAINGROUP_GOODS } from 'api/mutations';
import Excel from 'exceljs';

export default (() => {
    // 모달 표시 메소드 불러오기
    const { showAlertMessage } = useContext(AppContext);
    const { t } = useTranslation();

    // 선택된 항목 
    const [selectedItem, setSelectedItem] = useState('');
    // 검색 키워드 
    const [searchKeyword, setSearchKeyword] = useState('');

    // 메인상품진열 정보들 
    const { data, loading, refetch } = useQuery(GET_MAINGROUP_ALL);
    // 메인상품진열 동기화하기 액션 
    const [syncMainGroup] = useMutation(SYNC_MAINGROUP);
    // 메인상품 진열하기 액션
    const [setMainGroupGoods] = useMutation(SET_MAINGROUP_GOODS);

    const initSelectItem = () => {
        setSelectedItem('');
    };
    // 검색어 변경 이벤트 처리기 메소드 
    const onChangeSearchKeyword = e => {
        // 검색어 변경 
        setSearchKeyword(e.target.value);
    };

    // 동기화 클릭 이벤트 처리기 메소드 
    const onClickSync = async () => {
        // 메인상품진열 동기화
        await syncMainGroup();
        // 선택항목 초기화 
        initSelectItem();
        // 재확보 
        refetch();
        showAlertMessage(t('syncd'), t('syncd'));

    };
    // 엑셀 데이터 처리 메소드
    async function excelToArray(file) {
        try {
            const isEmptyOrNonNumber = (value) => {
                if (!value) return true;
                if (typeof value !== 'number') return true;
                return false;
            };
            const rows = [];

            const workbook = new Excel.Workbook();
            const data = await workbook.xlsx.load(file);
            data.worksheets[0].eachRow((row, rowIndex) => {
                // 왜 row.values가 0부터 시작이 아닌가요? (https://github.com/exceljs/exceljs/issues/698)
                rows.push({
                    sno: row.values[1],
                    goodsNo: row.values[2]
                });
            });
            // 첫 row는 제목 열이므로 삭제
            rows.shift();
            // sno 별로 goodsNo 취합
            const snoArr = rows.reduce((acc, row) => {
                const sno = row.sno;
                const goodsNo = row.goodsNo;
                const snoRow = acc.find(item => item.sno === sno);

                if (isEmptyOrNonNumber(sno) || isEmptyOrNonNumber(goodsNo)) {
                    return acc;
                }
                if (snoRow) {
                    snoRow.goodsNo.push(goodsNo);
                } else {
                    acc.push({
                        sno: sno,
                        goodsNo: [goodsNo]
                    });
                }

                return acc;
            }, []);
            
            return snoArr;
        } catch (ex) {
            console.error(ex);
            throw ex;
        }
    }
    // 파일 이벤트 처리기 메소드
    async function onChangeFileInput(e) {
        try {
            const file = e.target.files[0];
            switch (file.type) {
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                case 'application/vnd.ms-excel':
                    break;
                default:
                    showAlertMessage(t('alert'), t('isNotExcelFile'));
                    return;
            }
            if (file.size > 1048576) {
                showAlertMessage(t('alert'), t('fileUpTo1MB'));
                return;
            }

            // sno 별로 나열된 goodsNo 데이터
            const snoArr = await excelToArray(file);
            // 메인그룹에 상품 업로드
            const { data, errors } = await setMainGroupGoods({
                variables: {
                    data: snoArr
                }
            });
            // 완료 메시지
            if (!errors) {
                showAlertMessage(t('alert'), t('setGoodsSuccess'));
            }
        } catch (errObj) {
            const errorMessage = errObj.message;
            showAlertMessage(t('alert'), errorMessage);
        }
    }
    // 상품 엑셀 업로드 이벤트 처리기 메소드
    const onClickExcelUpload = () => {
        try {
            // 파일 input 생성 후 열기
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = '.xls, .xlsx';
            fileInput.addEventListener('change', onChangeFileInput, false);

            fileInput.click();
        } catch (ex) {
            throw ex;
        }
    };

    return (
        <Flex direction="column" justify-content="space-between">
            <FlexBox width="50" bottom="10">
                <WhiteButton onClick={onClickSync}>{t('sync')}</WhiteButton>
                <WhiteButton onClick={onClickExcelUpload}>
                    <img src="/assets/icon/excel.svg" /> &nbsp;
                    {t('batchUpload')}
                </WhiteButton>
            </FlexBox>
            <Flex>
                <Flex width="100" direction="column">
                    <Input placeholder={t('inputSearchKeyword')} onChange={onChangeSearchKeyword} />
                    <List>
                        {
                            !loading && data && data.getMainGroups.filter(x => x.name.includes(searchKeyword.trim())).map(x => {
                                return (
                                    <ListItem active={selectedItem.id === x.id} onClick={() => { setSelectedItem({ ...x }) }}>
                                        {x.name}
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Flex>
                <Flex width="100">
                    {selectedItem && <SelectView
                        refetch={refetch}
                        itemObj={selectedItem}
                        initSelectItem={initSelectItem}
                    />}
                </Flex>
            </Flex>
        </Flex>
    );
});