import { MenuItem, Select, Stack, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { countryState } from "./atom";
import countryData from "../../signup/assets/iso_3166";

export default function CountryField() {
  const [country, setCountry] = useRecoilState(countryState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(e.target.value);
  };

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Country of Residence</Typography>
      <Select
        size={"small"}
        fullWidth
        value={country}
        onChange={handleChange}
        sx={{ maxWidth: "230px" }}
      >
        {countryData.map((x, i) => (
          <MenuItem key={`country_${i}`} value={x.Code}>
            {x.Name}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
