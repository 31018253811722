import { ChildToBlock } from 'common/style';
import React, { useEffect, useState } from 'react';
import { Flex, FlexItem } from 'signup/style';
import styled from 'styled-components';
import { Field } from './field';

export const Label = styled.label`
    > * {
        pointer-events: none;
    }
`;

export const InputComponent = (props) => {
    let attrs = {...props};

    delete attrs.number;

    function onInput(e) {
        if (props.number) {
            let value = e.target.value;

            if (isNaN(+value)) {
                value = value.replaceAll(/[^0-9\-]/g, '');
            }

            value = parseInt(value || 0);


            if (props.max && value > props.max) {
                value = props.max;
            }

            if (props.min && value < props.min) {
                value = props.min;
            }

            e.target.value = value;
        }
    };

    return <input {...attrs} onInput={onInput} />
}

export const MaskingInputComponent = (props) => {
    const [ value, setValue ] = useState([]);

    const onChangeItem = (e) => {
        const target = e.target;
        const newValue = [...value];

        newValue[+target.dataset.idx] = target.value;

        setValue(newValue);

        props.onChange(e, newValue.join(props.split));
    };

    useEffect(() => {
        const value = props.defaultValue || props.value || '';

        setValue(value.split(props.split));
    }, []);

    return (
        <div className={props.className}>
            { value.map((item, idx) => {
                const attrs = {};

                if (idx === (+props.masking)) {
                    attrs.type = "password";
                }

                return <React.Fragment key={idx}>
                    {idx > 0 &&
                        <span>{props.split}</span>
                    }
                    <input 
                        {...attrs}
                        data-idx={idx}
                        maxLength={props.maxLength[idx]}
                        onChange={onChangeItem}
                        value={item}
                    />
                </React.Fragment>
            })}
        </div>
    );
}

export const MaskingInput = styled(MaskingInputComponent)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 2px;
    line-height: 40px;
    border-radius: 4px;
    border: 1px solid #CDCDCD;

    span {
        padding: 0 15px;
        font-size: 1.2em;
        font-weight: bold;
    }

    input {
        all: unset;
        flex: 1;
    }
`;

export const MaskingInputField = (props) => {
    return (
        <Field label={props.label}>
            <MaskingInput {...props} />
        </Field>
    )
};

export const Input = styled(InputComponent)`
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    border: 1px solid #CDCDCD;

    &[disabled] {
        pointer-events: none;
    }

    &[hidden] {
        display: none;
    }

    &[readonly] {
        color: #7C7C7C;
    }
`;

export const InputField = (props) => {
    return (
        <Field label={props.label}>
            <Input {...props}/>
        </Field>
    )
}

export const CustomButton = styled.button`
    margin-left: 12px;
    padding: 9px 13px;
    border: 0;
    border-radius: 4px;
    background: #333;
    color: white;
    font-size: 14px;
`;

export const ResetButton = styled.button`
    width: 100px;
    height: 40px;

    position: absolute;
    bottom: 16px;
    right: 16px;
    
    background: #FFFFFF;
    border: 1px solid #AAAAAA;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 1;
    font-size: 14px;

    color: #333333;
    font-weight: 500;
`;

export const FileInputComponent = (props) => {
    const id = Math.random().toString(36).substr(2,11);

    return (
        <Flex>
            <FlexItem>
                <Input hidden type="file" id={id} onChange={props.onChange} />
                <ChildToBlock>
                    <Label htmlFor={id}>
                        <Input disabled value={props.value} />
                    </Label>
                </ChildToBlock>
            </FlexItem>
            <Label htmlFor={id}>
                <CustomButton>업로드</CustomButton>
            </Label>
        </Flex>
    )
}