import { 
    Quill
} from '../../common/editor/constants';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { 
    EditorContents, 
    EditorToolBar, 
    EditorCustomTool, 
    BackgroundColor, 
    CloseButtonColor,
    CusotmToolText,
    CusotmToolItem,
    HexColorInputBox
} from './style';

export default ((props) => {
    const { t } = useTranslation();
    const [quill, setQuill] = useState(null);
    // 텍스트 색
    const [textColor, setTextColor] = useState('#ffffff');
    // Hex 선택 박스
    const HexColorBox = useRef(null);

    const preventDefault = (e) => e?.dataTransfer.files.length && e.preventDefault();

    const onPaste = (e) => {
        const clipboardData = ((e.originalEvent || e).clipboardData || window.clipboardData);
        const clipboardText = (clipboardData?.getData('text/html') || clipboardData?.getData('text/plain'))?.trim();

        if (!clipboardText) return false;

        const tempDom = document.createElement('div');
        tempDom.innerHTML = clipboardText;
    };

    const onChange = () => {
        if (props.onChange) {
            props.onChange(quill.root.innerHTML);

            // 텍스트가 없을 경우 흰색 으로 지정
            if(quill.getContents().ops[0].insert.trim() == "") {
                quill.format('color', '#ffffff');
            }
        }
    };

    // componentDidMount
    useEffect(() => {
        const wrap = document.getElementById('Editor-content');
        setQuill(new Quill(wrap, {
            theme: 'snow',
            modules: {
                toolbar: {
                    container: '#Editor-toolbar',
                    handlers: {
                        header(val) {
                            const { index } = this.quill.getSelection(true);
                            const { baseNode, baseOffset } = window.getSelection();
                            const { length } = baseNode;
                            const startOffset = index - baseOffset;
                            
                            this.quill.format('header', val);
                            this.quill.formatText(startOffset, length, 'bold', true);
                        }
                    }
                },
                keyboard: {
                    bindings: {
                        'list autofill': {
                            shiftKey: true
                        }
                    }
                }
            },
            placeholder: props.placeholder
        }));
    }, []);

    // quill initialization
    useEffect(() => {
        if (!quill) return;
        const vdom = document.createElement('div');
        vdom.innerHTML = props.contents;

        const headers = [...vdom.querySelectorAll('span[style]')].filter(span => span.style.fontSize === '18px');
        headers.forEach(header => {
            const h4 = document.createElement('h4');
            h4.innerHTML = header.innerHTML;
            header.parentElement.replaceChild(h4, header);
        });

        const delta = quill.clipboard.convert(vdom.innerHTML);
        quill.setContents(delta);

        quill.history.clear();

        // clear text formatting on paste
        quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            delta.ops = delta.ops.map((op) => {
                op.attributes = { color: '#ffffff' };
                return op;
            });

            return delta;
        });
        // --clear text formatting on paste

        // 기본 텍스트 색 변경
        quill.format('color', '#ffffff');

        quill.on('text-change', onChange);
        quill.root.addEventListener('paste', onPaste);
        onChange();
    }, [quill]);

    // 텍스트 색상 변경
    let textColorTimer = null;
    const onChangeTextColor = (e) => {
        e.persist();
        clearTimeout(textColorTimer);
        let color = e.target.value;
        let type = e.target.type;

        textColorTimer = setTimeout(() => {
            if(type !== 'text' || (color.length >= 7)) {
                textColorTimer = null;
                setTextColor(color);
                quill.format('color', color);
                onChangeTextColorBox(false);
            }
        }, 100);
    };

    // 현재 색상 참고하여 색상표 변경
    const onChangeTextColorBox = (isDefault = true) => {
        isDefault && setTextColor('#ffffff');
    };

    useEffect(() => {
        // document 클릭 이벤트
        document.addEventListener('click', onChangeTextColorBox, true);
        return () => {
            document.removeEventListener('click', onChangeTextColorBox, true);
        };
    });

    return (
        <>
            <div className={`Editor ${props.isDisp ? 'disabled' : ''}`} onDragOver={preventDefault} onDragEnter={preventDefault}>
                <EditorContents id="Editor-content" style={{backgroundColor: props.backgroundColor}} />
                <EditorToolBar className='Editor-toolbar' id="Editor-toolbar">
                    <div className="ql-formats" style={{width: '55%'}}>
                        <button className="ql-bold"></button>
                        <button className="ql-italic"></button>
                        <button className="ql-underline"></button>
                        <div style={{display: 'flex'}}>
                            <p style={{marginRight: '5px'}}>색상</p>
                            <input type="color" value={textColor} onChange={onChangeTextColor} />
                            <HexColorInputBox placeholder={'#ffffff'} ref={HexColorBox} maxLength={7} onClick={(e) => { e.preventDefault(); }} onInput={onChangeTextColor} />
                        </div>
                    </div>
                    <EditorCustomTool>
                        <BackgroundColor>
                            <CusotmToolText>{t('Banner.writer.backgroundColor')}</CusotmToolText>
                            <CusotmToolItem>
                                <input type="color" name="background" value={props.backgroundColor} onChange={props.onChangeColor} />
                            </CusotmToolItem>
                        </BackgroundColor>
                        <CloseButtonColor>
                            <CusotmToolText>{t('Banner.writer.closeButtonColor')}</CusotmToolText>
                            <CusotmToolItem>
                                <input type="color" name="close" value={props.closeColor} onChange={props.onChangeColor} />
                            </CusotmToolItem>
                        </CloseButtonColor>
                    </EditorCustomTool>
                </EditorToolBar>
            </div>
        </>
    );
});