import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import UploadField from "./UploadField";
import { bankBookState } from "user/User.atom";
import { useRecoilState } from "recoil";
import AttachFileIcon from "assets/icon_attach_file";

export default function BankBookField() {
  const [bankBook, setBankBook] = useRecoilState(bankBookState);

  const handleUploadFile = (fileName: string, key: string) => {
    setBankBook({
      key: key,
      name: fileName,
      size: null,
    });
  };

  const handleRemoveUploadFile = () => {
    setBankBook(null);
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <Typography variant={"body2"} color={"grey.600"}>
        통장사본
      </Typography>

      {!bankBook && (
        <>
          <UploadField onChange={handleUploadFile} />
        </>
      )}

      {bankBook && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <AttachFileIcon />
            <Typography fontSize={14} lineHeight={"22px"}>
              {bankBook.name}
            </Typography>
          </Stack>

          <Button variant={"outlined"} onClick={handleRemoveUploadFile}>
            삭제하기
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
