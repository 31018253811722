export const PLAN_TYPE = ({ type, isExclusive }) => {
  if (type === undefined) {
    return "미선택";
  }
  let before = "";
  let after = "BASIC";

  if (type === 1) after = "PLUS";
  if (type === 2) after = "PRO";
  if (type === 3) {
    if (isExclusive) return "독점";
    return "비독점";
  }

  if (isExclusive) before = "전속 ";

  return before + after;
};

export const strToPlanType = (str) => {
  if (typeof str !== "string") {
    return undefined;
  }

  if (str.includes("BASIC")) {
    return { type: 0, isExclusive: false };
  }

  if (str.includes("PLUS")) {
    return { type: 1, isExclusive: str.includes("전속") };
  }

  if (str.includes("PRO")) {
    return { type: 2, isExclusive: str.includes("전속") };
  }

  return undefined;
};
