import React from "react";
import { TextBox } from "signup/style";
import styled from "styled-components";
import { SectionTitle } from "./styled";

export default styled(Component)`
	${SectionTitle} {
		a {
			color: inherit;

			button {
				width: 210px;
				height: 28px;
				float: right;
				font-size: 14px;
				border: 1px solid #7C7C7C;
				border-radius: 3px;
				background: #FFFFFF;
			}
		}

		&::after {
			content: "";
			display: block;
			clear: both;
		}
	}
`;

function Component({ className, src }) {
	return (
		<section className={className}>
			<SectionTitle>
				에이콘3D 파트너십 계약서
				<a
					href={process.env.STORAGE_ORIGIN + "/term/prevTerm.pdf"}
					target="_blank"
				>
					<button>변경 전 계약서(공란) 확인하기</button>
				</a>
			</SectionTitle>
			<TextBox height="600">
				<img src={src} />
			</TextBox>
		</section>
	);
}
