// 이용약관
const TermsOfUse = `<div class="modalText">
     <pre>에이콘3D 이용약관

에이콘3D(ACON3D)는 3D모델링 및 데이터 아카이빙과 이를 위한 새로운 가치 창출을 돕는 플랫폼입니다. 에이콘3D는 서비스 이용자로 하여금 약관을 충분히 검토 및 숙지 할 수 있도록 하였으며 회원가입은 본 약관에 동의하였음을 의미합니다.


제1조 (목적)

본 약관은 에이콘3D 또는 ACON3D 서비스(이하 "서비스")에 가입한 회원이 회사 주식회사 카펜스트리트 (이하 "회사")가 제공하는 서비스를 이용함에 있어 회원(본 약관에 동의하고 회원등록을 완료한 서비스 이용자를 말합니다. 이하 “회원”이라고 합니다.) 및 회사 간의 제반 권리,의무와 관련 절차 및 책임사항 등을 규정하는데 그 목적이 있습니다.


제2조 (용어의 정의)

이 약관에서 사용하는 용어의 정의는 다음 각 항과 같으며, 정의되지 않은 용어에 대한 해석은 관계법령 및 홈페이지 내 별도 안내에서 정하는 바에 따릅니다.
​
1.에이콘3D 서비스(이하, "서비스") : 구현되는 단말기(PC, 태블릿, 휴대용 단말기 등의 각 종 유무선 장치)와 상관없이 에이콘3D 브랜드명을 가지고 "회사"가 제공하는 제반 서비스를 말합니다.
​2.에이콘3D 회원(이하, "회원") : 본 약관에 동의 후 이용계약을 체결하고, 서비스에 정상적으로 가입되어 "서비스"를 이용하는 고객을 의미합니다.
3.에이콘3D 계정(이하 "ID) : 회원의 식별과 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.
4.포인트 : 본 약관 제 7조의 기준에 부합하여 회원이 회사가 정한 규정에 따라 이벤트 참여 등에 이용이 가능한 포인트를 말하며 에이콘3D 서비스 외에서는 재화로서의 가치는 없습니다.
5.이벤트 : 회사가 제휴를 통하여 회원에게 일정 상품을 내걸고 진행하는 서비스를 의미합니다.
6.메세지 : 회원과 회원이 메시지를 전달하고 공유하는 서비스를 의미합니다.
7.콘텐츠 : 회사 및 회원이 서비스상에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다
​8.프로필 : 모든 회원에게 공개되는 프로필페이지입니다.
​9.게시물 : 회원이 에이콘3D 서비스에 게시 또는 등록하는 부호(URL 포함), 문자, 이미지(사진포함), 파일 등을 의미합니다.
​10.게시자 : 서비스에 게시물을 게시한 자를 의미합니다.
11.에이콘3D 파트너허브: 서비스 중 한 기능으로 판매회원이 사용할 수 있는 기능을 제공합니다.



제3조 (약관의 효력 및 개정)

1. 본 약관은 서비스 화면 게시 혹은 회원의 가입시 동의로 효력이 발생합니다.
​2. 회사는 “전자상거래 등에서의 소비자보호에 관한 법률”, "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률",“온라인 디지털콘텐츠산업 발전법”등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있으며, 개정 된 내용을 회원들에게 공지합니다.
3. 본 약관은 필요 시 개정될 수 있으며 약관을 개정하고자 할 경우, 회사는 개정된 약관을 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 개정약관의 적용일자 14일 이전부터 적용일자 전일까지 공지합니다. 개정약관의 공지는 규정된 방법 중 1가지 이상의 방법으로 회원에게 명확하게 공지하도록 합니다.
　　 1)웹사이트 내 게시
　　 2)E-mail통보
　　 3)SMS 통보
　　 4)회사가 정한 기타 공지 방법 등
​4. 본 조의 규정에 의하여 개정약관은 원칙적으로 그 효력 발생일로부터 장래를 향하여 유효합니다.
​5. 본 약관의 개정과 관련하여 이의가 있는 회원은 회원탈퇴를 할 수 있습니다. 단, 이의가 있음에도 불구하고 본 조 제1항및 제2항에 따른 회사의 공지가 이루어 진 후 7일 이내에 회원탈퇴 및 거부의사를 표명하지 않은 회원은 개정 약관에 동의한 것으로 간주합니다.
​6. 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다.
​7. 본 조의 통지방법 및 통지의 효력은 본 약관의 각 조항에서 규정하는 개별적 또는 전체적인 통지의 경우에 이를 준용합니다.


제4조 (약관의 해석)
1. 회사는 유,무료서비스 및 개별 서비스에 대해서는 별도의 이용약관 및 정책(이하 유료서비스약관 등)을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 유료서비스약관 등이 우선하여 적용됩니다.
2. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 유료서비스약관 등 및 관계법령 또는 상관례에 따릅니다.


제5조 (서비스 이용신청 및 이용계약 성립)
1. 서비스 이용계약은 회원이 본 약관 및 "개인정보취급방침" 등에 대하여 동의하고 회사가 제공하는 가입양식을 작성하여 서비스 이용을 신청한 경우 회사가 이를 승인함으로써 이용계약이 체결됩니다.
​2. 회원은 제1항의 이용 신청 시 반드시 실제정보(본인이 사용중인 유효한 이메일 등)를 기재하여야 하며, 허위정보를 기재한 회원의 경우 서비스 이용이 제한되거나 제5조에 의거 회원과의 서비스 이용계약을 해지할 수 있습니다.
3. 타인의 명의를 도용하는 등의 부정한 목적과 방법으로 이용 신청한 회원의 ID는 사전 통지없이 삭제될 수 있으며, 당해 회원은 서비스 이용금지 및 법적 제재를 받을 수 있습니다.
​4. 제1항에 따른 신청에 있어 회사는 회원의 본인인증을 요청할 수 있습니다.
​5. 회사는 위 각 항의 필수기재항목 외에도 회원에게 다양하고 유익한 정보를 제공하기 위하여 이용신청자에게 개인 작업과 관련 된 그림체, 작업 취향, 작업 방식 등의 정보를 제공할 것을 요청할 수 있습니다. 다만, 이용신청자는 그러한 정보의 제공을 거절할 수 있습니다.
​6. 회사는 회원들에게 원활한 서비스 제공을 위해 회원에게 이메일 및 SMS 등을 통한 광고 및 서비스 관련 각종 정보를 제공할 수 있습니다. 회원이 원치 않는 경우 언제든지 수신거부를 할 수 있습니다.
​7. 회사는 법률에서 정한 특별한 규정이 없는 경우를 제외하고 기본적으로 회원의 별도 동의없이 회원정보를 제3자에게 제공하지 않습니다.
단, 다음 호의 경우에는 예외로 합니다.
　　 1) 법령에 근거하여 회원정보의 이용과 제3자에 대한 정보제공을 허용하고 있는 경우
　　 2) 경품,구매물품 등의 배송업무에 필요한 최소한의 회원정보를 알려 주는 경우
　　 3) 회원에게 다양한 혜택 및 경험을 제공하기 위한 목적으로 회사가 선정한 제3자의 경우
　　 4) 기타 회사의 약관 및 정책에 따라 이용자의 동의를 구한 경우
8. 회사는 서비스 운영 중 회사 정책에 따라 제휴사가 이벤트 개최를 원하는 회원의 범주를 제한할 시 회원은 서비스 이용에 제한이 따를 수 있습니다.
9. 회원은 서비스 이용 중 신청양식에 기재한 회원정보가 변경되었을 경우 즉각 회원정보를 수정하여야 합니다. 회원정보 미 수정에 따라 발생하는 모든 책임은 회원에게 있습니다.
​10. 해당 작업자 또는 이벤트와 관련하여, 해당 업종에 종사 및 서비스 하면서 비정상적으로 이루어지는 지속적인 업로드의 경우, 당 해 회원은 서비스 이용에 제한이 따를 수 있으며 그와 관련한 모든 손실에 대한 책임은 회원에게 있습니다.
​11. 회원은 회원의 ID및 비밀번호를 철저히 관리하여야 합니다. 관리소홀, 부정사용 등으로 인해 발생하는 모든 결과의 책임은 회원 본인이 부담하며, 회사는 이에 대한 어떠한 책임도 지지 않습니다.
​12. 회원은 ID, 비밀번호 및 추가정보 등을 도난 당하거나 제 3자가 사용하고 있음을 인지한 경우에는 즉시 본인의 비밀번호를 수정하는 등의 조치를 취하여야 하며 이를 즉시 회사에 알려 안내를 따라야 합니다


제6조 (회원정보의 변경)
1. 회원은 서비스 내 마이페이지 화면을 통하여 본인의 개인정보를 열람하고 수정할 수 있습니다. 단, 단말기 식별번호, ID 등은 수정이 불가능합니다.
2. 회원은 본인의 개인정보를 허위로 설정하여 부당한 방법으로 이익을 취할 경우, 당사자의 포인트는 소멸되며 서비스 이용이 제한될 수 있습니다.


제7조 (이용계약의 해지 및 자격상실/정지)
1. 회원은 본인 희망 시 계약을 해지하거나 서비스의 이용중지를 요청할 수 있습니다.
​2. 회원이 서비스의 이용중지를 요청 시, 이메일 통보 등 회사가 지정한 절차를 거쳐 신청해야 합니다.
​3. 회사는 다음 각 호에 해당하는 회원이 확인될 경우, 당해 회원에 대한 사전 통보 없이 회원자격 유보/중지/해지/게시물삭제/포인트 및 쿠폰 회수 등의 조치를 취할 수 있으며, 이 경우 회원은 서비스와 관련된 모든 권리를 주장할 수 없습니다.
　　 1)회원가입 신청시에 허위의 내용을 등록한 경우
　　 2)이벤트 부정응모, 포인트 부정취득 등 서비스를 부정한 방법 또는 목적으로 이용한 경우
　　　　 -서면 통보
　　　　 -부정응모란 1인이 다수의 아이디를 생성하여 당첨률을 높이는 등 정상적이지 않은 방법으로 부당하게 이익을 취하는 것을 말합니다.
　　 3)회원이 사망한 경우
　　 4)다른 회원의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
　　 5)서비스 내 다른 회원에 대한 스토킹(stalking)/위협/희롱/욕설/채팅글 도배 등 지속적인 고통 및 불편을 끼치는 경우
　　 6)서비스 내에서 회사의 운영자/임직원 또는 회사를 사칭한 경우
　　 7)회원 가입 신청 시 제3자의 개인정보를 이용 및 불법으로 도용한 경우
　　 8)서비스 이용 중 법률 위반행위 및 사회의 안녕/질서/미풍양속을 해치는 내용의 정보, 문장, 도형, 음성, 사진 등을 유포하는 행위
　　 9)제3자의 특허권, 상표권, 저작권, 영업비밀 등 지적재산권을 포함한 기타 권리를 침해하는 행위를 한 경우
　　 10)회사로부터 특별한 권한을 부여 받지 않고 회사의 홈페이지 나 클라이언트 프로그램을 변경 또는 회사의 서버를 해킹하는 등의 시스템을 위협하는 행위를 한 경우
　　 11)회사의 사전승인 및 다른 회원에 대한 사전 동의없이 Active X, 스파이웨어, 애드웨어 등의 프로그램을 강제로 설치하게 한 경우
　　 12)서비스 방문자나 타 회원의 의사와 무관하게 특정 사이트로 방문을 유도한 경우
　　 13)서비스 이용 중 불법복제 소프트웨어, 제3자의 저작물을 밀매하는 등의 관련 법률에 저촉되는 행위를 한 경우
　　 14)인종/성/광신/이적 등의 반 사회적/도덕적 편견에 기반한 단체를 결성하는 행위를 한 경우
　　 15)다른 회원의 ID/이메일 계정 등의 개인정보를 수집하는 행위
　　 16)범죄와의 결부, 관련 법령 위반 활동으로 판단되는 행위를 한 경우
　　 17)다른 회원으로 하여금 상기 6항 내지 16항의 금지행위를 하도록 유발 및 조장하는 활동을 한 경우
　　 18) 회사가 제공하는 서비스를 이용하여 제3자에게 본인을 홍보할 기회를 제공 하거나 제3자의 홍보를 대행하는 등의 방법으로 금전을 수수하거나 서비스를 이용할 권리를 양도하고 이를 대가로 금전을 수수하는 행위
　　 19)회사의 사전 동의 없이 영리 목적의 광고성 정보를 전송하기 위하여 이용하는 행위
　　 20) 불특정 다수의 회원을 대상으로 하여 광고 또는 선전을 게시하거나 스팸메일을 전송할 목적으로 회사에서 제공하는 서비스를 이용하여 영리활동을 하는 행위
　　 21)기타 본 약관 13조에 규정된 회원의 의무를 위반한 경우


제8조 (회원탈퇴)
1. 본 약관 제7조 제2항에 정해진 방법으로 탈퇴를 하고자 하는 회원은 회원탈퇴 요청 후 회사가 제공하는 절차를 이행하면 탈퇴를 하게 됩니다. 이때 잔여포인트는 자동으로 소멸되고 "회원"가입 시 작성한 기본정보는 영구 소멸됩니다.


제 9조 (서비스의 내용 및 변경)
1. 회사는 서비스 사이트를 통하여 회원에 대한 에이콘3D 관련 제반 서비스 및 정보의 제공, 기타 회사가 정하는 업무를 수행합니다.
2. 회사는 서비스의 내용을 변경하고자 할 경우에는 변경된 서비스의 내용 및 제공일자를 공지합니다. 단, 변동내용을 구체적으로 공지하기가 불가능한 경우에는 7일 전에 그 취지 및 공지가 불가능한 변동사유를 공지합니다.


제10조 (서비스의 일시 중단)
1. 회사는 컴퓨터 등 정보통신설비의 점검,보수 ,교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
​2. 본 조 제1항의 사유로 서비스를 중단하고자 할 경우 회사는 SMS로 발송하거나 서비스 내 공지사항 및 사이트에 게시하는 방법으로 회원에게 서비스 중단사실을 알려드립니다.
​3. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.


제11조 (회원ID 및 비밀번호)
1. 회원ID와 비밀번호에 관한 관리책임은 회원 본인에게 있으며, 회원은 제3자에게 자신의 ID 및 비밀번호를 알려주거나 이용하게 해서는 안 됩니다.
​2. 회원이 자신의 ID 또는 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.


제12조 (회사의 의무)
1. 회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.
​2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.
​3. 회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.


제13조 (회원의 일반의무)
1. 일반회원은 상품을 구매하고 리뷰를 작성하는 등 상품을 판매하지 않는 모든 회원을 의미합니다.
2. 판매회원은 상품을 판매하는 모든 회원을 의미합니다. 판매회원의 경우 수수료, 회사와의 계약기간이 포함 된 별도의 계약서를 작성하게 될 수 있습니다.
3. 일반회원과 판매회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안 됩니다.
　　 1)서비스 이용 관련 제반 신청행위 또는 변경행위 시 허위내용 등록행위
　　 2)서비스 내 게시된 각종 정보의 무단 변경, 삭제 등 훼손 행위
　　 3)다른 회원의 명예를 손상시키거나 개인정보를 수집하는 행위
　　 4)회사의 동의 없이 영리 목적의 광고 정보를 전송하거나 회사가 허용한 정보 이외의 다른 정보(컴퓨터 프로그램 및 광고 등)를 송신하거나 게시하는 행위
　　 5)일체의 가공행위를 통해 서비스를 분해, 모방 또는 변형하는 행위
　　 6)회사 기타 제3자의 명예를 훼손하거나 지적재산권을 침해하는 등 회사나 제3자의 권리를 침해하는 행위
　　 7)스토킹(stalking) 등 다른 회원의 서비스 이용을 방해하는 행위.
　　 8)외설 또는 폭력적인 정보(메시지 ,화상 ,음성 등), 기타 미풍양속에 반하는 정보를 서비스에 공개하거나 게시하는 행위
　　 9)컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편, SMS로 발송하는 행위
　　 10) 정크메일(junk mail), 스팸메일(spam mail), 행운의 편지(chain letters), 피라미드 조직에 가입할 것을 권유하는 메일, 외설 또는 폭력적인 메시지•화상•음성 등이 담긴 메일을 보내거나 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위
　　 11) 청소년보호법에서 규정하는 청소년유해매체물을 게시하는 행위
　　 12) 회사의 서비스에 게시된 정보를 변경하거나 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 영리 또는 비영리의 목적으로 복제, 출판, 방송 등에 사용하거나 제3자에게 제공하는 행위
　　 13) 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 "회사"의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위
4. 일반회원은 서비스 이용과 관련하여 13조 3항 외에도 다음의 각 호의 행위를 하여서는 안 됩니다.
　　 1) 서비스에서 알게 된 판매회원에게 회사와의 협의 없이 개인적으로 연락하거나 서비스를 우회하여 구매 및 거래를 하려는 행위
5. 판매회원은 서비스 이용과 관련하여 13조 3항 외에도 다음의 각 호의 행위를 하여서는 안 됩니다.
　　 1) 서비스에서 알게 된 일반회원에게 회사와의 협의 없이 개인적으로 연락하거나 서비스를 우회하여 판매 및 거래를 하려는 행위
　　 2) 법률적으로 이상이 있는 상품을 판매 혹은 등록하려는 행위
　　 3) 계약기간을 일방적으로 파기하려는 행위
　　 4) 고의 혹은 실수로 회사에 손해를 입히는 행위
6. 회사는 회원이 13조 3항, 4항, 5항의 행위를 하는 경우 서비스의 이용을 제한하거나 계정의 해지, 게시물의 삭제 등 일방적으로 본 계약을 해지할 수 있고
이에 해당하는 일반회원 혹은 판매회원은 이로 인하여 발생한 피해에 대한 배상의 의무가 있습니다.



제14조 (저작권의 귀속 및 이용제한)
1.회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다. 단, 작성을 위하여 제공받은 모델 등의 작업물은 1차 제작자에게 저작권이 있으며, 회사는 이에 대한 사용권을 가집니다.
2.회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
3.회원이 서비스를 이용하는 과정에서 작성한 게시물에 대한 저작권을 포함한 일체에 관한 권리는 별도의 의사표시가 없는 한 해당 회원에게 귀속됩니다.
4.회원은 게시한 콘텐츠의 저작권 및 기타 산업재산권을 갖고 있음을 명확히 합니다. 다만 회원은 본 서비스에 콘텐츠를 게시함으로써 회사가 서비스 및 사업과 관련하여 해당 콘텐츠(및 그 2차적 저작물)의 일부 또는 전부를 전세계적으로 비독점적으로 무상으로 사용할 권리(이용, 공개, 반포, 광고, 출판, 복제, 공연, 공중송신, 전시, 배포, 대여, 2차저작물작성, 자동/수동 번역제공)를 허락하며 이를 양도 할 수 있음에 동의한 것으로 간주합니다. 또한 회사에 대해 저작인격권을 행사 하지 않을 것에 동의한 것으로 간주합니다. 이를 통하여 더 많은 수요자를 에이콘3D 서비스로 흡수하여 공급자에게도 더 나은 경험을 주려는 이유입니다.


제 15조 (콘텐츠의 관리)
1.회원의 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 콘텐츠의 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청 할 수 있으며 회사는 관련법에 따라 조치를 취하여야 합니다.
2.회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 서비스 운영방침 및 관련법에 따라 각 호에 해당하는 게시물에 대해 임시조치 등을 취할 수 있습니다.
　　 1) 다른 ‘회원’또는 제3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우
　　 2) 음란물을 게재하거나 음란사이트를 링크시킨 경우
　　 3) ‘회사’또는 제3자의 저작권 등 지적재산권을 포함한 일체의 권리를 침해할 우려가 있는 경우
　　 4) 해당 게시판의 성격에 부합하지 않는 게시물의 경우
　　 5) 제3자의 영리를 목적으로 하는 광고성 게시물의 경우
　　 6) ‘회사’에서 규정한 ‘서비스’내의 게시물 원칙과 조건에 부합하지 않는 경우
　　 7) ‘회사’나 ‘서비스’의 정상적인 운영을 방해하는 경우
3.회사는 콘텐츠와 관련한 문제시에 게시물 삭제 및 게시자에게 경고조치를 즉시 취할 것입니다.


제16조 (면책)
1.회사는 다음 사유로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
　　  1)천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우 
　　 2)서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우 
　　 3)디바이스 환경 등 회원의 귀책사유로 서비스 이용에 장애가 있는 경우 
　　 4)네트워크 환경 등 기타 회사의 고의,과실이 없는 사유로 인한 경우
2.회사는 CP가 제공하거나 회원이 작성하는 등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성, 저작권침해여부 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 어떠한 책임도 부담하지 않습니다.
​3.회원 상호간 또는 회원과 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
4.서비스 이용자가 서비스를 이용하여 기대하는 수익을 얻지 못한 것에 대하여 책임을 지지 않으며 서비스에 대한 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다


제 17조 (손해배상) 
1.회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 본 약관을 위반한 회원은 회사에 발생하는 모든 손해를 배상하여야 합니다.
2.회원이 서비스를 이용하는 과정에서 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제 3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받은 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
3.회사는 무료로 제공하는 서비스와 관련하여 발생하는 사항에 대하는 어떠한 손해도 책임을 지지 않습니다


제18조 (서비스 관련 분쟁해결)
1.회사는 서비스 이용과 관련하여 회원으로부터 제출되는 불만사항 및 의견을 최대한 신속하게 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 조속히 통보해 드립니다.
2.회사와 회원간에 발생한 분쟁은 전자거래기본법에 의하여 설치된 전자거래분쟁 조정위원회의 조정절차를 거쳐 진행됩니다.


제19조 (서비스 종료)
1.서비스를 종료하고자 할 경우, 회사는 서비스를 종료하고자 하는 날로부터 3개월 이전에 본 약관 제3조 제3항에 규정된 통지방법을 준용하여 회원에게 알려드립니다. 단, 서비스의 종료가 아닌 사업상 기타 운영상의 이유로 인한 양도의 경우에는 특별히 공지하지 않습니다.
2.본 조 제1항에 따른 서비스 종료 통지일 이후 회원은 회사로부터 서비스를 제공받지 못합니다. 이에 대하여 특별한 규정이 없는 한 서비스이용자에게 별도의 보상을 하지 않습니다.


제20조 (준거법 및 합의관할)
1.본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 대한민국법 및 상관례에 따릅니다.
2.서비스 및 본 약관과 관련한 제반 분쟁 및 소송은 서울중앙지방법원 또는 민사소송법상의 관할법원을 제1심 관할법원으로 합니다.


제21조 (개인정보보호 의무)
1.회사는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는 관련법령 및 회사가 정하는 "개인정보 취급방침"에 정한 바에 의하며, 회원이 상시 확인할 수 있도록 다양한 방법을 통해 공지하고 있습니다.
2.회사는 이용계약의 성립 및 이행에 필요한 최소한의 범위 내에서 개인정보취급방침에 따라 고객님의 회원정보를 수집합니다.
3.회사는 서비스와 관련하여 알고 있는 회원의 정보를 본인의 동의 없이 제 3자에게 제공하지 않습니다.
4.회사는 회원의 귀책사유로 인해 노출된 회원 정보에 대해서 책임을 지지 않습니다.
5.회사는 전기통신사업법, 통신비밀보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령에 따라 행정기관이나 수사기관 등에서 회원의 회원정보의 열람이나 제출을 요청하는 경우에는 이를 제공할 수 있습니다.


제 22조 (휴대폰 및 기타 기기)
1.회사는 에이콘3D 서비스 가입을 무료로 제공합니다. 판매되는 모델을 무료로 제공한다는 의미는 아닙니다. 다만 통신에 따른 요금은 이동통신사의 정책에 따릅니다. 통신에 따른 요금에 대해서 회사는 책임지지 않습니다.
​2.회원은 자신의 기기에서 서비스가 요구하는 모든 정보와 동기화(앱을 통한 동기화 포함)하는데 필요한 모든 권리를 제공하는데 있어 동의한 것으로 간주합니다.


제 23조 (구매신청)
1.회원은 본 약관 및 회사가 정한 규정에 따라 아래와 같거나 유사한 방법으로 구매를 신청하며, 회사는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
　　 1)재화 등의 검색 및 선택
　　 2)받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
　　 3)약관내용, 청약철회권이 제한되는 서비스, 배송료/설치비 등의 비용부담과 관련한 내용에 대한 확인
　　 4)이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)
　　 5)재화등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의
　　 6)결제방법의 선택


제 24조 (구매계약의 성립)
1.회사는 제 23조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
　　 1)신청 내용에 허위, 기재누락, 오기가 있는 경우
　　 2)미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
　　 3)기타 구매신청에 승낙하는 것이 회사의 기술상 현저히 지장이 있다고 판단하는 경우
​2.회사의 승낙이 제26조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
​3.회사의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.
​


제 25조 (지급방법)
1.회사에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 회사가 채택한 방법으로 할 수 있습니다. 단 회사는 구매자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다. 다만 배송비, 해외배송비는 이에 포함되지 않습니다.
　　 1)폰뱅킹, 인터넷뱅킹 등의 각종 계좌이체
　　 2)선불카드, 직불카드, 신용카드 등의 각종 카드 결제
　　 3)온라인무통장입금
　　 4)휴대폰 결제
　　 5)할인쿠폰 등 회사가 지급한 쿠폰에 의한 결제
　　 6)기타 회사가 추가 지정하는 결제 수단
2.회사는 회원의 대금지급에 법적, 기술적 문제가 발생하거나 회사가 예견하지 못한 장애(은행 통신망 장애 등)가 발생하는 경우 회사의 정책에 따라 회원에게 결제 수단의 변경을 요청하거나 잠정 결제보류 내지 거부할 수 있습니다.
3.구매대금의 결제와 관련하여 회원이 입력한 정보 및 그와 관련된 책임은 회원에게 있으며, 재화 또는 용역의 청약 이후 합리적인 일정 기간 내 결제가 이루어 지지 않는 경우 회사는 해당주문을 취소할 수 있습니다.
4.회사는 구매자의 결제수단 사용권한에 있어 정당성을 확인할 수 있으며 필요한 경우 해당 거래진행의 보류 및 소명자료의 제출을 요청할 수 있습니다.



제 26조 (수신확인통지, 구매신청 변경 및 취소)
1.회사는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
2.수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 할 수 있고 회사는 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제29조의 청약철회 등에 관한 규정에 따릅니다.



제 27조 (재화 등의 공급)
1.회사는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7영업일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장(혹은 서버 등록) 등 기타의 필요한 조치를 취합니다. 다만 회사가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이 때 회사는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
2.공휴일 및 기타 휴무일 또는 천재지변 등의 불가항력적인 사유가 발생하는 경우 그 해당기간은 배송소요기간에서 제외합니다.


제 28조 (환급)
1.회사는 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 7영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
2.무형의 상품인 특성상 구매 후 환불이 불가한 상품이 존재할 수 있습니다.


제 29조 (청약철회 등)
1.회사와 재화등의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날로부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 통지를 받은 때보다 공급이 늦게 이루어진 경우에는 재화 등의 공급을 받은 날로부터 7일 이내에 청약 철회를 할 수 있습니다.
2.이용자는 재화 등을 배송받은 경우 반품 및 교환을 할 수 없습니다.
3.제2항 제2호 내지 제4호의 경우 회사가 사전에 청약철회 등이 제한되는 사실을 이용자가 쉽게 알 수 있는 곳에 명시 하거나 사용 상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
4.이용자는 제1항 및 제2항의 규정에도 불구하고 재화 등의 내용이 표시,광고 내용과 다르거나 계약내용과 다르게 이행된 경우에는 당해 재화등을 공급받은 날로부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회를 할 수 있습니다.



제 30조 (청약철회 등의 효과)
1.회사는 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 회사가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
2.회사는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
3.청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다.회사는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시, 광고의 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 회사가 부담합니다.
4.이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 회사는 청약철회시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.


제 31조 (상품쿠폰)
1.회사는 재화 등을 구매하는 이용자에게 지정된 상품 구매 시 일정액 또는 일정비율을 할인 받을 수 있는 쿠폰을 발급할 수 있습니다.
2.회사에서 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며, 어떠한 경우에도 이를 타인에게 실질적으로 매매할 수 없습니다.
3.상품쿠폰은 현금으로 출금될 수 없으며, 표시된 유효기간이 만료되거나 이용계약이 종료되면 소멸합니다.
4.상품쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며, 회원을 탈퇴한 경우 상품쿠폰은 소멸됩니다.


제 32조 (포인트)
1.포인트는 회사가 운영하는 전자화폐로써 재화 등을 구매할 때 1P(포인트)=현금 1원의 등가로 결제 수단 사용이 가능 것들을 약관에서는 포인트로 규정합니다. 이는 "에이콘포인트", "마일리지", "에이콘마일리지"등의 이름으로 불려질 수 있습니다.
2.포인트는 회사의 정책, 이벤트 등을 통해 회사가 정한 절차와 방법에 따라 적립됩니다.
3.포인트의 유효기간은 적립일로부터 1년이며 유효기간이 지나면 자동적으로 소멸됩니다. 단 청약철회 등으로 환급되는 에이콘포인트의 경우 최초 적립일을 기준으로 유효기간이 적용됩니다.
4.포인트는 유효기간의 도래가 빠른 순서로 사용되며, 유효기간이 만료되거나 이용계약이 종료되면 미사용 에이콘포인트는 소멸됩니다.
​5.포인트는 회사가 회원에게 무상지급하는 혜택으로 현금 출금할 수 없으며 회사에서 정한 기한 및 이용방법에 따라서만 사용 가능합니다. 이 때 일부 품목이나 금액에 따라 사용이 제한될 수 있습니다.
​6.포인트는 회사에서 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며 부정한 목적이나 용도의 사용을 금지합니다. 위반시에 회사는 에이콘포인트를 소멸시키거나 회원자격을 정지할 수 있습니다.


제 33조 (판매회원의 추가의무)
1. 회원 즉, 약관읽고 본 서비스에 가입하는 창작자와 회사는 회사가 운영하는 에이콘3D(이하 ‘서비스’)를 이용함에 있어 다음과 같은 항목들에 동의하며, 약관을 확인하고 회원가입을 함으로써 회원과 회사 사이에 본 항목들의 효력이 발생합니다.
2. 판매수익과 관련하여 회사와 회원이 서면으로 합의한 수수료율을 따릅니다.
     - 세금 항목의 경우 사업자등록이 되어있는 회원에게는 세금 항목을 제하지 않고 정산금이 지급되며 이에 대한 세금계산서를 ‘회사’로 영업일 5일 안쪽으로 발행합니다. 
     - 사업자등록이 되어있지 않은 경우 프리랜서 원천징수를 현행법에 따라 제한 후 정산금을 지급합니다.
     - 결제수수료의 경우 국내는 4.4%가 발생하며 해외 결제 혹은 결제사의 정책이 변동되는 경우 등 변경사항이 생기면 ‘회사’는 정산내역서 등에 변동된 결제수수료율을 기입한다.
3. 입점 후 서비스 외에서의 판매에 대한 정책
     - 회사는 창작자의 개인판매 및 타 플랫폼 입점을 제한하지 않습니다.
     - 회사와 독점적 계약이 아닌 회원은 개인판매 및 타 플랫폼에서 판매하는 가격을 회사에 공유하며, 회사의 요청 시 해당 가격 중 최저판매가를 회사의 서비스에서 적용한다. 단, 회사와 회원 간 별도의 협의가 있을 경우, 유연한 판매가를 적용하며 이를 이메일 등의 서면을 통하여 기록합니다.
     - 회원은 회사의 서비스를 통해 알게 된 유저에게 개인적인 접근을 통해, 서비스를 통하지 않은 매매활동을 하지 않습니다.
4. 정산 방식 및 주기
     - 정산 주기는 월 1회이며 정산은 법률적으로 문제가 생기지 않는 범위에서 회사와 회원이 서면으로 협의한 방식으로 진행합니다.
     - 사업자등록이 되어있는 회원은 세금계산서 발행이 정산일로부터 영업일 5일 안에 발급이 불가하다면 지급일 전에 이메일 등을 통하여 ‘회사’에 서면으로 공유한다. 특별한 사유 없이 세금계산서 발행이 이루어지지 않는다면 이후 정산이 정상적으로 이루이지 않을 수 있습니다.
5. 약관의 효력이 유지되는 기간 및 갱신
본 약관은 내용은 회원이 가입한 날로부터 만 12개월 동안 적용된다. 단 만료 30일 전까지 회원탈퇴가 이루어지지 않거나 기간만료와 동시에 본건을 종료한다는 서면 통지가 없을 경우 전 약관과 동일한 조건으로 12개월씩 자동 갱신되는 것으로 합니다.
6. 본 약관의 동의 시점이 회사와 회원간 협의했던 계약/약관보다 뒤의 시점이라면 본 약관에 효력이 발생한다. 회사는 약관에 수정사항이 생기는 경우 수정을 반영한 시점으로부터 영업일 7일 안에 이메일, 서면 등을 통하여 고지합니다. 이때 회원이 이메일을 잘못 기입하여 발생하는 책임은 회원에게 있습니다.
7. 판매 권한의 해지
다음 각 항에 해당하는 경우 회사는 회원의 판매권한을 해지할 수 있습니다.
     - 회사와 창작자 중 어느 일방이 본 계약을 중대하게 위반하거나 이행하지 아니한 때
     - 창작자의 상품 및 서비스에 법률상, 저작권상의 문제가 생겼을 때
     - 창작자의 고의 또는 과실로 인하여 회사에 손해를 입힌 때
     - 회사 또는 창작자가 부도 등 중대한 사유로 본건의 서비스를 지속 할 수 없을 때
     - 상품 및 서비스에 하자가 많은 경우
     - 불건전한 제품을 게시한 경우 (음란물, 성인용품 및 미풍양속을 해할 우려가 있는 상품 및 서비스), 단 회사에서 법적인 부분을 검토하여 상호간 합의가 있을 경우는 예외로 할 수 있습니다.
     - 가입 및 계약 시 각종 정보들을 허위로 기재 한 경우
     - 제 1조를 위반하여 문제 혹은 불이익이 생겼을 경우
위의 이유로 계약이 해지 된 경우 회사는 회원의 상품 및 서비스를 품절 및 블라인드 처리 한다. 본 항에 의해 발생한 건에 대한 모든 책임과 배상은 회원에게 귀속되며, 이로인하여 회사에 금전적인 손해가 생기는 경우 회원은 이에 대한 배상의 의무가 있습니다.
8 약관의 해석
     - 본 약관에 정하지 아니한 사항이나 해석상 내용이 불분명한 사항에 대하여는 관계 법령 및 상관례에 따라 회사와 창작자가 서로 협의하여 상호 호혜적 차원에서 결정합니다.
9. 합의관찰
     - 필요시 본 약관 행의 이행과정에서 법적 분쟁이 발생한 경우, 회사의 소재지를 관할하는 법원을 제1심 합의관할 법원으로 합니다.
10.  일반조항
     - 회원은 소비자의 피드백에 성실히 답변해야 하며, 상품 및 서비스에 하자가 발생 한 경우 최대한 개선 과정을 거칩니다.
     - 회사는 회원의 최대 이윤을 위하여 타 분야시장에 대한 연구와 해외시장 확장에 대하여 성실히 검토하고 진행합니다.
     - 회원이 제작한 3D모델의 저작권은 추가적인 서면상의 합의가 있지 않는 한 회원에게 있으며, 제3자에게 판매행위와 관련되어 회원은 회사에게 복제권, 공연권, 공중송신권, 전시권, 배포권, 대여권에 대한 이용을 허락하며 제3자에게 판매 시 이를 허락 할 수 있는 권한을 부여합니다.
     - 변경 및 보수 필요 시 회사는 회원에게 이를 요청하거나 회원의 창작물의 품위를 훼손하지 않는 범주 안에서 이상에 대한 보수가 가능하고, 회사에서 판매 홍보를 위한 이미지의 사용은 가능하다. 단, 고객 대응이 필요한 시점에서 회원의 회신이 1영업일 이상 없는 경우 상품의 품위가 훼손되지 않는 선에서 단순 수정이 가능하다. 판매 홍보를 위한 글 또는 그림과 구매자에게 제공되는 회원의 3D모델은 상이하지 아니하여야 하며, 3D모델의 구매자에게는 구매 시 사용권 만을 제공합니다.
     - 회사는 구매자에게 약관 혹은 구매과정에서 저작권에 대한 안내 및 경고를 하며, 구매자가 과실 혹은 고의로 회원에게 재물적인 손해를 입히는 경우 그 책임은 회사가 아닌 구매자에게 있습니다. 회사는 구매자와 회원의 분쟁 발생시 구매자와 관련된 정보를 적극 제공합니다.
     - 구매자가 회사 및 팀 단위 구매를 원해 할 경우의 정책으로 다음 두 가지 중 하나 이상을 만족시키는 방향으로 진행합니다. (가) 팀원 모두가 해당 창작물의 사용권을 구매합니다. (나) 회사 및 팀 이름으로 구매하되, 해당 1개의 작품에만 사용이 가능합니다. 후자의 경우 회사는 구매자에게 작품의 이름까지 작성을 요청합니다.
     - 본 건의 계약이 종료되고 회원의 요청이 있는 경우 회사는 보유하고 있는 회원자의 3D모델을 파기합니다.




부칙

본 약관은 2019년 12월 24일부터 시행합니다.
종전 약관은 본 약관으로 대체되며, 개정이용약관 이전 가입자 또한 개정이용약관의 적용을 받습니다.</pre>
</div>`;

export default TermsOfUse;