import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'common/editor';
import parse from 'html-react-parser';
import { AppContext } from 'app';
import { HtmlView } from './style';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Box, Typography } from '@mui/material';
import { contentBodyState, contentHeadState, displayState, languageState } from 'boards/DetailBoardWrite/recoil';

export default (({ isChecked }) => {
    // 번역도구 
    const { t, ready } = useTranslation();
    const { userInfo } = useContext(AppContext);
    const display = useRecoilValue(displayState);
    const language = useRecoilValue(languageState);
    const [contentHead, setContentHead] = useRecoilState(contentHeadState);
    const [contentBody, setContentBody] = useRecoilState(contentBodyState);

    if (!ready) return <div></div>;

    return (
        <>
            <Box fontSize="12px" fontWeight="400" position="relative">
                <Typography
                    mb="12px"
                    color="#000000DE"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="22px"
                    letterSpacing="0.1px"
                >
                    {t('basicDetail.introduction')}
                </Typography>
                {(!display.isDisp && isChecked) && <Editor
                    key={language}
                    isAdmin={userInfo.isAdmin}
                    isDisp={display.isDisp}
                    docId="introduction"
                    contents={contentHead}
                    placeholder={t('basicDetail.introductionBody')}
                    onChange={(value) => {
                        setContentHead(value);
                    }}
                    defaultHeight={250}
                    showAlertMessage={() => { }}
                    isWarningVisible={false}
                />}
                {(display.isDisp || !isChecked) && <HtmlView height="250px">
                    {parse(contentHead || '')}
                </HtmlView>}
            </Box>
            <Box mt="24px" fontSize="12px" fontWeight="400" position="relative">
                <Typography
                    mb="12px"
                    color="#000000DE"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="22px"
                    letterSpacing="0.1px"
                >
                    {t('basicDetail.content')}
                </Typography>
                {(!display.isDisp && isChecked) && <Editor
                    key={language}
                    isAdmin={userInfo.isAdmin}
                    isDisp={display.isDisp}
                    docId="content"
                    contents={contentBody}
                    placeholder={t('basicDetail.contentBody')}
                    onChange={(value) => {
                        setContentBody(value);
                    }}
                    showAlertMessage={() => { }}
                />}
                {(display.isDisp || !isChecked) && <HtmlView>
                    {parse(contentBody || '')}
                </HtmlView>}
            </Box>
        </>
    );
});