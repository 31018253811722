import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { contactState } from "./atom";

export default function ContactField() {
  const { t } = useTranslation();
  const [contact, setContact] = useRecoilState(contactState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContact(e.target.value);
  };
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2">{t("user.contact")}</Typography>
      <TextField
        size="small"
        sx={{ maxWidth: "220px" }}
        value={contact}
        onChange={handleChange}
      />
    </Stack>
  );
}
