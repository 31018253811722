import React from "react";
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import { FieldsBox, FieldName, FieldValue, FieldGroup } from "./styled";
import { UserFormType } from "./type";
import { LanguageCodeEnum, useGetUserQuery } from "generated/graphql";
import { languageCodeToCountry } from "lib/multiLocale";
import { useTranslation } from "react-i18next";
import countries from "common/country/country.json";
import { UserTypeEnum } from "signup/type";

type Props = UserFormType & {
  onShowChangePasswordDialog: () => void;
  onClickShowDialog: () => void;
};

export default function OriginalForm({
  userId,
  onShowChangePasswordDialog,
  onClickShowDialog,
}: Props) {
  const { t, i18n } = useTranslation();
  const { data, loading } = useGetUserQuery({
    variables: {
      id: userId,
    },
  });

  const penNameGroup = [
    [
      {
        language: LanguageCodeEnum.En,
        label: "ENGLISH",
      },
      {
        language: languageCodeToCountry(LanguageCodeEnum.Zh),
        label: "CHINESE",
      },
    ],
    [
      {
        language: LanguageCodeEnum.Ko,
        label: "KOREAN",
      },
      {
        language: languageCodeToCountry(LanguageCodeEnum.Ja),
        label: "JAPANESE",
      },
    ],
  ];

  const handleShowPasswordModal = () => {
    onShowChangePasswordDialog();
  };

  if (loading || !data) {
    return <Skeleton />;
  }

  const isUser = data.userV2.type === UserTypeEnum.Personal;
  return (
    <>
      <FieldsBox>
        <FieldGroup>
          <FieldName>{t("user.email")}</FieldName>
          <FieldValue>{data.userV2.account}</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.password")}</FieldName>
          <FieldValue>
            <Button
              variant="text"
              sx={{ padding: 0 }}
              onClick={handleShowPasswordModal}
            >
              {t("user.changePassword")}
            </Button>
          </FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.accountType")}</FieldName>
          <FieldValue>
            {isUser
              ? t("user.personal")
              : `사업자(${data.userV2.subTypeString})`}
          </FieldValue>
        </FieldGroup>
        {!isUser && (
          <FieldGroup>
            <FieldName>상호명</FieldName>
            <FieldValue>{data.userV2.companyName}</FieldValue>
          </FieldGroup>
        )}
        <FieldGroup>
          <FieldName>{isUser ? t("user.name") : "대표자명"}</FieldName>
          <FieldValue>{data.userV2.name}</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.creatorName")}</FieldName>
          <FieldValue>
            <Stack spacing={1}>
              {penNameGroup.map((x, i) => (
                <Stack key={`penname_${i}`} direction="row" spacing={1}>
                  {x.map((y, j) => (
                    <Stack
                      spacing={0.5}
                      key={`penname_${i}_${j}`}
                      width={"50%"}
                    >
                      <Typography variant="overline" fontWeight={700}>
                        {y.label}
                      </Typography>
                      <Typography variant="body2">
                        {data.userV2.brand &&
                          (() => {
                            if (data.userV2.brand.item.length === 0) return "-";
                            const brandObj = data.userV2.brand.item.find(
                              (z) => z.language.code === y.language
                            );
                            return brandObj ? brandObj.name : "-";
                          })()}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              ))}
            </Stack>
          </FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.contact")}</FieldName>
          <FieldValue>
            {data.userV2.contact ? data.userV2.contact : "-"}
          </FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.country")}</FieldName>
          <FieldValue>
            {countries.find((x) => x.code === data.userV2.settle.country).name}
          </FieldValue>
        </FieldGroup>
        {i18n.language !== LanguageCodeEnum.Ko && (
          <FieldGroup>
            <FieldName>SNS</FieldName>
            <FieldValue>{data.userV2.snsUrl}</FieldValue>
          </FieldGroup>
        )}
        {i18n.language === LanguageCodeEnum.Ko && (
          <FieldGroup>
            <FieldName>추가 이메일</FieldName>
            <FieldValue>{data.userV2.contactAdditional}</FieldValue>
          </FieldGroup>
        )}
      </FieldsBox>
      <Stack alignItems={"end"} mt={1.5}>
        <Button variant="text" onClick={onClickShowDialog}>
          {t("user.editInfo")}
        </Button>
      </Stack>
    </>
  );
}
