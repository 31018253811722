import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { AppContext } from 'app';
import axios from 'axios';
import Loading from 'common/editor/Loading';
import { Title, User } from './style';
import InfoAdmin from './components/info/admin';
import InfoUser from './components/info/user';
import InfoRequest from './components/info/request';
import InfoRejected from './components/info/rejected';
import Settle from './components/settle';
import { mergeDeep } from '@apollo/client/utilities';
import { UPDATE_USER } from 'api/mutations';
import { NavLink } from 'react-router-dom';
import { authOrigin, downloadFile, uploadFile } from 'api';
import InputSign from 'user/component/InputSign';
import { Field } from './components/common/field';
import { FileInputComponent, Input } from './components/common/input';
import { PLAN_TYPE } from 'user/value/PlanType';
import { confirmContract, updateContract } from 'api/contract';

const Component = (props) => {
  const { id, data } = props;
  const { userInfo, showAlertMessage } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [changeData, setChangeData] = useState({});
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [getBlob, setGetBlob] = useState(null);
  const [isSignatureChanged, setIsSignatureChanged] = useState(false);
  const [signFile, setSignFile] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER);
  const saveMent = userInfo.isAdmin ? '저장' : '검토 요청';

  const nestedObject = (key, value) => {
    const isNested = key.includes('.');
    if (!isNested) {
      const isArray = key.includes('[');

      if (isArray) {
        const regex = /\[(\d+)\]/g;
        const regexResult = regex.exec(key);

        const match = regexResult[0];
        const idx = regexResult[1];
        const array = [];
        array[idx] = value;

        return { [key.replace(match, '')]: array };
      }
      return { [key]: value };
    }

    const list = key.split('.');

    return { [list.shift()]: nestedObject(list.join('.'), value) };
  };

  const downloadTerm = () => {
    setIsLoading(true);

    try {
      downloadFile(data.termAgree.uploadFileId, data.termAgree.file.path);
    } finally {
      setIsLoading(false);
    }
  };

  const getSignImg = async (signId) => {
    const url = await axios.post(authOrigin + '/auth/download', { id: signId });

    const img = await new Promise((resolve, reject) => {
      let img = document.createElement('img');
      img.crossOrigin = 'anonymous';
      img.src = url.data;

      img.onload = () => {
        resolve(img);
      };
    });

    return img;
  };

  const onChangeData = (key, value) => {
    // 이름이 변경된 경우 계약서를 새로 갱신해야함
    // 개인일 경우 (type === '0')
    if (data.user.type === '0' && key === 'name') {
      setIsNameChanged(true);
    }
    // 사업자일 경우 (type === '1')
    if (data.user.type === '1' && key === 'companyName') {
      setIsNameChanged(true);
    }

    if (key === 'reference') {
      const newChangeData = { ...changeData, reference: value };
      setChangeData(newChangeData);
      return;
    }

    const changedData = nestedObject(key, value);
    if (changedData?.type === '1' && changedData.subType === undefined) {
      changedData.subType = '0';
    } else if (changedData.type === '0') {
      delete changedData.subType;
    }
    const newChangeData = mergeDeep(changeData, changedData);

    setChangeData(newChangeData);
  };

  const onChangeSign = (e) => {
    const file = e.target.files[0];
    setIsSignatureChanged(true);
    setSignFile(file);
  };

  const onSave = async () => {
    const promiseList = [];

    // 관리자가 입점 승인한 경우
    if (userInfo.isAdmin && changeData?.accept?.status === 1) {
      const contact = data.user.contact;
      const PIN = data.user.settle.nationalIdNumber;
      const { type, isExclusive, commissionRate } = data.userPlan || {};
      const name = isNameChanged ? (data.user.type === '0' ? changeData.name : changeData.companyName) : data.user.type === '0' ? data.user.name : data.user.companyName;
      const planType = data.userPlan && PLAN_TYPE({ type, isExclusive });

      // 우선 여기서 확정을 해야하기 때문에 await로 받아야함 (즉, create -> confirm -> update 순서보장이 되어야함)
      await confirmContract({
        userId: id,
        locale: userInfo?.settle?.locale,
        userName: name,
        userContact: contact,
        userPin: PIN,
        planType,
        commissionRate,
        password: changeData?.brand?.godoBrandCd || data.user.brand.godoBrandCd,
      });

      setIsNameChanged(false);
      // 이름이나 서명이 변경된 경우
    } else if (isNameChanged || isSignatureChanged) {
      const name = isNameChanged ? (data.user.type === '0' ? changeData.name : changeData.companyName) : data.user.type === '0' ? data.user.name : data.user.companyName;

      const signaturePath = isSignatureChanged ? await uploadFile(data.user.type === '0' ? await getBlob() : signFile, data.user.settle.sign.fullName) : null;

      const contact = data.user.contact;
      const PIN = data.user.settle.nationalIdNumber;
      const { type, isExclusive, commissionRate } = data.userPlan || {};
      const planType = data.userPlan && PLAN_TYPE({ type, isExclusive });

      await updateContract({
        userId: id,
        signatureAzureStoragePath: signaturePath,
        locale: userInfo?.settle?.locale,
        userName: name,
        userContact: contact,
        userPin: PIN,
        planType,
        commissionRate,
        password: changeData?.brand?.godoBrandCd || data.user.brand.godoBrandCd,
      });

      isNameChanged && setIsNameChanged(false);
      isSignatureChanged && setIsSignatureChanged(false);
    }

    // 고도브랜드코드가 변경된 경우
    if (userInfo.isAdmin && changeData?.brand?.godoBrandCd) {
      const contact = data.user.contact;
      const PIN = data.user.settle.nationalIdNumber;
      const { type, isExclusive, commissionRate } = data.userPlan || {};
      const name = isNameChanged ? (data.user.type === '0' ? changeData.name : changeData.companyName) : data.user.type === '0' ? data.user.name : data.user.companyName;
      const planType = data.userPlan && PLAN_TYPE({ type, isExclusive });

      await updateContract({
        userId: id,
        locale: userInfo?.settle?.locale,
        userName: name,
        userContact: contact,
        userPin: PIN,
        planType,
        commissionRate,
        password: changeData?.brand?.godoBrandCd || data.user.brand.godoBrandCd,
      });
    }

    /***************************************************************************/

    if (changeData.file) {
      for (const key in changeData.file) {
        const target = changeData.file[key];

        if (target.id) {
          // 이미 파일이 존재할경우 덮어쓰기
          promiseList.push(uploadFile(target.file, target.fileName));
          delete target.file;
          delete target.fileName;
          continue;
        }

        // promiseList.push(async () => {
        const result = await uploadFile(target.file);
        target.fullName = result.key;
        delete target.id;
        delete target.fileName;
        delete target.file;
        // })
      }
    }

    if (typeof changeData?.accept?.status === 'number') {
      if (changeData.accept.status !== 1) {
        try {
          delete changeData.brand.godoBrandCd;
        } catch {}
      }
    } else if (data.user.accept.status !== 1) {
      try {
        delete changeData.brand.godoBrandCd;
      } catch {}
    }

    if (changeData.brand?.koName) {
      changeData.brand.name = changeData.brand.koName;
    }

    const updateUserPromise = updateUser({
      variables: {
        id,
        user: changeData,
      },
    });

    promiseList.push(updateUserPromise);

    const callback = () => {
      window.location.reload();
    };

    Promise.all(promiseList)
      .then(() => {
        showAlertMessage(saveMent, saveMent + '되었습니다', { callback });
      })
      .catch((err) => {
        if (err.graphQLErrors) {
          const firstError = err.graphQLErrors[0];
          const { message } = firstError;

          if (message === 'not unique') {
            showAlertMessage('중복오류', '중복된 브랜드코드입니다', {
              callback,
            });
            return false;
          }
        }
        console.log(err);
        showAlertMessage('오류', '잠시 후 다시시도하십시오', { callback });
      });
  };

  return (
    <User>
      {isLoading || !data ? (
        <Loading />
      ) : (
        <>
          <section>
            <Title>
              <h4>일반 정보</h4>
              {/* 관리자는 제외 */}
              {!userInfo.isAdmin &&
                // 요청 상태
                (data.user.accept.status === 0 ? (
                  <a href="mailto:contact@acon3d.com">
                    <b>수정요청</b>
                  </a>
                ) : (
                  // 승인 상태
                  data.user.accept.status === 1 && (
                    <NavLink to="/userChange">
                      <b>수정요청</b>
                    </NavLink>
                  )
                ))}
            </Title>
            <div className="box">
              {userInfo.isAdmin ? (
                <InfoAdmin user={data.user} userPlan={data.userPlan} onChangeData={onChangeData} downloadTerm={downloadTerm} />
              ) : data.user.accept.status === 1 ? (
                <InfoUser user={data.user} userPlan={data.userPlan} downloadTerm={downloadTerm} />
              ) : data.user.accept.status === 0 ? (
                <InfoRequest user={data.user} />
              ) : (
                <InfoRejected user={data.user} onChangeData={onChangeData} />
              )}
            </div>
          </section>
          <section>
            <Title>
              <h4>정산 정보</h4>
              {/* 관리자는 제외 */}
              {!userInfo.isAdmin && data.user.accept.status === 1 && (
                // 승인 상태
                <a href={`${process.env.STORAGE_ORIGIN}/term/계좌변경신청서.docx`}>
                  <b>수정요청</b>
                </a>
              )}
            </Title>
            <div className="box">
              <Settle id={id} data={data} userInfo={userInfo} changeData={changeData} onChangeData={onChangeData} />
            </div>
          </section>
          {!userInfo.isAdmin && data.user.accept.status === -1 && (
            <section>
              <h4>계약 서명</h4>
              <div className="box">
                {data.user.type === '0' ? (
                  <Field label="서명">
                    <Input readOnly defaultValue={data.user.settle.sign.fullName} />
                    <InputSign width={'466px'} summary="네모 칸 속에 서명을 그려주세요" setGetBlob={setGetBlob} setIsDrawn={setIsSignatureChanged} />
                  </Field>
                ) : (
                  <Field label="인감/서명">
                    <FileInputComponent value={data.user.settle.sign.fullName} onChange={onChangeSign} />
                  </Field>
                )}
              </div>
            </section>
          )}
          {(userInfo.isAdmin || data.user.accept.status === -1) && <button onClick={onSave}>{saveMent}</button>}
        </>
      )}
    </User>
  );
};

export default Component;
