import { SmallWhiteButton } from "common/style";
import React, { useEffect, useState } from "react";
import { Select } from "user/User/style";
import { Field } from "../common/field";
import { Input, InputField } from "../common/input";
import { PLAN_TYPE, strToPlanType } from "user/value/PlanType";
import CommissionRate from "user/value/Commission";
import { useQuery } from "@apollo/client";
import { GET_BRANDS_NOT_HAVING_USER } from "api/quries";
import RcSelect from "react-select";
import { STATUS_CODE } from "../../../Mypage/constants";
import * as Styled from "../../../Mypage/style";

const Component = (props) => {
  // variables
  const { user, userPlan } = props;
  const { data, loading } = useQuery(GET_BRANDS_NOT_HAVING_USER);
  const isLoading = !(!loading && data && data.getBrandsNotHavingUser);

  const [isExistBrand, setIsExistBrand] = useState(false);
  const [status, setStatus] = useState(user.accept.status);
  const [commissionRate, setCommissionRate] = useState(
    userPlan?.commissionRate || ""
  );

  let planTypeList = [
    null,
    { type: 0 },
    { type: 1 },
    { type: 2 },
    { type: 1, isExclusive: true },
    { type: 2, isExclusive: true },
  ];

  planTypeList = planTypeList.map((data) =>
    data === null ? null : PLAN_TYPE(data)
  );

  const onChangePlanType = (value) => {
    const plan = strToPlanType(value);

    if (plan === undefined) {
      props.onChangeData("plan", { type: -1 });
    } else {
      setCommissionRate(CommissionRate({ ...plan, sumDefault: true }));
      props.onChangeData("plan", plan);
    }
  };

  const onChangeStatus = (value) => {
    const idx = user.accept.statusStringList.indexOf(value) - 1;
    setStatus(idx);
    props.onChangeData("accept.status", idx);
  };

  const onChangeExistBrand = (data) => {
    props.onChangeData("brandId", data.value);
  };

  const onChangeData = (e) => {
    const element = e.target;
    const key = element.dataset.key;
    let value = element.value;

    if (element.dataset.allowNum) {
      value = element.value.replace(/[^0-9\-]/g, "");
      element.value = value;
    }

    props.onChangeData(key, value);
  };

  useEffect(() => {
    if (commissionRate !== "") {
      const key = "plan.commissionRate";
      const value = commissionRate;

      props.onChangeData(key, value);
    }
  }, [commissionRate]);

  useEffect(() => {
    props.onChangeData("isExistBrand", isExistBrand);
  }, [isExistBrand]);

  const brands =
    user.brand?.item.slice().sort((a, b) => {
      return a.language.id - b.language.id;
    }) ?? [];

  return (
    <>
      <Field label="작가 승인상태">
        <Select
          data={user.accept.statusStringList}
          value={user.accept.statusStringList[status + 1]}
          onChange={onChangeStatus}
        />
      </Field>

      {status === -1 && (
        <>
          <Field label="사유입력">
            <textarea
              data-key="accept.comment"
              defaultValue={user.accept.comment}
              onChange={onChangeData}
            />
          </Field>
          <Field label="반려사유">
            <textarea readOnly defaultValue={user.accept.comment} />
          </Field>
        </>
      )}

      {/* [TODO] 국문몰의 경우 작가 수정상태 "승인"으로 고정, 나중 리뉴얼 때 적용 예정 */}
      <Field label="작가 수정상태">
        <Styled.Select
          data={user.accept.statusStringList}
          value={user.accept.statusStringList[STATUS_CODE.APPROVE + 1]}
          onChange={onChangeStatus}
          disabled={true}
        />
      </Field>

      {user.accept.status === 1 && (
        <>
          <Field label="파트너십 유형">
            <Select
              data={planTypeList}
              value={userPlan && PLAN_TYPE(userPlan)}
              onChange={onChangePlanType}
            />
          </Field>
          <InputField
            label="수수료율(%)"
            value={commissionRate}
            onChange={(e) => setCommissionRate(parseInt(e.target.value))}
            number
            min="0"
            max="100"
          />
        </>
      )}
      <InputField
        label="아이디(이메일)"
        data-key="account"
        defaultValue={user.account}
        onChange={onChangeData}
      />
      <InputField
        label="필명"
        data-key="brand.koName"
        defaultValue={brands.find((x) => x.language.code === "ko")?.name}
        onChange={onChangeData}
        style={{ marginBottom: 10 }}
      />
      <Input
        data-key="brand.enName"
        defaultValue={brands.find((x) => x.language.code === "en")?.name}
        onChange={onChangeData}
        placeholder={"영문 필명을 입력해 주세요."}
        style={{ marginBottom: 10 }}
      />
      <Input
        data-key="brand.cnName"
        defaultValue={brands.find((x) => x.language.code === "cn")?.name}
        onChange={onChangeData}
        placeholder={"중문 필명을 입력해 주세요."}
        style={{ marginBottom: 10 }}
      />
      <Input
        data-key="brand.jpName"
        defaultValue={brands.find((x) => x.language.code === "jp")?.name}
        onChange={onChangeData}
        placeholder={"일문 필명을 입력해 주세요."}
        style={{ marginBottom: 36 }}
      />

      <InputField
        label="연락처"
        data-key="contact"
        data-allow-num
        defaultValue={user.contact}
        onChange={onChangeData}
      />
      <InputField
        label="추가 이메일"
        data-key="contactAdditional"
        defaultValue={user.contactAdditional || ""}
        onChange={onChangeData}
      />
      {user.accept.status !== 1 && (
        <Field label="입점 검토 자료">
          {user.reference.map((document) => (
            <Input readOnly key={document.id} defaultValue={document.url} />
          ))}
        </Field>
      )}
      <Field label="계약서">
        <span>계약서 파일의 비밀번호는 작가님의 브랜드코드 입니다.</span>
        <div>
          <SmallWhiteButton onClick={props.downloadTerm}>
            다운로드
          </SmallWhiteButton>
        </div>
      </Field>

      <Field label="브랜드코드">
        <label>
          &nbsp;&nbsp;
          <input
            type="checkbox"
            value={isExistBrand}
            onClick={() => setIsExistBrand(!isExistBrand)}
          />{" "}
          기존 브랜드 선택하기
        </label>
        {isExistBrand ? (
          !isLoading &&
          (() => {
            console.log(data.getBrandsNotHavingUser);
            const brandItems = data.getBrandsNotHavingUser.map((x) => {
              return {
                value: x.id,
                label: x.name,
              };
            });

            const getBrandItem = (value) => {
              return brandItems.find((x) => x.value === value);
            };

            return (
              <RcSelect
                {...{
                  placeholder: "작가",
                  // value: getBrandItem(state.brandId),
                  isMulti: false,
                  options: brandItems,
                  onChange: onChangeExistBrand,
                }}
              />
            );
          })()
        ) : (
          <Input
            data-key="brand.godoBrandCd"
            defaultValue={user.brand?.godoBrandCd || ""}
            onChange={onChangeData}
          />
        )}
      </Field>
    </>
  );
};

export default Component;
