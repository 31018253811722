import styled from 'styled-components';

export const Modal = styled.div`
    position: fixed;
    box-sizing: border-box;
    box-shadow: 4px 12px 18px rgb(0 0 0 / 24%);
    border: 1px solid #cdcdcd;
    padding: 30px;
    z-index: 1000;
    border-radius: 4px;
    background: #ffffff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 320px;
`;

export const Background = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 999;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const Contents = styled.div`
    color: #333333;
    font-size: 15px;
    text-align: center;
    padding: 22px 0px 20px 0px;
    font-weight: 700;
`;

export const BlackButton = styled.div`
    min-width: 280px;
    height: 40px;
    background-color: #333333;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
`;