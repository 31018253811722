import { Autocomplete, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { inputStyle } from 'acon-mui/style';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Chevron } from '../icons/icon-chevron.svg';
import { isMobile } from 'react-device-detect';

type Option = {
  id: number;
  label: string;
  [key: string]: unknown;
};

type Props = React.CSSProperties & {
  options: Option[];
  onSelect: (e, val?) => void;
  isKeyboardAvailable?: boolean;
  defaultValue?: number;
  defaultValues?: number[];
  disabled?: boolean;
  placeholder?: string | null;
  width?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: string;
  isBorder?: boolean;
  inputLabelVisible?: boolean;
  isMultiple?: boolean;
  size?: 'medium'|'small';
};

export default function SelectBox({
  options,
  onSelect,
  isKeyboardAvailable=false,
  defaultValue,
  defaultValues,
  disabled=false,
  placeholder=null,
  width="100%",
  fontSize="15px",
  fontWeight="500",
  isBorder=true,
  inputLabelVisible=true,
  isMultiple=false,
  size="medium",
  ...rest
}: Props): JSX.Element {
  const inputRef = useRef(null);
  const [value, setValue] = useState(options.find((x) => x.id === defaultValue));
  const [values, setValues] = useState(options.filter((x) => defaultValues && defaultValues?.includes(x.id)));
  const [inputValue, setInputValue] = React.useState(options.find((x) => x.id === defaultValue)?.label || null);
  
  const handleChange = (e) => {
    let newValue = options.find((x) => `${x.id}` === `${e.target.value as string}`);
    if (isKeyboardAvailable) {
      newValue = options.find((x) => x.label === e.target.value);
    }
    if (newValue) {
      onSelect(e, newValue);
      setValue(newValue);
    } else {
      onSelect(e);
    }
  };

  const handleChangeMultipleValues = (e) => {
    onSelect(e, values || null);
    const newValues = options.filter((x) => e.target.value.includes(x.id));
    if (newValues) {
      onSelect(e, newValues);
      setValues(newValues);
    } else {
      onSelect(e);
    }
  };

  useEffect(() => {
    setValues(options.filter((x) => defaultValues && defaultValues?.includes(x.id)));
  }, [options, defaultValues]);

  useEffect(() => {
    setValue(options.find((x) => defaultValue && x.id === defaultValue));
  }, [options, defaultValue]);

  if (!isKeyboardAvailable) {
    return (
      <FormControl 
        size={size}
        disabled={disabled}
        sx={{ 
          width, 
          fontSize, 
          fontWeight, 
          ...!isBorder && { 
            '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline': { 
              border: 'none' 
            } 
          },
          '& label': { zIndex: '0' },
          ...(!inputLabelVisible && (inputValue || value || values.length > 0)) && ({
            '& label': {
              display: 'none',
            },
            '& legend': {
              display: 'none'
            }
          }),
          '& label[data-shrink="false"]': {
            top: '50%',
            transform: 'translate(14px, -50%)'
          },
          ...!inputLabelVisible && ({
            '& label.Mui-focused': {
              display: 'none',
            },
            '& legend': {
              display: 'none'
            }
          }),
          ...inputStyle,
          ...isMobile && {
            '& input': {
              py: '0 !important',
            },
            '& .MuiSelect-select': {
              paddingTop: '8.5px',
              paddingBottom: '8.5px'
            },
          },
          ...size==='small' && {
            '& .MuiOutlinedInput-notchedOutline': {
              top: '0',
              height: '40px',
            }
          },
          ...rest 
        }}
      >
        {placeholder && <InputLabel>{placeholder}</InputLabel>}
        <Select
          {...defaultValue !== undefined && {
            defaultValue: isMultiple ? 
              options.filter((x) => defaultValues && defaultValues.includes(x.id))?.map(x => x.id) 
              : options.find((x) => x.id === defaultValue) && options.find((x) => x.id === defaultValue)?.id
          }}
          value={isMultiple ? values.map(x => x.id) : value ? value?.id : ''}
          label={placeholder}
          onChange={(e) => {
            if (isMultiple) {
              handleChangeMultipleValues(e);
            } else {
              handleChange(e);
            }
          }}
          multiple={isMultiple}
          renderValue={(value) => (
            <span>{isMultiple ? 
              options.filter(
                x => typeof value !== 'number' && value.includes(x.id)
              ).map(x => x.label).toString()
              : options.find((x) => x.id === value).label
            }</span>
          )}
          size={size}
        >
          <MenuItem disabled value="" sx={{ display: 'none' }} />
          {options.map((option) => (
            <MenuItem key={`listItem-${option.id}-${option.label}`} value={option.id}>
              {isMultiple && <Checkbox checked={!!values.find(x => x.id === option.id)} />}
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Autocomplete
      disablePortal
      options={options}
      multiple={isMultiple}
      disabled={disabled}
      isOptionEqualToValue={(option: Option, val: Option) => option.id === val.id}
      getOptionLabel={(option: Option) => option.label}
      {...defaultValue !== undefined && {
        defaultValue: isMultiple ? 
          options.filter((x) => defaultValues && defaultValues.includes(x.id))
          : options.find((x) => x.id === defaultValue)
      }}
      value={isMultiple ? values || null : value || null}
      // duplicate key error 해결하기 위한 코드
      renderOption={(props, option: Option) => (
        <li {...props} key={`listItem-${option.id}-${option.label}`}>
          {isMultiple && <Checkbox checked={!!values.find(x => x.id === option.id)} />}
          {option.label}
        </li>
      )}
      popupIcon={<Chevron style={{ margin: '4px 6px' }} />}
      sx={{ width, '& input': { fontSize, fontWeight }, ...rest }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label={placeholder} 
          {...!inputLabelVisible && ({ 
            InputLabelProps: {
              shrink: false
            }
          })}
          ref={inputRef}
          size={size}
          sx={{
            ...!isBorder && { 
              '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline': { 
                border: 'none' 
              } 
            },
            '& label': {
              zIndex: '0',
              ...(!inputLabelVisible && (inputValue || value || values.length > 0)) && ({
                display: 'none',
              }),
            },
            '& label[data-shrink="false"]': {
              top: '50%',
              transform: 'translate(14px, -50%)'
            },
            '& label.Mui-focused': {
              ...!inputLabelVisible && ({
                display: 'none',
              }),
            },
            ...inputStyle,
            ...isMobile && {
              '& input': {
                py: '0 !important',
              },
              '& .MuiSelect-select': {
                paddingTop: '8.5px',
                paddingBottom: '8.5px'
              },
            },
          }}
        />
      )}
      onInputChange={(e, val) => setInputValue(val)}
      {...isMultiple ? {
        limitTags: 2,
        onChange: (e, val) => {
          onSelect(e, val);
        }
      } : {
        onSelect: (e) => {
          handleChange(e);
        },
      }}
    />
  ); 
};
