import React from "react";
import { Typography, Stack } from "@mui/material";

// ----------------------------------------------------------------------

export default function FormBlockContent() {
  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.3202 9.77335L20.4002 3.10668C20.2755 2.96856 20.1233 2.85806 19.9534 2.78228C19.7835 2.7065 19.5996 2.66712 19.4135 2.66668H8.74683C8.30383 2.6614 7.86413 2.74343 7.45283 2.90809C7.04153 3.07275 6.6667 3.31682 6.34974 3.62635C6.03277 3.93589 5.77989 4.30483 5.60552 4.7121C5.43115 5.11938 5.33872 5.55701 5.3335 6.00002V26C5.33872 26.443 5.43115 26.8807 5.60552 27.2879C5.77989 27.6952 6.03277 28.0641 6.34974 28.3737C6.6667 28.6832 7.04153 28.9273 7.45283 29.0919C7.86413 29.2566 8.30383 29.3386 8.74683 29.3333H23.2535C23.6965 29.3386 24.1362 29.2566 24.5475 29.0919C24.9588 28.9273 25.3336 28.6832 25.6506 28.3737C25.9675 28.0641 26.2204 27.6952 26.3948 27.2879C26.5692 26.8807 26.6616 26.443 26.6668 26V10.6667C26.666 10.3362 26.5424 10.0179 26.3202 9.77335V9.77335ZM18.6668 5.33335L23.6535 10.6667H19.6535C19.5139 10.6583 19.3773 10.6221 19.2518 10.5603C19.1263 10.4986 19.0144 10.4124 18.9225 10.3069C18.8307 10.2014 18.7608 10.0787 18.7169 9.94585C18.673 9.81303 18.656 9.67281 18.6668 9.53335V5.33335Z"
          fill="#637381"
        />
        <path
          d="M15.999 13.3334C16.2063 13.3318 16.409 13.3947 16.579 13.5134L20.579 16.3334C21.0278 16.6518 21.1351 17.2729 20.819 17.7234C20.6659 17.9418 20.4319 18.0901 20.169 18.1351C19.9061 18.1802 19.6361 18.1184 19.419 17.9634L15.999 15.5734L12.599 18.1334C12.1572 18.4647 11.5304 18.3752 11.199 17.9334C10.8676 17.4915 10.9572 16.8647 11.399 16.5334L15.399 13.5334C15.5721 13.4036 15.7826 13.3334 15.999 13.3334Z"
          fill="#F4F6F8"
        />
        <path
          d="M15.9985 15.3334C16.5508 15.3334 16.9985 15.7812 16.9985 16.3334L16.9985 24.3334C16.9985 24.8857 16.5508 25.3334 15.9985 25.3334C15.4463 25.3334 14.9985 24.8857 14.9985 24.3334L14.9985 16.3334C14.9985 15.7812 15.4463 15.3334 15.9985 15.3334Z"
          fill="#F4F6F8"
        />
      </svg>

      <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
        파일을 업로드 해주세요.&nbsp;
      </Typography>
    </Stack>
  );
}
