import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { CustomSmallSelect } from "components/custom-input";
import { LanguageCodeEnum } from "generated/graphql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import Term from "user/component/Term";
import { termLanguageState, userStoreState } from "./atom";

const language = [
  {
    key: LanguageCodeEnum.En,
    value: "English",
  },
  {
    key: LanguageCodeEnum.Zh,
    value: "中文",
  },
];

export default function TermField() {
  const { ready, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const { name, contact, nationalIdNumber } = useRecoilValue(userStoreState);

  const [termLanguage, setTermLanguage] = useRecoilState(termLanguageState);

  useEffect(() => {
    if (!ready) return;

    setTermLanguage(i18n.language as LanguageCodeEnum);
    setIsLoading(false);
  }, [i18n.language, ready]);

  return (
    <Card>
      {i18n.language !== LanguageCodeEnum.Ko && (
        <CardHeader
          action={
            <CustomSmallSelect
              value={termLanguage}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setTermLanguage(event.target.value as LanguageCodeEnum)
              }
            >
              {language.map((option, i) => (
                <option key={`lang_${i}`} value={option.key}>
                  {option.value}
                </option>
              ))}
            </CustomSmallSelect>
          }
        />
      )}

      <Box height={"24px"} />

      <CardContent sx={{ padding: 0 }}>
        {!isLoading && (
          <Term
            name={name}
            contact={contact}
            nationalIdNumber={nationalIdNumber}
            language={termLanguage}
          />
        )}
      </CardContent>
    </Card>
  );
}
