import React, { useMemo } from "react";
import { SelectBox } from "acon-mui/components";
import { useTranslation } from "react-i18next";

export default function ScrollSelector() {
  const { t } = useTranslation();

  const options = useMemo(() => [
    { id: 1, label: t("document.productType"), value: 'hubWrite_field_document_type' },
    { id: 2, label: t("document.title.label"), value: 'hubWrite_field_title' },
    { id: 3, label: t('goods.price'), value: 'hubWrite_field_price' },
    { id: 4, label: t("goods.model"), value: 'hubWrite_field_file' },
    { id: 5, label: t("goods.copyright"), value: 'hubWrite_field_copyright' },
    { id: 6, label: t("thumbnail"), value: 'hubWrite_field_image' },
    { id: 7, label: t("document.productDetails"), value: 'hubWrite_field_content' },
    { id: 8, label: t("document.keywords.title"), value: 'hubWrite_field_keywords' }
  ], [t]);

  const handleSelect = (value) => {
    const currentClassName = options.find(x => x.id === value)?.value;
    const ele = (document.getElementsByClassName(currentClassName) as HTMLCollectionOf<HTMLElement>)[0];
    if (document && ele !== null) {
      window.scrollTo(0, ele.offsetTop - 150);
    }
  };

  return (
    <SelectBox
        placeholder={t('document.shortcut')}
        inputLabelVisible={false}
        options={options}
        onSelect={(e, val) => {
          handleSelect(val.id);
        }}
        size="small"
        width="220px"
        marginRight="auto"
    />
  )
};
