import { WhiteButton } from 'components/button';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ButtonRow, View, ViewItem, ViewItemTitle, ViewItemValue } from '../style';
import { AppContext } from 'app';
import { Tab, TabView, Input } from './style';
import { DELETE_BRAND_ITEM, SET_BRAND_ITEM } from 'api/mutations';
import { useMutation } from '@apollo/client';

export default (({ refetch, itemObj, initSelectItem }) => {
    const { t } = useTranslation();

    // 모달 표시 메소드 불러오기
    const { showAlertMessage, showConfirmMessage } = useContext(AppContext);
    // 항목 ID 
    const [itemId, setItemId] = useState('');
    // 브랜드 ID 
    const [brandId, setBrandId] = useState('');
    // 고도몰 명 
    const [godoName, setGodoName] = useState('');

    // 저장하기 액션
    const [saveMutation] = useMutation(SET_BRAND_ITEM);
    // 삭제하기 액션
    const [deleteMutation] = useMutation(DELETE_BRAND_ITEM);

    // 활성화 된 언어 
    const [activeLanguage, setActiveLanguage] = useState('ko');
    // 하위 항목 배열 
    const [itemArr, setItemArr] = useState([]);

    useEffect(() => {
        const { id, godoBrandCd, name, item } = itemObj;

        setItemId(id);
        setBrandId(godoBrandCd);
        setGodoName(name);
        setActiveLanguage('ko');
        // 항목 조립 
        item && setItemArr(item.map(x => {
            return { name: x.name, lang: x.language.code }
        }));
    }, [itemObj]);

    // 삭제하기 버튼 이벤트 처리기 메소드 
    const onClickRemove = async () => {
        // 안내 메세지 표시 
        showConfirmMessage(
            t('reallyDelete'),
            t('loseContents'),
            async () => {
                // 삭제하기 실행 
                await deleteMutation({
                    variables: {
                        brandId: itemId,
                    }
                });
                // 안내 메세지 표시
                showAlertMessage(t('deleted'), t('deleted'));
                // 선택된 항목 초기화 
                initSelectItem();
                // 정보 재확보 
                refetch();
            }
        );
    };
    // 저장하기 버튼 이벤트 처리기 메소드
    const onClickSave = async () => {
        // 삭제하기 실행 
        await saveMutation({
            variables: {
                brandId: itemId,
                koName: itemArr.find(x => x.lang === 'ko')?.name,
                enName: itemArr.find(x => x.lang === 'en')?.name,
                cnName: itemArr.find(x => x.lang === 'cn')?.name,
                jpName: itemArr.find(x => x.lang === 'jp')?.name
            }
        });
        // 안내 메세지 표시
        showAlertMessage(t('saved'), t('saved'));
        // 정보 재확보 
        refetch();
    };

    return (
        <View>
            <ViewItem>
                <ViewItemTitle>{t('itemId')}</ViewItemTitle>
                <ViewItemValue>{itemId}</ViewItemValue>
            </ViewItem>
            <ViewItem>
                <ViewItemTitle>{t('brandId')}</ViewItemTitle>
                <ViewItemValue>{brandId}</ViewItemValue>
            </ViewItem>
            <ViewItem>
                <ViewItemTitle>{t('godoName')}</ViewItemTitle>
                <ViewItemValue>{godoName}</ViewItemValue>
            </ViewItem>

            <TabView>
                <Tab onClick={() => { setActiveLanguage('ko'); }} active={activeLanguage === 'ko'}>KO</Tab>
                <Tab onClick={() => { setActiveLanguage('en'); }} active={activeLanguage === 'en'}>EN</Tab>
                <Tab onClick={() => { setActiveLanguage('cn'); }} active={activeLanguage === 'cn'}>CN</Tab>
                <Tab onClick={() => { setActiveLanguage('jp'); }} active={activeLanguage === 'jp'}>JP</Tab>
            </TabView>

            {(() => {
                const clone = [...itemArr];

                let obj = clone.find(x => x.lang === activeLanguage);
                if (!obj) {
                    obj = {
                        name: '',
                        lang: activeLanguage
                    };
                    clone.push(obj);
                }
                return (
                    <ViewItem>
                        <ViewItemTitle>{t('brandName')}</ViewItemTitle>
                        <ViewItemValue>
                            <Input value={obj.name} onChange={e => {
                                clone.find(y => obj.lang === y.lang).name = e.target.value;
                                setItemArr(clone);
                            }} />
                        </ViewItemValue>
                    </ViewItem>
                );
            })()}

            <ButtonRow>
                <WhiteButton onClick={onClickSave}>{t('save')}</WhiteButton>
                <WhiteButton onClick={onClickRemove}>{t('remove')}</WhiteButton>
            </ButtonRow>
        </View>
    );
});