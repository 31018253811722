import { downloadFile } from "api";
import Loading from "common/editor/Loading";
import { SmallWhiteButton } from "common/style";
import React, { useEffect, useState } from "react";
import { Select } from "user/User/style";
import { Field } from "../common/field";
import {
  FileInputComponent,
  InputField,
  MaskingInputField,
} from "../common/input";
import { BANKS } from "api/quries";
import { useQuery } from "@apollo/client";
import BankModal from "signup/popup/bank";

const Component = (props) => {
  const { data, isAdmin } = props;

  const { loading, error, data: bankData } = useQuery(BANKS);

  const [isShowBankModal, setIsShowBankModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idCardName, setIdCardName] = useState(data.user.settle.idCard?.path);
  const [bankBookName, setBankBookName] = useState(
    data.user.settle.bankBook?.path
  );
  const [bank, setBank] = useState(data.user.settle.bankId);
  const [bankAccountOwner, setBankAccountOwner] = useState(
    data.user.settle.bankAccountOwner
  );

  const onChangeData = (e) => {
    const element = e.target;
    const key = element.dataset.key;
    let value = element.value;

    if (element.dataset.allowNum) {
      value = element.value.replace(/[^0-9\-]/g, "");
      element.value = value;
    }

    props.onChangeData(key, value);

    if (key === "name") {
      setBankAccountOwner(value);
    }
  };

  const download = (uploadFileId, fileName) => {
    setIsLoading(true);

    try {
      downloadFile(uploadFileId, fileName);
    } finally {
      setIsLoading(false);
    }
  };

  const hideBankModal = () => {
    setIsShowBankModal(false);
  };

  const onChangeType = (value) => {
    const idx = data.user.typeStringList.indexOf(value);
    props.onChangeData("type", idx.toString());
  };

  const onSelectBank = (e) => {
    const item = +e.target.dataset.value;
    console.log(item);
    props.onChangeData("settle.bankId", item);
    setBank(item);
    hideBankModal();
  };

  const onChangeRrn = (e, value) => {
    if (+e.target.dataset.idx === 0 && e.target.value.length > 5) {
      e.target.nextSibling.nextSibling.focus();
    }
    props.onChangeData("settle.nationalIdNumber", value);
  };

  const onChangeIdCard = (e) => {
    const file = e.target.files[0];

    const result = {
      id: data.user.settle?.idCard?.id,
      path: file.name,
      fileName: data.user.settle?.idCard?.fullName,
      file,
    };

    setIdCardName(file.name);

    props.onChangeData("file.idCard", result);
  };

  const onChangeBankBook = (e) => {
    const file = e.target.files[0];

    const result = {
      id: data.user.settle?.bankBook?.id,
      path: file.name,
      fileName: data.user.settle?.bankBook?.fullName,
      file,
    };

    setBankBookName(file.name);

    props.onChangeData("file.bankBook", result);
  };

  useEffect(() => {
    props.onChangeData("settle.bankAccountOwner", bankAccountOwner);
  }, [bankAccountOwner]);

  return (
    <>
      {bankData?.banks && (
        <BankModal
          isShow={isShowBankModal}
          onHide={hideBankModal}
          onSelect={onSelectBank}
          selected={bank}
          bankList={bankData?.banks}
        />
      )}
      {isLoading && <Loading />}
      {isAdmin && (
        <Field label="구분">
          <Select
            data={data.user.typeStringList}
            value={data.user.typeStringList[0]}
            onChange={onChangeType}
          />
        </Field>
      )}
      <InputField
        label="이름"
        data-key="name"
        defaultValue={data.user.name}
        onChange={onChangeData}
      />
      {isAdmin ? (
        <MaskingInputField
          label="주민등록번호"
          split="-"
          maxLength={[6, 7]}
          data-key="settle.nationalIdNumber"
          defaultValue={data.user.settle.nationalIdNumber}
          onChange={onChangeRrn}
        />
      ) : (
        <MaskingInputField
          label="주민등록번호"
          split="-"
          maxLength={[6, 7]}
          masking="1"
          data-key="settle.nationalIdNumber"
          defaultValue={data.user.settle.nationalIdNumber}
          onChange={onChangeRrn}
        />
      )}
      <Field label="신분증 사본">
        {isAdmin && (
          <div>
            <SmallWhiteButton
              onClick={() =>
                download(
                  data.user.settle.idCard.id,
                  data.user.settle.idCard.path
                )
              }
            >
              다운로드
            </SmallWhiteButton>
          </div>
        )}
        <FileInputComponent value={idCardName} onChange={onChangeIdCard} />
      </Field>
      <Field label="은행">
        {bankData?.banks && (
          <Select
            readOnly
            value={bankData.banks.find((a) => a.id === bank)?.name}
            onClick={() => setIsShowBankModal(true)}
          />
        )}
      </Field>
      {bankData?.banks.length === bank && (
        <InputField
          label="기타 은행명"
          data-key="settle.bankName"
          defaultValue={data.user.settle.bankName || ""}
          onChange={onChangeData}
        />
      )}
      <InputField
        label="예금주"
        readOnly
        data-key="settle.bankAccountOwner"
        value={bankAccountOwner}
        onChange={(e) => setBankAccountOwner(e.target.value)}
      />
      <InputField
        label="계좌번호"
        data-key="settle.bankAccountNumber"
        data-allow-num
        defaultValue={data.user.settle.bankAccountNumber}
        onChange={onChangeData}
      />
      <Field label="통장 사본">
        {isAdmin && (
          <div>
            <SmallWhiteButton
              onClick={() =>
                download(
                  data.user.settle.bankBook.id,
                  data.user.settle.bankBook.path
                )
              }
            >
              다운로드
            </SmallWhiteButton>
          </div>
        )}
        <FileInputComponent value={bankBookName} onChange={onChangeBankBook} />
      </Field>
    </>
  );
};

export default Component;
