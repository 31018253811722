import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import queryString from 'query-string';
import './BannerWrite.scss';
import { AppContext } from '../app'
import { CREATE_BENEFIT_BANNER, UPDATE_BENEFIT_BANNER, DELETE_BENEFIT_BANNER } from '../api/mutations';
import { GET_BENEFIT_BANNER_WRITING_MATERIALS } from '../api/quries';
import DateSetting from '../common/DateSetting';
import { uploadFile } from '../api'
import CouponDownloadIcon from '../img/coupon-download.png';
import UploadIcon from '../img/upload-icon.png';
import AutosizeInput from 'react-input-autosize';

const hiddenFileInput = document.createElement("input");
hiddenFileInput.setAttribute("type", "file");

export default ((props) => {
    const { userInfo } = useContext(AppContext);

    // 관리자 권한이 없을 경우 홈 화면으로 돌려보냅니다.
    const { isAdmin } = userInfo;
    if (!isAdmin) {
        props.history.push("/");
        return <></>;
    }

    // 배너 DB id
    const docId = +props.match.params.docId;

    // 모달 표시 메소드 불러오기
    const {
        showAlertMessage,
        showNoButtonMessage
    } = useContext(AppContext);

    // 번역기 불러오기
    const { t } = props;

    /* State 설정 */
    // 쿠폰 메인 타이틀
    const [couponTitle, setCouponTitle] = useState('');
    // 쿠폰 서브 타이틀 1
    const [couponSubTitle1, setCouponSubTitle1] = useState('');
    // 쿠폰 서브 타이틀 2
    const [couponSubTitle2, setCouponSubTitle2] = useState('');
    // 이미지 업로드
    const [fileInputName, setFileInputName] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    // 언어
    const [languageValue, setLanguageValue] = useState(1);
    // 쿠폰 번호
    const [couponNoValue, setCouponNoValue] = useState('');
    // 배너 표시 시작일 yyyy-mm-dd
    const [startDate, setStartDate] = useState(null);
    // 배너 표시 종료일 yyyy-mm-dd
    const [endDate, setEndDate] = useState(null);
    /* State 설정 끝 */

    // 초기 설정
    useEffect(() => {
        hiddenFileInput.value = '';
        hiddenFileInput.type = '';
        hiddenFileInput.type = 'file';
    }, []);

    // 배너타입, 몰(국가) 설정을 위한 선택지 불러오기, 배너 아이디가 있을 경우 내용 가져오기
    const { data } = useQuery(GET_BENEFIT_BANNER_WRITING_MATERIALS, {
        fetchPolicy: "no-cache",
        variables: { id: docId || 0 }
    });
    const languages = data?.getLanguages;
    const bannerData = data?.getItem;

    useEffect(() => {
        // 해당 배너 ID에 대한 데이터가 있을 경우 값 세팅
        if (bannerData) {
            // 공통 항목 세팅
            setCouponTitle(bannerData.title);
            setCouponSubTitle1(bannerData.conditionFirst);
            setCouponSubTitle2(bannerData.conditionSecond);
            setCouponNoValue(bannerData.couponNo);
            setImageUrl(bannerData.image?.fullName);
            setLanguageValue(bannerData.language.id);
            setStartDate(new Date(bannerData.startDate));
            setEndDate(new Date(bannerData.endDate));
        }
    }, [bannerData]);

    // 저장 쿼리
    const [updateBandBannerMutation] = useMutation(UPDATE_BENEFIT_BANNER);
    const [createBandBannerMutation] = useMutation(CREATE_BENEFIT_BANNER);
    // 삭제 쿼리
    const [deleteBenefitBannerMutation] = useMutation(DELETE_BENEFIT_BANNER);

    // 저장 쿼리 파라미터
    const queryVariables = {
        id: null,
        langId: languageValue,
        title: couponTitle,
        conditionFirst: couponSubTitle1,
        conditionSecond: couponSubTitle2,
        couponNo: Number(couponNoValue),
        startDate: startDate || new Date(),
        endDate: endDate || new Date("2099-01-01T12:00Z")
    }

    if (docId) queryVariables.id = docId;

    // 저장 버튼 클릭 이벤트
    const onClickSaveButton = async () => {
        try {
            let imageKey = null;
            
            if(imageUrl && fileInputName) {
                // 이미지 업로드 중 알림 문구
                showNoButtonMessage(t("Banner.writer.imageUploadMessage.title"), t("Banner.writer.imageUploadMessage.content"));
                // 저장 전 이미지 먼저 저장 후 이미지 키를 세팅합니다.
                await uploadFile(hiddenFileInput.files[0], true).then((r) => {
                    console.log("complete image upload :)")
                    imageKey = {
                        key: r.key,
                        name: r.key.split('/').pop()
                    }
                    return null;
                }).catch((err) => {
                    throw new Error(`image upload failed(${err.message})`)
                });
            }

            // 생성 및 저장 쿼리
            // 2021-10-01 변경해야 함.
            let mutation = docId ? updateBandBannerMutation : createBandBannerMutation;

            const { data } = await mutation({
                variables: {  ...queryVariables, imageKey }
            });

            // 2021-10-01 쿼리 실행 후 받는 부분 수정해야 함.
            const isSucceed = data?.updateItem || data?.createItem;

            if (isSucceed) {
                showAlertMessage(t('saved'), t('saved'))
                const langCode = languages?.find(x => x.id === languageValue).code;
                props.history.push({
                    pathname: "/banner/benefitPage",
                    search: queryString.stringify({ lang: langCode })
                });
            } else {
                throw new Error("graphql query failed")
            }
        } catch (err) {
            console.log(err);
            // (TODO: 에러 로깅)
            showAlertMessage(t('serverError'), t('serverError'))
        }
    };

    // 게시글 삭제하는 함수
    const deleteDoc = async (e) => {
        if (docId) {
            const mutation = deleteBenefitBannerMutation;

            const { data } = await mutation({
                variables: { id: docId }
            })

            if (data?.deleteItem) {
                showAlertMessage(t("Boards.delete.title"), t("Boards.delete.content"))
            } else {
                showAlertMessage(t("Boards.delete.title"), t("Boards.deleteFail.content"))
            }

            props.history.goBack()
        }
    };

    // lang 설정 UI
    const LanguageSelectTag = () => {
        const options = languages?.map((e, i) => {
            return (
                <option key={i} value={e.id}>{e.code.toUpperCase()}</option>
            )
        })
        return (
            <select className={'commonSelectBox'} onChange={onChangeLanguage} value={languageValue}>
                {options}
            </select>
        )
    }
    // lang 설정 변경시 작동하는 로직
    const onChangeLanguage = (e) => {
        setLanguageValue(Number(e.target.value))
    }

    // 이미지 업로드
    const onClickUpload = () => {
        hiddenFileInput.addEventListener("change", () => {
            if (!hiddenFileInput.files[0]) return
            setFileInputName(hiddenFileInput.files[0].name)

            const fileReader = new FileReader();
            fileReader.onload = () => {
                setImageUrl(fileReader.result);
            }

            fileReader.readAsDataURL(hiddenFileInput.files[0]);
        });

        hiddenFileInput.click();
    }

    const preventDefault = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    // 이미지 업로드 드랍 처리
    const onDropUpload = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // 파일들
        const files = e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files;

        // 파일이 존재하지 않을경우 
        if (!files)
            // 종료 
            return;

        // 파일 이름 설정
        setFileInputName(files[0].name);

        // 드랍된 파일 설정
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setImageUrl(fileReader.result);
        }
        
        hiddenFileInput.files = files;
        fileReader.readAsDataURL(files[0]);
    }

    // 버튼 그룹 (미리보기, 저장, 삭제) 
    const btnGroup = (
        <div style={{ 'display': 'flex', 'justify-content': 'space-between', 'margin-top': '30px' }} className="banner-write_buttonGroup">
            <div></div>
            <div>
                <button
                    className="black-button"
                    type="button"
                    onClick={onClickSaveButton}
                >
                    {t("Save.label")}
                </button>
                {
                    (!! docId) &&
                    <button
                        className="white-button"
                        type="button"
                        onClick={deleteDoc}
                    >
                        {t("Remove.label")}
                    </button>
                }
            </div>
        </div>
    );

    return (
        <div className={'banner-write'}>
            { /* 버튼 영역 */ }
            { btnGroup }

            { /* 배너 등록 */ }
            <div className={'fieldContainer'}>
                <div className={'fieldTitle'}>
                    {t('Banner.writer.create')}
                </div>
                <div className={'fieldBenefitBanner'}>
                    <div className={'benefitBannerLeft'}>
                        <div>
                            <AutosizeInput 
                                value={ couponTitle }
                                data-type={'title'} 
                                onChange={ (e) => { setCouponTitle(e.target.value) } }
                                className={'benefitBannerTitle'} 
                                placeholder={'내용을 입력하세요'}
                                style={{ border: 'none' }}
                            />
                        </div>
                        <div>
                            <AutosizeInput 
                                value={ couponSubTitle1 }
                                data-type={'subTitle1'} 
                                onChange={ (e) => { setCouponSubTitle1(e.target.value) } }
                                className={'benefitBannerSubTitle'} 
                                placeholder={'• 조건 1을 입력하세요'}
                                style={{ border: 'none' }}
                            />
                        </div>
                        <div>
                            <AutosizeInput 
                                value={ couponSubTitle2 }
                                data-type={'subTitle2'} 
                                onChange={ (e) => { setCouponSubTitle2(e.target.value) } }
                                className={'benefitBannerSubTitle'} 
                                placeholder={'• 조건 2를 입력하세요'}
                                style={{ border: 'none' }}
                            />
                        </div>
                    </div>
                    <div className={'benefitBannerRight'}>
                        <div className={'benefitBannerUpload'} 
                             onClick={onClickUpload} 
                             onDragEnter={preventDefault} 
                             onDragOver={preventDefault} 
                             onDragLeave={preventDefault}
                             onDrop={onDropUpload}
                        >
                            <div style={{ opacity: imageUrl ? 0 : 1 }} className={'benefitBannerBackground'}>
                                <img src={UploadIcon} 
                                        alt={'couponImageUpload'} 
                                        title={'couponImageUpload'}
                                        width={60}
                                        height={60}
                                />
                                <div className={'benefitBannerUploadText'}>Drag and drop or Browse</div>
                            </div>
                            { imageUrl &&
                                <div className={'benefitBannerPreview'}>
                                    <img style={{ objectFit: 'scale-down', 'width': '100%', 'height': '100%' }} src={imageUrl} alt={'couponBanner'} title={'couponBanner'} />
                                </div>
                            }
                        </div>
                        <div className={'couponDownload'}>
                            <div className={'couponDownloadText'}>
                                {(() => {
                                    // 각 국가별 언어에 맞게 문구를 설정하고 값이 없거나 JP는 EN 문구를 설정합니다.
                                    const langaugeCode = languages?.find(x => x.id === languageValue).code;
                                    let downloadText = 'Download';

                                    if(langaugeCode === 'ko') {
                                        downloadText = '쿠폰 다운로드';
                                    } else if(langaugeCode === 'cn') {
                                        downloadText = '下载优惠券';
                                    }

                                    return downloadText;
                                })()}
                            </div>
                            <img className={'couponDownloadIcon'} src={CouponDownloadIcon} alt={'couponDownload'} title={'couponDownload'} />
                        </div>
                    </div>
                </div>
            </div>
            
            { /* 몰 선택 */ }
            <div className={'fieldContainer'}>
                <div className={'fieldTitle'}>
                    {t('Banner.writer.langauge')}
                </div>
                <LanguageSelectTag />
            </div>

            { /* 쿠폰 번호 입력 */ }
            <div className={'fieldContainer'}>
                <div className={'fieldTitle'}>
                    {t('Banner.writer.coupon.title')}
                </div>
                <input type="text" value={couponNoValue} onChange={(e) => { setCouponNoValue(e.target.value) }} className={'commonInputBox'} placeholder={t('Banner.writer.coupon.placeholder')} />
            </div>

            { /* 노출 기간 */ }
            <div className={'fieldContainer'}>
                <div className={'fieldTitle'}>
                    {t('Banner.writer.date')}
                </div>

                <DateSetting
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
            </div>
        </div>
    );
});