import React, { useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ReactComponent as Chevron } from '../icons/icon-chevron.svg';
import { getI18n } from 'react-i18next';
import { color } from 'acon-mui/style';

export default (({ 
  pageName='page', limit, totalCnt, pathName, history, buttonVisible=false }) => {
  const i18n = getI18n();
  if (pathName) {
  pathName = pathName.replace(/\/$/, '')
  } else {
  pathName = '/'
  }

  // 현재 페이지 
  let currentPage = (() => {
  const page = queryString.parse(history.location.search)[pageName];
  return page ? Number(page) : 1;
  })();

  const getUrlQuery = useCallback((page) => {
    const urlParamObj = queryString.parse(history.location.search);
    urlParamObj[pageName] = page;
    return queryString.stringify(urlParamObj);
  },[history.location.search, pageName]);



  const prevBtn = useMemo(function () {
    var btn = (<></>);
    // 현재 페이지가 1이 아닐경우 
    const isFirstPage = currentPage === 1;
    if (!isFirstPage) {
      btn = (<NavLink to={pathName + "/?" + getUrlQuery(currentPage - 1)} style={{ padding: '0 16px' }}>
        <Chevron style={{ transform: 'rotate(90deg)' }} />
      </NavLink>);
    }
    if (buttonVisible && isFirstPage) {
      btn = (<Box px={2} sx={{ '& path': { fill: color.gray.border } }}>
        <Chevron style={{ transform: 'rotate(90deg)'}} />
      </Box>);
    }

    return btn;
  }, [buttonVisible, currentPage, getUrlQuery, pathName]);

  const nextBtn = useMemo(function () {
    var btn = (<></>);
    // 현재 페이지가 최대 페이지가 아닐경우 
    const isLastPage = currentPage === Math.ceil(totalCnt / limit);
    if (!isLastPage) {
      btn = (<NavLink to={pathName + "/?" + getUrlQuery(currentPage + 1)} style={{ padding: '0 16px' }}>
        <Chevron style={{ transform: 'rotate(-90deg)'}} />
      </NavLink>);
    }
    if (buttonVisible && isLastPage) {
      btn = (<Box px={2} sx={{ '& path': { fill: color.gray.border } }}>
        <Chevron style={{ transform: 'rotate(-90deg)'}} />
      </Box>);
    }

    return btn;
  },[buttonVisible, currentPage, getUrlQuery, pathName, limit, totalCnt]);

  return (
  <>
    <Box display="flex">
      <Typography>
        {i18n.language === 'ko' ? <>
          {totalCnt} 중 {1 + (currentPage - 1) * limit}-{currentPage * limit}
        </> : <>
          {1 + (currentPage - 1) * limit}-{currentPage * limit} of {totalCnt}
        </>}
      </Typography>
      {prevBtn}
      {nextBtn}
    </Box>
  </>
  );
});
