import { Typography, Box, Button, Stack } from "@mui/material";
import { LanguageCodeEnum } from "generated/graphql";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

let isDrawing = false;
let prevPoint = null;

type Props = {
  file?: File;
  isDrawn: boolean;
  setIsDrawn: (isDrawing: boolean) => void;
};

export default function Sign({ file, isDrawn, setIsDrawn }: Props) {
  // 번역 도구
  const { t, i18n } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!file) return;

    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setIsDrawn(true);
        const image = new Image();
        image.title = file.name;
        image.src = reader.result as string;
        image.onload = () => {
          const context = canvasRef.current.getContext("2d");
          context.drawImage(image, 0, 0);
        };
      },
      false
    );

    reader.readAsDataURL(file);
  }, [file]);

  async function getBlob() {
    const { current: canvas } = canvasRef;

    return new Promise((resolve, reject) => {
      canvas.toBlob(resolve);
    });
  }

  function draw({ x, y }) {
    const { current: canvas } = canvasRef;
    const context = canvas.getContext("2d");

    context.beginPath();
    context.arc(x, y, 5, 0, 2 * Math.PI);
    context.fill();
    context.closePath();

    context.beginPath();
    context.moveTo(prevPoint.x, prevPoint.y);
    context.lineTo(x, y);
    context.lineWidth = 12;
    context.stroke();
    context.closePath();
  }

  function getPosition({ clientX, clientY }) {
    const { current: canvas } = canvasRef;
    const { x, y } = canvas.getBoundingClientRect();
    // console.log(clientX,clientY);
    return {
      x: clientX - x,
      y: clientY - y,
    };
  }

  function onDrawStart(e) {
    setIsDrawn(true);
    isDrawing = true;
    prevPoint = getPosition(e);
  }

  function onDraw(e) {
    // 그리기

    if (isDrawing) {
      draw(getPosition(e));
      prevPoint = getPosition(e);
    }
  }

  function onDrawEnd(e) {
    // 그리기 끝
    isDrawing = false;
    prevPoint = null;
  }

  const handleReset = () => {
    const { current: canvas } = canvasRef;
    const context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);

    setIsDrawn(false);
  };

  useEffect(() => {
    const { current: canvas } = canvasRef;

    canvas.width = 776;
    canvas.height = 300;
  }, []);

  return (
    <Box>
      <Stack
        sx={{
          width: "100%",
          height: "300px",
          position: "relative",
          background: "rgba(145, 158, 171, 0.12)",
          border: "1px solid rgba(145, 158, 171, 0.32)",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
          borderRadius: "16px",
        }}
      >
        {!isDrawn && (
          <Stack
            spacing={0.5}
            textAlign={"center"}
            position={"absolute"}
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
          >
            {i18n.language === LanguageCodeEnum.Ko ? (
              <Typography
                color={"grey.800"}
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "30px",
                }}
              >
                네모 칸 속에 서명을 그려주세요.
              </Typography>
            ) : (
              <>
                <Typography
                  color={"grey.800"}
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "30px",
                  }}
                >
                  Please write your signature here
                </Typography>
                <Typography
                  color={"grey.800"}
                  sx={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  (If you do not sign here, you may need to rewrite the
                  contract.)
                </Typography>
              </>
            )}
          </Stack>
        )}
        <canvas
          id="sign"
          style={{ position: "absolute", zIndex: 3 }}
          ref={canvasRef}
          onMouseDown={onDrawStart}
          onMouseMove={onDraw}
          onMouseUp={onDrawEnd}
          onMouseLeave={onDrawEnd}
        />
        <Button
          onClick={handleReset}
          sx={{
            zIndex: 4,
            position: "absolute",
            bottom: "18px",
            right: "18px",
            background: "#fff",
            border: "1px solid rgba(145, 158, 171, 0.32)",
            borderRadius: "8px",
          }}
        >
          <Typography variant="button" color={"grey.800"}>
            {t("signup.resetSign")}
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
