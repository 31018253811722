export function Masking(props) {
    let target = '';
    let result = '';

    if (typeof props === 'string') {
        target = props;
    } else {
        target = props.children;
    }

    result += target.slice(0, 2);
    result += ''.padStart(target.length - 4, '*');
    result += target.slice(-2);

    return result;
}