export class CategoryTree {
  value: {
    id: number;
    code: string;
    name: string;
    parent: number;
  };
  children: CategoryTree[];
  constructor(value) {
    this.value = value;
    this.children = [];
  }

  insertNode(value) {
    const childNode = new CategoryTree(value);
    this.children.push(childNode);
  }

  contains(value) {
    if (this.value.id === value.id) {
      return true;
    }

    for (let i = 0; i < this.children.length; i++) {
      const childNode = this.children[i];
      if (childNode.contains(value)) {
        return true;
      }
    }
    return false;
  }
}

export const recursiveTreeFunc = (treeList, node) => {
  for (const key in treeList) {
    if (treeList[key].value.id === node.parent) {
      treeList[key].insertNode(node);
      return;
    }
    recursiveTreeFunc(treeList[key].children, node);
  }
};
