import React, { useContext } from 'react';
import { AppContext } from '../app'
import BannerWrite from './BannerWrite';
import BenefitBannerWrite from './BenefitBannerWrite';
import EventBannerWrite from './EventBannerWrite';
import ProductBadgeWrite from './ProductBadgeWrite';

export default ((props) => {
    const { userInfo } = useContext(AppContext);
    // 관리자 권한이 없을 경우 홈 화면으로 돌려보냅니다.
    const { isAdmin } = userInfo;
    if (!isAdmin) {
        props.history.push("/")
        return <></>;
    }

    // 번역기 불러오기
    const { match } = props;
    // 배너 종류: 상세페이지(detailPage) || 메인페이지(mainPage)
    const bannerDisplayLocation = match.params.displayLocation || "detailPage";

    return (
        <>
            { ['fullPage', 'detailPage'].includes(bannerDisplayLocation) && 
                <BannerWrite {...props} />
            }
            { bannerDisplayLocation === 'benefitPage' && 
                <BenefitBannerWrite {...props} />
            }
            { bannerDisplayLocation === 'eventPage' &&
                <EventBannerWrite {...props} />
            }
            { bannerDisplayLocation === 'productBadgePage' &&
                <ProductBadgeWrite {...props} />
            }
        </>
    );
});