import React from 'react';
import { Field } from '../common/field';
import { Input, InputField } from '../common/input';

const Component = (props) => {
    const { user } = props;
    const brands = user.brand.item.slice().sort((a, b) => {
        return a.language.id - b.language.id;
    });

    return (
        <>
            <InputField readOnly label="작가 승인상태" value={user.accept.statusString} />
            <InputField readOnly label="아이디(이메일)" value={user.account} />
            <Field label={'필명'}>
                {brands.map((b, i, arr) => {
                    return (
                        <Input
                            readOnly
                            key={i}
                            style={arr.length - 1 > i ? { marginBottom: 10 } : {}}
                            value={b?.name}
                        />
                    );
                })}
            </Field>
            <InputField readOnly label="연락처" value={user.contact} />
            <InputField readOnly label="추가 이메일" value={user.contactAdditional} />
            <Field label="입점 검토 자료">
                { user.reference.map(ref => 
                    <Input readOnly value={ref.url} />
                )}
            </Field>
        </>
    );
};

export default Component;