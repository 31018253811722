import React from 'react';
import * as Styled from './styled';

export const BlackButtonModal = ({ contentsText, onClose }) => {
  return (
    <div>
      <Styled.Modal>
        <Styled.Contents>{contentsText}</Styled.Contents>
        <Styled.BlackButton onClick={onClose}>OK</Styled.BlackButton>
      </Styled.Modal>
      <Styled.Background />
    </div>
  );
};
