import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-sizing: border-box;
  width: 420px;
`;

export const ModalButton = styled.button`
  height: 32px;
  box-shadow: 0px 1.75px 3.5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #7c7c7c;
  box-shadow: 0px 1.75px 3.5px rgba(0, 0, 0, 0.1);
`;

export const ModalContent = styled.div`
  height: calc(100% - 20px);
  font-size: 12px;
  line-height: 1.5;
  background-color: #efefef;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  ${props => props.isImage
    ? ``
    : `border-top-left-radius: 8px;
      border-top-right-radius: 8px;`
  }
`;
