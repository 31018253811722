import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CustomButton, ResetButton } from "user/User/components/common/input";

const Component = (props) => {
  // 번역 도구
  const { t } = useTranslation();
  const ref = useRef();

  const [isDrawn, setIsDrawn] = useState(false);
  let isDrawing = false;
  let prevPoint = null;

  function init() {
    const { current: canvas } = ref;
    const context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);

    setIsDrawn(false);
    props.setIsDrawn(false);
  }

  async function getBlob() {
    const { current: canvas } = ref;

    return new Promise((resolve, reject) => {
      canvas.toBlob(resolve);
    });
  }

  function draw({ x, y }) {
    const { current: canvas } = ref;
    const context = canvas.getContext("2d");

    context.beginPath();
    context.arc(x, y, 5, 0, 2 * Math.PI);
    context.fill();
    context.closePath();

    context.beginPath();
    context.moveTo(prevPoint.x, prevPoint.y);
    context.lineTo(x, y);
    context.lineWidth = 12;
    context.stroke();
    context.closePath();
  }

  function getPosition({ clientX, clientY }) {
    const { current: canvas } = ref;
    const { x, y } = canvas.getBoundingClientRect();
    // console.log(clientX,clientY);
    return {
      x: clientX - x,
      y: clientY - y,
    };
  }

  function onDrawStart(e) {
    // 그리기 시작
    if (props.setIsDrawn) {
      props.setIsDrawn(true);
    }

    setIsDrawn(true);
    isDrawing = true;
    prevPoint = getPosition(e);
  }

  function onDraw(e) {
    // 그리기

    if (isDrawing) {
      draw(getPosition(e));
      prevPoint = getPosition(e);
    }
  }

  function onDrawEnd(e) {
    // 그리기 끝
    isDrawing = false;
    prevPoint = null;
  }

  useEffect(() => {
    const { current: canvas } = ref;
    const { width, height } = canvas.getBoundingClientRect();

    canvas.width = width;
    canvas.height = height;

    if (props.setGetBlob) {
      props.setGetBlob(() => getBlob);
    }
  }, []);

  return (
    <div className={props.className}>
      <div className="wrap">
        {!isDrawn && props.summary && (
          <div className="summary">{props.summary}</div>
        )}
        {useMemo(
          () => (
            <canvas
              ref={ref}
              id={props.id}
              onMouseDown={onDrawStart}
              onMouseMove={onDraw}
              onMouseUp={onDrawEnd}
              onMouseLeave={onDrawEnd}
            />
          ),
          []
        )}
        <ResetButton onClick={init}>{t("signup.resetSign")}</ResetButton>
      </div>
    </div>
  );
};

const StyledComponent = styled(Component)`
  .wrap {
    width: ${(props) => (props?.width ? props.width : "776px")};
    height: 300px;
    position: relative;
    padding-top: 32%;

    background: #f0f0f0;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
    border-radius: 6px;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    > canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .summary {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      font-size: 20px;
      line-height: 30px;
      line-height: 700;
      pointer-events: none;
    }
  }

  ${CustomButton} {
    margin: 0;
    margin-top: 10px;
  }
`;

const InputSign = StyledComponent;

export default InputSign;
