import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const CustomText = ({
  tagName = `div`,
  size = 14,
  color = "#333333",
  letter = 0.0,
  autoLetter = false,
  bold,
  medium,
  weight,
  lineHeight = "100%",
  numLines,
  style,
  marginLeft,
  className = "",
  children,
  verticalAlign,
  textAlign,
  ...rest
}) => {
  const { i18n } = useTranslation();
  // 한국어 여부
  const isKo = i18n.language === "ko";

  const styleObj = {
    fontSize: size,
    color: color,
    fontWeight: weight ? weight : bold ? 700 : medium ? 500 : 400,
    ...(!autoLetter
      ? letter && { letterSpacing: `${letter}em` }
      : isKo && { letterSpacing: "-0.01em" }),
    lineHeight: lineHeight,
    marginLeft: marginLeft,
    verticalAlign,
    textAlign,
    ...(numLines && {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: numLines,
      WebkitBoxOrient: "vertical",
    }),
    ...style,
  };

  // 커스텀 태그
  const CustomTag = `${tagName}`;

  return (
    <CustomTag style={styleObj} {...rest} className={className}>
      {children}
    </CustomTag>
  );
};

export const Flex = styled.div`
  display: flex;
  ${(props) => (props.width ? `width: ${props.width}%;` : ``)}
  ${(props) => (props.direction ? `flex-direction: ${props.direction};` : ``)}
    ${(props) => (props.bottom ? `margin-bottom: ${props.bottom}px;` : ``)}
    ${(props) => (props.align === "center" ? "align-items: center;" : "")}
`;
export const FlexBox = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  ${(props) => (props.width ? `width: ${props.width}%;` : ``)}
  ${(props) => (props.direction ? `flex-direction: ${props.direction};` : ``)}
    ${(props) =>
    props.bottom ? `margin-bottom: ${props.bottom}px;` : `margin-bottom: 10px;`}
`;
export const Select = styled.select`
  min-width: 110px;
  width: auto;
  height: 28px;
  padding: 5px 10px;
  box-sizing: border-box;
`;

export const Input = styled.input.attrs((props) => ({
  type: "text",
}))`
  min-width: 215px;
  height: 28px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  box-sizing: border-box;
`;
export const Label = styled.label`
  font-size: 12px;
  font-weight: 400;
  ${(props) => (props.width ? `width: ${props.width}px;` : ``)}
`;

export const Checkbox = styled.input.attrs((props) => ({
  type: "checkbox",
}))``;
export const Button = styled.button.attrs((props) => ({
  type: "button",
}))`
  min-width: 58px;
  height: 28px;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  text-align: center;

  &:hover {
    opacity: 0.5;
  }
`;

export const BlockContainer = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  background-color: white;
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const Anchor = styled.a`
  color: black;
  &:hover {
    color: black;
  }
`;
