import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import parse from 'html-react-parser';
import palette from 'theme/palette';
import { ReactComponent as IconArchive } from './archive.svg';
import { ReactComponent as IconBox } from './box-seam.svg';
import { ReactComponent as IconCash } from './cash-stack.svg';
import { ReactComponent as IconDownload } from './cloud-download.svg';
import { ReactComponent as IconUpload } from './cloud-upload.svg';
import { ReactComponent as IconGraph } from './graph-up.svg';
import { ReactComponent as IconPen } from './pen.svg';
import { ReactComponent as IconProtect } from './protect.svg';
import { ReactComponent as IconTelephone } from './telephone-inbound.svg';
import { ReactComponent as IconTwitter } from './twitter.svg';

const benefits = [
  {
    title: '상품 판매 지원',
    list: [
      { icon: <IconUpload/>, title: '간편한<br/>상품 등록' },
      { icon: <IconPen/>, title: '상세페이지<br/>번역 지원<br/>(영/중/일)' },
      { icon: <IconBox/>, title: '상품 진열 및 노출' },
      { icon: <IconDownload/>, title: '구매자 파일 전달' },
      { icon: <IconTwitter/>, title: 'SNS 홍보' },
    ],
  },
  {
    title: '상품 관리 지원',
    list: [
      { icon: <IconTelephone/>, title: '유저 문의 대응' },
      { icon: <IconGraph/>, title: '판매 기록 제공' },
      { icon: <IconCash/>, title: '수익 자동 정산' },
      { icon: <IconArchive/>, title: '파일 저장 및<br/>서버 관리' },
      { icon: <IconProtect/>, title: '저작권 보호' },
    ],
  }
];

export default function Benefits() {
  return (
    <Box
      p="60px"
      bgcolor={palette.dark.primary.main}
      color="#fff"
      textAlign="center"
    >
      <Box mx="auto" width="fit-content">
        <Typography component="h4" fontWeight={800} fontSize="32px">
          입점 기본 혜택
        </Typography>
        <Grid mt={5} mx="auto" display="grid" gridTemplateColumns="repeat(6, 1fr)" gap="20px 16px">
          {benefits.map(benefit => (
            <React.Fragment key={benefit.title}>
              <Grid item maxWidth="138px" textAlign="left">
                <Typography fontWeight={600}>
                  {benefit.title}
                </Typography>
              </Grid>
              {benefit.list.map(item => (
                <Grid 
                  item 
                  key={item.title}
                  p="20px"
                  maxWidth="138px"
                  height="138px"
                  bgcolor="#3E3ECD99"
                  borderRadius="8px"
                  textAlign="left"
                >
                  {item.icon}
                  <Typography 
                    mt={1} 
                    fontWeight={600} 
                    fontSize="14px" 
                    lineHeight="20px"
                    sx={{ wordBreak: 'keep-all' }}
                  >
                    {parse(item.title, )}
                  </Typography>
                </Grid>
              ))}
            </React.Fragment>
          ))} 
        </Grid>
      </Box>
    </Box>
  );
}