import React, { useState, useContext, useEffect } from 'react';
import { Flex } from 'components/style';
import { WhiteButton } from 'components/button';
import { initReactI18next, useTranslation } from 'react-i18next';
import { GET_BRANDS_ALL } from 'api/quries';
import { useMutation, useQuery } from '@apollo/client';
import { AppContext } from 'app';
import SelectView from './selected';
import { List, ListItem, Input } from './style';
import { SYNC_BRAND } from 'api/mutations';

export default (() => {
    // 모달 표시 메소드 불러오기
    const { showAlertMessage } = useContext(AppContext);
    const { t } = useTranslation();

    // 선택된 항목 
    const [selectedItem, setSelectedItem] = useState('');
    // 검색 키워드 
    const [searchKeyword, setSearchKeyword] = useState('');

    // 브랜드 정보들 
    const { data, loading, refetch } = useQuery(GET_BRANDS_ALL);
    // 브랜드 동기화하기 액션 
    const [syncBrand] = useMutation(SYNC_BRAND);

    const initSelectItem = () => {
        setSelectedItem('');
    };
    // 검색어 변경 이벤트 처리기 메소드 
    const onChangeSearchKeyword = e => {
        // 검색어 변경 
        setSearchKeyword(e.target.value);
    };

    // 동기화 클릭 이벤트 처리기 메소드 
    const onClickSync = async () => {
        // 브랜드 동기화
        await syncBrand();
        // 선택항목 초기화 
        initSelectItem();
        // 재확보 
        refetch();
        showAlertMessage(t('syncd'), t('syncd'));

    };

    return (
        <Flex direction="column">
            <Flex bottom="10">
                <WhiteButton onClick={onClickSync}>{t('sync')}</WhiteButton>
            </Flex>
            <Flex>
                <Flex width="100" direction="column">
                    <Input placeholder={t('inputSearchKeyword')} onChange={onChangeSearchKeyword} />
                    <List>
                        {
                            !loading && data && data.getBrands.filter(x => x.name.includes(searchKeyword.trim())).map(x => {
                                return (
                                    <ListItem active={selectedItem.id === x.id} onClick={() => { setSelectedItem({ ...x }) }}>
                                        {x.name}
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Flex>
                <Flex width="100">
                    {selectedItem && <SelectView
                        refetch={refetch}
                        itemObj={selectedItem}
                        initSelectItem={initSelectItem}
                    />}
                </Flex>
            </Flex>
        </Flex>
    );
});