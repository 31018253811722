import React, { useContext, useState } from 'react';
import { AppContext } from '../../app';
import ProductList from './list';
import QueryString from 'query-string';
import { useTranslation } from 'react-i18next';
import CoverLangModal from 'Home/coverLangModal';
import SentenceModal from 'Home/sentenceModal';
import { useMutation } from '@apollo/client';
import { SET_OVERWRITE_PROD_ITEM, SET_PROD_SENTENCE } from '../../api/mutations';
import { isKorean } from 'lib/multiLocale';
import { useGetUserQuery } from 'generated/graphql';
import useAuth from 'hooks/useAuth';
import { Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Button } from 'acon-mui/components';

export default function HomeProducts(props) {
  const { showAlertMessage } = useContext(AppContext);
  const { user } = useAuth();
  const { data } = useGetUserQuery();

  // 언어 덮어쓰기 모달 표시여부
  const [isShowCoverLangModal, setIsShowCoverLangModal] = useState(false);
  // 문장 모달 표시여부
  const [isShowSentenceModal, setIsShowSentenceModal] = useState(false);

  // 선택한 항목 객체
  const [selectedItemObjArr, setSelectedItemObjArr] = useState([]);
  // 완료된 ID들
  const [completedCount, setCompletedCount] = useState(-1);

  // 덮어쓰기
  const [setCover] = useMutation(SET_OVERWRITE_PROD_ITEM);
  const [setSentence] = useMutation(SET_PROD_SENTENCE);
  // 프로퍼티에서 가져온 정보
  const { history, match } = props;
  // 번역 도구
  const { t, i18n } = useTranslation();
  // url 매개변수 객체
  let urlParamObj = (() => {
    let obj: {
      page: number;
      limit: number;
      godoGoodsNo?: number;
      title: string | string[];
      contents: string | string[];
      brands: string | string[];
      status: string | string[];
      createStartDate?: string | string[];
      createEndDate?: string | string[];
      requestStartDate?: string | string[];
      requestEndDate?: string | string[];
    } = {
      // 페이지 개수제한
      limit: 20,
      // 제목
      title: '',
      // 내용
      contents: '',
      // 고도몰 상품번호
      godoGoodsNo: null,
      // 페이지
      page: 1,
      // 브랜드
      brands: '',
      // 상태
      status: '',
    };

    // 프로퍼티가 정상적으로 넘어오지 않은경우
    if (history?.location?.search) {
      // url 파라메터 객체
      const urlParamObj = QueryString.parse(history.location.search);

      obj.page = Number(urlParamObj.page);
      obj.title = urlParamObj.title;
      obj.contents = urlParamObj.contents;
      obj.godoGoodsNo = Number(urlParamObj.godoGoodsNo);
      obj.limit = urlParamObj.limit ? Number(urlParamObj.limit) : 20;
      obj.brands = urlParamObj.brands;
      obj.status = urlParamObj.status;
      obj.createStartDate = urlParamObj.createStartDate;
      obj.createEndDate = urlParamObj.createEndDate;
      obj.requestStartDate = urlParamObj.requestStartDate;
      obj.requestEndDate = urlParamObj.requestEndDate;
    }
    for (let key in obj) {
      if (!obj[key]) delete obj[key];
    }
    // 종료함
    return obj;
  })();

  // 특정 문장 일괄 변경페이지에 대한 관리자권한 여부
  const isAuthDetailChangePage = user.isAdmin;
  // 상품에 대한 관리자권한 여부
  const isAuthWriteAllProduct = user.isAdmin;

  // 문장 일괄변경 버튼 클릭 이벤트 처리기 메소드
  const onClickChangeSentenceButtonTag = () => {
    // 모달창 표시
    setIsShowSentenceModal(true);
  };

  //언어 덮어쓰기 버튼 클릭 이벤트 처리기 메소드
  const onClickCoverLangButtonTag = () => {
    // 선택한 항목이 존재하지 않을경우
    if (selectedItemObjArr.length === 0) {
      // 안내 메세지 표시
      showAlertMessage(t('cannotAction'), t('pleaseSelectProd'));
      // 종료
      return;
    }
    // 검토완료 되지 않은 항목이 존재할 경우
    if (selectedItemObjArr.filter((x) => x.isEdit || x.status === 'complete').length === 0) {
      // 안내 메세지 표시
      showAlertMessage(t('cannotAction'), t('mustCompletedFunction'));
      // 종료
      return;
    }
    // 모달창 표시
    setIsShowCoverLangModal(true);
  };

  const hideCoverLangModal = () => {
    setIsShowCoverLangModal(false);
  };
  // 언어 덮어쓰기 메소드
  const setCoverLang = async (standardLang, targetLangs, isOverwrite) => {
    setCompletedCount(0);
    for (let i = 0; i < selectedItemObjArr.length; i++) {
      // 현재 ID
      const docId = selectedItemObjArr[i].id;
      // 데이타
      await setCover({
        variables: { docId, standardLang, targetLangs, isOverwrite },
      });
      document.getElementById('currentCount').innerText = `${Number(document.getElementById('currentCount').innerText) + 1}`;
    }
    setSelectedItemObjArr([]);
    setCompletedCount(-1);
    window.location.reload();
  };
  // 체크박스 변경 이벤트 처리기 메소드
  const onChangeCheckbox = (id, status, isEdit) => {
    const arr = [...selectedItemObjArr];
    // 선택된 ID들
    const ids = selectedItemObjArr.map((x) => x.id);

    // 인덱스 결과
    const index = ids.indexOf(id);
    // 존재하지 않으면 삽입, 존재하면 제거
    if (index === -1) arr.push({ id, status, isEdit });
    else arr.splice(index, 1);

    setSelectedItemObjArr(arr);
  };

  // 언어페이지 덮어쓰기 버튼 클릭 이벤트 처리기 메소드

  const propSearchParam = { ...urlParamObj, history };
  const productListParams = {
    ...propSearchParam,
    selectedItemObjArr,
    setSelectedItemObjArr,
    match,
    onChangeCheckbox,
  };

  return (
    <>
      <SentenceModal
        isShow={isShowSentenceModal}
        onHide={() => {
          setIsShowSentenceModal(false);
        }}
        setSentence={setSentence}
      />
      <CoverLangModal isShow={isShowCoverLangModal} onHide={hideCoverLangModal} setCoverLang={setCoverLang} selectedIds={selectedItemObjArr} completeCount={completedCount} />
      <Box display="flex" pt="50px" mb={3} {...(isMobile && { flexDirection: 'column' })}>
        <Typography mr="auto" fontSize="24px" lineHeight="36px" fontWeight="700">
          {t('Home.AllDetailList')}
        </Typography>
        <Box display="flex" {...(isMobile && { flexDirection: 'column-reverse' })}>
          {!isMobile && (
            <>
              {isAuthDetailChangePage && (
                <Button variant="contained" onClick={onClickChangeSentenceButtonTag} fontWeight="700" width="fit-content" marginRight={2}>
                  {t('DetailChange.label')}
                </Button>
              )}

              {/** [todo]: 덮어쓰기 버튼 임시 비활성 처리 */}
              {isAuthWriteAllProduct && (
                <Button variant="contained" onClick={onClickCoverLangButtonTag} disabled fontWeight="700" width="fit-content" marginRight={2}>
                  {t('home.product.overWriteLang')}
                </Button>
              )}
            </>
          )}
          {/* 프로모션 신청 버튼 */}
          <a
            href={
              isKorean(i18n.language)
                ? 'https://docs.google.com/forms/d/e/1FAIpQLSeOPdHO9fxhd1JXQBWeN4-SsaVg-kPecU36k_fmwu3KL2-kWg/viewform'
                : 'https://10i6ujkjcqy.typeform.com/to/XLIfwqmn'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained" fontWeight="700" width={isMobile ? '100%' : 'fit-content'} {...(isMobile ? { mt: 1, height: '48px' } : { mr: 2 })}>
              {t('home.product.applyPromotion')}
            </Button>
          </a>
          {/* 글 작성 버튼 */}
          <Button
            colorTheme="primary"
            variant="contained"
            onClick={() => {
              window.location.href = `/goods/write/${i18n.language}`;
            }}
            fontWeight="700"
            width={isMobile ? '100%' : 'fit-content'}
            boxShadow="none"
            {...(isMobile && { mt: 2, height: '48px' })}
          >
            {t('detailBoard.write')}
          </Button>
        </Box>
      </Box>
      <ProductList {...productListParams} />
      <Box pt="100px" />
    </>
  );
}
