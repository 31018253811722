import styled from 'styled-components';

export const Wrap = styled.div<{ isDisp?: boolean }>`
  ${(props) => (props.isDisp ? `pointer-events: none;` : ``)}
  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    background-color: white;
    border: 1px solid black;
  }
  .rs-radio-wrapper::before {
    border: 1px solid black;
  }
  .rs-radio-wrapper .rs-radio-inner::after {
    width: 8px;
    height: 8px;
    background: black;
    margin-top: 4px;
    margin-left: 4px;
  }
`;
