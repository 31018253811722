// 리액트 라이브러리
import React, { createContext, Suspense, useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import adapterLocale from 'date-fns/locale/ko';

// Routing에 사용되는 컴포넌트
import { MainRoute } from './components/MainRoute';

// 페이지 공통으로 사용되는 컴포넌트들
import ConfirmAlertModal from '../common/ConfirmAlertModal';

// 기타
import './index.scss';
import { client, setCallback } from '../api';
import UnsupportedBrowser from 'UnsupportedBrowser';

import { ModalGroup } from './components/popup';
import ThemeProvider from 'theme';
import Header from 'header/Header';
import { AuthProvider } from 'contexts/AuthContext';
import useAuth from 'hooks/useAuth';
import LoadingScreen from 'components/LoadingScreen';
import { datadogRum } from '@datadog/browser-rum';
import { useTranslation } from 'react-i18next';

const AppContext = createContext();

const App = () => {
  // 공통으로 사용되는 모달창 관련 state 변수입니다.
  const defaultModalState = {
    // title은 모달창 제목에 굵은 글씨로 표시되는 문구입니다.
    title: '',
    // contents는 모달창 내용에 표시되는 문구입니다.
    contents: '',
    // show는 모달창 표시여부입니다. truthy로 변경하면 모달창이 표시되고 falsey로 변경하면 모달창이 닫힙니다.
    show: false,
    // cancelText는 모달창에서 취소 버튼에 표시되는 문구입니다. t() 함수에 argument로 사용됩니다.
    cancelText: 'cancel',
    // confirmText는 모달창에서 결정 버튼에 표시되는 문구입니다. t() 함수에 argument로 사용됩니다.
    confirmText: 'confirm',
    // btnType은 css 스타일 설정을 위한 클래스 이름으로 사용됩니다. small / big
    btnType: 'small',
    // alignCenter는 모달창 제목 및 메시지를 가운데로 정렬할지 결정합니다. true / false
    alignCenter: false,
    // textType은 css 스타일 설정을 위한 클래스 이름으로 사용됩니다. small / big
    textType: 'small',
    // OK 클릭할 때 실행하는 콜백
    onOkConfirmMessage: null,
    // 모달창 닫을 때 실행하는 콜백
    onCloseMessage: null,
    // 모달 폼에서 오늘하루 숨김 버튼 클릭시 사용할 팝업메시지 id
    popupId: null,
    // 모달 폼에서 닫기 버튼 표시 여부
    showButtons: true,
    // 모달 내 textfield 표시 여부
    showTextField: false,
    // 모달 내 textfield placeholder
    textFieldPlaceholder: '',
    textFieldRows: null,
    textFieldRequired: false,
  };
  const [modalState, setModalState] = useState(defaultModalState);

  const { i18n } = useTranslation();
  const { user } = useAuth();
  // background-color
  const [backgroundColor, setBackgroundColor] = useState('none');
  // 디테일 언어
  const [detailLang, setDetailLang] = useState('ko');

  // 모달창 표시하기 함수: confirm과 cancel 버튼이 있는 모달창
  const showConfirmMessage = (title, contents, callback, options = {}) => {
    const {
      cancelText = 'cancel',
      confirmText = 'confirm',
      btnType = 'small',
      alignCenter = false,
      textType = 'small',
      showTextField = false,
      textFieldPlaceholder = '',
      textFieldRows = null,
      textFieldRequired = false,
    } = options;

    // 필수 파라미터가 입력되지 않았을 경우 에러를 던집니다.
    if ((!title && !contents) || !callback) {
      throw new Error('title or contents, and callback are required arguments.');
    }

    setModalState({
      title,
      contents,
      onOkConfirmMessage: callback,
      show: true,
      cancelText,
      confirmText,
      btnType: `${btnType}Btn`,
      alignCenter: alignCenter,
      textType: `${textType}Text`,
      showButtons: true,
      showTextField,
      textFieldPlaceholder,
      textFieldRows,
      textFieldRequired,
    });
  };

  // 모달창 표시하기 함수: close 버튼만 있는 모달창
  const showAlertMessage = (title, contents, options = {}) => {
    const {
      confirmText = 'confirm',
      btnType = 'small',
      alignCenter = false,
      textType = 'small',
      callback = null,
      closeCallback = null,
      popupId,
      showTextField = false,
      textFieldPlaceholder = '',
      textFieldRows = null,
      textFieldRequired = false,
    } = options;

    // 필수 파라미터가 입력되지 않았을 경우 에러를 던집니다.
    if (!title && !contents) {
      throw new Error('title or contents are required arguments');
    }

    setModalState({
      title,
      contents,
      onOkConfirmMessage: callback,
      onCloseMessage: closeCallback,
      show: true,
      cancelText: null,
      confirmText,
      btnType: `${btnType}Btn`,
      alignCenter: alignCenter,
      textType: `${textType}Text`,
      popupId,
      showButtons: true,
      showTextField,
      textFieldPlaceholder,
      textFieldRows,
      textFieldRequired,
    });
  };

  // 모달창 표시하기 함수: close 버튼이 없고 자동으로 사라지는 모달창
  const showNoButtonMessage = (title, contents, options = {}) => {
    const {
      alignCenter = false,
      textType = 'small',
      callback = null,
      // 표시 후 자동으로 사라질 때 까지의 시간(ms)
      duration,
    } = options;

    // 필수 파라미터가 입력되지 않았을 경우 에러를 던집니다.
    if (!title && !contents) {
      throw new Error('title or contents are required arguments');
    }

    setModalState({
      ...modalState,
      title,
      contents,
      onOkConfirmMessage: callback,
      show: true,
      alignCenter: alignCenter,
      textType: `${textType}Text`,
      showButtons: false,
    });

    if (duration) {
      setTimeout(closeConfirmMessage, duration);
    }
  };

  // 모달창 닫기 함수
  const closeConfirmMessage = () => {
    const { onCloseMessage } = modalState;
    if (onCloseMessage) onCloseMessage();
    setModalState({ ...modalState, show: false });
  };

  // 모달창 confirm버튼 눌렀을 때 작동하는 함수
  const onConfirmMessage = (val) => {
    // 모달창 종료
    closeConfirmMessage();
    // 콜백함수 실행
    const { onOkConfirmMessage } = modalState;
    if (onOkConfirmMessage) onOkConfirmMessage(val);
  };

  // context provider에 passing해주는 변수들을 오브젝트에 넣어서 선언합니다.
  const contextValue = {
    setBackgroundColor,
    setDetailLang,
    lang: detailLang,
    showConfirmMessage,
    showAlertMessage,
    showNoButtonMessage,
    userInfo: user,
  };

  useEffect(() => {
    if (process.env) {
      datadogRum.init({
        applicationId: '197d3d0a-8e2f-485a-82b9-87d8d57c207b',
        clientToken: 'pubb814b717df3fe6bfb3eaa5770e74ee00',
        site: 'datadoghq.com',
        service: 'partnerhub',

        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        env: process.env.NODE_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    }
  }, []);

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.id,
      });
    }
  }, [user]);

  return (
    <AppContext.Provider value={contextValue}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={adapterLocale}>
        <ErrorHandler showAlertMessage={showAlertMessage} />
        <div style={{ position: 'relative' }}>
          <UnsupportedBrowser />
          <Suspense fallback={<LoadingScreen />}>
            <ModalGroup />
            <ConfirmAlertModal {...modalState} onConfirm={onConfirmMessage} onClose={closeConfirmMessage} />
            <Header />
            <div className={`wrap ${user && user.isAdmin ? 'menu-margin' : ''}`}>
              <div>
                <MainRoute init={true} />
              </div>
            </div>
          </Suspense>
        </div>
      </LocalizationProvider>
    </AppContext.Provider>
  );
};

const ErrorHandler = (props) => {
  setCallback((message, callback) => {
    props.showAlertMessage('오류', message, { callback, confirmText: 'confirm' });
  });

  return <></>;
};

const WrappedApp = ({ initialUserInfo }) => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <CookiesProvider>
          <BrowserRouter>
            <ThemeProvider>
              <App initialUserInfo={initialUserInfo} />
            </ThemeProvider>
          </BrowserRouter>
        </CookiesProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export { WrappedApp, AppContext };
