import React, { useState } from "react";
import { Typography, Box, Stack, Skeleton } from "@mui/material";
import { UserFormBox } from "./styled";
import {
  ChangePasswordDialog,
  CreatorEditRequestDialog,
  RequestCheckedDialog,
  RequestDialog,
  SettleEditRequestDialog,
  StoreEditRequestDialog,
} from "./Dialog";
import { useTranslation } from "react-i18next";
import { useGetAdminQuery } from "generated/graphql";
import AdminOriginalForm from "./AdminOriginatorForm";

enum InfoTypeEnum {
  Originiator = "originator",
  Store = "store",
  Settle = "settle",
}

type Props = {
  userId?: number;
};

export default function AdminForm({ userId }: Props) {
  const { t } = useTranslation();
  const { data, loading } = useGetAdminQuery();

  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  const [openRequestDialog, setOpenRequestDialog] = useState<boolean>(false);
  const [openRequestCheckedDialog, setOpenRequestCheckedDialog] =
    useState<boolean>(false);

  const [value, setValue] = useState<InfoTypeEnum>(InfoTypeEnum.Originiator);

  const handleShowEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseChangePasswordDialog = () => {
    setOpenChangePasswordDialog(false);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  if (loading || !data) {
    return <Skeleton />;
  }

  return (
    <>
      <RequestDialog
        isOpen={openRequestDialog}
        onClose={() => {
          setOpenRequestDialog(false);
        }}
      />
      <RequestCheckedDialog
        isOpen={openRequestCheckedDialog}
        onClose={() => {
          setOpenRequestCheckedDialog(false);
        }}
      />
      <SettleEditRequestDialog
        isOpen={value === InfoTypeEnum.Settle && openEditDialog}
        onClose={handleCloseEditDialog}
      />
      <StoreEditRequestDialog
        isOpen={value === InfoTypeEnum.Store && openEditDialog}
        onClose={handleCloseEditDialog}
      />
      <CreatorEditRequestDialog
        isOpen={value === InfoTypeEnum.Originiator && openEditDialog}
        onClose={handleCloseEditDialog}
      />
      <ChangePasswordDialog
        isOpen={openChangePasswordDialog}
        onClose={handleCloseChangePasswordDialog}
      />
      <Box maxWidth={"576px"} width={"100%"}>
        <Stack alignItems={"center"}>
          <Box mb={5}>
            <Typography
              variant={"h4"}
              fontSize={24}
              fontWeight={700}
              lineHeight={"36px"}
              color={"#212B36"}
            >
              {t("user.mypageTitle")}
            </Typography>
          </Box>

          <Box width={"100%"}>
            <UserFormBox>
              <AdminOriginalForm
                userId={userId}
                onShowChangePasswordDialog={() => {
                  setOpenChangePasswordDialog(true);
                }}
                onClickShowDialog={handleShowEditDialog}
              />
            </UserFormBox>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
