import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalComponent as Modal } from "../components/modal";
import { InfoLabel, PageTitle, FormGroup, Summary, FormTitle } from "../style";
import {
  useCreateAdminMutation,
  useGetIsUsedEmailQuery,
} from "generated/graphql";
import { useForm } from "react-hook-form";
import { FormProvider } from "components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Button as MuiButton } from "@mui/material";
import OldRHFTextField from "signup/page/OldRHFTextField";

type SignUpAdminValues = {
  userEmail: string;
  name: string;
  password: string;
  checkPassword: string;
};

export default function SignUpAdmin() {
  // 번역 도구
  const { t } = useTranslation();

  const getIsUsedEmail = useGetIsUsedEmailQuery({
    skip: true,
  });

  const [createAdminUser] = useCreateAdminMutation();

  // 모달
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const defaultValues: SignUpAdminValues = {
    name: "",
    userEmail: "",
    password: "",
    checkPassword: "",
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required("이름은 필수값입니다."),
    userEmail: Yup.string()
      .email(t("signupPage.emailIncorrect"))
      .required(t("signupPage.emailIncorrect"))
      .test("userEmail", "에이콘3D 계정만 입력해주세요.", (value) => {
        return value.replace(/.*@/, "") === "acon3d.com";
      })
      .test("userEmail", t("signupPage.emailAlreadyUse"), (value) => {
        return new Promise((resolve, reject) => {
          getIsUsedEmail
            .refetch({
              account: value,
            })
            .then(({ data }) => {
              resolve(!data.existsUser);
            });
        });
      }),
    password: Yup.string().matches(
      /^(?=.*[\w ])(?=.*[\d])(?=.*[^ \w\d\t\r\n\v\f]).{8,}$/i,
      t("signupPage.passwordCondition")
    ),
    checkPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("signupPage.passwordIncorrect")
    ),
  });

  const methods = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(formSchema),
  });
  const {
    watch,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = methods;

  const values = watch();

  const onSubmit = async (data: SignUpAdminValues) => {
    await createAdminUser({
      variables: {
        name: data.name,
        account: data.userEmail,
        password: data.password,
      },
    });

    alert("생성 성공");
    window.location.reload();
  };

  return (
    <>
      {isShowModal && (
        <Modal message={modalMessage} onConfirm={() => setIsShowModal(false)} />
      )}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={7.5}>
          <Stack direction="row" justifyContent={"center"}>
            <PageTitle>{t("signupPage.accountInfomation")}</PageTitle>
          </Stack>
          <Stack spacing={4.5}>
            <FormGroup>
              <FormTitle>이름</FormTitle>
              <Stack>
                <Stack direction="row">
                  <OldRHFTextField
                    name="name"
                    error={!errors.name}
                    placeholder={"홍길동"}
                  />
                </Stack>
                {errors.name && <Summary fail>{errors.name.message}</Summary>}
              </Stack>
            </FormGroup>
            <FormGroup>
              <FormTitle>{t("signupPage.id")}</FormTitle>
              <Stack>
                <Stack direction="row">
                  <OldRHFTextField
                    name="userEmail"
                    error={!errors.userEmail}
                    placeholder={"example@example.com"}
                  />
                </Stack>
                {errors.userEmail && (
                  <Summary fail>{errors.userEmail.message}</Summary>
                )}

                {values.userEmail &&
                  !errors.userEmail &&
                  dirtyFields.userEmail && (
                    <Summary success>{t("signupPage.emailAvailable")}</Summary>
                  )}
              </Stack>
            </FormGroup>
            <FormGroup>
              <FormGroup>
                <FormTitle>임시발급 비밀번호</FormTitle>
                <Stack>
                  <OldRHFTextField
                    name="password"
                    type="password"
                    placeholder={"**********"}
                  />
                  {values.password &&
                    !errors.password &&
                    dirtyFields.password && (
                      <Summary success>
                        {t("signupPage.passwordAvailable")}
                      </Summary>
                    )}
                  {values.password && errors.password && (
                    <InfoLabel color={"#F300BA"}>
                      {errors.password.message}
                    </InfoLabel>
                  )}
                </Stack>
              </FormGroup>
              <Stack>
                <OldRHFTextField
                  name="checkPassword"
                  type="password"
                  placeholder={t("signupPage.passwordRepeatPlaceholder")}
                />
                {!errors.checkPassword && values.checkPassword && (
                  <Summary success>{t("signupPage.passwordCorrect")}</Summary>
                )}
                {errors.checkPassword && values.checkPassword && (
                  <Summary fail>{t("signupPage.passwordIncorrect")}</Summary>
                )}
              </Stack>
            </FormGroup>
          </Stack>
          <MuiButton type="submit" variant="contained" size={"medium"}>
            회원가입
          </MuiButton>
        </Stack>
      </FormProvider>
    </>
  );
}
