import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import UploadField from "./UploadField";
import { useRecoilState } from "recoil";
import { idCardState } from "user/User.atom";
import AttachFileIcon from "assets/icon_attach_file";

type Props = {
  isCompany: boolean;
};
export default function IdCardField({ isCompany }: Props) {
  const [idCard, setIdCard] = useRecoilState(idCardState);

  const handleUploadFile = (fileName: string, key: string) => {
    setIdCard({
      key: key,
      name: fileName,
      size: null,
    });
  };

  const handleRemoveUploadFile = () => {
    setIdCard(null);
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <Typography variant={"body2"} color={"grey.600"}>
        {isCompany ? "사업자등록증" : "신분증"} 사본
      </Typography>

      {!idCard && (
        <>
          <UploadField onChange={handleUploadFile} />

          <Typography fontSize={12} lineHeight={"18px"} color={"grey.600"}>
            마스킹하지 않은 {isCompany ? "사업자등록증" : "신분증"} 사본을
            첨부해주세요.
          </Typography>
        </>
      )}

      {idCard && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <AttachFileIcon />
            <Typography fontSize={14} lineHeight={"22px"}>
              {idCard.name}
            </Typography>
          </Stack>

          <Button variant={"outlined"} onClick={handleRemoveUploadFile}>
            삭제하기
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
