import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const SelectComponent = (props) => {
    if (!props.readOnly && !props.data) {
        throw new Error('must have data');
    }

    const ref = useRef();
    const [ value, setValue ] = useState(props.value || props.data && props.data[0]);

    const select = (val) => {
        ref.current.blur();
        setValue(val);
        if (props.onChange) {
            props.onChange(val);
        }
    }

    return (
        <div ref={ref} tabIndex="0" className={props.className} onClick={props.onClick}>
            <div className={props.className+'wrap'}>
                <div className="selected">{ props.readOnly ? props.value : value}</div>
                <div className="polygon"></div>
                <div className="options">
                    {props.data?.map((val,idx) => 
                        <div 
                            key={idx} 
                            onClick={() => select(val)}
                        >{val}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const Select = styled(SelectComponent)`
    position: relative;

    :focus {
        z-index: 10;

        .options {
            display: block;
        }
    }

    .selected {
        height: 40px;
        line-height: 40px;
        background: #FFFFFF;
        border: 1px solid #CDCDCD;
        box-sizing: border-box;
        border-radius: 3px;
    }

    .polygon {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 12px;
        top: 12px;

        ::before {
            content: '';
            display: block;
            position: absolute;
            left: calc(50% - 10px/2);
            top: calc(50% - 6px / 2);

            border: 5px solid transparent;
            border-bottom: 0;
            border-top: 6px solid black;
        }
    }

    .options {
        position: absolute;
        display: none;
        width: 100%;
        border-radius: 3px;
        border: 1px solid #CDCDCD;
        border-top: 0;
        border-bottom: 0;
        overflow: hidden;

        > * {
            width: 100%;
            height: 40px;
            line-height: 40px;
            background: #FFFFFF;
            border-bottom: 1px solid #CDCDCD;
            
            &:hover {
                background: #ffe3e3;
            }
        }
    }

    select {
        display: none;
    }
`;