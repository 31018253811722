import React, { useState } from "react";
import { Card, Stack, Tab } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import SignUpUser from "../../signup/manual/SignUp";
import { TabContext, TabList } from "@mui/lab";
import SignUpAdmin from "signup/manual/SignUpAdmin";

enum UserTypeEnum {
  user = "user",
  admin = "admin",
}

export default function ManualSignup() {
  const [userType, setUserType] = useState<UserTypeEnum>(UserTypeEnum.user);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    setUserType(newValue);
  };

  return (
    <Stack>
      <TabContext value={userType}>
        <Stack spacing={5}>
          <TabList onChange={handleChange}>
            <Tab label="사용자" value={UserTypeEnum.user} />
            <Tab label="관리자" value={UserTypeEnum.admin} />
          </TabList>
          <TabPanel value={UserTypeEnum.user}>
            <SignUpUser />
          </TabPanel>
          <TabPanel value={UserTypeEnum.admin}>
            <Stack alignItems={"center"}>
              <Card sx={{ p: 3, maxWidth: "576px", width: "100%" }}>
                <SignUpAdmin />
              </Card>
            </Stack>
          </TabPanel>
        </Stack>
      </TabContext>
    </Stack>
  );
}
