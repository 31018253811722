import axios from 'axios';
import { authOrigin } from '.';

export type AuthUser = {
  acceptStatus: number;
  account: string;
  brandId?: number;
  // 유효기간
  exp: number;
  // group table value, TODO: change graphql type enum
  groupName: 'admin' | 'user' | 'unapproved' | '신' | '일반 사용자' | 'superadmin';
  hasntPlan: boolean;
  iat: number;
  id: number;
  isAccept: boolean;
  isAdmin: boolean;
  isCompany: boolean;
  isFirst: boolean;
  isUpdateTerm: boolean;
  name: string;
};

class Auth {
  constructor() {}

  ORIGIN = `${authOrigin}/auth`;

  CONTROLLER = {
    INFO: `${this.ORIGIN}/info`,
    SIGNIN: `${this.ORIGIN}/signin`,
    REFRESH: `${this.ORIGIN}/refresh`,
    DOWNLOAD: `${this.ORIGIN}/download`,
    SIGNOUT: `${this.ORIGIN}/signout`,
  };

  isLogin = async () => {
    const { data } = await axios.get<AuthUser>(this.CONTROLLER.INFO);

    return data;
  };

  login = async (id: string, password: string) => {
    try {
      const { data } = await axios.post<AuthUser>(this.CONTROLLER.SIGNIN, {
        account: id,
        password,
      });

      return data;
    } catch (err) {
      if (err.response.status === 403 && err.response.data === '계약서 갱신이 필요합니다.') throw new Error('contract', err);
      else throw new Error('로그인 실패', err);
    }
  };

  refresh = async () => {
    await axios.post(this.CONTROLLER.REFRESH);
  };

  logout = async () => {
    await axios.post(this.CONTROLLER.SIGNOUT);
  };
}

export default new Auth();
