import { selector } from 'recoil';
import { categoryTreeState } from '../categoryTree';
import { CategoryTree, recursiveTreeFunc } from '../class';

export const categoryTreeSelector = selector<any>({
  key: 'categoryTreeSelector',
  get: ({ get }) => get(categoryTreeState),
  set: ({ set }, val) => {
    const genreList = val?.genre;
    const themeList = val?.theme;
    const gameList = val?.game;
    const lectureList = val?.lecture;

    let genreNode = [];
    let themeNode = [];
    let gameNode = [];
    let lectureNode = [];

    for (const key in genreList) {
      if (genreList[key].parent) {
        recursiveTreeFunc(genreNode, genreList[key]);
      } else {
        genreNode.push(new CategoryTree(genreList[key]));
      }
    }
    for (const key in themeList) {
      if (themeList[key].parent) {
        recursiveTreeFunc(themeNode, themeList[key]);
      } else {
        themeNode.push(new CategoryTree(themeList[key]));
      }
    }
    for (const key in gameList) {
      if (gameList[key].parent) {
        recursiveTreeFunc(gameNode, gameList[key]);
      } else {
        gameNode.push(new CategoryTree(gameList[key]));
      }
    }
    for (const key in lectureList) {
      if (lectureList[key].parent) {
        recursiveTreeFunc(lectureNode, lectureList[key]);
      } else {
        lectureNode.push(new CategoryTree(lectureList[key]));
      }
    }

    set(categoryTreeState, {
      genre: genreNode,
      theme: themeNode,
      game: gameNode,
      lecture: lectureNode,
    });
  },
});
