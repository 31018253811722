import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import './LoadingCancelConfirmModal.scss';

// 요청하기 위한 모달입니다.
export default React.memo(function ({ onClose, onConfirm, show}) {
    const { t } = useTranslation()
    return (
        <Modal className="loadingCancelConfirmModal" show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {parse(t("cancelModal"))}
            </Modal.Body>
            <Modal.Footer>
                <button className="closeBtn" onClick={onClose}>
                    {t('close')}
                </button>
                <button className="requestBtn" onClick={onConfirm}>
                    {t('Save.label')}
                </button>
            </Modal.Footer>
        </Modal>
    )
});