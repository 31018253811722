import React from "react";
import { Divider, MenuItem, Stack, Typography } from "@mui/material";
import MenuPopover from "components/MenuPopover";
import { useTranslation } from "react-i18next";
import { PATH_PAGE, PATH_PRODUCT, PATH_SETTLE, PATH_USER } from "routes/paths";
import { NavLink, useHistory } from "react-router-dom";
import useAuth from "hooks/useAuth";

type Props = {
  open: boolean;
  onClose: () => void;
  anchorEl: Element;
};

export default function AccountPopover({ open, onClose, anchorEl }: Props) {
  const { user, isAuthenticated, logout } = useAuth();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleLogout = async () => {
    await logout();
    onClose();
    history.push(PATH_PAGE.root);
  };

  const MENU_OPTIONS = [
    {
      onlyUser: false,
      link: PATH_SETTLE.root,
      text: t("settleList"),
    },
    {
      onlyUser: false,
      link: PATH_PRODUCT.write(i18n.language),
      text: t("productWrite"),
    },
    {
      onlyUser: false,
      link: PATH_USER.root,
      text: t("mypage"),
    },
  ];

  const handleClose = () => {
    onClose();
  };
  return (
    <MenuPopover
      disableScrollLock={true}
      open={Boolean(open)}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={{
        p: 0,
        mt: 0.5,
        ml: -2.75,
        "& .MuiMenuItem-root": {
          typography: "body2",
          borderRadius: 0.75,
        },
      }}
    >
      {user && user.acceptStatus !== -1 && (
        <>
          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.filter((x) =>
              isAuthenticated && user.isAdmin ? !x.onlyUser : true
            ).map((x, i) => (
              <NavLink to={x.link} key={`accountPopover_${i}`}>
                <MenuItem onClick={handleClose} sx={{ padding: "8px 16px" }}>
                  <Typography variant="body2" color={"text.primary"} noWrap>
                    {x.text}
                  </Typography>
                </MenuItem>
              </NavLink>
            ))}
          </Stack>

          <Divider />
        </>
      )}

      <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
        {t("logout")}
      </MenuItem>
    </MenuPopover>
  );
}
