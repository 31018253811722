import Quill from 'quill';

// 허용 이미지 경로
const EDITOR_ALLOW_IMAGE_PATH_LIST = [
    'https://carpenstreet.blob.core.windows.net', 
    'https://dgs8fvgymeofx.cloudfront.net', 
    'https://dofaplktgw3ef.cloudfront.net', 
    'https://d3aj4qxknj6i24.cloudfront.net'
];

// 퀼 기본 설정
const QUILL_DEFAULT_OPTION = {
    theme: 'snow',
    modules: {
        toolbar: {
            container: '#Editor-toolbar',
            handlers: {
                header(val) {
                    const { index } = this.quill.getSelection(true);
                    const { baseNode, baseOffset } = window.getSelection();
                    const { length } = baseNode;
                    const startOffset = index - baseOffset;
                    
                    
                    this.quill.format('header', val);
                    this.quill.formatText(startOffset, length, 'bold', true);
                }
            }
        },
        keyboard: {
            bindings: {
                'list autofill': {
                    shiftKey: true
                }
            }
        }
    },
};

// 퀼 클래스 확장
const OriginalImage = Quill.import('formats/image');
const OriginalBlock = Quill.import('blots/block');
const OriginalClipboard = Quill.import('modules/clipboard');

class Image extends OriginalImage {
    static create(value) {
        let node = super.create(value);
        node.style.maxWidth = '100%';
        node.style.marginTop = '5px';
        node.style.marginBottom = '5px';
        return node;
    }
}

class Block extends OriginalBlock {
    constructor(node) {
        super(node);
        
        node.style.margin = 0;
    }
}

Quill.register(Image, true);
Quill.register(Block);

export {Quill, QUILL_DEFAULT_OPTION, EDITOR_ALLOW_IMAGE_PATH_LIST};