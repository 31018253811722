import React from "react";
import { Button, Stack } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { pageState, userStoreState } from "./atom";
import { useTranslation } from "react-i18next";
import { LanguageCodeEnum } from "generated/graphql";
import { UserTypeEnum } from "signup/type";

export default function Next() {
  const { t, i18n } = useTranslation();
  const setPage = useSetRecoilState(pageState);
  const state = useRecoilValue(userStoreState);

  const handleNext = () => {
    setPage(2);
  };

  const disabled = (() => {
    if (i18n.language !== LanguageCodeEnum.Ko) {
      return !(
        state.name &&
        state.brandNames.en &&
        state.country &&
        state.documents.length > 0
      );
    } else {
      if (state.userType === UserTypeEnum.Personal) {
        return (
          state.nationalIdNumber === "-" ||
          state.nationalIdNumber.split("-")[1] === "0" ||
          !state.contact ||
          state.documents.length === 0 ||
          !state.brandNames[i18n.language] ||
          !state.name
        );
      } else {
        return (
          !state.nationalIdNumber ||
          !state.contact ||
          state.documents.length === 0 ||
          !state.brandNames[i18n.language] ||
          !state.name ||
          !state.companyName
        );
      }
    }
  })();
  return (
    <Button
      variant="contained"
      size={"large"}
      onClick={handleNext}
      disabled={disabled}
      sx={{ width: "176px" }}
    >
      {t("store.next")}
    </Button>
  );
}
