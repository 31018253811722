import React from 'react';
import { Masking } from 'user/util';
import { InputField } from '../common/input';

const Component = (props) => {
    const { data, isAccept } = props;

    return (
            <>
                <InputField readOnly label="이름" value={data.user.name} />
                { !isAccept &&
                    <InputField readOnly label="주민등록번호" value={Masking(data.user.settle.nationalIdNumber)} />
                }
                { !isAccept &&
                    <InputField readOnly label="신분증 사본" value={data.user.settle.idCard?.path || ''} />
                }
                <InputField readOnly label="은행" value={data.user.settle.bank.name} />
                { data.user.settle.bankName &&
                    <InputField readOnly label="기타 은행" value={data.user.settle.bankName} />
                }
                <InputField readOnly label="예금주" value={data.user.settle.bankAccountOwner} />
                <InputField readOnly label="계좌번호" value={Masking(data.user.settle.bankAccountNumber)} />
                { !isAccept &&
                    <InputField readOnly label="통장 사본" value={data.user.settle.bankBook?.path || ''} />
                }
            </>
    );
};

export default Component;