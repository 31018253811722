import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from 'react-dom/client';

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import "./lib/global";
import * as serviceWorker from "./serviceWorker";
import { WrappedApp } from "./app";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

axios.defaults.withCredentials = true;

const SENTRY_DSN = process.env.SENTRY_DSN;
Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const root = createRoot(document.getElementById("root"));
root.render(<WrappedApp />);

serviceWorker.unregister();
