import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
    TabContainer,
} from '../style';
import { Wrapper, Top, Row, Column, Table, ButtonWrap, RemoveButton, WriteButton, ColumnLink, NaviWrapper } from './style';
import { useMutation, useQuery } from '@apollo/client';
import { GET_BENEFIT_LIMIT_LIST, GET_BENEFIT_LIMIT_LIST_COUNT } from 'api/quries';
import { DELETE_BENEFIT_LIMIT_ITEM } from 'api/mutations';
import dateFormat from 'dateformat';
import queryString from 'query-string';
import PageNavigation from 'components/pageNavigation';
import { AppContext } from 'app';

export default ((props) => {
    const { match, history } = props;
    const { showConfirmMessage } = useContext(AppContext);
    // 현재 페이지 타입을 가져옵니다.
    const burdenDisplayLocation = match.params.displayLocation || "brandBurden";
    
    // 항목 삭제
    const [removePointLimitPolicy] = useMutation(DELETE_BENEFIT_LIMIT_ITEM);
    // 선택된 id들
    const [selectedIds, setSelectedIds] = useState([]);
    // 페이지
    let page;
    // 페이지 쿼리
    if (history.location.search) {
        const query = queryString.parse(history.location.search);
        if (query?.page) {
            page = Number(query.page);
        }
    }
    // 혜택 제한 항목들 쿼리
    const { data, loading, refetch } = useQuery(GET_BENEFIT_LIMIT_LIST, {
        fetchPolicy: "no-cache",
        variables: {
            page: page ?? 1,
            limit: 10
        }
    });

    // 혜택 제한 항목 생성 페이지 이동
    const onClickWritePage = () => {
        history.push(`/manager/benefit/benefitRestric/write`);
    };
    // 삭제 버튼 클릭 이벤트
    const onClickRemoveButton = () => {
        if (selectedIds.length === 0) {
            return;
        }

        showConfirmMessage('항목 삭제', `선택된 항목 ${selectedIds.length}개를 모두 삭제합니다.`,
        async () => {
            await removePointLimitPolicy({
                variables: {
                    id: selectedIds
                }
            });
            await refetch();
        });
    };
    // 모두 체크 클릭 이벤트
    const onClickAllCheckButton = () => {
        // 전체선택이 되어있지 않은경우 
        if (selectedIds.length !== data.getPointLimitPolicyList.length) {
            // 항목들 전체 선택 
            setSelectedIds(data.getPointLimitPolicyList.map(x => x.id));
            // 종료 
            return;
        }
        // 초기화 
        setSelectedIds([]);
    };
    // 표시용 타입 가져오기
    const getDisplayType = (item) => {
        if (item.type === 'goods') return '상품번호';
        if (item.type === 'category') return '카테고리';
        if (item.type === 'brand') return '브랜드';
    };
    // 표시용 제한 값 가져오기
    const getDisplayLimit = (item) => {
        if (item.type === 'goods') {
            const goodsNoArr = item.goodsNo.split(',');
            if (goodsNoArr.length === 1) {
                return `${goodsNoArr[0]}`;
            } else {
                return `${goodsNoArr[0]} 외 ${goodsNoArr.length - 1}개`;
            }
        }
        if (item.type === 'category') {
            const category = item.category.i18n.find(x => x.language.code === 'ko');
            return category.name;
        }
        if (item.type === 'brand') {
            return item.brand.name;
        }
    };
    // 표시용 기간 가져오기
    const getDisplayPeriod = (item) => {
        return `${dateFormat(item.startDate, 'yyyy-mm-dd')} ~ ${dateFormat(item.endDate, 'yyyy-mm-dd')}`;
    };

    return (
        <TabContainer>
            <Wrapper>
                <Top>
                    <ButtonWrap>
                        <RemoveButton disabled={selectedIds.length === 0} onClick={onClickRemoveButton}>
                            선택 삭제
                        </RemoveButton>
                        <WriteButton onClick={onClickWritePage}>
                            혜택 제한 생성
                        </WriteButton>
                    </ButtonWrap>
                </Top>
                <Table>
                    <Row isHead={true}>
                        <Column flex="0.1" align="center">
                            <input type="checkbox" checked={data && data.getPointLimitPolicyList && selectedIds.length === data.getPointLimitPolicyList.length}
                             onClick={onClickAllCheckButton} />
                        </Column>
                        <Column flex="0.2">구분</Column>
                        <Column flex="0.5">제한값</Column>
                        <Column flex="0.2">기간</Column>
                    </Row>
                    {
                        !loading && data && data.getPointLimitPolicyList && data.getPointLimitPolicyList.map(x => {
                            return (<Row key={x.id}>
                                <Column flex="0.1" align="center">
                                    <input type="checkbox"
                                        checked={selectedIds.filter(y => y === x.id).length > 0}
                                        onChange={() => {
                                            // 선택된 항목이 존재하지 않는경우 
                                            if (selectedIds.filter(y => y === x.id).length === 0) {
                                                // 선택된 항목 추가 
                                                setSelectedIds([...selectedIds, x.id])
                                                // 종료 
                                                return;
                                            }
                                            // 해당 항목 제외하고 설정 
                                            setSelectedIds(selectedIds.filter(y => y !== x.id));
                                        }} />
                                </Column>
                                <ColumnLink flex="0.9" href={`/manager/benefit/benefitRestric/write/${x.id}`}>
                                    <Column flex="0.2">{getDisplayType(x)}</Column>
                                    <Column flex="0.5">{getDisplayLimit(x)}</Column>
                                    <Column flex="0.2">{getDisplayPeriod(x)}</Column>
                                </ColumnLink>
                            </Row>)
                        })
                    }
                </Table>
                <NaviWrapper>
                    {
                        !loading && data && data.getPointLimitPolicyCount && data.getPointLimitPolicyCount !== 0 && (
                            <PageNavigation
                                match={match}
                                totalPage={Math.ceil(data.getPointLimitPolicyCount / 10)}
                                pathName={`/manager/benefit/${burdenDisplayLocation}`}
                                history={history}
                                secPathName={"/"}
                            />
                        )
                    }
                </NaviWrapper>
            </Wrapper>
        </TabContainer>
    );
});