import React, { useCallback } from "react";
import { FormUpload } from "components/upload";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserTypeEnum } from "signup/type";
import Sign from "user/component/Sign";
import { isDrawnState, signFileState, userStoreState } from "./atom";
import { Stack, Typography } from "@mui/material";

export default function SignField() {
  const { userType } = useRecoilValue(userStoreState);
  const [isDrawn, setIsDrawn] = useRecoilState(isDrawnState);
  const [file, setFile] = useRecoilState(signFileState);

  const handleRemoveFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setFile(undefined);
  };

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file) {
      setFile(file);
    }
  }, []);

  return (
    <>
      {userType === UserTypeEnum.Personal && (
        <Sign file={file} isDrawn={isDrawn} setIsDrawn={setIsDrawn} />
      )}
      {userType === UserTypeEnum.Business && (
        <Stack spacing={1.5}>
          <Typography variant="subtitle2">인감/서명</Typography>
          <FormUpload
            file={file}
            onDrop={handleDrop}
            onRemove={handleRemoveFile}
          />
        </Stack>
      )}
    </>
  );
}
