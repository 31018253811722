import { Flex } from 'signup/style';
import styled from 'styled-components';
import { SmallWhiteButton } from 'common/style';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  color: #4a4a4a;
  background: white;
  padding-bottom: 55px;

  hr {
    margin: 18px 0;
    border: 0;
  }

  section {
    width: 588px;
    margin: 45px auto;

    textarea {
      width: 100%;
      height: 78px;
      box-sizing: border-box;
      border-radius: 3px;
    }

    .download {
      width: 120px;
      height: 28px;
      border-radius: 3px;
      border: 1px solid #7c7c7c;
      background: white;
    }

    .flex {
      display: flex;

      button {
        width: 65px;
        height: 38px;
        margin-left: 12px;
        color: white;
        background: #333;
        border-radius: 3px;
      }
    }
  }

  > button {
    display: block;
    width: 216px;
    height: 40px;
    margin: 0 auto;
    color: white;
    background: #333;
    border-radius: 3px;
  }
`;

export const Label = styled.strong`
  display: block;
  margin-bottom: 16px;
  font-size: 14px;
`;

export const TitleBar = styled(Flex)`
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  position: relative;

  ${(props) =>
    props.isNotice &&
    `
        &:after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #F300BA;
        }
    `}
`;

export const Anchor = styled.a`
  margin-left: 20px;
  color: #fa0070;
  font-weight: bold;
`;

export const Contents = styled.div`
  padding: 60px 78px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const FlexWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const FlexItem = styled.div`
  flex: 1;
`;

export const BorderButton = styled(SmallWhiteButton)`
  width: 40px;
  height: 40px;
`;

export const CustomButton = styled.button`
  margin-left: 12px;
  padding: 9px 13px;
  border: 0;
  border-radius: 3px;
  background: #333;
  color: white;
  font-size: 14px;
`;

export const GuideText = styled.div`
  margin-right: 5px;
  margin-left: 0;
  color: #7c7c7c;
  margin-bottom: 16px;
`;

export const GuideLink = styled.a`
  color: #000000;
  text-decoration: underline;
`;

export const FileItem = styled(FlexWrap)`
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 14px 10px;
  margin-bottom: 16px;
  text-align: center;
`;

export const FileIcon = styled.img`
  cursor: pointer;
`;

export const FileNotFound = styled.div`
  text-align: center;
  width: 100%;
  color: #7c7c7c;
`;

export const ChangePassword = styled(NavLink)`
  display: block;
  text-align: right;
  color: #000000;
  text-decoration: underline;
  margin-top: 5px;
`;

export * from './input';
export * from './field';
