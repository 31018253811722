import { authOrigin } from "api";
import axios from "axios";
import dateFormat from "dateformat";

const getVirtualImg = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    let img = document.createElement("img");
    img.crossOrigin = "anonymous";
    img.src = process.env.STORAGE_ORIGIN + src;

    img.onload = () => {
      resolve(img);
    };
  });
};

const getSignImg = async (sign) => {
  const result = await axios.post(authOrigin + "/auth/download", { id: sign });

  return getVirtualImg(
    process.env.STORAGE_ORIGIN + "/" + result.data.split("/").slice(3).join("/")
  );
};

export async function generateTermImage(
  {
    sign,
    name,
    contact,
    nationalIdNumber,
    planType,
    commissionRate,
    lang,
  }: any,
  toDataUrl = false,
  hideDate = false
) {
  const promiseTerm = getVirtualImg(
    lang === "ko"
      ? '/term/v2/ko/term_full.png'
      : '/term/v2/en/term_full.png'
  );
  const promiseArtistSign = sign
    ? typeof sign === "number"
      ? getSignImg(sign)
      : getVirtualImg(sign)
    : null;

  const [term, artistSign] = await Promise.all([
    promiseTerm,
    promiseArtistSign,
  ]);
  const now = new Date();

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = term.width;
  canvas.height = term.height;
  context.font = "bold 30px serif";
  context.drawImage(term, 0, 0);

  if (lang === "ko") {
    if (artistSign) {
      context.drawImage(artistSign, 600, canvas.height - 200, 160, 50);
    }

    if (name) {
      context.fillText(name, 650, 340, 400);
      context.font = "bold 25px serif";
      context.fillText(name, 1335, canvas.height - 275, 150);
    }

    context.font = "bold 25px serif";

    if (contact) {
      context.fillText(contact, 1335, canvas.height - 205, 150);
    }

    if (nationalIdNumber) {
      context.fillText(nationalIdNumber, 1335, canvas.height - 140, 150);
    }

    if (!hideDate) {
      context.font = "bold 30px serif";
      context.fillText(
        now.getFullYear().toString(),
        600,
        canvas.height - 1217,
        45
      );
      context.fillText(
        (now.getMonth() + 1).toString(),
        790,
        canvas.height - 1217,
        45
      );
      context.fillText(now.getDate().toString(), 895, canvas.height - 1217, 45);
    }

    // if (planType) {
    //   context.fillText(planType, 1270, canvas.height - 875);
    // } else {
    //   context.fillStyle = "gray";
    //   context.font = "bold 20px serif";
    //   context.fillText("가입 승인 후 선택 반영", 1270, canvas.height - 875);
    // }

    // if (commissionRate) {
    //   context.fillText(commissionRate + "%", 1270, canvas.height - 827);
    // } else {
    //   context.fillStyle = "gray";
    //   context.font = "bold 20px serif";
    //   context.fillText("가입 승인 후 선택 반영", 1270, canvas.height - 827);
    // }
  } else {
    // 국문이외의 언어는 영문으로 통합됨
    context.font = "bold 60px serif";
    if (name) {
      context.fillText(name, 2010, 825, 450);
      context.fillText(name, 2030, canvas.height - 771, 450); // 10130
    }
    context.font = "bold 50px serif";
    if (contact) {
      context.fillText(contact, 2030, canvas.height - 667, 500);
    }
    if (nationalIdNumber) {
      context.fillText(nationalIdNumber, 2030, canvas.height - 564, 500);
    }
  }

  return new Promise((resolve, reject) => {
    try {
      if (toDataUrl) {
        resolve(canvas.toDataURL());
      } else {
        canvas.toBlob(resolve);
      }
    } catch (err) {
      reject(err);
    }
  });
}
