import React, { MouseEvent } from "react";
import { Box, Stack, Typography } from "@mui/material";
import CloseIcon from "assets/icon_close";
import { fileSizeStr } from "utils/fileSizeStr";

type Props = {
  name: string;
  size: number;
  onRemove: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function FormFileContent({ name, size, onRemove }: Props) {
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.3199 9.77335L20.3999 3.10668C20.2753 2.96856 20.1231 2.85806 19.9531 2.78228C19.7832 2.7065 19.5993 2.66712 19.4133 2.66668H8.74658C8.30358 2.6614 7.86388 2.74343 7.45259 2.90809C7.04129 3.07275 6.66646 3.31682 6.34949 3.62635C6.03253 3.93589 5.77964 4.30483 5.60528 4.7121C5.43091 5.11938 5.33847 5.55701 5.33325 6.00002V26C5.33847 26.443 5.43091 26.8807 5.60528 27.2879C5.77964 27.6952 6.03253 28.0641 6.34949 28.3737C6.66646 28.6832 7.04129 28.9273 7.45259 29.0919C7.86388 29.2566 8.30358 29.3386 8.74658 29.3333H23.2533C23.6963 29.3386 24.136 29.2566 24.5473 29.0919C24.9585 28.9273 25.3334 28.6832 25.6503 28.3737C25.9673 28.0641 26.2202 27.6952 26.3946 27.2879C26.5689 26.8807 26.6614 26.443 26.6666 26V10.6667C26.6657 10.3362 26.5422 10.0179 26.3199 9.77335ZM18.6666 5.33335L23.6532 10.6667H19.6533C19.5136 10.6583 19.3771 10.6221 19.2516 10.5603C19.1261 10.4986 19.0141 10.4124 18.9223 10.3069C18.8304 10.2014 18.7605 10.0787 18.7166 9.94585C18.6727 9.81303 18.6557 9.67281 18.6666 9.53335V5.33335Z"
              fill="#212B36"
            />
          </svg>
          <Stack>
            <Typography variant="body2" fontWeight={500}>
              {name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {fileSizeStr(size)}
            </Typography>
          </Stack>
        </Stack>
        <button
          style={{ padding: 0, margin: 0, background: "none" }}
          onClick={onRemove}
        >
          <CloseIcon customSize={24} sx={{ cursor: "pointer" }} />
        </button>
      </Stack>
    </Box>
  );
}
