import React from "react";
import { Button, Skeleton, Stack } from "@mui/material";
import { FieldsBox, FieldName, FieldValue, FieldGroup } from "./styled";
import { UserFormType } from "./type";
import { useGetAdminQuery } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { UserTypeEnum } from "signup/type";

type Props = UserFormType & {
  onShowChangePasswordDialog: () => void;
  onClickShowDialog: () => void;
};

export default function AdminOriginalForm({
  userId,
  onShowChangePasswordDialog,
  onClickShowDialog,
}: Props) {
  const { t, i18n } = useTranslation();
  const { data, loading } = useGetAdminQuery({
    variables: {
      id: userId,
    },
  });

  const handleShowPasswordModal = () => {
    onShowChangePasswordDialog();
  };

  if (loading || !data) {
    return <Skeleton />;
  }

  const isUser = data.userV2.type === UserTypeEnum.Personal;
  return (
    <>
      <FieldsBox>
        <FieldGroup>
          <FieldName>{t("user.email")}</FieldName>
          <FieldValue>{data.userV2.account}</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.password")}</FieldName>
          <FieldValue>
            <Button
              variant="text"
              sx={{ padding: 0 }}
              onClick={handleShowPasswordModal}
            >
              {t("user.changePassword")}
            </Button>
          </FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.accountType")}</FieldName>
          <FieldValue>관리자</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.name")}</FieldName>
          <FieldValue>{data.userV2.name}</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.creatorName")}</FieldName>
          <FieldValue>
            <Stack spacing={1}>-</Stack>
          </FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.contact")}</FieldName>
          <FieldValue>-</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.country")}</FieldName>
          <FieldValue>-</FieldValue>
        </FieldGroup>
      </FieldsBox>
    </>
  );
}
