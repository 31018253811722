export const TermsConditionsText = `
ACON PARTNER HUB Terms & Conditions

Terms and Conditions

ACON PARTNER HUB Terms and Conditions

ACON PARTNER HUB (hereinafter referred to as, “Service”) operated by ‘carpenstreet Inc.’ (hereinafter referred to as, “Company”) is ACON3D’s seller-exclusive site that aids 3D modeling and data archiving to create new value. The company allows the service users (hereinafter referred to as, “Member”) to sufficiently review and understand the Terms and Conditions and agees to faithfully act in accordance with the Terms and Conditions as a service user.

Article 1 (Purpose)

The following terms serve the purpose of prescribing all rights, obligations and related procedures and responsibilities between the member and the company whilst the member who has signed up for the services uses the services provided by the company.

Article 2 (Definition of Terms)

The definition of the terms used in this agreement are as follows and the interpretation of undefined terms is as defined in the related laws and separate guides on the website.

1. ACON PARTNER HUB (hereinafter referred to as, “Service”) : This is a service provided by the company under the brand name ACON PARTNER HUB, ACON3D, or ACON regardless of the device (All sorts of wired and wireless devices such as PC, tablet, portable terminals etc.)
2. Service user (hereinafter referred to as, “Member”) : Refers to the customer who after agreeing to these terms, has been properly registered for the ACON PARTNERHUB site and is using the service.
3. PARTNERHUB Account (hereinafter referred to as “ID”) : is a combination of letters and numbers selected by the member and approved by the company for the member's identification and service use.
4. Message : Refers to the service used amongst members to deliver and share messages.
5. Contents : Refers to the text, photos, videos, and various files and links in the form of information such as codes, texts, voices, sounds, images, and videos posted by the company and its members on the service.
6. Profile : This is the member’s profile page that is open to all members and the company.
7. Postings : Signs (including URL), text, images (including photos), files, etc. that members post or register on ACON3D service.
8. Publisher : Refers to the person who posted the post on the service.

Article 3 (Effectiveness and Amendment of the Terms)

1. These terms shall be effective upon its posting on the Service Screen or upon the consent of the member by registering.
2. The company can amend this agreement to the extent that it does not violate the following laws: “The Act on Consumer Protection in Electronic Commerce”, “Act on Regulation of Terms and Conditions”, “Act on Promotion of Information and Communication Network Utilization and Information Protection”, and “Online Digital Contents Industry Development Law” and the amended contents will be notified to the members.
3. These Terms and Conditions may be amended if necessary, and if the company intends to make amendments, it shall specify the date of its effectiveness and the reason for the amendment, together with the present Terms and Conditions which will be noticed 14 days prior to the effective date of the amended terms until the day before the Effective Date. Notice of the amendment shall be made clear to members in at least one or more of the prescribed ways.
    1. Post on the website
    2. E-mail notification
    3. SMS notification
    4. Other notice methods determined by the company
4. In accordance with the provisions of this Article, the amended terms shall, in principle, be effective for the future from the effective date.
5. A member who does not agree to the amendment of these Terms and Conditions may withdraw from the membership. However, members who do not withdraw their membership or express their disagreement within 7 days after the notification of the company under paragraphs 1 and 2 of this Article shall be deemed to have agreed to the amended agreement.
6. The method of notifying and the effect of the notifications in this section shall apply in the case of individual or general notice as described in each section of this Agreement.

Article 4 (Interpretation of Terms and Conditions)

1. For the charged and free services or individual services, the company may have separate Terms and Conditions, agreements and policies (hereinafter, referred to as Agreements, etc.) and if the contents conflict with these terms, the Agreements shall take precedence in application.
2. Any matters or interpretations not specified in these terms shall be in accordance with the Agreements, etc., and related laws or regulations.

Article 5 (Application for Service Use and Establishment of User Agreement)

1. If the member agrees to these Terms and Conditions and the "Privacy Policy", fills out the subscription form provided by the company and applies for the use of the service, the company will approve the user agreement.
2. Members must provide true information (valid e-mails of current use, etc.) when applying for the User Agreement of Paragraph 1 and if a member lists false information, the use of the service will be restricted or the user agreement can be terminated, following Article 5.
3. The ID of a member who applied for use through illegal purposes such as theft of another's identity may be deleted without prior notice and the member may be prohibited from using the service and be subjected to legal sanctions.
4. For the application mentioned in paragraph 1, the company may request for the member's identity verification.
5. In addition to the essential details listed above, the company may request the applicant to provide information related to his/her work in order to provide diverse and beneficial information to the members. However, the applicant may refuse the provision of such information.
6. The company may provide various service-related information and advertisements through e-mail and SMS to members to efficiently provide the required service to the members.
7. The company will not provide a member’s information to third parties without the member's consent, except in cases where there is no special provision prescribed by law. However, the following cases are exceptions.
    1. Where member information is allowed to be used and provided to third parties based on laws and regulations
    2. In the case of providing minimal information of the member necessary for delivery of prizes, purchases, etc.
    3. In the case of third parties that are selected by the company for the purpose of providing various benefits and experiences to members
    4. In case of obtaining the user's consent according to the Terms and Conditions of other companies
8. The company may restrict the member’s use of the service if the partner affiliate restricts a category of the members for events in accordance with the company policy during the service operation.
9. If a member's information on the application form is changed while using the service, the member must immediately make modifications. Members are responsible for any consequences arising from unmodified information.
10. In the recognition of any abnormal use of service by the member, the company may restrict the member from using the service and the member shall be responsible for any relevant loss.

Article 6 (Change of Member Information)

1. Members can view and modify their personal information through the My Page screen in the service. However, the ID cannot be modified and certain information can only be modified after consultation with the company.
2. If a member makes undeserved profits by falsely setting their personal information, the individual’s unfair profit will be extinguished and the use of the service may be restricted.

Article 7 (Termination of User Contract and Disqualification / Suspension)

1. A member may terminate the contract or request service suspension if he/she wishes.
2. When a member requests to quit the service, he/she must apply through the procedures specified by the company, such as e-mail notification.
3. When a member falling under any of the following subparagraphs is identified, the company may take measures on all aspects related to the service use, such as reservation/suspension/termination/ deletion of postings etc. without prior notice to the members, in which case the member cannot claim any rights related to the service.
    1. If false information is registered during membership application
    2. In case of using the service for fraudulent methods or purposes, such as unjust event participation or point collection
    3. Death of the member
    4. In case of disrupting electronic transaction order, such as interfering with other members' use of the service or stealing information.
    5. In case of causing continuous inconvenience or discomfort to other members such as by stalking, threatening, harassing, using profanity, or dictating chatrooms
    6. In the case of impersonating an operator / employee or the company in the service
    7. In case of using and illegally stealing personal information of a third party for membership application
    8. The act of distributing information, sentences, figures, voices, photos, etc. that violates the law and the well-being order, and morals of the society while using the service.
    9. In case of the infringement of a third party’s rights including intellectual property rights such as patent rights, trademark rights, copyrights, business confidentiality, etc.
    10. In case of threatening the system such as changing the company's homepage or client program or hacking the company's server without the appointment of any special permission from the company.
    11. In case of forcibly installing programs such as Active X, spyware, and adware without prior approval of the company and prior consent of other members.
    12. In case of inducing a visit to a specific site regardless of the intentions of service visitors or other members
    13. Violation of laws related to illegal copying software or trafficking of third party works etc. while using the service
    14. The act of forming an organization based on anti-social / moral prejudice such as race, sex, fanaticism, transfer, etc.
    15. Collection of personal information such as the ID / email accounts of other members
    16. In the case of acts related to crimes or activities that are judged to be violations of related laws
    17. In the event of inducing or encouraging another member to prohibit the clauses 6 to 16 above
    18. In the case of using the service provided by the company to acquire profit by providing an opportunity to promote oneself to a third party or by acting on behalf of the promotion of a third party or by transferring the right to use the service and acquiring profit in return for the act.
    19. The act of transferring promotive information for commercial purposes without the company's prior consent
    20. The act of conducting commercial activities using the company’s service for the purpose of posting advertisements or propaganda or sending spam mails to an unspecified number of members.
    21. Any other breach of the member's obligations set out in Article 13

Article 8 (Withdrawal of Membership)

1. A member who wishes to withdraw their membership in the manner specified in Article 7, Paragraph 2 of the Terms and Conditions shall do so after following the company’s withdrawal process. The personal information created when registering as a "member" is permanently erased.

Article 9 (Contents and Changes of Service)

1. The company provides various ACON3D-related services and information to the members through the service site and performs other duties appointed by the company.
2. If the company wishes to change the contents of the service, the service’s renewed contents and the date of provision will be announced. However, if it is impossible to announce the details of the change, the company will announce the purpose and reasons for why the change cannot be announced 7 days in advance.

Article 10 (Suspension of Service)

1. The company may temporarily suspend the provision of services in the event of inspection, repair, replacement or breakdown, or disconnection of information and communication facilities such as computers.
2. If a member wishes to stop the service due to the situation specified in paragraph 1 of this Article, the company will notify the member of the suspension by posting on the bulletin within the website or notify it on the site.
3. The company may modify, suspend or change some or all of the services provided free of charge according to the company's policies and operations, and there will not be any compensations for the member regarding this unless specified in the relevant laws.

Article 11 (Member ID and Password)

1. The member is responsible for managing one’s own member ID and password and the member must not inform or let a third party use his/her ID and password. 2.       If the member recognizes that his or her ID or password is stolen or being used by a third party, he/she shall immediately notify the company and follow the company's instructions if there are any.

Article 12 (Obligations of the Company)

1. The company shall not engage in any act prohibited by the relevant laws and this agreement or act against the customs, but will do its best to provide its “service” continuously and stably.
2. The company shall have a security system to protect personal information (including credit information) so that members can use the service safely and shall disclose and comply with the privacy policy.
3. The company shall deal with any reasonable opinions or complaints raised by the members on the service. Regarding the opinions or complaints, the process and results are to be delivered to the "members" through the bulletin board or e-mail.

Article 13 (Obligations of Members)

1. General members refer to all members that have registered for the service. 
2. Partner member refers to all members who have entered into a partnership agreement and have selected a partnership. For partner members, a separate contract will be drawn up that includes sales commissions and contract periods with the company.
3. General members and partner members shall not engage in the following acts related to the use of the service. 
    1. Registration of false information in case of application or alteration related to the use of services
    2. Damage, in terms of unauthorized alteration, deletion, etc. of various information posted within the service
    3. Defamation or collecting personal information of other members
    4. Transmitting advertisement related information for commercial purposes without the company's consent, or the transmitting or posting of information (such as computer programs and advertisements) other than that permitted by the company. 5)     Disassembling, imitating, or modifying the service
    5. Violating the rights of the company or third parties, such as by defamation, or infringement of intellectual property rights
    6. Acts of disturbing other members' use of the service, such as by stalking.
    7. Disclosing/posting obscene or violent information (messages, face chat, voice record, etc.) or other information against the morals of the service
    8. Posting or sending emails or SMS materials containing software viruses or other computer code, files or programs designed to disrupt or destroy the regular operation of computer software, hardware or telecommunications equipment.
    9. Sending junk mails, spam mails, chain letters, e-mails suggesting to join pyramidal organizations, e-mails with obscene or violent messages/images/voices or any disclosure or posting of contents contrary to the public order
    10. Posting harmful materials for juveniles as specified under the Juvenile Protection Act
    11. Changing the information posted on the company's services or the reproduction, publishing, broadcasting, or provision of information acquired by utilization of the service to third parties for profit or non-profit purposes without the company's prior consent.
    12. Using the service in a way that is different from the normal usage, such as by using an automatic connection program, etc., to cause an overload on the “company” server, interfering with the regular service of the company 
4. Regarding the use of the service, general members shall not engage in any of the following acts in addition to Article 13, Paragraph 3.
    1. The act of personally contacting or attempting to purchase or trade with a sales member, who the individual has acquainted through the service, without consulting with the company
5. Regarding the use of the service, the sales members must not engage in any of the following acts in addition to Article 13, Paragraph 3.
    1. The act of personally contacting or attempting to sell or trade by bypassing the service with a general member who the individual has acquainted through the service without consulting the company
    2. Acts of attempting to sell or register a product with legal issues
    3. An act of unilaterally terminating the contract period
    4. Acts that both intentionally or unintentionally cause damage to the company
6. If the member commits the acts specified under Article 13, Paragraphs 3, 4, and 5, the company may unilaterally terminate this contract by restricting the use of the service, terminating the account, or deleting posts and the general member or sales member is obligated to compensate for any damage caused by this.

Article 14 (Restrictions on Use and Ownership of Copyright)

1. Copyrights and other intellectual property rights for works created by the company belongs to the company. However, the primary producer shall have the copyrights to works such as models provided for completion, and the company has the right to use it.
2. Members shall not use the information obtained from the service for commercial purposes or make it available to third parties by copying, transmitting, publishing, distributing, broadcasting or through other means without prior consent of the company.
3. All rights, including copyright, for the posts made by members in the course of using the service belong to the members unless indicated otherwise.
4. Members should be clear that they have the copyright and other industrial property rights of their posted contents. However, by posting the contents on the service, it is understood that he/she agrees to the contents (and its secondary works), in part or in whole, to be used (usage, disclosure, distribution, advertising, publication, reproduction, performing, public transmission, exhibition, distribution, renting, creation of secondary works, and automatic / manual translation), unexclusively free, worldwide by the company regarding its service and business and approves of transferring the rights. In addition, it is agreed that the member will not exercise authorship rights against the company. This is because by doing so, the company wishes to attract more consumers to ACON3D service and provide the suppliers with a better experience.

Article 15 (Management of Contents)

1. If the member's posts contain contents that violate related laws such as the Information Network Law and the Copyright Law, the contents administrator may request the suspension or deletion of the postings according to the procedure specified by the relevant law and the company shall take action in respect to the related laws.
2. If it has been recognized that there has been a violation of rights or in accordance to the Service Operation Policy and its related laws, the company may take temporary measures for postings that fall under the following conditions, even if there is no request by the right holder as specified in the preceding paragraph.
    1. If the content is abusive or damaging to other members or third parties
    2. Posting pornography or forming any links to pornographic sites
    3. If there is a risk of infringement of any rights including intellectual property rights, such as those regarding the copyright of the company or third parties.
    4. Posts that do not align with the nature of the bulletin board
    5. Posting advertisements that target third parties for profit
    6. If it does not comply with the posting principles and conditions of the service prescribed by the company
    7. If it interferes with the regular operation of the company or service
3. The company will take immediate action to remove posts and warn publishers in case of problems with the content.

Article 16 (Exemption)

1. If the company cannot provide the service for the following reasons, the company shall not be responsible for the related damages caused to the member.
    1. When there is an occurrence of a natural disaster or a force of equivalence in condition
    2. If there is an intentional interruption of the service by a third party that has partnered with the company for the provision of the service;
    3. When there is a problem in using the service due to the member's fault such as the device’s environment
    4. In case of unintentional or negligible reasons of the company such as those regarding the network’s environment
2. The company does not guarantee the reliability, accuracy, or copyright infringement of information, materials, facts, or information that has been posted on the service by the CP or that has been written by the member, and shall not be held liable for any damages caused by such causes.
3. The company has no obligation to intervene in disputes during the service between the members or between the members and third parties, and has no responsibility to compensate for the damages either.
4. The company is not responsible for the user’s inability to obtain the expected profit from using the service, and is exempted from liability for damages caused by the use of the service.

Article 17 (Compensation for Damage)

1. If a member causes damages to the company by violating this agreement, the member who has violated the agreement should reimburse all damages to the company.
2. If the company receives various objections, including claims for damages or litigations, from a third party other than the member due to illegal activities or violations of these Terms and Conditions during the member’s use of the service, the member shall indemnify the company through with his/her responsibility and expenses, and if fails to do so, the member shall pay for all damages.
3. The company shall not be liable for any damages incurred in relation to the services provided free of charge.

Article 18 (Service Dispute Settlement)

1. Disputes arising between the company and its members will be processed through the mediation procedure of the Electronic Commerce Dispute Mediation Committee established under the Framework Act on Electronic Commerce.

Article 19 (Termination of Service)

1. When terminating the service, the company will notify the member by applying the notification method provided in Article 3 Paragraph 3 of this agreement 3 months prior to the date of terminating the service. However, no notice will be given in case of transfer due to business or other operational reasons other than the termination of the service.
2. The member shall not receive the service from the company after the service termination date that the individual has been notified with in accordance with paragraph 1 of this Article. There is no separate compensation for service users unless otherwise specified.

Article 20 (Governing Law and Jurisdiction)

1. The matters not specified in these terms and its interpretation are subject to the laws and regulations of the Republic of Korea.
2. All disputes and litigations related to the service and these terms shall be governed by the first court of jurisdiction of the Seoul Central District Court or the competent court under the Civil Procedure Act.

Article 21 (Obligations to Protect Personal Information)

1. The company shall try to protect the member's personal information, including member registration information, as prescribed by relevant laws and regulations. Regarding the protection of the members’ personal information, it is in accordance with the relevant laws and the "Personal Information Handling Policy" set by the company and it is notified through various methods for members to check at all times.
2. The company collects the member's information to its minimum in accordance with the privacy policy necessary for establishing and implementing the service contract.
3. The company will not provide the information of the member to a third party without the user's consent.
4. The company is not responsible for the member’s information being exposed due to the member's fault.
5. The company may provide the member's personal information when the administrative agency or investigative agency requests to view or submit the member's information in accordance with related laws such as the Telecommunication Business Act, the Protection of Communications Secrets Act, Acts on the Promotion of Information Network Utilization and Information Protection.

Article 22 (Mobile Phones and Other Devices)

1. The company provides the registration of the service for free. This does not mean that the model that is being sold is free. However, the charges for telecommunications are subject to the policy of the mobile carrier. The company is not responsible for the charges related to telecommunication.
2. A member shall be deemed to have agreed to provide all rights necessary for synchronization (including synchronization through the App) with all information required by the service on his/her device.

Supplementary Provision

Terms and Conditions Revision Date : 2021-05-27

Terms and Conditions Effective Date : 2021-06-10

The previous Terms and Conditions will be replaced by these Terms and Conditions and members registered prior to the amended Terms and Conditions shall also be governed by the amended Terms and Conditions.
`;

export const PersonalInformationProcessingPolicy = `
Personal Information Processing Policy
ACON3D User Privacy Policy / 隐私政策 / プライバシーポリシー

‘carpenstreet Inc.’ (hereinafter referred to as ‘Company') places great importance on the protection of users' personal information and is committed to protecting the personal information provided to the Company online as he/she uses the company's services. The Company shall comply with the personal information protection regulations in accordance with the relevant laws and regulations to be followed by Information and Communications Service providers and the personal information protection guidelines established by the Ministry of Information and Communication. The company will inform users about the purpose and method of using personal information provided through the privacy policy and measures taken to protect personal information.
The Company discloses the privacy policy on the homepage so that users can always easily check it.
The Company's privacy policy may be changed from time to time due to changes in government laws and guidelines or the company's internal policy, and the procedures necessary for the continuous improvement of the privacy policy are to be established. If the company revises the privacy policy, it will notify users through the privacy policy on the first page of the online shopping mall. Users should check frequently when visiting the website.
The Company's privacy policy contains the following.
1. Consent to the Collection of Personal Information
2. Purpose of collecting and using personal information
3. Items of personal information collected and methods of collection
4. Period of Retention and Use of Personal Information
5. Sharing and Providing Collected Personal Information
6. Matters concerning the management of personal information of users (view, correction, deletion, etc.)
7. Operation and Rejection of Cookies
8. Personal information management of non-members
9. Consignment processing of personal information
10. The affiliation, name, and contact information of the person in charge of personal information management.
11. Duty of notification


0. Consent to the Collection of Personal Information

The Company has established a procedure for users to click "Agree" on the contents of the Company's Privacy Policy or Terms of Use, and when they click the "Agree" button, they are considered to have agreed to the collection of personal information.


1. Purpose of collecting and using personal information

"Personal information" is information about a living individual. This means information that can identify the individual in accordance with such matters as the name included in the information (even if this information alone does not identify a specific individual, it can be easily combined with other information to identify).
Most services can be used at any time without additional user registration. However, the Company collects user's personal information in order to provide better quality services, including customized services, to users through membership service.
The Company will not disclose users’ personal information without prior consent, and the collected information is used as follows.

First, the Company can develop more useful services based on the personal information provided by users. When the Company develops new services or expands the contents, the Company prioritizes the services that need to be developed more efficiently based on the personal information provided to existing users and the Company reasonably selects and provide the contents that users need.

Second, the personal information items collected and the additional purpose of the collection are as follows
-Name, ID, Password: Used for the verification process for membership service
-Email address and phone number: Secure communication channels for delivering notices, notices, and complaints, handling complaints, and providing information on new services and new products or events.
-Bank account information, credit card information: Payment of services and additional services
-Address, phone number: Secure the correct shipping address when invoice and goods delivery
-Other optional items: Materials for providing personalized service
-IP Address: Prevention of illegal use of unauthorized members and unauthorized use
-Legal Representative’s Information in the case of applicants under the age of 14: Confirm whether the legal representative agrees when collecting personal information of children under 14
Other information collected above is used for contract execution, fee settlement, membership management, marketing and advertising.


2. Items of personal information collected and methods of collection

The Company receives online information that is essential for providing services when users sign up as members to use services. The essential information collected when signing up is member’s name and email address. Also, in order to provide high quality service, company is receiving phone numbers.

In addition, members can selectively enter personal information for statistical analysis or giveaways during surveys or event events in the online shopping mall. However, the Company does not collect sensitive personal information (race and ethnicity, ideology and creed, place of origin and origin, political orientation and criminal record, health condition and sexual life, etc.) that may infringe the basic human rights of the user. In this case it definitely ask for their consent.
In any case, the information entered will not be used for any purpose other than the purpose stated in advance to users, and will not be revealed to the third parties.


3. Period of Retention and Use of Personal Information

While the user uses the service provided by the Company as a shopping mall member, the Company keeps the user's personal information and uses it to provide the service. However, if the member requests or cancels his / her subscription in accordance with the procedure and method described in "6, it will be destructed.
Personal information received for temporary purposes (such as surveys, events, identity verification, etc.), as in "3. Items of personal information collected and methods of collection," will be destroyed after the purpose is achieved.
In principle, your personal information is destroyed when the purpose of collecting or receiving the personal information is achieved as follows. If the Company needs to preserve it according to the provisions of related laws, such as the Consumer Protection Act in Commercial Law, Electronic Commerce, etc., the Company keeps the member information for a certain period of time. In this case, the Company uses the information kept only for the purpose of keeping it and the retention period is as follows. However, in case of the member, the period is applied after the withdrawal.
-Record on contract or withdrawal of subscription: 5 years
-Record on payment and supply of goods: 5 years
-Records of consumer complaints or disputes: 3 years

However, despite the above period, information that the company deems appropriate to resolve disputes in the future can be kept permanently for the purpose of submitting to the court.


4. Sharing and Providing Collected Personal Information

The Company shall use the personal information of the users within the range as stated in "2. Purpose of collecting and using personal information", and shall not use it beyond the scope without the prior consent of the user or, in principle, disclose the personal information of the user outside. However, exceptions are as follows.
-If the users have agreed to the disclosure in advance
-If necessary for the settlement of charges following service provision
-If the terms of service or operating principles such as the Terms of Service and other member services posted on the website are violated
-If disclosing personal information to take legal action against those who abused the services to cause psychological and material damage to others is sufficiently evidenced;
-If it is determined in good faith that it is required by other laws (ex.At the request of a government / investigation agency, etc.)
-If providing statistics, academic research or market research to in a form that does not identify a specific individual to advertisers, partners, or research organizations


5. Matters concerning the management of personal information of users (view, correction, deletion, etc.)
If you wish, you can view your personal information at any time and modify the required information kept. In addition, some of the additional information other than the required information for membership can always be viewed, modified or deleted. Changes, deletions and withdrawal of members' personal information are available after logging into our customer center.


6. Operation and Rejection of Cookies

A. Purpose of using cookies
① The Company uses 'cookies' that store and retrieve information about users in order to provide personalized services. Cookies are small pieces of information that a website and/or software server sends to your browser and are stored on your computer's hard disk.
② The Company can provide a specific customized service that can only be achieved through the use of cookies.
③ The Company may use cookies to identify members and keep members logged in.

B. Installation / Operation and Rejection of Cookies
① The user has the option to install cookies. As a result, you can adjust your options in your web browser to allow / deny all cookies or to be noticed each time a cookie is stored.
-Here's how to specify whether cookies are allowed (in Internet Explorer):
From the Tools menu, select Internet Options.
Click the Privacy tab.
You can set the [Privacy Level].
② If you refuse to store cookies for the Company’s website, some services provided by the Company, such as personalized services, may be difficult to use. If you refuse to store cookies for the Company’s software, installing and using the software will not be available.


7. Personal information management of non-members

-Non-member customers can also purchase goods and services in the Company’s service. In the case of non-member orders, the Company only request personal information necessary for delivery, payment, and delivery of the product.
-If you purchase as a non-member, the payer information and the recipient information entered by the non-member customer will not be used for any other purpose except for payment, product delivery and product recommendation.


8. Consignment processing of personal information

In order to improve the service, the Company may collect, process, and manage the personal information, etc., if necessary, and provide it to a third party.
-The Company entrusts the following tasks with regard to the processing of personal information and regulates the necessary matters so that personal information can be safely managed when entrusting contracts according to related laws. In addition, the information the Company shares is limited to the minimum amount of information necessary to achieve that purpose.
-Eligible Persons: Entering companies contracted with the Company
-Consignment service content: Analyzing data and sending goods and event benefits
-Consignee: PG Company contracted with the Company, AWS, Megazone Cloud, Microsoft Azure, Mixpanel, Sentry
-Consignment Service: Payment through credit card and virtual account


9. Name and Contact Information of Personal Information Manager

We are committed to keeping your information safe and secure. In the event of an accident that goes against in protecting your personal information, the Privacy Officer is responsible.
The user is responsible for maintaining the security of his / her own password related to the user's personal information. Carpenstreet Inc.. does not directly ask the user in any way about the password, so please be careful not to leak the password to others. Be especially careful when you are online at a public place. CarpenStreet Inc. designates the personal information protection officer and the person in charge of receiving feedbacks and handling complaints about personal information.


Name: Minhong Lee
E-M A I L: cs@acon3d.com
Text message: 82+10-7105-2164


10. Obligations of Notice
The contents of the current privacy policy will be notified through the 'Notice' on the homepage if there is any further deletion or modification of the contents due to the change of government policy or security technology.

Privacy Policy Revision Date: 2022-07-18
Privacy Policy Effective Date: 2022-07-18
`;
