import React, { useState } from 'react';
import { SmallWhiteButton } from 'common/style';
import { GLOBAL_PAYMENT_FEE_LIST } from '../../constants';
import * as Styled from '../../style';
import { STATUS_CODE } from '../../constants';
import { GLOBAL_STATUS_LIST } from '../../constants';

const Component = (props) => {
    const { data, userInfo, onSave, onOpenModal, inputRefs } = props;
    // 편집 모드 여부
    const [isEdit, setIsEdit] = useState(false);

    // 회원 정보
    const userData = data?.user;
    const userPlan = data?.userPlan;
    // 입점 검토 자료 값
    const reference = data?.reference;
    // 수수료율
    const commissionRate = userPlan?.commissionRate;

    // 작가 승인상태 값
    const acceptStatus = userData.accept.status;
    // 작가 수정상태
    const modifyStatus = userData.accept.modifyStatus;
    // 작가 승인상태 텍스트
    const acceptStatusText = GLOBAL_STATUS_LIST[acceptStatus + 1];
    // 상태 값
    const STATUS = {
        accept: {
            reject: acceptStatus === STATUS_CODE.REJECT,
            request: acceptStatus === STATUS_CODE.REQUEST,
            approve: acceptStatus === STATUS_CODE.APPROVE,
        },
        modify: {
            reject: modifyStatus === STATUS_CODE.REJECT,
            request: modifyStatus === STATUS_CODE.REQUEST,
            approve: modifyStatus === STATUS_CODE.APPROVE,
        },
    }

    // 작가 승인상태 변경
    const onChangeStatus = (value) => {
        const idx = userData.accept.statusStringList.indexOf(value) - 1;
        props.onChangeData('data.user.accept.status', idx);
    };

    // 값 변경
    const onChangeData = (e) => {
        const element = e.target;
        const key = element.dataset.key;
        let value = element.value;

        if (element.dataset.allowNum) {
            value = element.value.replace(/[^0-9\-]/g, '');
            element.value = value;
        }

        props.onChangeData(key, value);
    };

    // 입점 검토 자료 값 변경 이벤트
    const onChangeReference = (e) => {
        const { target } = e;
        const key = target.dataset.key;
        const value = target.value;

        let newReference = [...reference];
        newReference[+key].url = value;

        props.onChangeData('reference', newReference);
    };

    // 입점 검토 자료 추가
    const referencePush = () => {
        if (reference.length >= 3) {
            return false;
        }

        reference.push({ url: '' });
        props.onChangeData('reference', [...reference]);
    };

    // 입점 검토 자료 제거
    const referencePop = () => {
        reference.pop();
        props.onChangeData('reference', [...reference]);
    };

    // 일반 정보 수정 요청
    const onChangeInfoSave = async () => {
        if (isEdit) {
            const userSaveData = {
                snsUrl: userData?.snsUrl,
                accept: {
                    isPass: true,
                }
            };

            // 저장 완료되면 완료 모달 띄움.
            const isSave = await onSave(userSaveData);
            if (isSave) {
                onOpenModal("Submitted", true);
                setIsEdit(!isEdit);
            }

            return;
        }

        setIsEdit(!isEdit);
    };

    const addToRefs = el => {
        if (el && !inputRefs.current.includes(el)) {
            inputRefs.current.push(el);
        }
    };

    const onChangeCreatorName = (e) => {
        const value = e.target.value;
        props.onChangeData('data.user.brand.name', value.replace(/[^a-zA-Z\s]/g, ""));
    };

    const brands = userData.brand.item.slice().sort((a, b) => {
        return a.language.id - b.language.id;
    });

    return (
        <section>
            {/* 제목 */}
            <Styled.TitleBar>
                <Styled.Title>Creator Information</Styled.Title>
                {STATUS.accept.approve && (
                    <Styled.Anchor onClick={onChangeInfoSave}>
                        {!isEdit ? 'Edit' : 'Submit Changes'}
                    </Styled.Anchor>
                )}
            </Styled.TitleBar>

            {/* 영역 */}
            <Styled.Contents>
                {/* 작가 승인 상태 */}
                <Styled.Field label={'Brand Status'}>
                    <Styled.Select
                        data={userData.accept.statusStringList}
                        value={acceptStatusText}
                        onChange={onChangeStatus}
                        disabled={true}
                    />
                </Styled.Field>

                { /* 승인 상태가 반려일 경우 반려사유 입력 폼 표시 */
                    STATUS.accept.reject &&
                    <Styled.Field label={"Editor's Notes"}>
                        <Styled.Textarea
                            disabled={true}
                            defaultValue={data.comment}
                        />
                    </Styled.Field>
                }


                {/* ID(Email) */}
                <Styled.InputField
                    label={'ID(Email)'}
                    data-key="data.user.account"
                    defaultValue={userData.account}
                    onChange={onChangeData}
                    disabled={true}
                />
                { /* 승인일 경우 비밀번호 찾기 문구 표시 */
                    (STATUS.accept.approve && isEdit) &&
                    <Styled.ChangePassword
                        to={{
                            pathname: "/password/changePw",
                            _id: userInfo?._id
                        }}
                    >
                        Change Password
                    </Styled.ChangePassword>
                }

                {/* 필명 */}
                <Styled.Field label={'Creator Name'}>
                    {brands.map((b, i, arr) => {
                        return (
                            <Styled.Input
                                key={i}
                                style={arr.length - 1 > i ? {marginBottom: 10} : {}}
                                defaultValue={b?.name}
                                onChange={onChangeCreatorName}
                                disabled={!STATUS.accept.reject}
                                inputRef={addToRefs}
                                value={b?.name}
                            />
                        );
                    })}
                </Styled.Field>

                {/* SNS Account Link (Optional) - 글로벌 몰 */}
                <Styled.InputField
                    label={'SNS Account Link (Optional)'}
                    data-key="data.user.snsUrl"
                    defaultValue={userData.snsUrl}
                    onChange={onChangeData}
                    disabled={!(isEdit || STATUS.accept.reject)}
                />

                {/* 입점 검토 자료 */}
                <Styled.Field label={'Project Reference'}>
                    {reference.map((document, i) => (
                        <Styled.FlexWrap style={{ marginBottom: '10px' }} key={i}>
                            <Styled.Input
                                style={{ width: STATUS.accept.reject ? '95%' : '100%' }}
                                data-key={i}
                                value={document.url}
                                onChange={onChangeReference}
                                readOnly={!STATUS.accept.reject}
                                isEmpty={!document.url}
                                inputRef={addToRefs}
                            />
                            {STATUS.accept.reject && (
                                <>
                                    {i === 0 ? (
                                        <Styled.BorderButton onClick={referencePush}>+</Styled.BorderButton>
                                    ) : i !== 2 && (
                                        <Styled.BorderButton onClick={referencePop}>-</Styled.BorderButton>
                                    )}
                                </>
                            )}
                        </Styled.FlexWrap>
                    ))}
                </Styled.Field>

                {STATUS.accept.approve && (
                    <>
                        {/* 브랜드 코드 */}
                        <Styled.InputField
                            readOnly
                            label={'Brand Number'}
                            value={userData?.brand?.godoBrandCd}
                            disabled={true}
                        />

                        {/* 계약서 */}
                        <Styled.Field label={'Contract'}>
                            <div style={{ marginBottom: '10px' }}>
                                <SmallWhiteButton onClick={props.downloadTerm}>Download</SmallWhiteButton>
                            </div>
                            <div>The password is your brand number.</div>
                        </Styled.Field>

                        {/* 수수료율 */}
                        <Styled.InputField
                            readOnly
                            label={'Platform Fee'}
                            value={GLOBAL_PAYMENT_FEE_LIST.find(x => x.value === commissionRate)?.text || ''}
                            disabled={true}
                        />
                    </>
                )}
            </Styled.Contents>
        </section>
    );
};

export default Component;