import React, { useState, useRef, useContext, useEffect } from "react";
import axios from "axios";
import ACON from "lib/global";
import SignUpView from "./SignUpView";
import { AppContext } from "app";
import TermsOfUse from "./popup/TermsOfUse";
import CollectionUseOfPersonalInformation from "./popup/CollectionUseOfPersonalInfo";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_IS_USED_EMAIL } from "api/quries";
import { CREATE_USER } from "api/mutations";
import { uploadFile } from "api";
import { useTranslation } from "react-i18next";

export default (props) => {
  const { t } = useTranslation();
  const { showConfirmMessage, showAlertMessage } = useContext(AppContext);
  const [getIsUsedEmail, getIsUsedEmailData] = useLazyQuery(GET_IS_USED_EMAIL);
  const [createUser] = useMutation(CREATE_USER);

  // 회원가입 정보 객체
  const [signUpInfoObj, setSignUpInfoObj] = useState({
    /* 통과여부 */
    // 이메일 통과여부
    isEmail: undefined,
    // 이메일 인증 통과여부
    isAuthEmail: undefined,
    // 패스워드 통과여부
    isPassword: undefined,
    // 첫번째 패스워드 통과여부
    isFirstPassword: undefined,
    // 패스워드 확인 통과여부
    isRepassword: undefined,
    // 전화번호 통과여부
    isPhone: undefined,
    // 사용자명 통과여부
    isUsername: undefined,
    // 작가명 통과여부
    isPenname: undefined,
    // 브랜드코드 통과여부
    isBrandCode: undefined,
    // 브랜드코드 인증 통과여부
    isAuthBrandCode: undefined,
    // 사업자번호 통과여부
    isBusinessNumber: undefined,
    // 계좌번호 통과여부
    isAccountNumber: undefined,
    // 은행 통과여부
    isBank: undefined,
    // 예금주 통과여부
    isDepositor: undefined,
    // 이용약관 동의여부
    isAgreeTermsOfUseModal: undefined,
    // 개인정보 수집 및 이용 동의여부
    isAgreeCollectionUseOfPersonalInformation: undefined,

    /* 필드들의 유효성 검사 메세지 state */
    // 이메일 유효성검사 메세지
    userIdValidationText: "",
    // 패스워드 유효성검사 메세지
    passwordValidationText: "",
    // 패스워드 확인 유효성검사 메세지
    rePasswordValidationText: "",
    // 전화번호 유효성검사 메세지
    phoneValidationText: "",
    // 사용자명 유효성검사 메세지
    usernameValidationText: "",
    // 작가명 유효성검사 메세지
    pennameValidationText: "",
    // 사업자번호 유효성검사 메세지
    businessNumberValidationText: "",
    // 계좌번호 유효성검사 메세지
    accountNumberValidationText: "",
    // 은행 유효성검사 메세지
    bankValidationText: "",
    // 예금주 유효성검사 메세지
    depositorValidationText: "",

    /* 필드 값들의 대한 state */
    // 이메일
    userId: "",
    // 이메일 도메인
    emailDomain: "",
    // 패스워드
    password: "",
    // 패스워드 확인
    rePassword: "",
    // 휴대폰번호
    phone: "",
    // 사용자이름
    username: "",
    // 작가명
    penname: "",
    // 브랜드코드
    brandCode: "",
    // 사업자번호
    businessNumber: "",
    // 계좌번호
    accountNumber: "",
    // 은행
    bank: "",
    // 예금주
    depositor: "",

    // 언어
    lang: "",
  });

  // 필명 input 태그
  const pennameInput = useRef();
  // 브랜드코드 input 태그
  const brandCodeInput = useRef();
  // 브랜드코드 인증 button 태그
  const brandCodeAuthButton = useRef();
  // 이메일 input 태그
  const emailInput = useRef();
  // 이메일 도메인 input
  const emailDomainInput = useRef();
  // 이메일 인증버튼
  const emailAuthButton = useRef();
  // 패스워드 input
  const passwordInput = useRef();
  // 패스워드 확인 input
  const rePasswordInput = useRef();
  // 휴대폰번호 input
  const phoneInput = useRef();
  // 작가본명 input
  const usernameInput = useRef();
  // 이용약관 button
  const termsOfUseButton = useRef();
  // 개인정보 수집 및 이용 button
  const collectionUseOfPersonalInformationButton = useRef();

  // 이용약관 모달 동의하기
  const agreeTermsOfUse = () => {
    setSignUpInfoObj({
      ...signUpInfoObj,
      isAgreeTermsOfUseModal: true,
    });
  };
  // 이용약관 모달 열기
  const openTermsOfUseModal = () => {
    showConfirmMessage(t("TermsOfUse.label"), TermsOfUse, agreeTermsOfUse, {
      cancelText: t("close"),
      confirmText: t("Agree.label"),
      btnType: "big",
    });
  };

  // 개인정보 수집 및 이용 모달 동의하기
  const agreeCollectionUseOfPersonalInformation = () => {
    setSignUpInfoObj({
      ...signUpInfoObj,
      isAgreeCollectionUseOfPersonalInformation: true,
    });
  };

  // 개인정보 수집 및 이용 모달 열기
  const openCollectionUseOfPersonalInformationModal = () => {
    showConfirmMessage(
      t("CollectingAndUsingPersonalInformation.label"),
      CollectionUseOfPersonalInformation,
      agreeCollectionUseOfPersonalInformation,
      {
        cancelText: t("close"),
        confirmText: t("Agree.label"),
        btnType: "big",
      }
    );
  };

  // 필드 변경 이벤트 처리기 메소드
  const handleFieldChange = (fieldId, value) => {
    setSignUpInfoObj({
      ...signUpInfoObj,
      [fieldId]: value,
    });
  };
  // 이메일 변경 이벤트 처리기 메소드
  const onChangeEmailId = (e) => {
    // 이메일 도메인
    const emailDomain = e.target.value;
    // 이메일 도메인 @ 분리처리 하기위한 변수
    const strSplitArr = emailDomain.split("@");

    // 도메인 영역에 아이디 및 도메인이 공존하여 들어오지 않은경우
    if (strSplitArr.length <= 1) {
      setSignUpInfoObj({
        ...signUpInfoObj,
        userId: strSplitArr[0],
      });

      // 종료
      return;
    }

    // 공존하여 들어온 경우 ㅣ
    setSignUpInfoObj({
      ...signUpInfoObj,
      userId: strSplitArr[0],
      emailDomain: strSplitArr[1],
    });
  };
  // 이메일 변경 이벤트 처리기 메소드
  const onChangeEmailDomain = (e) => {
    // 이메일 도메인
    const emailDomain = e.target.value;
    // 이메일 도메인 @ 분리처리 하기위한 변수
    const strSplitArr = emailDomain.split("@");

    // 도메인 영역에 아이디 및 도메인이 공존하여 들어오지 않은경우
    if (strSplitArr.length <= 1) {
      setSignUpInfoObj({
        ...signUpInfoObj,
        emailDomain: strSplitArr[0],
      });
      // 종료
      return;
    }

    // 공존하여 들어온 경우 ㅣ
    setSignUpInfoObj({
      ...signUpInfoObj,
      userId: strSplitArr[0],
      emailDomain: strSplitArr[1],
    });
  };
  // 브랜드 코드 input 태그 변경 이벤트 처리기 메소드
  const onChangeBrandCodeInput = (e) => {
    // 입력된 브랜드 코드
    var brandCode = e.target.value;

    // 입력된 브랜드 코드가 숫자가 아닐경우
    if (ACON.GetIsStr(brandCode)) {
      // 종료
      return;
    }

    // 브랜드 코드값 적립
    setSignUpInfoObj({
      ...signUpInfoObj,
      brandCode: e.target.value,
    });
  };
  // 값 변경 이벤트 처리기
  const handleChange = (e) => {
    setSignUpInfoObj({
      ...signUpInfoObj,
      [e.target.name]: e.target.value,
    });
  };
  // 에러 메세지 표시하기 메소드
  const showErrorMessage = (message) => {
    // 메세지 확보
    message = message ? message : t("anInternalErrorHasOccurred");

    // 메세지 모달 표시
    showAlertMessage(t("ErrorSignUp.label"), message);
  };
  // 중복확인 button 클릭 이벤트
  const onClickDuplicateCheckBtn = async () => {
    // 이메일 사용할 수 있는지의 대한 여부
    let isEmail = false;
    // 이메일 인증 여부
    let isAuthEmail = false;
    // 사용자 안내 메세지
    let displayText = t("CanNotUseEmail.label");

    try {
      // 사용자 이메일
      const userEmail = `${signUpInfoObj.userId}@${signUpInfoObj.emailDomain}`;
      // 이메일 유효여부
      const isValidEmail = ACON.GetEmailEnabled(userEmail);

      // 이메일이 유효하지 않을경우
      if (!isValidEmail) {
        // 안내 메세지 조립
        displayText = t("CanNotUseEmail.label");
        isEmail = false;
        isAuthEmail = false;
        // 유효성 에러 발생
        throw new ACON.ValidationError();
      }

      getIsUsedEmail({ variables: { account: userEmail } });
    } catch (errObj) {
      // 에러가 유효성검사 실패 에러일 경우
      if (errObj instanceof ACON.ValidationError) {
        // 종료
        return;
      }

      // 실패 메세지
      const failMessage = `중복확인 button 이벤트 처리 도중, 예상치 못한 예외 발생(${errObj.message})`;
      // 실패 로그 기록 TODO
      console.log(failMessage);
      // 사용자 안내 메세지 표시
      showErrorMessage();
    } finally {
      // 상태 변경
      setSignUpInfoObj({
        ...signUpInfoObj,
        isEmail: isEmail,
        isAuthEmail: isAuthEmail,
        userIdValidationText: displayText,
      });
    }
  };
  // 이메일 유효성검사 onblur 이벤트
  const onBlurValidEmail = (e) => {
    // 사용자 이메일
    var userEmail = `${signUpInfoObj.userId}@${signUpInfoObj.emailDomain}`;

    // 이메일이 사용이 불가능할 경우
    if (!ACON.GetEmailEnabled(userEmail)) {
      // 이메일 인증 false
      setSignUpInfoObj({
        ...signUpInfoObj,
        isAuthEmail: false,
      });
    }
  };
  // 비밀번호 유효성검사 onblur 이벤트
  const onBlurValidPw = (e) => {
    // 패스워드 확인 state 들 초기화
    setSignUpInfoObj({
      ...signUpInfoObj,
      rePassword: "",
      rePasswordValidationText: "",
    });

    // 패스워드, 패스워드 확인
    const { password, rePassword } = signUpInfoObj;

    // 표시 텍스트 (기본값 사용불가)
    var displayText = t("PwValidText.label");
    // 첫번째 패스워드 통과여부 (기본값 false)
    var isFirstPassword = false;
    // 패스워드 여부 (기본값 false)
    var isPassword = false;

    // 유효여부 (사용자가 입력한 패스워드가 규칙에 맞는 문자일 경우)
    if (ACON.GetPasswordEnabled(password)) {
      displayText = t("Available.label");
      isFirstPassword = true;
      // 패스워드 확인이랑 패스워드랑 동일할 경우, 패스워드 통과
      isPassword = password === rePassword;
    }

    // 사용자 안내 메세지 변경
    setSignUpInfoObj({
      ...signUpInfoObj,
      passwordValidationText: displayText,
      isPassword: isPassword,
      isFirstPassword: isFirstPassword,
    });
  };
  // 비밀번호 확인 유효성검사 onblur 이벤트
  const onBlurValidRePw = (e) => {
    // 패스워드
    const { password, rePassword, isFirstPassword } = signUpInfoObj;
    // 유효여부 (패스워드가 일치할 경우)
    var isValid = signUpInfoObj.password === signUpInfoObj.rePassword;
    // 패스워드 여부 (기본값 false)
    var isRepassword = false;
    // 패스워드 여부 (기본값 false)
    var isPassword = false;
    // 표시 텍스트
    var displayText = t("Mismatch.label");

    // 유효여부
    if (isValid) {
      displayText = t("Match.label");
      // 패스워드 확인이랑 패스워드랑 동일할 경우, 패스워드 통과
      isPassword = isFirstPassword && password === rePassword;
      isRepassword = password === rePassword;
    }
    // 상태변경
    setSignUpInfoObj({
      ...signUpInfoObj,
      rePasswordValidationText: displayText,
      isPassword: isPassword,
      isRepassword: isRepassword,
    });
  };
  // 휴대폰 번호 유효성검사 onblur 이벤트
  const onBlurPhone = (e) => {
    // 휴대폰 번호
    const phone = signUpInfoObj.phone.trim();
    // 폰 여부
    const isPhone = phone ? true : false;
    // 표시 텍스트
    const displayText = isPhone ? "" : t("NoInput.label");

    // 상태 변경
    setSignUpInfoObj({
      ...signUpInfoObj,
      phoneValidationText: displayText,
      isPhone: isPhone,
    });
  };
  // 작가본명 유효성검사 onblur 이벤트
  const onBlurUsername = (e) => {
    // 휴대폰 번호
    var { username } = signUpInfoObj;
    // 빈 문자열 제거
    username = username.trim();
    // 작가본명 여부
    const isUsername = username ? true : false;
    // 표시 텍스트
    const displayText = isUsername ? "" : t("NoInput.label");

    // 상태 변경
    setSignUpInfoObj({
      ...signUpInfoObj,
      usernameValidationText: displayText,
      isUsername: isUsername,
    });
  };
  // 필명 유효성검사 onblur 이벤트
  const onBlurPenname = (e) => {
    // 필명
    const penname = signUpInfoObj.penname.trim();
    // 폰 여부
    const isPenname = penname ? true : false;
    // 표시 텍스트
    const displayText = isPenname ? "" : t("NoInput.label");

    // 상태 변경
    setSignUpInfoObj({
      ...signUpInfoObj,
      pennameValidationText: displayText,
      isPenname: isPenname,
    });
  };
  // 브랜드코드 유효성검사 keydown 이벤트
  const onKeyUpBrandCode = (e) => {
    // 브랜드 코드
    const brandCode = signUpInfoObj.brandCode.trim();

    setSignUpInfoObj({
      ...signUpInfoObj,
      isBrandCode: brandCode ? true : false,
    });
  };
  // 사업자번호 유효성검사 onblur 이벤트
  const onBlurBusinessNumber = (e) => {
    // 사업자번호
    const businessNumber = signUpInfoObj.businessNumber.trim();
    // 사업자번호 여부
    const isBusinessNumber = businessNumber ? true : false;
    // 표시 텍스트
    const displayText = businessNumber ? "" : t("NoInput.label");

    // 상태 변경
    setSignUpInfoObj({
      ...signUpInfoObj,
      businessNumberValidationText: displayText,
      isBusinessNumber: isBusinessNumber,
    });
  };
  // 계좌번호 유효성검사 onblur 이벤트
  const onBlurAccountNumber = (e) => {
    // 휴대폰 번호
    const accountNumber = signUpInfoObj.accountNumber.trim();
    // 폰 여부
    const isAccountNumber = accountNumber ? true : false;

    // 상태 변경
    setSignUpInfoObj({
      ...signUpInfoObj,
      isAccountNumber: isAccountNumber,
    });
  };
  // 은행 유효성검사 change 이벤트
  const onChangeBankSelect = (e) => {
    // 선택된 값
    var selectedValue = e.target.value;
    let { name, value } = e.target;
    // 상태 설정하기
    setSignUpInfoObj({
      ...signUpInfoObj,
      [name]: value,
      // 사업자일 경우, 사업자번호 영역 표시
      isBank: selectedValue ? true : false,
    });
  };
  // 예금주 유효성검사 onblur 이벤트
  const onBlurDepositor = (e) => {
    // 예금주
    const depositor = signUpInfoObj.depositor.trim();
    // 예금주 여부
    const isDepositor = depositor ? true : false;
    // 표시 텍스트
    const displayText = isDepositor ? "" : t("NoInput.label");

    // 상태 변경
    setSignUpInfoObj({
      ...signUpInfoObj,
      depositorValidationText: displayText,
      isDepositor: isDepositor,
    });
  };
  // 취소 button 태그 click 이벤트
  const onClickCancelButton = (e) => {
    // 홈으로 이동
    props.history.push("/");
  };
  // 회원가입 button 태그 click 이벤트
  const onClickJoinButton = async (e) => {
    const {
      isEmail,
      isAuthEmail,
      isPassword,
      isFirstPassword,
      isRepassword,
      isPhone,
      isUsername,
      isPenname,
      isBrandCode,
      isAgreeTermsOfUseModal,
      isAgreeCollectionUseOfPersonalInformation,
    } = signUpInfoObj;

    // 포커스 할 대상
    let focusTarget = null;
    try {
      // 필명이 통과되지 않았을 경우
      if (!isPenname) {
        // 포커싱 대상 지정
        focusTarget = pennameInput;
        // 에러 호출
        throw new ACON.ValidationError(t("PennameValidText.label"));
      }
      // 브랜드코드가 통과되지 않았을 경우
      if (!isBrandCode) {
        // 포커싱 대상 지정
        focusTarget = brandCodeInput;
        // 에러 호출
        throw new ACON.ValidationError(t("BrandCodeValidText.label"));
      }
      // 이메일이 통과되지 않았을 경우
      if (!isEmail) {
        // 포커싱 대상 지정
        focusTarget = emailInput;
        // 에러 호출
        throw new ACON.ValidationError(t("NoEmailValidText.label"));
      }
      // 이메일이 통과되지 않았을 경우
      if (!isAuthEmail) {
        // 포커싱 대상 지정
        focusTarget = emailAuthButton;
        // 에러 호출
        throw new ACON.ValidationError(t("DblCheckEmailValidText.label"));
      }
      // 패스워드가 통과되지 않았을 경우
      if (!isFirstPassword) {
        // 포커싱 대상 지정
        focusTarget = passwordInput;
        // 에러 호출
        throw new ACON.ValidationError(t("ReEnterPwValidText.label"));
      }
      // 패스워드 확인 통과되지 않았을 경우
      if (!isRepassword) {
        // 포커싱 대상 지정
        focusTarget = rePasswordInput;
        // 에러 호출
        throw new ACON.ValidationError(t("ReEnterRePwValidText.label"));
      }
      // 패스워드가 통과되지 않았을 경우
      if (!isPassword) {
        // 포커싱 대상 지정
        focusTarget = passwordInput;
        // 에러 호출
        throw new ACON.ValidationError(t("ReEnterPwValidText.label"));
      }
      // 휴대폰번호가 통과되지 않았을 경우
      if (!isPhone) {
        // 포커싱 대상 지정
        focusTarget = phoneInput;
        // 에러 호출
        throw new ACON.ValidationError(t("PhoneValidText.label"));
      }
      // 작가본명이 통과되지 않았을 경우
      if (!isUsername) {
        // 포커싱 대상 지정
        focusTarget = usernameInput;
        // 에러 호출
        throw new ACON.ValidationError(t("AuthorRealNameValidText.label"));
      }
      // 이용약관이 통과되지 않았을 경우
      if (!isAgreeTermsOfUseModal) {
        // 포커싱 대상 지정
        focusTarget = termsOfUseButton;
        // 에러 호출
        throw new ACON.ValidationError(t("AgreeTerms1.label"));
      }
      // 개인정보 수집 및 이용이 동의되지 않았을 경우
      if (!isAgreeCollectionUseOfPersonalInformation) {
        // 포커싱 대상 지정
        focusTarget = collectionUseOfPersonalInformationButton;
        // 에러 호출
        throw new ACON.ValidationError(t("AgreeTerms2.label"));
      }

      const tmpFiles = await Promise.all(
        Array(4)
          .fill(null)
          .map(() => uploadFile("수동회원가입 임시파일"))
      );
      const [idCard, bankBook, signedTerm, sign] = tmpFiles.map(({ key }) => ({
        name: "tmp",
        key,
      }));

      const { data } = await createUser({
        variables: {
          user: {
            type: "0",
            subType: "",
            account: `${signUpInfoObj.userId}@${signUpInfoObj.emailDomain}`,
            companyName: null,
            password: signUpInfoObj.password,
            name: signUpInfoObj.username,
            contact: signUpInfoObj.phone,
            contactAdditional: null,
            settle: {
              bank: 22,
              bankName: "",
              bankAccountNumber: "",
              bankAccountOwner: "",
              nationalIdNumber: "",
            },
            reference: [""],
          },
          brand: {
            name: signUpInfoObj.penname,
            godoBrandCd: signUpInfoObj.brandCode,
          },
          file: {
            idCard,
            bankBook,
            signedTerm,
            sign,
          },
        },
      });

      if (data.createUser) {
        // 회원가입 완료 메시지 표시
        const options = {
          confirmText: "close",
          btnType: "big",
          alignCenter: true,
          textType: "big",
        };
        showAlertMessage(
          t("FirstGreetingMessageTitle"),
          t("completedSignup"),
          options
        );
      } else {
        throw new ACON.ValidationError(data);
      }

      // 회원가입 성공했을 경우, 로그인 폼으로 이동
      // api 기입 위치
      props.history.push("/signin");
    } catch (errObj) {
      // 에러가 유효성검사 실패 에러일 경우
      if (errObj instanceof ACON.ValidationError) {
        showAlertMessage(t("ErrorSignUp.label"), errObj.message);
        focusTarget && focusTarget.current && focusTarget.current.focus();
        // 종료
        return;
      }
      // 에러 로그 기록 (TODO)

      if (errObj?.message === "not unique") {
        showAlertMessage("중복오류", "중복된 브랜드코드입니다");
        return false;
      }
    }
  };

  useEffect(() => {
    const { loading, data } = getIsUsedEmailData;

    if (loading === false && data) {
      // 이메일이 사용 가능할 경우
      if (!data.existsUser) {
        setSignUpInfoObj({
          ...signUpInfoObj,
          isEmail: true,
          isAuthEmail: true,
          userIdValidationText: t("CanUseEmail.label"),
        });
      }
    }
  }, [getIsUsedEmailData]);

  return (
    <>
      <SignUpView
        t={t}
        signUpInfoObj={signUpInfoObj}
        pennameInput={pennameInput}
        brandCodeInput={brandCodeInput}
        brandCodeAuthButton={brandCodeAuthButton}
        emailInput={emailInput}
        emailDomainInput={emailDomainInput}
        emailAuthButton={emailAuthButton}
        passwordInput={passwordInput}
        rePasswordInput={rePasswordInput}
        phoneInput={phoneInput}
        usernameInput={usernameInput}
        termsOfUseButton={termsOfUseButton}
        collectionUseOfPersonalInformationButton={
          collectionUseOfPersonalInformationButton
        }
        openTermsOfUseModal={openTermsOfUseModal}
        openCollectionUseOfPersonalInformationModal={
          openCollectionUseOfPersonalInformationModal
        }
        handleFieldChange={handleFieldChange}
        handleChange={handleChange}
        showErrorMessage={showErrorMessage}
        onBlurValidEmail={onBlurValidEmail}
        onBlurValidPw={onBlurValidPw}
        onBlurValidRePw={onBlurValidRePw}
        onBlurPhone={onBlurPhone}
        onBlurUsername={onBlurUsername}
        onBlurPenname={onBlurPenname}
        onKeyUpBrandCode={onKeyUpBrandCode}
        onBlurBusinessNumber={onBlurBusinessNumber}
        onBlurAccountNumber={onBlurAccountNumber}
        onChangeBankSelect={onChangeBankSelect}
        onBlurDepositor={onBlurDepositor}
        onClickCancelButton={onClickCancelButton}
        onClickJoinButton={onClickJoinButton}
        onClickDuplicateCheckBtn={onClickDuplicateCheckBtn}
        onChangeBrandCodeInput={onChangeBrandCodeInput}
        onChangeEmailId={onChangeEmailId}
        onChangeEmailDomain={onChangeEmailDomain}
      />
    </>
  );
};
