import { Stack, Typography } from "@mui/material";
import React from "react";

type Props = {
  text: string;
};

export default function GnbTab({ text }: Props) {
  return (
    <Stack alignItems={"center"} direction="row" height={"100%"}>
      <Typography fontSize={"16px"} color={"#333"}>
        {text}
      </Typography>
    </Stack>
  );
}
