import { FlexBox } from 'components/style';
import styled from 'styled-components';

export const Wrap = styled.section`
	width: 855px;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 177px;
	color: #4a4a4a;
	font-size: 14px;

	> h1 {
		font-size: 30px;
		font-weight: normal;
	}

	> section {
		margin-top: 65px;
	}

	${FlexBox} {
		margin-top: 20px;
		align-items: baseline;
	}
`;

export const SectionTitle = styled.h2`
	margin-bottom: 10px;
	font-size: 20px;
	font-weight: normal;
	line-height: 1.7;
`;

export const SubmitButton = styled.button`
	width: 276px;
	height: 40px;
	border-radius: 3px;
	background: #333333;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	color: white;

	&:disabled {
		opacity: 0.5;
	}
`;