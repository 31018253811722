import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { CustomButton } from 'user/User/components/common/input';

const SignWrap = styled.div`
    position: relative;
    width: 100%;
    height: 138px;
    background: #FAFAFD;
    border: 1px solid #EAEAF5;
    margin-top: 10px;
    
    & > canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const Summary = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    pointer-events: none;
    color: #c4c4c4;
    font-style: italic;
    font-size: 20px;
`;

const ResetButton = styled(CustomButton)`
    margin: 0;
    margin-top: 10px;
`;

const Component = (props) => {
    const ref = useRef();

    const [isDrawn, setIsDrawn] = useState(false);
    let isDrawing = false;
    let prevPoint = null;

    function init() {
        const { current: canvas } = ref;
        const context = canvas.getContext('2d');

        context.clearRect(0, 0, canvas.width, canvas.height);

        setIsDrawn(false);
        props.setIsDrawn(false);
    }

    async function getBlob() {
        const { current: canvas } = ref;

        return new Promise((resolve, reject) => {
            canvas.toBlob(resolve);
        });
    }

    function draw({ x, y }) {
        const { current: canvas } = ref;
        const context = canvas.getContext('2d');

        context.beginPath();
        context.arc(x, y, 5, 0, 2 * Math.PI);
        context.fill();
        context.closePath();

        context.beginPath();
        context.moveTo(prevPoint.x, prevPoint.y);
        context.lineTo(x, y);
        context.lineWidth = 12;
        context.stroke();
        context.closePath();
    }

    function getPosition({ clientX, clientY }) {
        const { current: canvas } = ref;
        const { x, y } = canvas.getBoundingClientRect();
        // console.log(clientX,clientY);
        return {
            x: clientX - x,
            y: clientY - y,
        };
    }

    function onDrawStart(e) {
        // 그리기 시작
        if (props.setIsDrawn) {
            props.setIsDrawn(true);
        }

        setIsDrawn(true);
        isDrawing = true;
        prevPoint = getPosition(e);
    }

    function onDraw(e) {
        // 그리기

        if (isDrawing) {
            draw(getPosition(e));
            prevPoint = getPosition(e);
        }
    }

    function onDrawEnd(e) {
        // 그리기 끝
        isDrawing = false;
        prevPoint = null;
    }

    useEffect(() => {
        const { current: canvas } = ref;
        const { width, height } = canvas.getBoundingClientRect();

        canvas.width = width;
        canvas.height = height;

        if (props.setGetBlob) {
            props.setGetBlob(() => getBlob)
        }
    }, [])

    return (
        <div className={props.className}>
            <SignWrap>
                {!isDrawn && props.summary &&
                    <Summary>{props.summary}</Summary>
                }
                {useMemo(() => (
                    <canvas
                        ref={ref}
                        id={props.id}
                        onMouseDown={onDrawStart}
                        onMouseMove={onDraw}
                        onMouseUp={onDrawEnd}
                        onMouseLeave={onDrawEnd}
                    />
                ), [])}
            </SignWrap>
            <ResetButton onClick={init}>{props.button}</ResetButton>
        </div>
    )
}

export default Component;