import React from "react";
import {
  MenuItem,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { useBanksQuery } from "generated/graphql";
import { bankIdState, bankNameState } from "user/User.atom";
import { useRecoilState, useRecoilValue } from "recoil";

export default function BankSelectField({ value, onChange }: TextFieldProps) {
  const bankDataResult = useBanksQuery();
  const bankId = useRecoilValue(bankIdState);
  const [bankName, setBankName] = useRecoilState(bankNameState);

  const banks = bankDataResult.data?.banks
    ? [{ id: 0, name: "선택하기" }, ...bankDataResult.data?.banks]
    : [];

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setBankName(e.target.value);
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <Typography variant={"body2"} color={"grey.600"}>
        은행
      </Typography>
      <Stack direction="row" spacing={1}>
        <TextField
          select
          fullWidth
          value={value}
          onChange={onChange}
          size={"medium"}
          SelectProps={{ displayEmpty: true }}
        >
          {banks.map((option, optionIdx) => (
            <MenuItem
              key={optionIdx}
              value={option.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: "body2",
                textTransform: "capitalize",
              }}
            >
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {bankId === 22 && (
          <TextField fullWidth value={bankName} onChange={handleChange} />
        )}
      </Stack>
    </Stack>
  );
}
