import styled from "styled-components";

export const EditorContents = styled.div`
    border-radius: 3px;
`;

export const EditorToolBar = styled.div`
    border: none !important;
    padding: 8px 0px !important;
    display: flex;
    justify-content: space-between;
`;

export const EditorCustomTool = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 45%;
`;

export const BackgroundColor = styled.div`
    margin-right: 5px;
    display: flex;
`;

export const CloseButtonColor = styled.div`
    display: flex;
`;

export const CusotmToolText = styled.div`
    margin-right: 5px;
`;

export const CusotmToolItem = styled.div``;

export const HexColorInputBox = styled.input`
    width: 60px;
    height: 23px;
    margin-left: 5px;
    border: 1px #ccc solid;
    border-radius: 3px;
    padding-left: 5px;
`;