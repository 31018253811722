import styled from 'styled-components';

export const Wrap = styled.div`
`;
export const List = styled.div`
    height: 500px;
    overflow-y: auto;
`;
export const ListItem = styled.div`
    padding: .75rem 1.25rem;
    font-size: 14px;
    &:hover {
        cursor: pointer;
        background-color: RGB(248, 249, 250);
    }
    ${props => props.active ? 'background-color: RGB(225, 225, 225) !important;' : ''}
`;

export const Input = styled.input.attrs(props => ({
    type: 'text'
}))`
    width: 100%;
    padding: 9.5px 16px;
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    
    & ~input, & ~button {
        margin-left: 12px;
    }
`;
export const View = styled.div`
    width: 100%;
    padding-top: 40px;
    padding-left: 20px;
`;
export const ViewItem = styled.div`
    display: flex;
    justify-content: stretch;
    margin: 5px;
`;
export const ViewItemTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    width: 100px;
    text-align: center;
    padding:5px;
`;
export const ViewItemValue = styled.div`
    width: 100%;
    padding: 5px;
    text-align: left;
    align-items: center;
    font-size: 14px;
`;
export const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    button {
        margin-right: 10px;
    }
`;