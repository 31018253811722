import React, { useState, useEffect, useCallback, useContext } from "react";
import { NavLink } from "react-router-dom";
import Spinner from "../../spinner/Spinner";
import axios from "axios";
import "./User.scss";
import { useTranslation } from "react-i18next";
import { MessageContext } from "../../common/Message2";

import { AppContext } from '../../app'
import { useQuery } from "@apollo/client";
import { USER_CHANGE } from "api/quries";
import Loading from "common/editor/Loading";

function User(props) {
    const { t } = useTranslation();
    
    const { userInfo: { _id }, setBackgroundColor } = useContext(AppContext);
    const openMessage = useContext(MessageContext);
    
    const { loading, error, data } = useQuery(USER_CHANGE);
    
    const [ form, setForm ] = useState({});
    const [ change, setChange ] = useState({});
    
    async function onChange(e) {
        const target = e.target;

        console.log(target.name, target.value);
    }

    async function onSubmit() {

            let tempMessage = [
                t("user.updateSuccess"),
                t("user.updateFail"),
                t("user.usedPenName")
            ];
        // openMessage({ message: modalMessage })
    }

    function goBack() {
        props.history.goBack();
    }

    useEffect(() => {
        if (data && data.user) {
            const { account, contact, brand } = data.user;
            
            setForm({
                email: account      || '',
                phone: contact      || '',
                penName: brand.name || '',
            });
        }
    }, [data]);

    useEffect(() => {
        setBackgroundColor('white')
        return () => {
            setBackgroundColor('none')
        }
    }, [])

    return (
        <>
            { loading && (
                <Loading />
            )}
            <div className="userInfo">
                <div className="userInfo__title">
                    {t("EditPersonalInformation.label")}
                </div>
                <div className="userInfo__form">
                    {/* 아이디 필드 */}
                    <div className="userInfo__field">
                        <div className="userInfo__field__name">
                            {t("ID.label")}
                        </div>
                        <div className="userInfo__field__value">
                            <div className="text">{form.email}</div>
                            <div className="link">
                                <NavLink
                                    to={{
                                        pathname: "/password/changePw",
                                        _id
                                    }}
                                    className="vivid"
                                >
                                    {t("ChangePassword.label")}
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    {/* 휴대폰번호 필드 */}
                    <div className="userInfo__field">
                        <div className="userInfo__field__name">
                            {t("Phone.label")}
                        </div>
                        <div className="userInfo__field__value">
                            <div className="value">
                                <input name="contact"
                                    value={form.phone}
                                    disabled={true}
                                    placeholder={t("Phone.label")}
                                    onChange={onChange}
                                    type="text"
                                    className="underline"
                                />
                                <span className="userInfo__field__validaiton"></span>
                            </div>
                        </div>
                    </div>

                    {/* 필명 필드 */}
                    <div className="userInfo__field">
                        <div className="userInfo__field__name">{t("Penname.label")}</div>
                        <div className="userInfo__field__value">
                            <div className="value">
                                <input name="brand.name"
                                    value={form.penName}
                                    disabled={true}
                                    placeholder={t("Penname.label")}
                                    onChange={onChange}
                                    type="text"
                                    className="underline"
                                />
                                <span className="userInfo__field__validaiton"></span>
                            </div>
                        </div>
                    </div>
                    { false && 
                    // 임시 비활성화 (회원가입에 언어 선택이 없음)
                        <div className="userInfo__field">
                            <div className="userInfo__field__name">{t("user.useLanguage")}</div>
                            <div className="userInfo__field__value">
                                <select
                                    name="lang"
                                    className="max"
                                    value={form.lang}
                                    onChange={onChange}
                                >
                                    <option value="ko">{t("korean")}</option>
                                    <option value="en">{t("english")}</option>
                                </select>
                            </div>
                        </div>
                    }
                    {/* 버튼 */}
                    <div className="userInfo__line btn-line">
                        {/* 취소 버튼 */}
                        <button onClick={goBack}
                            type="button"
                            className="white-button"
                        >
                            {t("cancel")}
                        </button>

                        {/* 회원 정보 수정 버튼 */}
                        <button
                            onClick={onSubmit}
                            type="button"
                            className="vivid-button"
                        >
                            {t("EditMember.label")}
                        </button>
                    </div>
                </div>
            </div>
        </>);
}

export default User;