import React from 'react';
import styled from 'styled-components';

const Component = (props) => {
    return (
        <div className={props.className}>
            <strong>{props.label}</strong>
            { props.children }
        </div>
    )
}

export const Field = styled(Component)`
    strong {
        display: inline-block;
        font-size: 14px;
        margin-bottom: 16px;
    }

    & + & {
        margin-top: 36px;
    }
`;