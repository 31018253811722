import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Field, Title } from 'acon-mui/components/Board';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { displayState, gameState, isAdultOnlyState, lectureState, statusState, webtoonState } from 'boards/DetailBoardWrite/recoil';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export default function DocumentType() {
  const { t } = useTranslation();
  const display = useRecoilValue(displayState);
  const status = useRecoilValue(statusState);
  const [webtoon, setWebtoon] = useRecoilState(webtoonState);
  const [game, setGame] = useRecoilState(gameState);
  const [lecture, setLecture] = useRecoilState(lectureState);
  const setIsAdultOnly = useSetRecoilState(isAdultOnlyState);
  const disabled = useMemo(() => display.isDisp || status === STATUS_DRAFT_ON_OPEN, [display.isDisp, status]);
  const isWebtoonDisabled = useMemo(() => disabled || lecture, [disabled, lecture]);
  const isGameDisabled = useMemo(() => disabled || lecture, [disabled, lecture]);
  const isLectureDisabled = useMemo(() => disabled || webtoon || game, [disabled, webtoon, game]);

  const handleClickToon = (e) => {
    setWebtoon(e.target.checked);
    setLecture(false);
  };
  const handleClickGame = (e) => {
    setGame(e.target.checked);
    setLecture(false);
  };
  const handleClickLecture = (e) => {
    setLecture(e.target.checked);
    setWebtoon(false);
    setGame(false);
    setIsAdultOnly(false);
  };

  return (
    <Field className="hubWrite_field_document_type" mt={3}>
      <Title checkboxVisible={false} marginRight="auto">
        {t('document.productType')}
      </Title>
      <Box display="flex" alignItems="center" color="#000000DE" letterSpacing="0.15px">
        <FormGroup sx={{ ml: -1, '& label': { margin: '0 !important' } }}>
          <FormControlLabel
            control={<Checkbox checked={webtoon} disabled={isWebtoonDisabled} onChange={handleClickToon} />}
            label={t('document.toon')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            }}
          />
        </FormGroup>
        <FormGroup sx={{ ml: 1, '& label': { margin: '0 !important' } }}>
          <FormControlLabel
            control={<Checkbox checked={game} disabled={isGameDisabled} onChange={handleClickGame} />}
            label={t('document.game')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            }}
          />
        </FormGroup>
        <FormGroup sx={{ ml: 1, '& label': { margin: '0 !important' } }}>
          <FormControlLabel
            control={<Checkbox checked={lecture} disabled={isLectureDisabled} onChange={handleClickLecture} />}
            label={t('document.lecture')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            }}
          />
        </FormGroup>
      </Box>
    </Field>
  );
}
