import { LANG_CODE, NEW_STATUS_DRAFT, STATUS_COMPLETE, STATUS_COMPLETE_ON_OPEN, STATUS_DENIED, STATUS_DRAFT_ON_OPEN, STATUS_IN_REVIEW, STATUS_IN_REVIEW_ON_OPEN, STATUS_ON_SALE, STATUS_ON_SALE_ON_OPEN, STATUS_REJECTED, STATUS_REJECTED_ON_OPEN, STATUS_REQUESTED, STATUS_REQUESTED_ON_OPEN } from "boards/DetailBoardWrite/constants";
import { DefaultValue, selector } from "recoil";
import { originalDocumentData } from "../originalDocumentData";
import { DocumentDataType } from "../type";

export const originalDocumentDataSelector = selector<DocumentDataType>({
  key: 'originalDocumentDataSelector',
  get: ({get}) => get(originalDocumentData),
  set: ({get, set}, val) => {
    if (!(val instanceof DefaultValue)) {
      const {
        id,
        assetId,
        brand,
        language,
        originalLanguage,
        title,
        contentHead,
        contentBody,
        file,
        extensions,
        applications,
        mainImage,
        subImage,
        categories,
        price,
        keywords,
        copyright,
        status,
        version,
        isAdultOnly,
      } = val;

      set(originalDocumentData, {
        ...typeof id === 'string' && {id},
        ...typeof assetId === 'number' && {assetId},
        ...typeof brand === 'number' && {brand},
        ...[LANG_CODE.KO, LANG_CODE.EN, LANG_CODE.JA, LANG_CODE.ZH].includes(language) && {language},
        ...[LANG_CODE.KO, LANG_CODE.EN, LANG_CODE.JA, LANG_CODE.ZH].includes(originalLanguage) && {originalLanguage},
        ...typeof title === 'string' && {title},
        ...typeof contentHead === 'string' && {contentHead},
        ...typeof contentBody === 'string' && {contentBody},
        ...typeof file === 'object' && {file},
        ...Array.isArray(extensions) && {extensions},
        ...Array.isArray(applications) && {applications},
        ...typeof mainImage === 'object' && {mainImage},
        ...typeof subImage === 'object' && {subImage},
        ...typeof categories === 'object' && {categories},
        ...typeof price === 'number' && {price},
        ...Array.isArray(keywords) && {keywords},
        ...typeof copyright?.isOriginal === 'boolean' && {copyright},
        ...[
          NEW_STATUS_DRAFT, 
          STATUS_REQUESTED, 
          STATUS_IN_REVIEW, 
          STATUS_DENIED, 
          STATUS_REJECTED, 
          STATUS_REJECTED_ON_OPEN, 
          STATUS_COMPLETE, 
          STATUS_DRAFT_ON_OPEN, 
          STATUS_REQUESTED_ON_OPEN, 
          STATUS_IN_REVIEW_ON_OPEN, 
          STATUS_COMPLETE_ON_OPEN, 
          STATUS_ON_SALE, 
          STATUS_ON_SALE_ON_OPEN
        ].includes(status) && {status},
        ...typeof version === 'number' && {version},
        ...typeof isAdultOnly === 'boolean' && {isAdultOnly},
      });
    }
  },
});