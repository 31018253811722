export const nestedObject = (key, value) => {
    const isNested = key.includes('.');
    if (!isNested) {
        const isArray = key.includes('[');

        if (isArray) {
            const regex = /\[(\d+)\]/g;
            const regexResult = regex.exec(key);

            const match = regexResult[0];
            const idx = regexResult[1];
            const array = [];
            array[idx] = value;

            return { [key.replace(match, '')]: array };
        }
        return { [key]: value };
    }

    const list = key.split('.');

    return { [list.shift()]: nestedObject(list.join('.'), value) }
};

// 국가 정보 가져오기
export const convertCountryData = (data) => {
    // 국가 정보 데이터
    const countryData = data.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    }).map(x => ({ value: x.code, text: x.name }));

    return countryData;
};

// 저장되지 않고 업로드한 파일 다운로드
export const downloadImageFile = (file) => {
    const blob = new Blob([file]);
    const objectURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectURL;
    link.download = file.name;
    link.click();
};