import React from "react";
import { useTranslation } from "react-i18next";
import { GrayButton, Button } from "./input";
import { TextCenter } from "components/style";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accountFormState,
  chapterState,
  signState,
  statusState,
} from "./SignUp.atoms";
import { SignupChapterEnum, SignupChapters } from "./type";
import { useHistory } from "react-router-dom";
import useTerm from "./useTerm";
import { Signin } from "lib/user";
import { LanguageCodeEnum } from "generated/graphql";
import { Stack } from "@mui/material";
import useResponsive from "hooks/useResponsive";
import { SIZE } from "../constants";

type Props = {
  onNext?: () => Promise<string>;
  onPrev?: () => void;
};

export default function Navigator({ onNext, onPrev }: Props) {
  const { t, i18n } = useTranslation();
  const { completeCreateUser } = useTerm();
  const isDesktop = useResponsive("up", SIZE.DESKTOP);
  const [status, setStatus] = useRecoilState(statusState);
  const account = useRecoilValue(accountFormState);
  const [chapterValid] = useRecoilState(chapterState);
  const [signaturePath] = useRecoilState(signState);

  const isLast =
    i18n.language === LanguageCodeEnum.Ko
      ? status === SignupChapterEnum.Contract
      : status === SignupChapterEnum.Confirm;

  const prevStatus = () => {
    const index = SignupChapters.findIndex((x) => x === status);
    const prev = SignupChapters[index - 1];
    if (prev < 0) return;

    setStatus(prev);
  };

  const nextStatus = () => {
    const index = SignupChapters.findIndex((x) => x === status);
    const prev = SignupChapters[index + 1];
    if (!prev) return;

    setStatus(prev);
  };

  const handlePrev = () => {
    onPrev && onPrev();
    prevStatus();
  };

  const handleNext = async () => {
    onNext && (await onNext());

    if (isLast) {
      if (i18n.language !== LanguageCodeEnum.Ko) {
        await completeCreateUser(signaturePath);
      }

      await Signin(account.userEmail, account.password);

      window.location.href = "/welcome";
      return;
    }

    nextStatus();
  };

  const isDisabled = chapterValid[status];

  return (
    <Stack
      spacing={1.25}
      direction={isDesktop ? "row" : "column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {status > 0 && (
        <div>
          <Button
            style={{ margin: 0 }}
            disabled={i18n.language !== "ko" && status === 1}
            onClick={handlePrev}
          >
            {t("signup.prev")}
          </Button>
        </div>
      )}
      <div>
        <GrayButton
          onClick={handleNext}
          disabled={!isDisabled}
          style={{ margin: 0 }}
        >
          {isLast ? t("signup.confirm") : t("signup.next")}
        </GrayButton>
      </div>
    </Stack>
  );
}
