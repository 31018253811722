import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import AccountInfo from '../account/AccountInfo';
import PageNavi from '../boards/PageNavi';
import queryString from 'query-string';
import { GET_USER_SEARCH } from '../api/quries';
import './Account.scss';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../app';

const Account = (props) => {
  const { setBackgroundColor } = useContext(AppContext);
  // 국내 정의 코드
  const domesticCode = 'domestic';
  // 해외 정의 코드
  const overseasCode = 'overseas';
  // 국적 검색 필터 리스트
  const nationalityData = [
    {
      name: '국내',
      value: domesticCode,
    },
    {
      name: '해외',
      value: overseasCode,
    },
  ];

  useEffect(() => {
    setBackgroundColor('white');
    return () => {
      setBackgroundColor('none');
    };
  }, []);

  const { match, t } = props;

  const defaultState = {
    inputType: 'brand.name',
    inputKeyword: '',
  };
  const [state, setState] = useState(defaultState);

  const limit = 10;

  let query = { type: 'brand.name', q: '' };
  if (props.history.location.search) {
    query = queryString.parse(props.history.location.search);
  }

  const { data } = useQuery(GET_USER_SEARCH, {
    variables: {
      offset: ((+match.params.page || 1) - 1) * limit,
      type: query.type || 'brand.name',
      keyword: query.q || '',
      limit,
    },
    fetchPolicy: 'cache-and-network',
  });
  const users = data?.getUsers;

  let items;
  let totalPage = 1;

  if (data && users?.count >= 0) {
    items = users.rows;
    totalPage = Math.ceil(users.count / limit);
  }

  const handleSearch = (e) => {
    let query = '';
    const keyword = state.inputKeyword;
    if (keyword) {
      query = queryString.stringify({ q: keyword, type: state.inputType });
    }
    props.history.push({
      pathname: '/account/1',
      search: query,
    });
  };

  const handleSearchChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleTypeChange = (e) => {
    let keyword = state.inputKeyword;

    if (state.inputType === 'country') {
      keyword = '';
    }

    // 국적 선택 시 "국내" 값으로 세팅
    if (e.target.value === 'country') {
      keyword = domesticCode;
    }

    setState({
      ...state,
      [e.target.name]: e.target.value,
      inputKeyword: keyword,
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // 검색 양식
  let searchForm = (
    <div className="account__search">
      <select name="inputType" value={state.inputType} onChange={handleTypeChange}>
        <option value="brand.name">{t('Penname.label')}</option>
        <option value="brandId">{t('BrandCode.label')}</option>
        <option value="account">{t('E-mail')}</option>
        <option value="name">{t('AuthorName.label')}</option>
        <option value="country">{t('country.label')}</option>
      </select>
      {state.inputType === 'country' ? (
        <select name="inputKeyword" onChange={handleSearchChange}>
          {nationalityData.map((x) => (
            <option value={x.value}>{x.name}</option>
          ))}
        </select>
      ) : (
        <input name="inputKeyword" value={state.inputKeyword} onChange={handleSearchChange} onKeyPress={handleKeyPress} type="text" className="line" />
      )}
      <button onClick={handleSearch} className="min-btn black-button" type="button">
        {t('Search.label')}
      </button>
    </div>
  );

  return (
    <div className="account">
      <div className="account__title">{t('계정관리')}</div>
      <div className="account__line">{searchForm}</div>
      <AccountInfo {...props} items={items} />
      <div className="account__line center">{searchForm}</div>
      <div className="account__line center">
        <PageNavi {...props} totalPage={totalPage} pathName="/account" />
      </div>
    </div>
  );
};

export default withTranslation()(Account);
