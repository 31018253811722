import { Box } from "@mui/material";
import { LanguageCodeEnum } from "generated/graphql";
import React, { useEffect, useState } from "react";
import { generateTermImage } from "user/util";

type Props = {
  name: string;
  contact: string;
  nationalIdNumber: string;
  language: LanguageCodeEnum;
};

export default function Term({
  name,
  contact,
  nationalIdNumber,
  language,
}: Props) {
  const [termSource, setTermSource] = useState<string>("");

  const setTerm = async () => {
    const source = await generateTermImage(
      {
        name,
        contact,
        nationalIdNumber,
        lang: language,
      },
      true,
      true
    );

    setTermSource(source as string);
  };

  useEffect(() => {
    setTerm();
  }, [name, contact, nationalIdNumber, language]);

  return (
    <Box height={"585px"} overflow={"auto"} borderRadius={"6px"}>
      <img src={termSource} style={{ width: "100%" }} />
    </Box>
  );
}
