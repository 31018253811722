import styled from 'styled-components';

export const TabView = styled.div`
    width: 100%;
    display: flex;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
`;
export const Tab = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top:25px;
    height: 66px;
    box-sizing: border-box;
    justify-content: center;
    cursor: pointer;
    ${props => props.active ? `border-bottom: 7px solid #FF007B;` : ``}
`;
export const Input = styled.input.attrs(props => ({
    type: 'text'
}))`
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    border: 1px solid #CDCDCD;
    padding-left: 10px;
    &[disabled] {
        pointer-events: none;
    }

    &[hidden] {
        display: none;
    }

    &[readonly] {
        color: #7C7C7C;
    }
`;