import { PinkLabel, YelloLabel } from "components/label";
import React from "react";
import { NavLink } from "react-router-dom";
import "./AccountInfo.scss";
import { STATUS_CODE } from "user/Mypage/constants";
import { COUNTRY_KO_CODE } from "common/country/constants";

function AccountInfo(props) {
  const { t, items } = props;

  const trTags = items?.map((item) => {
    let penName = "";
    let brandId = "";

    if (item.brand) {
      const temp = item.brand;
      penName = temp?.item?.find((x) => x.language.code === "ko")?.name;
      brandId = temp?.id;
    }

    return (
      <tr key={item.id}>
        <td className="center">
          {item.settle?.country === COUNTRY_KO_CODE ? "국내" : "해외"}
        </td>
        <td className="center">{brandId}</td>
        <td className="center">
          <NavLink to={"/user/" + item.id}>{item.account}</NavLink>
        </td>
        <td className="center">{penName}</td>
        <td className="center">{item.name}</td>
        <td className="center">
          {item.accept.status === "요청" ? (
            <YelloLabel>{item.accept.status}</YelloLabel>
          ) : item.accept.status === "반려" ? (
            <PinkLabel>{item.accept.status}</PinkLabel>
          ) : (
            item.accept.status
          )}
        </td>
        <td className="center">
          {item.accept.modifyStatus ===
          item.accept.statusStringList[STATUS_CODE.REQUEST + 1] ? (
            <YelloLabel>{item.accept.modifyStatus}</YelloLabel>
          ) : item.accept.modifyStatus ===
            item.accept.statusStringList[STATUS_CODE.REJECT + 1] ? (
            <PinkLabel>{item.accept.modifyStatus}</PinkLabel>
          ) : (
            item.accept.modifyStatus
          )}
        </td>
      </tr>
    );
  });

  return (
    <div className="accountInfo">
      <table className="table">
        <thead>
          <tr>
            <th>{t("Country.label")}</th>
            <th>{t("BrandCode.label")}</th>
            <th>{t("e-mail")}</th>
            <th>{t("Penname.label")}</th>
            <th>{t("AuthorName.label")}</th>
            <th>승인상태</th>
            <th>수정상태</th>
          </tr>
        </thead>
        <tbody>{trTags}</tbody>
      </table>
    </div>
  );
}
export default AccountInfo;
