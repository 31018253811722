import React from "react";
import { SvgIconStyle } from "components";
import { SvgProps } from "components/SvgIconStyle";

type Props = SvgProps & {
  direction?: "up" | "down" | "left" | "right";
};
function ArrowIcon({ direction = "up", ...other }: Props) {
  const transform = (() => {
    if (direction === "down") return "rotate(180deg)";
    if (direction === "left") return "rotate(270deg)";
    if (direction === "right") return "rotate(90deg)";
  })();

  return (
    <SvgIconStyle
      {...other}
      src={"/assets/icon/ic_arrow.svg"}
      sx={{
        ...(transform && {
          transform,
        }),
      }}
    />
  );
}

export default ArrowIcon;
