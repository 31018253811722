import React from "react";
import { Field } from "../common/field";
import { Input, InputField } from "../common/input";
import { SmallWhiteButton } from "common/style/index";
import { PLAN_TYPE } from "user/value/PlanType";
import { Flex } from "signup/style";

const Component = (props) => {
  const { user, userPlan } = props;

  const { type, isExclusive, commissionRate } = userPlan || {};
  const planType = PLAN_TYPE({ type: type, isExclusive: isExclusive });
  const reference = user.reference.map((a) => ({ url: a.url }));
  const brands = user.brand.item.slice().sort((a, b) => {
    return a.language.id - b.language.id;
  });

  return (
    <>
      <InputField
        readOnly
        label="작가 승인상태"
        value={user.accept.statusString}
      />
      <InputField readOnly label="아이디(이메일)" value={user.account} />
      <Field readOnly label={"필명"}>
        {brands.map((b, i, arr) => {
          return (
            <Input
              readOnly
              key={i}
              style={arr.length - 1 > i ? { marginBottom: 10 } : {}}
              value={b?.name}
            />
          );
        })}
      </Field>
      <InputField readOnly label="연락처" value={user.contact} />
      <InputField readOnly label="추가 이메일" value={user.contactAdditional} />
      <InputField readOnly label="브랜드코드" value={user.brand?.godoBrandCd} />
      <Field label="입점 검토 자료">
        {reference.map((document, i) => (
          <Flex>
            <Input key={i} data-key={i} value={document.url} />
          </Flex>
        ))}
      </Field>
      <Field label="계약서">
        <span>계약서 파일의 비밀번호는 작가님의 브랜드코드 입니다.</span>
        <div>
          <SmallWhiteButton onClick={props.downloadTerm}>
            다운로드
          </SmallWhiteButton>
        </div>
      </Field>
      {userPlan && (
        <>
          <InputField readOnly label="파트너십 유형" value={planType || ""} />
          <InputField
            readOnly
            label="수수료율(%)"
            value={commissionRate || ""}
          />
        </>
      )}
    </>
  );
};

export default Component;
