import { Stack, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import "./Logo.scss";

type Props = {
  subText: string;
};
export default function Logo({ subText }: Props) {
  return (
    <NavLink exact to="/">
      <Stack direction="row" alignItems={"baseline"}>
        <img
          src={"/assets/icon/logo.svg"}
          alt="acon3dLogo"
          style={{ width: "120px" }}
        />
        <Typography
          fontSize={12}
          fontWeight={700}
          color={"#000"}
          marginLeft={"6px"}
        >
          {subText}
        </Typography>
      </Stack>
    </NavLink>
  );
}
