import React, { useState, useContext, useEffect, useRef } from 'react';
import { Item, Value } from '../../style';
import { useTranslation } from 'react-i18next';
import { ImageBox, Message } from './style';
import SubImage from './image';
import { AppContext } from 'app';
import { useRecoilState, useRecoilValue } from 'recoil';
import { subImageSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import downloadImg from 'utils/downloadImg';
import { Box, Typography } from '@mui/material';
import { displayState } from 'boards/DetailBoardWrite/recoil';
import palette from 'theme/palette';

export default (({ isChecked }) => {
    const { t } = useTranslation();
    const { userInfo } = useContext(AppContext);
    const display = useRecoilValue(displayState);
    const [subImage, setSubImage] = useRecoilState(subImageSelector);

    // 현재 이미지 유형 (기본 1)
    const [imageType, setImageType] = useState(1);

    // 메세지 
    const [message, setMessage] = useState({
        type: false,
        txt: ''
    });

    // 이미지
    const [image, setImage] = useState('');


    // 서브이미지 크로퍼
    const cropperTag = useRef(null);

    useEffect(() => {
        if (!subImage.isComplete && subImage.cropInfo) setSubImage({ isComplete: false });
    }, [subImage.cropInfo]);

    const allImageEventHandler = [
        {
            setImageType: () => { setImageType(1); },
            cropperTag: cropperTag,
            setCropInfo: value => {
                setSubImage({ 
                    cropInfo: value,
                });
            },
            setImageUrl: (key, name) => {
                setSubImage({ 
                    path: key,
                    name: name,
                });
            },
            setImage: setImage,
            setComplete: val => setSubImage({ isComplete: val })
        },
    ];
    // 메세지 표시하기 메소드
    const showMessage = (txt, type) => {
        // type이 정의되지 않았을 경우 
        if (type === undefined)
            type = false;

        // 메세지 표시 
        setMessage({
            type,
            txt
        });

        setTimeout(() => {
            // 2초 뒤 메세지 초기화 
            setMessage({
                ...message,
                txt: ''
            });
        }, 2000);
    };
    // 이미지 다운로드 버튼 클릭 이벤트 처리기 메소드 
    const onClickImageDownload = async () => {
        downloadImg(process.env.STORAGE_ORIGIN + '/' + subImage.path, "서브이미지");
    };

    const setCompleteCallback = () => {};

    const setCallback = () => { };
    const setFileCallback = () => { };
    
    return (
        <>
            <Box mt={3} mb={1.5} display="flex" color="#000000DE">
                <Typography
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="22px"
                    letterSpacing="0.1px"
                >
                    {t("subImageTitle")}
                </Typography>
                <Typography
                    ml={1}
                    padding="3px 10px"
                    bgcolor="#00000014"
                    borderRadius="99px"
                    color="#000000DE"
                    fontSize="13px"
                    lineHeight="18px"
                    letterSpacing="0.16px"
                >
                    {t('document.image.subImageBallonDescription')}
                </Typography>
            </Box>
            <Item>
                <Value>
                    {message.txt && <Message type={message.type}>{message.txt}</Message>}
                    {isChecked ? <SubImage
                        message={message}
                        showMessage={showMessage}
                        setCroppedImageBlob={value => {
                            setSubImage({ 
                                imageBlob: value,
                            });
                        }}
                        setCroppedImageDataUrl={value => {
                            setSubImage({ 
                                croppedImageDataUrl: value,
                            });
                        }}
                        imgType={'subImage1'}
                        cropperTag={cropperTag}
                        disabled={display.isDisp}
                        image={image}
                        setImage={setImage}
                        allImageEventHandler={allImageEventHandler}
                        isComplete={subImage.isComplete}
                        setComplete={val => setSubImage({ isComplete: val })}
                        isShow={imageType === 1}
                        setCallback={setCallback}
                        setCompleteCallback={setCompleteCallback}
                        setFileCallback={setFileCallback}
                        onDeleteImage={() => {
                            setImage('');
                            setSubImage({
                                path: "",
                                name: "",
                                cropInfo: null,
                            });
                        }}
                    /> : (
                        <Box 
                            component="img"
                            alt={subImage.name || "메인 이미지"}
                            src={subImage.croppedImageDataUrl || subImage.path}
                            width="100%"
                            height="auto"
                            border={`1px solid ${palette.dark.divider}`}
                        />
                    )}
                </Value>
            </Item>
            {
                subImage.croppedImageDataUrl&& (
                    <ImageBox id="thumbnailImage">
                        <div>
                            <img alt="cropped_sub_image" src={subImage.croppedImageDataUrl} />
                        </div>
                    </ImageBox>
                )
            }
        </>
    );
});