import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import countryData from "../assets/iso_3166";
import { Contents, FormGroup, FormTitle, SectionTitle } from "../style";
import { SelectPicker } from "rsuite";
import { Stack, Typography } from "@mui/material";
import { FormProvider } from "components/hook-form";
import { SignupChapterEnum, UserTypeEnum } from "../type";
import { LanguageCodeEnum } from "generated/graphql";
import { useForm } from "react-hook-form";
import OldRHFTextField from "./OldRHFTextField";
import { useRecoilState } from "recoil";
import {
  chapterState,
  contractFormState,
  userTypeState,
} from "signup/SignUp.atoms";
import * as Yup from "yup";
import "./3.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import Navigator from "signup/Navigator";

type ContractInformationFormValues = {
  rrnFront?: string;
  rrnBack?: string;
  name?: string;
  country?: string;

  companyName?: string;
  rrn?: string;
};

export default function ContractInformationForm() {
  // 번역 도구
  const { t, i18n } = useTranslation();
  const [type] = useRecoilState(userTypeState);
  const [, setChapterValid] = useRecoilState(chapterState);
  const [, setContractForm] = useRecoilState(contractFormState);

  const formSchema = Yup.object().shape({});

  const defaultValues: ContractInformationFormValues = {
    // 주민 등록 번호 (국내 사용자에게만 사용)
    rrnFront: "",
    rrnBack: "",
    // 이름 (국내 사용자에게만 사용)
    name: "",

    // 국가 (해외 사용자에게만 사용)
    country: "",

    // 상호명
    companyName: "",
    // 사업자 등록 번호
    rrn: "",
  };

  const methods = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(formSchema),
  });

  const { handleSubmit, setValue, setFocus, watch, register } = methods;
  const values = watch();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  // 주민번호 앞자리
  const setFrontRrn = (value) => {
    const result = value.replaceAll(/\D/g, "");

    if (result.length === 6) {
      setFocus("rrnBack");
    }

    setValue("rrnFront", result);
  };

  // 주민번호 뒷자리
  const setBackRrn = (value) => {
    const result = value.replaceAll(/\D/g, "");
    setValue("rrnBack", result);
  };

  const onNameChange = (e) => {
    let value = e.target.value;

    if (i18n.language !== LanguageCodeEnum.Ko) {
      value = value.replace(/[^A-Za-z ]/gi, "");
    }

    setValue("name", value);
  };

  let countries = countryData.map((x) => {
    return {
      label: x.Name,
      value: x.Code,
      role: "Master",
    };
  });
  if (i18n.language !== LanguageCodeEnum.Ko)
    countries = countries.filter((x) => !["KR", "KP"].includes(x.value));

  const setCurrentChapterValid = (valid: boolean) => {
    setChapterValid((prev) => {
      return {
        ...prev,
        [SignupChapterEnum.Payment]: valid,
      };
    });
  };

  // 사업자 - 상호명 / 대표자 이름 / 사업자 등록번호
  // 해외 - 이름 / 나라
  // 개인 - 이름 / 주민번호

  useEffect(() => {
    const { name, rrn, rrnFront, rrnBack, companyName, country } = values;

    if (type) {
      if (type === UserTypeEnum.Business) {
        setCurrentChapterValid(!!(companyName && name && rrn));
      } else if (
        i18n.language !== LanguageCodeEnum.Ko &&
        type === UserTypeEnum.Personal
      ) {
        setCurrentChapterValid(!!(name && country));
      } else {
        setCurrentChapterValid(
          !!(
            name &&
            rrnFront &&
            rrnFront.length === 6 &&
            rrnBack &&
            rrnBack.length === 7
          )
        );
      }
    }
  }, [
    type,
    values.name,
    values.rrn,
    values.rrnFront,
    values.rrnBack,
    values.companyName,
    values.country,
  ]);

  const handleNext: () => Promise<string> = () => {
    return new Promise((resolve) => {
      setContractForm({
        name: values.name,
        companyName: values.companyName,
        rrn:
          values.rrnFront && values.rrnBack
            ? `${values.rrnFront}-${values.rrnBack}`
            : values.rrn,
        country: values.country,
      });
      resolve("");
    });
  };

  return (
    <>
      <FormProvider methods={methods}>
        <Stack>
          <SectionTitle>{t("signupPage.paymentInfo")}</SectionTitle>
          <Stack spacing={4.5}>
            {type === UserTypeEnum.Business && (
              <FormGroup>
                <FormTitle>상호명</FormTitle>
                <OldRHFTextField name={"companyName"} />
              </FormGroup>
            )}
            <FormGroup>
              <FormTitle>{`${
                type === UserTypeEnum.Business ? "대표자 " : ""
              }${t("signupPage.name")}`}</FormTitle>
              <OldRHFTextField
                name="name"
                onChange={onNameChange}
                placeholder={t("signupPage.placeholder.name")}
              />
            </FormGroup>

            {i18n.language !== LanguageCodeEnum.Ko && (
              <FormGroup>
                <FormTitle>{"Country of Residence"}</FormTitle>
                <SelectPicker
                  name="country"
                  onChange={(value) => {
                    setValue("country", value);
                  }}
                  value={values.country}
                  className={isSelectOpen && "open"}
                  size="lg"
                  data={countries}
                  style={{ width: "100%", height: 48 }}
                  cleanable={false}
                  placeholder={
                    <span className="select-placeholder">
                      {
                        "Please select country of residence based on your current legal address."
                      }
                    </span>
                  }
                  onOpen={() => setIsSelectOpen(true)}
                  onClose={() => setIsSelectOpen(false)}
                  caretAs={"ArrowDownIcon"}
                />
              </FormGroup>
            )}

            {i18n.language === LanguageCodeEnum.Ko &&
              type === UserTypeEnum.Personal && (
                <FormGroup>
                  <FormTitle>주민등록번호</FormTitle>
                  <Stack spacing={1}>
                    <Typography
                      fontSize={12}
                      color={"#7c7c7c"}
                      lineHeight={"130%"}
                    >
                      계약서 작성을 위해 정확하게 기입해주세요.
                      <br />
                      (기입된 정보는 개인정보처리방침에 의거하여 안전하게 보관
                      및 활용됩니다.)
                    </Typography>
                    <Stack direction="row">
                      <OldRHFTextField
                        name="rrnFront"
                        onChange={(e) => setFrontRrn(e.target.value)}
                        maxLength={6}
                      />
                      <Stack
                        width={"28px"}
                        justifyContent={"center"}
                        ml={1}
                        mr={1}
                      >
                        <div
                          style={{
                            border: "1px solid #cdcdcd",
                            width: "12px",
                            height: "1px",
                          }}
                        ></div>
                      </Stack>
                      <OldRHFTextField
                        type="password"
                        name="rrnBack"
                        onChange={(e) => setBackRrn(e.target.value)}
                        maxLength={7}
                      />
                    </Stack>
                  </Stack>
                </FormGroup>
              )}

            {i18n.language === "ko" && type === UserTypeEnum.Business && (
              <FormGroup>
                <FormTitle>{"사업자등록번호"}</FormTitle>
                <OldRHFTextField name="rrn" maxLength={12} />
              </FormGroup>
            )}
          </Stack>
        </Stack>
      </FormProvider>
      <Navigator onNext={handleNext} />
    </>
  );
}
