import { DefaultValue, selector } from "recoil";
import { fileState, mainImageState, subImageState } from "..";
import { FileConfig, ImageType } from "../type";
import { documentDataSelector } from "./documentData";


export const fileSelector = selector<FileConfig|undefined>({
  key: 'fileSelector',
  get:({get}) => get(fileState),
  set: ({get, set}, val) => {
    if (!(val instanceof DefaultValue)) {
      set(fileState, {
        ...get(fileState),
        ...(val?.filePath || val?.filePath === null) && { filePath: val?.filePath },
        ...(val?.fileName || val?.fileName === null) && { fileName: val?.fileName },
      });
    }
  },
});

export const mainImageSelector = selector<ImageType|undefined>({
  key: 'mainImageSelector',
  get:({get}) => get(mainImageState),
  set: ({get, set}, val) => {
    if (!(val instanceof DefaultValue)) {
      const oldValue = get(mainImageState);
      return set(mainImageState, {
        ...oldValue,
        ...val,
      });
    }
  },
});

export const subImageSelector = selector<ImageType|undefined>({
  key: 'subImageSelector',
  get:({get}) => get(subImageState),
  set: ({get, set}, val) => {
    if (!(val instanceof DefaultValue)) {
      const oldValue = get(subImageState);
      return set(subImageState, {
        ...oldValue,
        ...val,
      });
    }
  },
});

export { documentDataSelector };

