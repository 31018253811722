import React from "react";
import { Button, Skeleton, Typography, Stack } from "@mui/material";
import { FieldsBox, FieldGroup, FieldName, FieldValue } from "./styled";
import { UserFormType } from "./type";
import { LanguageCodeEnum, useGetUserQuery } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { downloadFile } from "api/index";
import { PLAN_TYPE } from "./value/PlanType";

type Props = UserFormType & {
  onClickShowDialog: () => void;
};

export default function StoreForm({ userId, onClickShowDialog }: Props) {
  const { t, i18n } = useTranslation();
  const { data, loading } = useGetUserQuery({
    variables: {
      id: userId,
    },
  });

  if (loading || !data) {
    return <Skeleton />;
  }

  const handleDownload = () => {
    downloadFile(data.termAgree.uploadFileId, data.termAgree.file.path);
  };

  return (
    <>
      <FieldsBox>
        <FieldGroup>
          <FieldName>{t("user.brandNumber")}</FieldName>
          <FieldValue>
            {data.userV2.brand?.godoBrandCd
              ? data.userV2.brand?.godoBrandCd
              : "-"}
          </FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.projectReference")}</FieldName>
          <Stack gap={0.5}>
            {data.userV2.reference.map((x, i) => (
              <FieldValue key={`project_url_${i}`}>
                <u>
                  <a
                    href={x.url}
                    target={"_blank"}
                    style={{ color: "inherit" }}
                  >
                    {x.url}
                  </a>
                </u>
              </FieldValue>
            ))}
          </Stack>
        </FieldGroup>
        {i18n.language === LanguageCodeEnum.Ko && (
          <FieldGroup>
            <FieldName>파트너십 유형</FieldName>
            <FieldValue>
              {data.userPlan?.type
                ? PLAN_TYPE({
                    type: data.userPlan?.type,
                    isExclusive: data.userPlan?.isExclusive,
                  })
                : "미선택"}
            </FieldValue>
          </FieldGroup>
        )}
        <FieldGroup>
          <FieldName>{t("user.fee")}</FieldName>
          <FieldValue>
            {data.userPlan
              ? `${data.userPlan.commissionRate}% ${
                  data.userPlan.commissionRate === 0 &&
                  i18n.language !== LanguageCodeEnum.Ko
                    ? `(Early Entry)`
                    : ``
                }`
              : "-"}
          </FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t("user.contract")}</FieldName>
          <FieldValue>
            <Stack spacing={1} pt={0.5}>
              <Button
                onClick={handleDownload}
                variant="outlined"
                size="medium"
                sx={{ width: "fit-content" }}
              >
                {t("download")}
              </Button>
              <Typography fontSize={12} lineHeight={"18px"} fontWeight={400}>
                {t("user.downloadDescription")}
              </Typography>
            </Stack>
          </FieldValue>
        </FieldGroup>
      </FieldsBox>
      <Stack alignItems={"end"} mt={1.5}>
        <Button variant="text" onClick={onClickShowDialog}>
          {t("user.editInfo")}
        </Button>
      </Stack>
    </>
  );
}
