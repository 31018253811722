import React, { useState, useEffect } from 'react';
import MypageGlobal from './Mypage';
import Mypage from './User';
import Loading from 'common/editor/Loading';
import { downloadFile } from 'api';
import { COUNTRY_KO_CODE } from 'common/country/constants';
import { useGetUserForAdminQuery, useGetUserQuery } from 'generated/graphql';
import useNotification from 'hook/useNotification';

type Props = {
  userId: number;
};

export default function AdminUserForm({ userId }: Props) {
  const { readNotification } = useNotification('statusChange');
  const [isLoading, setIsLoading] = useState(false);

  // 회원 정보 조회
  const { data, loading, error } = useGetUserForAdminQuery({
    variables: { id: userId },
    skip: !userId,
  });

  const isGlobal = !loading && !error && data.user.settle?.country !== COUNTRY_KO_CODE;

  // 계약서 다운로드
  const downloadTerm = () => {
    const fileId = data.termAgree?.uploadFileId;
    setIsLoading(true);

    try {
      if (fileId) {
        downloadFile(data.termAgree.uploadFileId, data.termAgree.file.path);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (loading || error) {
      setIsLoading(true);
    } else {
      readNotification();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error]);

  // 유저 정보 조회 전까지 로딩
  if (isLoading || !data) {
    return <Loading />;
  }

  return <>{!isGlobal ? <Mypage id={userId} data={data} /> : <MypageGlobal id={userId} data={data} downloadTerm={downloadTerm} />}</>;
}
