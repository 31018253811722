import styled from 'styled-components';
import { Label } from '../style';

export const PinkLabel = styled(Label)`
    width: 61px;
    background-color: #EFB2CC;
    white-space: normal;
`;
export const YelloLabel = styled(Label)`
    width: 61px;
    background-color: #FFF7AA;
    white-space: normal;
`;