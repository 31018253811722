import React, {useContext, useState} from "react";
import { Button, Stack } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isAgreeState, isDrawnState, pageState, userStoreState } from "./atom";
import { useTranslation } from "react-i18next";
import { useRequestUserRevisionMutation } from "generated/graphql";
import { UserTypeEnum } from "signup/type";
import { uploadFile } from "api/index";
import RequestDialog from "./RequestDialog";
import useAuth from "hooks/useAuth";
import {updateContract} from "api/contract";
import {AppContext} from "../../app";
import dataURItoBlob from "@uppy/utils/lib/dataURItoBlob";

export default function Navigator() {
  const { t } = useTranslation();

  const { userInfo } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { refresh } = useAuth();

  const setPage = useSetRecoilState(pageState);
  const state = useRecoilValue(userStoreState);
  const isDrawn = useRecoilValue(isDrawnState);
  const isAgree = useRecoilValue(isAgreeState);

  const [requestUserRevision] = useRequestUserRevisionMutation();

  const handleBefore = () => {
    setPage(1);
  };

  const uploadSign = async () => {
    const signatureCanvas = document.getElementById("sign") as HTMLCanvasElement;
    const signatureBlob = dataURItoBlob(signatureCanvas.toDataURL(), {mimeType: 'image/png'});
    const { key: azureStorageTmpPath } = await uploadFile(signatureBlob);
    return azureStorageTmpPath;
  };

  const handleConfirm = async () => {
    const key = state.userType === UserTypeEnum.Personal ?
        await uploadSign() : (await uploadFile(state.signFile)).key;

    // 계약서 갱신
    await updateContract({
      userId: userInfo.id,
      signatureAzureStoragePath: key,
      locale: state.termLanguage,
      userName: state.userType === UserTypeEnum.Personal ? state.name : state.companyName,
      userContact: state.contact,
      userPin: state.nationalIdNumber,
      planType: null,
      commissionRate: null,
      password: null,
      // isRejected: true,
    }).catch();
    // 사용자 업데이트
    await requestUserRevision({
      variables: {
        user: {
          ...(state.name && { name: state.name }),
          ...(state.companyName && { companyName: state.companyName }),
          brand: {
            ...(state.brandNames.ko && { koName: state.brandNames.ko }),
            ...(state.brandNames.en && { enName: state.brandNames.en }),
            ...(state.brandNames.zh && { cnName: state.brandNames.zh }),
            ...(state.brandNames.ja && { jpName: state.brandNames.ja }),
          },
          ...(state.contact && { contact: state.contact }),
          reference: state.documents.map((x) => {
            return {
              url: x,
            };
          }),
          ...(state.country && { country: state.country }),
          ...(state.nationalIdNumber && {
            nationalIdNumber: state.nationalIdNumber,
          }),
          file: {
            sign: {
              id: null,
              fullName: key,
              path: key.split("/")[1],
            },
          },
        },
      },
    });

    await refresh();
  };

  const disabled = (() => {
    if (state.userType === UserTypeEnum.Business) {
      return !(isAgree && state.signFile);
    }

    return !(isAgree && isDrawn);
  })();

  return (
    <>
      <Stack direction="row" spacing={1.5} justifyContent={"center"}>
        <Button
          size="large"
          variant={"outlined"}
          onClick={handleBefore}
          sx={{ width: "176px" }}
        >
          {t("prev")}
        </Button>
        <Button
          size="large"
          variant={"contained"}
          sx={{ width: "176px" }}
          onClick={() => setIsOpen(true)}
          disabled={disabled}
        >
          {t("confirmButtonText")}
        </Button>
      </Stack>
      <RequestDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
}
