import React from "react";
import { useDropzone } from "react-dropzone";
// @mui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
// type
import { CustomFile, UploadProps } from "./types";
import FormBlockContent from "./FormBlockContent";
import FormFileContent from "./FormFileContent";

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

type Props = UploadProps & {
  onRemove: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function FormUpload({
  error = false,
  file,
  helperText,
  sx,
  onRemove,
  ...other
}: Props) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      multiple: false,
      ...other,
    });

  return (
    <Box sx={{ width: "100%", ...sx }}>
      {
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: "error.main",
              borderColor: "error.light",
              bgcolor: "error.lighter",
            }),
            padding: `10px 16px`,
            ...(file && {
              border: `1px solid rgba(145, 158, 171, 0.32)`,
              background: "#fff",
              borderRadius: "8px",
              padding: `4px 16px`,
            }),
          }}
        >
          <input {...getInputProps()} />

          {!file && <FormBlockContent />}
          {file && (
            <FormFileContent
              name={(file as CustomFile).name}
              size={(file as CustomFile).size}
              onRemove={onRemove}
            />
          )}
        </DropZoneStyle>
      }

      {helperText && helperText}
    </Box>
  );
}
