import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    TabContainer,
} from '../../style';
import { Wrapper, Top, Flex, ButtonWrap, RemoveButton, WriteButton, Field, Title, Value, Label, Select, SelectContainer, Option } from './style';
import DatePicker from '../../../../components/datepicker';
import DateFormat from 'dateformat';
import RcSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useMutation, useQuery } from '@apollo/client';
import { GET_BRANDS, GET_CATEGORIES, GET_BENEFIT_LIMIT } from 'api/quries';
import { DELETE_BENEFIT_LIMIT_ITEM, CREATE_BENEFIT_LIMIT_ITEM } from 'api/mutations';
import { AppContext } from 'app';

export default ((props) => {
    const { match, history } = props;
    const { showConfirmMessage, showAlertMessage } = useContext(AppContext);

    // URL 파라미터
    const { docId } = useParams();
    // 보기 페이지 여부
    const isView = Boolean(docId);

    // 포인트 지급 제한 정책 상태
    const [item, setItem] = useState({
        // 타입
        type: 'goods',
        // 상품 번호
        goodsNo: [],
        // 카테고리 id
        categoryId: null,
        // 브랜드 id
        brandId: null,
        // 시작일
        startDate: null,
        // 종료일
        endDate: null
    });
    // 상품 번호 input
    const [goodsNoInput, setGoodsNoInput] = useState('');
    const [goodsNo, setGoodsNo] = useState([]);

    // 정책 데이터 쿼리
    const { data, loading } = useQuery(GET_BENEFIT_LIMIT, {
        variables: { id: Number(docId) },
        skip: !isView
    });
    // 브랜드 쿼리
    const { data: brand, loading: brandLoading } = useQuery(GET_BRANDS);
    // 카테고리 쿼리
    const { data: category, loading: categoryLoading } = useQuery(GET_CATEGORIES, {
        variables: {
            lang: 'ko'
        }
    });
    // 항목 삭제
    const [removePointLimitPolicy] = useMutation(DELETE_BENEFIT_LIMIT_ITEM);
    // 항목 생성
    const [createPointLimitPolicy] = useMutation(CREATE_BENEFIT_LIMIT_ITEM);

    // 정책 데이터가 변경될 때
    useEffect(() => {
        // 작성 페이지일 경우
        if (!isView) {
            return;
        }
        // 정책 데이터 로딩 중이면
        if (loading) {
            return;
        }
        // 정책 데이터가 없는데, 로딩이 완료되었다면
        const policy = data?.getPointLimitPolicy;
        if (!loading && !policy) {
            // 작성 페이지로 이동 
            history.push('/manager/benefit/benefitRestric/write');
            return;
        }

        // 항목 설정
        setItem({
            // 타입
            type: policy.type,
            // 상품 번호
            goodsNo: policy.goodsNo,
            // 카테고리
            categoryId: policy.category?.id,
            // 브랜드 id
            brandId: policy.brand?.id,
            // 시작일
            startDate: policy.startDate,
            // 종료일
            endDate: policy.endDate
        });
        if (policy.goodsNo) {
            setGoodsNo(policy.goodsNo.split(',').map(x => createOption(x)));
        }
    }, [data, loading]);

    // 삭제 이벤트
    const onClickRemoveButton = () => {
        if (!docId) {
            return;
        }

        showConfirmMessage('항목 삭제', '이 항목을 삭제합니다.',
        async () => {
            await removePointLimitPolicy({
                variables: {
                    id: [Number(docId)]
                }
            });
            history.push(`/manager/benefit/benefitRestric`);
        });
    };
    // 작성 유효성 검사
    const onClickWriteValidate = () => {
        let isValidate = true;
        let message = '';

        if (isView) {
            isValidate = false;
            message = '생성된 항목은 수정할 수 없습니다.'
        }
        switch (item.type) {
            case 'brand':
                if (!item.brandId) {
                    isValidate = false;
                    message = '브랜드를 선택해주세요.';
                }
                break;
            case 'category':
                if (!item.categoryId) {
                    isValidate = false;
                    message = '카테고리를 선택해주세요.';
                }
                break;
            case 'goods':
                if (item.goodsNo.length === 0) {
                    isValidate = false;
                    message = '상품번호를 입력해주세요.';
                }
                break;
            default:
                return `유효하지 않은 구분 값인 "${item.type}" 이 존재합니다.`;
        }

        return { isValidate, message };
    };
    // 작성 이벤트
    const onClickWriteButton = async () => {
        // 유효성 검사
        const { isValidate, message } = onClickWriteValidate();
        if (!isValidate) {
            showAlertMessage('오류', message);
            return;
        }
        // 시작일이 없을 경우
        if (!item.startDate) {
            item.startDate = new Date();
        }

        // 포인트 지급 제한 정책 생성
        const result = await createPointLimitPolicy({
            variables: {
                ...item
            }
        });

        history.push(`/manager/benefit/benefitRestric`);
    };
    // 타입 변경 이벤트
    const onChangeType = (e) => {
        const type = e.target.value;
        item.type = type;
        setItem({
            ...item
        });
    };
    // 브랜드 변경 이벤트
    const onChangeBrand = (brand) => {
        item.brandId = brand.value;
        setItem({
            ...item
        });
    };
    // 카테고리 변경 이벤트
    const onChangeCategory = (category) => {
        item.categoryId = category.value;
        setItem({
            ...item
        });
    };
    // 상품 번호 변경 이벤트
    const onChangeGoodsNo = (goodsNoArr) => {
        item.goodsNo = goodsNoArr?.map(x => x.value) ?? [];
        setGoodsNo(goodsNoArr ?? []);
        setItem({
            ...item
        });
    };
    // 상품 번호 입력 이벤트
    const onChangeGoodsNoInput = (inputValue) => {
        setGoodsNoInput(inputValue);
    };
    // 입력한 상품 번호를 option 데이터로 만드는 메소드
    const createOption = (label) => ({ label, value: label });
    // 상품 번호 입력 후 Enter, Tab 키 입력 이벤트
    const onKeyDownGoodsNo = (event) => {
        if (!goodsNoInput) return;
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            item.goodsNo.push(goodsNoInput);
            setGoodsNo([...goodsNo, createOption(goodsNoInput)]);
            setItem({
                ...item
            });
            setGoodsNoInput('');
            event.preventDefault();
        }
    };

    return (
        <TabContainer>
            <Wrapper>
                <Top>
                    <ButtonWrap>
                        <RemoveButton disabled={!isView} onClick={onClickRemoveButton}>
                            삭제
                        </RemoveButton>
                        <WriteButton disabled={isView} onClick={onClickWriteButton}>
                            저장
                        </WriteButton>
                    </ButtonWrap>
                </Top>
                <Field>
                    <Title>제한 적용 구분</Title>
                    <Value>
                        <Flex>
                            <Select name='type' onChange={onChangeType} defaultValue={'goods'} value={item.type} disabled={isView}>
                                <Option value="goods">상품번호</Option>
                                <Option value="category">카테고리</Option>
                                <Option value="brand">브랜드</Option>
                            </Select>
                        </Flex>
                        <Flex>
                            { item.type === 'goods' &&
                                <SelectContainer>
                                    <CreatableSelect
                                        isDisabled={isView}
                                        components={{ DropdownIndicator: null }}
                                        inputValue={goodsNoInput}
                                        isClearable
                                        isMulti
                                        menuIsOpen={false}
                                        onChange={onChangeGoodsNo}
                                        onInputChange={onChangeGoodsNoInput}
                                        onKeyDown={onKeyDownGoodsNo}
                                        placeholder='상품번호를 입력해주세요. (Tab, Enter)'
                                        value={goodsNo}
                                    />
                                </SelectContainer>
                            }
                            { item.type === 'category' && !categoryLoading && (() => {
                                const categoryItems = category.getCategoriesByLang.map(x => {
                                    return {
                                        value: x.id,
                                        label: x.i18n[0].name
                                    }
                                });

                                const getCategoryItem = (value) => {
                                    return categoryItems.find(x => x.value === value);
                                };

                                return (
                                    <SelectContainer>
                                        <RcSelect styles={{
                                            menu: provided => ({...provided, zIndex: 999})
                                        }}
                                            {...{
                                                isDisabled: isView,
                                                placeholder: '카테고리를 선택하세요.',
                                                value: getCategoryItem(item?.categoryId),
                                                isMulti: false,
                                                options: categoryItems,
                                                onChange: onChangeCategory
                                            }}
                                        />
                                    </SelectContainer>
                                );
                             })()
                            }
                            { item.type === 'brand' && !brandLoading && (() => {
                                const brandItems = brand.getBrands.map(x => {
                                    return {
                                        value: x.id,
                                        label: x.name
                                    }
                                });

                                const getBrandItem = (value) => {
                                    return brandItems.find(x => x.value === value);
                                };

                                return (
                                    <SelectContainer>
                                        <RcSelect styles={{
                                            menu: provided => ({...provided, zIndex: 999})
                                        }}
                                            {...{
                                                isDisabled: isView,
                                                placeholder: '브랜드를 선택하세요.',
                                                value: getBrandItem(item?.brandId),
                                                isMulti: false,
                                                options: brandItems,
                                                onChange: onChangeBrand
                                            }}
                                        />
                                    </SelectContainer>
                                );
                             })()
                            }
                        </Flex>
                    </Value>
                </Field>
                <Field>
                    <Title>기간 설정</Title>
                    <Value>
                        <Flex>
                            <Label>시작일</Label> 
                            {
                                isView && 
                                <>{DateFormat(item.startDate, 'yyyy-mm-dd HH:MM')}</>
                            }
                            {
                                !isView &&
                                <DatePicker
                                    date={item.startDate}
                                    placeholder={'미설정시 현재 날짜'}
                                    setDate={value => {
                                        item.startDate = value;
                                        setItem({
                                            ...item
                                        })
                                    }}
                                />
                            }
                        </Flex>
                        <Flex>
                            <Label>종료일</Label>
                            {
                                isView && 
                                <>{DateFormat(item.endDate, 'yyyy-mm-dd HH:MM')}</>
                            }
                            {
                                !isView && 
                                <DatePicker
                                    date={item.endDate}
                                    placeholder={'미설정시 계속 진행'}
                                    setDate={value => {
                                        item.endDate = value;
                                        setItem({
                                            ...item
                                        })
                                    }}
                                />
                            }
                        </Flex>
                    </Value>
                </Field>
            </Wrapper>
        </TabContainer>
    );
});