import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import queryString from "query-string";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "app";
import { useUrl } from "../hook/useUrl";
import "../../common/editor/index.scss";
import "./board.scss";

const NoticeInfo = (props) => {
    const { items, refetch, reorder } = props;
    const { data: urlObj } = useUrl();
    const { name } = useParams();
    const history = useHistory();
    const { userInfo } = useContext(AppContext);
    const { isAdmin } = userInfo;

    const [draggedRow, setDraggedRow] = useState(null);
    const [dragTargetRow, setDragTargetRow] = useState(null);

    if (!isAdmin) {
        history.push("/");
        return <></>;
    }

    let query;
    if (history.location.search) {
        query = queryString.parse(history.location.search);
    }

    // db에서 가져온 게시판 정보 object를 DOM으로 렌더해주는 함수
    const renderBoardItems = (e) => {
        const titleHref = `/aconfaq/${name}/write/${e.id}`;

        // 게시물 등록 날짜가 현재로부터 7일 이내일 경우 NEW 배지를 표시합니다.
        const NewBadge = () => {
            if (new Date() - new Date(e.created) > 1000 * 60 * 60 * 24 * 7) {
                return null;
            } else {
                return <div className="newBadge">NEW</div>;
            }
        };

        // 게시물 등록 날짜 또는 팝업 기간을 표시할 때 날짜를 formatting해주는 옵션입니다.
        const localeDateOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };

        const langCode = e.language?.code || e.lang;
        const typeName = e.type.i18n.find(
            (x) => x.language?.code === langCode || x.lang === langCode
        ).name;

        const onDrageStart = (element) => {
            setDraggedRow({ ...e, element });
        };

        const onDragOver = (element) => {
            element.preventDefault();
        };

        const onDragEnter = (element) => {
            element.preventDefault();
            setDragTargetRow({ ...e, element });
        };

        const onDragEnd = async () => {
            if (!(draggedRow?.order - dragTargetRow?.order)) return;

            await reorder({
                variables: {
                    id: draggedRow.id,
                    lang: urlObj.lang,
                    typeId: urlObj.typeId ? Number(urlObj.typeId) : null,
                    oldOrder: draggedRow.order,
                    newOrder: dragTargetRow.order,
                },
            });

            refetch();

            setDraggedRow(null);
            setDragTargetRow(null);
        };

        let className = "";

        if (draggedRow?.id !== e.id && dragTargetRow?.id === e.id) {
            if (draggedRow?.order > dragTargetRow?.order) {
                className = "highlight-bottom";
            } else className = "highlight-top";
        }

        // 위의 로직에 따른 결과를 조립하여 최종적으로 게시물 item을 DOM으로 내보내기합니다.
        return (
            <tr
                id={"dragItem_" + e.id}
                className={className}
                key={e.id}
                draggable
                onDragStart={onDrageStart}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragEnd={onDragEnd}
            >
                <td>
                    <div className="category-container">
                        <div>{langCode && langCode.toUpperCase()}</div>
                    </div>
                </td>
                <td>
                    <div className="category-container">
                        <div>{typeName}</div>
                    </div>
                </td>
                <td>
                    <div className="title">
                        <div className="title__text__container">
                            <NavLink className="title__text" to={titleHref}>
                                {e.title}
                            </NavLink>
                            <NewBadge />
                        </div>
                    </div>
                </td>
                <td>
                    <div className="center date">
                        {new Date(e.created).toLocaleDateString("ko-KR", localeDateOptions)}
                    </div>
                </td>
            </tr>
        );
    };

    const itemTags = items?.map(renderBoardItems);

    // 내보내기
    return <tbody>{itemTags}</tbody>;
};

export default NoticeInfo;
