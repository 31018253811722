import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../common/ConfirmAlertModal';

// 요청하기 위한 모달입니다.
export default React.memo(function ({ onClose, onConfirm, show, completed }) {
    const { t } = useTranslation()
    const title = !completed ? 
        t("requestReviewCompletedModalTitle") : 
        t("requestReviewCompletedModalTitle2");
    const contents = !completed ? 
        t("requestReviewCompletedModalContents") :
        t("requestReviewCompletedModalContents2");
    return (
        <Modal
            title={title}
            contents={contents}
            cancelText={t("goBack")}
            confirmText={t("confirm")}
            onClose={onClose}
            onConfirm={onConfirm}
            show={show}
        />
    )
})