import React from 'react';
import { color } from 'acon-mui/style';
import { Box, Typography } from '@mui/material';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = React.CSSProperties & {
  children: React.ReactNode;
  checkboxVisible?: boolean;
  isChecked?: boolean;
  onClick?: (value, checked) => void;
};

export default function Title({
  children,
  checkboxVisible=false,
  isChecked,
  onClick,
  ...rest
}: Props) {
  const { t } = useTranslation();
  
  if (!checkboxVisible) return (
    <Typography
      color={color.text.primary}
      fontWeight="700"
      fontSize="16px"
      lineHeight="24px"
      sx={rest}
    >
      {children}
    </Typography>
  );
  return (
    <Box sx={rest} display="flex" alignItems="center">
      <Typography
        color={color.text.primary}
        fontWeight="700"
        fontSize="16px"
        lineHeight="24px"
      >
        {children}
      </Typography>
      <FormGroup sx={{ ml: 1, '& label': { margin: '0 !important' } }}>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={onClick} />}
          label={t('document.update')}
        />
      </FormGroup>
    </Box>
  );
};
