import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as IconNonExclusive } from "./non-exclusive.svg";
import { ReactComponent as IconExclusive } from "./exclusive.svg";
import { ReactComponent as IconCheck } from "./ic_checkmark.svg";
import { Button } from "acon-mui/components";
import palette from "theme/palette";

const partnerships = [
  {
    title: "비독점",
    description: "다른 플랫폼에서도 상품을 판매하고 싶어요",
    icon: <IconNonExclusive />,
    commission: 20,
    isExclusive: false,
  },
  {
    title: "독점",
    description: "모든 상품을 에이콘3D에서만 판매하고 싶어요",
    icon: <IconExclusive />,
    commission: 15,
    isExclusive: true,
  },
];

export default function Partnerships({
  onClick,
}: {
  onClick: (value: boolean) => void;
}) {
  return (
    <Box p="60px" bgcolor="#fff" color="#313135" textAlign="center">
      <Typography component="h4" fontWeight={800} fontSize="32px">
        파트너십 구분
      </Typography>
      <Box
        mt={5}
        mx="auto"
        width="fit-content"
        display="grid"
        textAlign="left"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="32px"
      >
        {partnerships.map((partnership) => (
          <Box
            key={partnership.title}
            p={5}
            display="flex"
            flexDirection="column"
            border="1px solid #8E8EFF"
            borderRadius={2}
          >
            <Box pb={3} borderBottom="1px dashed rgba(162, 162, 164, 0.12)">
              {partnership.icon}
              <Typography mt={1.5} fontWeight={800} fontSize="24px">
                {partnership.title}
              </Typography>
              <Typography mt={1.5} fontSize="12px" lineHeight="16px">
                {partnership.description}
              </Typography>
            </Box>
            <Box
              py={3}
              borderBottom="1px dashed rgba(162, 162, 164, 0.12)"
              display="flex"
              minHeight={200}
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              {partnership.isExclusive && (
                <Box mb={3}>
                  <Box
                    p="4px 10px"
                    width="fit-content"
                    bgcolor={palette.dark.primary.main}
                    borderRadius="8px"
                  >
                    <Typography
                      color="#fff"
                      fontWeight={700}
                      fontSize="12px"
                      lineHeight="22px"
                    >
                      5% 인하
                    </Typography>
                  </Box>
                  <Box
                    ml="8px"
                    width="12px"
                    height="6px"
                    borderTop={`6px solid ${palette.dark.primary.main}`}
                    borderLeft="6px solid transparent"
                    borderRight="6px solid transparent"
                  />
                </Box>
              )}
              <Typography fontWeight={600} fontSize="12px" lineHeight="16px">
                수수료
              </Typography>
              <Typography
                mt={1.5}
                fontWeight={800}
                fontSize="40px"
                lineHeight="56px"
                sx={{ "& span": { fontSize: "24px" } }}
              >
                {partnership.commission}
                <span>%</span>
              </Typography>
            </Box>
            {partnership.isExclusive && (
              <Box py={3} display="flex">
                <IconCheck />
                <Typography fontWeight={600} fontSize="14px" lineHeight="20px">
                  자체 몰, 포털사이트 블로그를 제외한
                  <br />타 중개 플랫폼에서 판매 및 펀딩 불가
                </Typography>
              </Box>
            )}
            <Button
              onClick={() => onClick(partnership.isExclusive)}
              colorTheme="primary"
              variant="outlined"
              marginTop="auto"
              padding="12px"
              fontSize="16px"
              lineHeight="24px"
              fontWeight="600"
            >
              선택하기
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
