import React, { useState } from "react";
import { downloadFile } from "api";
import { STATUS_CODE } from "../../constants";
import * as Styled from "../../style";
import downloadIcon from "../../assets/download_icon.svg";
import deleteIcon from "../../assets/delete_icon.svg";
import countryList from "common/country/country.json";
import { downloadImageFile, convertCountryData } from "../../util";

const Component = (props) => {
  const { data, onSave, onOpenModal, inputRefs } = props;
  // 편집 모드 여부
  const [isEdit, setIsEdit] = useState(false);
  // 사업자등록증 사본
  const [idCardFile, setIdCardFile] = useState(data?.user.settle.idCard);
  // 제한세율적용신청서
  const [limitedTaxApl, setLimitedTaxApl] = useState(
    data?.user.settle.limitedTaxApl
  );

  // 회원 정보
  const userData = data?.user;
  // 작가 승인상태 값
  const acceptStatus = userData.accept.status;
  // 작가 수정상태
  const modifyStatus = userData.accept.modifyStatus;

  // 상태 값
  const STATUS = {
    accept: {
      reject: acceptStatus === STATUS_CODE.REJECT,
      request: acceptStatus === STATUS_CODE.REQUEST,
      approve: acceptStatus === STATUS_CODE.APPROVE,
    },
    modify: {
      reject: modifyStatus === STATUS_CODE.REJECT,
      request: modifyStatus === STATUS_CODE.REQUEST,
      approve: modifyStatus === STATUS_CODE.APPROVE,
    },
  };

  const onChangeData = (e) => {
    const element = e.target;
    const key = element.dataset.key;
    let value = element.value;

    if (element.dataset.allowNum) {
      value = element.value.replace(/[^0-9\-]/g, "");
      element.value = value;
    }

    props.onChangeData(key, value);
  };

  // 신분증 사본
  const onChangeIdCard = async (e) => {
    const file = e.target.files[0];
    const result = {
      id: null,
      path: file.name,
      fileName: file.name,
      size: file.size,
      file,
    };

    setIdCardFile(result);
    props.onChangeData("data.file.idCard", result);
  };

  // 신분증 이미지 제거
  const onDeleteIdCard = () => {
    const fileData = data.file;

    if (fileData?.idCard) {
      delete fileData.idCard;
      props.onChangeData("data.file", fileData);
    }
    setIdCardFile({});
  };

  // 제한 세율 적용 신청서 변경 이벤트
  const onChangeLimitedTaxApl = (e) => {
    const file = e.target.files[0];
    const result = {
      id: null,
      path: file.name,
      fileName: file.name,
      size: file.size,
      file,
    };

    setLimitedTaxApl(result);
    props.onChangeData("data.file.limitedTaxApl", result);
  };

  // 제한 세율 적용 신청서 이미지 제거
  const onDeleteLimitedTaxApl = () => {
    const fileData = data.file;

    if (fileData?.limitedTaxApl) {
      delete fileData.limitedTaxApl;
      props.onChangeData("data.file", fileData);
    }
    setLimitedTaxApl({});
  };

  const convertFileSize = (size) => {
    return size ? (size / 1024 / 1024).toFixed(2) : null;
  };

  // 정산 정보 수정 요청
  const onClickPaymentSave = async () => {
    if (isEdit) {
      const userFile = data.file;
      const userPaymentData = {
        accept: {
          isModifyPayment: true,
        },
        file: {
          idCard: userFile?.idCard,
          limitedTaxApl: userFile?.limitedTaxApl,
        },
      };

      // 값이 존재하지 않을 경우 저장하지 않음.
      if (!userPaymentData.file.idCard || !userPaymentData.file.limitedTaxApl) {
        return;
      }

      // 정산 정보 저장
      const isSave = await onSave(userPaymentData);
      if (isSave) {
        onOpenModal(
          "Once the documents are verified, you will receive an email notice.",
          true
        );
        setIsEdit(!isEdit);
      }

      return;
    }

    setIsEdit(!isEdit);
  };

  // 국적 변경 이벤트
  const onChangeCountry = (value) => {
    props.onChangeData("data.user.settle.country", value);
  };

  // 정산 문서 컴포넌트
  const FileItemComponent = (props) => {
    const { file, onClickDelete } = props;

    return (
      <Styled.FileItem>
        {file?.path ? (
          <>
            <div>{file.path}</div>
            <div>
              <span>{convertFileSize(file?.size)} MB</span>
              <span>
                <Styled.FileIcon
                  src={downloadIcon}
                  onClick={() => {
                    file?.file
                      ? downloadImageFile(file?.file)
                      : downloadFile(file?.id, file?.path);
                  }}
                />
              </span>
              {isEdit && (
                <span>
                  <Styled.FileIcon src={deleteIcon} onClick={onClickDelete} />
                </span>
              )}
            </div>
          </>
        ) : (
          <Styled.FileNotFound>No Files</Styled.FileNotFound>
        )}
      </Styled.FileItem>
    );
  };

  const addToRefs = (el) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  return (
    <section>
      {/* 제목 */}
      <Styled.TitleBar>
        <Styled.Title>Payment Information</Styled.Title>
        {STATUS.accept.approve && (
          <Styled.Anchor onClick={onClickPaymentSave}>
            {!isEdit ? "Edit" : "Submit Changes"}
          </Styled.Anchor>
        )}
      </Styled.TitleBar>

      {/* 영역 */}
      <Styled.Contents>
        {/* 이름 */}
        <Styled.InputField
          label={"Name(English)"}
          data-key="data.user.name"
          value={data.user.name}
          onChange={onChangeData}
          disabled={!STATUS.accept.reject}
          isEmpty={STATUS.accept.reject && !data.user.name}
          inputRef={addToRefs}
        />

        <Styled.Field label={"Country of Residence"}>
          <Styled.SelectBox
            data={convertCountryData(countryList)}
            value={data.user.settle?.country}
            onChange={(value) => {
              onChangeCountry(value);
            }}
            disabled={!STATUS.accept.reject}
            isEmpty={STATUS.accept.reject && !data.user.settle?.country}
          />
        </Styled.Field>

        <Styled.InputField
          label={"PayPal Account"}
          data-key="data.user.settle.bankAccountNumber"
          defaultValue={data.user.settle?.bankAccountNumber}
          onChange={onChangeData}
          disabled={!STATUS.accept.reject}
          isEmpty={STATUS.accept.reject && !data.user.settle?.bankAccountNumber}
          inputRef={addToRefs}
        />

        {
          /* 수정 상태가 반려일 경우 반려 사유 영역 표시 */
          STATUS.modify.reject && (
            <Styled.Field label={"Editor's Notes"}>
              <Styled.Textarea
                disabled={true}
                defaultValue={data.modifyRejectComment}
                isEmphasis={true}
              />
            </Styled.Field>
          )
        }

        {/* 정산 문서 */}
        {STATUS.accept.approve && (
          <Styled.Field
            label={
              <>
                Payment Documents
                <Styled.SubLabel>
                  Click the [Edit] button above to update your information.
                </Styled.SubLabel>
              </>
            }
          >
            <div>
              <Styled.GuideText>
                1. To receive payments, please attach a signed copy of your
                application of limited tax rate.
                <Styled.GuideLink
                  target={"_blank"}
                  href={
                    "https://acon3d.notion.site/ACON-FWT-Form-Guide-0fffee8b20274b19a99ace79fee01122"
                  }
                >
                  View Guide
                </Styled.GuideLink>
              </Styled.GuideText>

              {isEdit && (
                <Styled.FileListInputComponent
                  value={limitedTaxApl?.path}
                  onChange={onChangeLimitedTaxApl}
                  isGlobal={true}
                  isEmpty={!limitedTaxApl?.path}
                />
              )}

              {/* 해외원천세 제한세율 신청서 - 파일 정보 */}
              <FileItemComponent
                file={limitedTaxApl}
                onClickDelete={onDeleteLimitedTaxApl}
              />
            </div>

            <div>
              <Styled.GuideText>
                2. Please attach a copy of your ID, passport or TIN document to
                receive payments. Your full name and ID number must be included.
              </Styled.GuideText>
              {isEdit && (
                <Styled.FileListInputComponent
                  value={idCardFile?.path}
                  onChange={onChangeIdCard}
                  isGlobal={true}
                  isEmpty={!idCardFile?.path}
                />
              )}

              {/* 신분 증빙 서류 - 파일 정보 */}
              <FileItemComponent
                file={idCardFile}
                onClickDelete={onDeleteIdCard}
              />
            </div>
          </Styled.Field>
        )}
      </Styled.Contents>
    </section>
  );
};

export default Component;
