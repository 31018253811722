import React, { useMemo, useEffect } from 'react';
import Layout from 'components/layout';
import UserForm from 'user/UserForm';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { PATH_USER } from 'routes/paths';
import useAuth from 'hooks/useAuth';
import AdminUserForm from 'user/AdminUserForm';
import AdminForm from 'user/AdminForm';

interface MatchParams {
  no: string;
}

export default function UserPage({ match }: RouteComponentProps<MatchParams>) {
  const { user, isAuthenticated } = useAuth();
  const history = useHistory();

  useEffect(() => {
    // 관리자는 의도적으로 루트 페이지 접근을 막음
    // => 훈이) 임시 요청으로, 루트 페이지 접근을 허용
    // if (user.isAdmin && !match.params.no) {
    //   history.push(PATH_PAGE.root);
    //   return;
    // }

    // 사용자의 경우, url param을 받지않음.
    if (!user.isAdmin && match.params.no) {
      history.push(PATH_USER.root);
      return;
    }
  }, [user.isAdmin, match.params.no]);

  const targetUserId = useMemo(() => (user.isAdmin && match.params.no ? Number(match.params.no) : undefined), [user.isAdmin, match.params.no]);

  return (
    <Layout>
      {isAuthenticated && !targetUserId && !user.isAdmin && <UserForm userId={targetUserId} />}
      {isAuthenticated && !targetUserId && user.isAdmin && <AdminForm userId={targetUserId} />}
      {isAuthenticated && user.isAdmin && targetUserId && <AdminUserForm userId={targetUserId} />}
    </Layout>
  );
}
