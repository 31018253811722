import { graphqlUrl } from 'api';
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../app';
import ACON from '../lib/global';
import './search.scss';

export default (props) => {
    const { t } = props;
    const { showAlertMessage, setBackgroundColor } = useContext(AppContext);
    // 검색어 항목 객체 
    const [searchKeywordItemObj, setSearchKeywordItemObj] = useState({
        'ko': '', 'en': '', 'cn': '', 'jp': ''
    });

    useEffect(() => {
        try {
            // 배경색 변경 
            setBackgroundColor('white');
            // 검색 키워드 리스트 렌더링 
            renderSearchKeywordList();
        }
        catch (errObj) {
            // 로그 기록(TO DO)
            // 관리자에게 문의해주세요.
            showAlertMessage(t("anInternalErrorHasOccurred"), t("pleaseContactAdministrator"));
        }
    }, []);

    // 검색 키워드 input 태그 변경 이벤트 핸들러 
    const onChangeInputTags = (e) => {
        try {
            // 현재 언어 
            const lang = e.target.getAttribute('data-lang');
            // 변경된 값 적용 
            searchKeywordItemObj[lang] = e.target.value;
            // 검색 키워드 설정 
            setSearchKeywordItemObj({ ...searchKeywordItemObj });
        }
        catch (errObj) {
            // 로그 기록(TO DO)
            // 관리자에게 문의해주세요.
            showAlertMessage(t("anInternalErrorHasOccurred"), t("pleaseContactAdministrator"));
        }
    };
    // 저장 button 태그 클릭 이벤트 핸들러 
    const onClickSaveBtnTag = async () => {
        try {
            // 모든 언어 검색어 항목에 대한 유효성 검사
            // 언어 (키) 루프 
            for (let key in searchKeywordItemObj) {
                // 현재 언어에 대한 키워드 
                const keyword = searchKeywordItemObj[key];
                // 콤마 단위로 검색어 구분 
                const keywordArr = keyword.split(',');

                // 유효성검사 #1 : 검색어가 50자 이상인 항목이 존재할 경우 
                if (keywordArr.filter(x => x.length > 50).length > 0) {
                    // 검색어가 너무 깁니다. 
                    throw new ACON.ValidationError(t('searchKeyword.validationException.longKeyword'));
                }
                // 유효성검사 #2 : 검색어 항목이 10개 초과 존재할 경우
                if (keywordArr.length > 10) {
                    // 검색어는 최대 10개까지 입력해주세요.. 
                    throw new ACON.ValidationError(t('searchKeyword.validationException.limitKeyword'));
                }
            }

            // 유효성검사가 모두 통과가 된 경우 
            // 저장 쿼리 실행 
            const query = `mutation {
                taskA: setSearchKeyword(keyword: "${searchKeywordItemObj['ko']}", lang: "ko")
                taskB: setSearchKeyword(keyword: "${searchKeywordItemObj['en']}", lang: "en")
                taskC: setSearchKeyword(keyword: "${searchKeywordItemObj['cn']}", lang: "cn")
                taskD: setSearchKeyword(keyword: "${searchKeywordItemObj['jp']}", lang: "jp")
            }`
            // mutation 결과 
            const resultMutation = await axios.post(graphqlUrl, {
                query: query
            });

            // 결과값이 정상적으로 넘어오지 않은경우 
            if (!resultMutation?.data.data) {
                throw `mutation 실패 ${JSON.stringify(resultMutation)}`;
            }

            // 저장완료 메세지 표시 
            showAlertMessage(t('searchKeyword.completSave'), t('searchKeyword.completeSaveDescription'));
        }
        catch (errObj) {
            // 에러가 유효성검사 실패 에러일 경우 
            if (errObj instanceof ACON.ValidationError) {
                // 유효성검사 실패 메세지 표시 
                showAlertMessage(t('searchKeyword.donotCompleteSave'), errObj.message);
                // 종료
                return;
            }
            // 관리자에게 문의해주세요.
            showAlertMessage(t("anInternalErrorHasOccurred"), t("pleaseContactAdministrator"));
        }
    };

    // 검색 키워드 목록들 렌더링하기 메소드 
    const renderSearchKeywordList = async () => {
        // 검색어 키워드 항목 객체 배열 
        const searchKeywordItemObjArr = await getKeywordItems();

        // 검색 키워드 상태 설정 
        setSearchKeywordItemObj({
            'ko': searchKeywordItemObjArr.filter(x => x.language.code === 'ko').map(x => x.keyword).join(', '),
            'en': searchKeywordItemObjArr.filter(x => x.language.code === 'en').map(x => x.keyword).join(', '),
            'cn': searchKeywordItemObjArr.filter(x => x.language.code === 'cn').map(x => x.keyword).join(', '),
            'jp': searchKeywordItemObjArr.filter(x => x.language.code === 'jp').map(x => x.keyword).join(', '),
        });
    };
    // 키워드 항목들 가져오기 메소드 
    const getKeywordItems = async () => {
        // 키워드 항목들 반환 
        let resultObj = await axios.post(graphqlUrl, {
            'query': `{
                getSearchKeywords {
                    language {
                        code
                    },
                    keyword
                }
            }`
        });
        // 검색어 목록 반환 
        return resultObj?.data.data.getSearchKeywords;
    };
    return (
        <>
            <div className="search-page">
                <div className="search-page__title">
                    {t('popularKeywordMng.title')}</div>
                <div className="search-page__description">
                    {t('popularKeywordMng.description')}
                </div>

                <div className="search-page__field">
                    <div className="search-page__field__title">
                        {t('krPopularKeywordMng')}
                    </div>
                    <input type="text" data-lang="ko" value={searchKeywordItemObj.ko} onChange={onChangeInputTags} autoFocus />
                </div>

                <div className="search-page__field">
                    <div className="search-page__field__title">
                        {t('enPopularKeywordMng')}
                    </div>
                    <input type="text" data-lang="en" value={searchKeywordItemObj.en} onChange={onChangeInputTags} />
                </div>

                <div className="search-page__field">
                    <div className="search-page__field__title">
                        {t('cnPopularKeywordMng')}
                    </div>
                    <input type="text" data-lang="cn" value={searchKeywordItemObj.cn} onChange={onChangeInputTags} />
                </div>

                <div className="search-page__field">
                    <div className="search-page__field__title">
                        {t('jpPopularKeywordMng')}
                    </div>
                    <input type="text" data-lang="jp" value={searchKeywordItemObj.jp} onChange={onChangeInputTags} />
                </div>
                <div className="search-page__field">
                    <button className="search-page__field__save-button" type="button" onClick={onClickSaveBtnTag}>{t('save')}</button>
                </div>
            </div>
        </>
    );
};
