import styled from 'styled-components';
import { BlackButton, WhiteButton } from 'components/button';

/* 공통 스타일 - 시작 */
export const Page = styled.div`
  margin-top: 50px;
  td {
    border-left: 0;
    border-right: 0;
  }
`;
export const Wrap = styled.div`
  margin-top: 23px;
  padding: 0 0 0 20px;
`;

export const TabContainer = styled.div`
  display: flex;
  padding: 0 20px;
`;

export const Tab = styled.div`
  font-size: 20px;
  line-height: 29px;
  font-weight: bold;
  color: #575757;
  width: 125px;
  text-align: center;
  cursor: pointer;
  ${(props) => `border-bottom: 10px ${props.isActive ? '#EB357B' : '#C4C4C4'} solid;`}
`;

export const Top = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Wrapper = styled.div`
  width: 1060px;
  margin: 0 auto;
`;

export const Contents = styled.div``;
export const Board = styled.div``;
export const Row = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #aaa;
  padding-top: 30px;
  padding-bottom: 30px;

  ${(props) =>
    props.isHead
      ? `
        font-size: 14px;
        font-weight: 700;
        border-bottom: 2px solid #333;
    `
      : ``}
`;
export const Column = styled.div`
  ${(props) => (props.flex ? `flex: ${props.flex};` : ``)}
  ${(props) => (props.align ? `text-align: ${props.align};` : '')}
`;

export const ColumnItem = styled.div``;

export const ColumnLink = styled.a`
  display: flex;
  color: #333;
  &: hover {
    color: inherit;
  }
  ${(props) => (props.flex ? `flex: ${props.flex};` : ``)}
`;

export const SearchWrapper = styled.div`
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SearchForm = styled.div`
  align-items: center;
`;

export const SearchTypeCombo = styled.select`
  width: 110px;
  height: 28px;
  font-size: 12px;
  color: #000;
  border: 1px solid #cdcdcd;
`;

export const SearchInput = styled.input.attrs((props) => ({
  type: 'text',
}))`
  width: 190px;
  height: 28px;
  border: 1px solid #aaa;
  padding-left: 5px;
  box-sizing: border-box;
  margin-left: 4px;
  margin-right: 10px;
`;

export const SearchButton = styled(BlackButton)``;

export const NaviWrapper = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

export const ButtonWrap = styled.div`
  align-items: center;
  display: flex;

  button {
    margin-left: 5px;
  }
`;
export const WritePromotionButton = styled(BlackButton)`
  width: 187px;
  height: 40px;
  & > a {
    color: inherit;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  & > a:hover {
    color: inherit;
  }
`;

export const RemovePromotionItemButton = styled(WhiteButton)`
  width: 187px;
  height: 40px;
  &:disabled {
    opacity: 0.5;
  }
  & > a {
    color: black;
  }
  & > a:hover {
    color: inherit;
  }
`;

export const Flex = styled.div`
  display: flex;
  ${(props) => (props.type === 'space-between' ? 'justify-content: space-between;' : '')}
  ${(props) => (props.justifyContent ? `justify-content: ${props.justifyContent};` : '')}
    ${(props) => (props.align === 'center' ? 'align-items: center;' : '')}
`;

export const Value = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 17px;
  position: relative;
`;
/* 공통 스타일 - 끝 */
