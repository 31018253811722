import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserTypeEnum } from "signup/type";
import { onlyNumber, onlyNumberStr } from "utils/validation";
import {
  nationalIdNumberState,
  rrnBackState,
  rrnFrontState,
  userTypeState,
} from "./atom";

export default function NationalNumberIdField() {
  const rrnFrontRef = useRef<HTMLInputElement>(null);
  const rrnBackRef = useRef<HTMLInputElement>(null);

  const userType = useRecoilValue(userTypeState);

  const [nationalIdNumber, setNationalIdNumber] = useRecoilState(
    nationalIdNumberState
  );
  const [rrnFront, setRRNFront] = useRecoilState(rrnFrontState);
  const [rrnBack, setRRNBack] = useRecoilState(rrnBackState);

  const handleChangeNationalIdNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = onlyNumberStr(e.target.value);

    setNationalIdNumber(value);
  };

  const handleRRNFrontNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = onlyNumberStr(e.target.value);

    setRRNFront(value);

    if (value.toString().length === 6) {
      rrnBackRef.current.focus();
    }
  };

  const handleRRNBackNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = onlyNumberStr(e.target.value);

    setRRNBack(value);
  };

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2">
        {userType === UserTypeEnum.Personal ? "주민등록번호" : "사업자등록번호"}
      </Typography>
      <Stack direction="row" spacing={1.5} alignItems={"center"}>
        {userType === UserTypeEnum.Personal && (
          <>
            <TextField
              value={rrnFront ? rrnFront : ""}
              size="small"
              inputProps={{ maxLength: 6 }}
              inputRef={rrnFrontRef}
              onChange={handleRRNFrontNumber}
            />
            <Box
              sx={{
                backgroundColor: "rgba(145, 158, 171, 0.32)",
                width: "12px",
                height: "2px",
              }}
            />
            <TextField
              value={rrnBack ? rrnBack : ""}
              size="small"
              inputProps={{ maxLength: 7 }}
              type="password"
              inputRef={rrnBackRef}
              onChange={handleRRNBackNumber}
            />
          </>
        )}
        {userType === UserTypeEnum.Business && (
          <TextField
            value={nationalIdNumber}
            size="small"
            onChange={handleChangeNationalIdNumber}
          />
        )}
      </Stack>
    </Stack>
  );
}
