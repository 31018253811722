import React from 'react';
import './SignUp.scss';
import SignUpInput from './SignUpInput';

export default ((props) => {
    // 번역도구  
    const {
        t,
        signUpInfoObj,
        pennameInput,
        brandCodeInput,
        emailInput,
        emailDomainInput,
        emailAuthButton,
        passwordInput,
        rePasswordInput,
        phoneInput,
        usernameInput,
        termsOfUseButton,
        collectionUseOfPersonalInformationButton,
        openTermsOfUseModal,
        openCollectionUseOfPersonalInformationModal,
        handleFieldChange,
        handleChange,
        onBlurValidEmail,
        onBlurValidPw,
        onBlurValidRePw,
        onBlurPhone,
        onBlurUsername,
        onBlurPenname,
        onKeyUpBrandCode,
        onClickCancelButton,
        onClickJoinButton,
        onClickDuplicateCheckBtn,
        onChangeBrandCodeInput,
        onChangeEmailId,
        onChangeEmailDomain
    } = props;

    return (
        <>
            {/* 회원가입 폼 */}
            <div className="block-container signup">
                <div>
                    {/* 승인된 필명 / 닉네임 */}
                    <div className="signup__field">
                        <span className="signup__field__title">{t("Penname.label")}</span>
                        <input
                            name="penname"
                            value={signUpInfoObj.penname || ''}
                            type="text"
                            readOnly={signUpInfoObj.isAuthBrandCode}
                            onChange={handleChange}
                            className={"underline " + (signUpInfoObj.isPenname === false ? "error" : "")}
                            onBlur={onBlurPenname}
                            ref={pennameInput}
                        />
                        <span className="guide-msg">
                            {signUpInfoObj.pennameValidationText}
                        </span>
                    </div>

                    {/* 브랜드코드 */}
                    <div className="signup__field signup__field__brandcode">
                        <span className="signup__field__title">{t("approvedAcon3dBrandCode")}</span>
                        <div className="signup__authField">
                            <input name="brandCode"
                                className="underline"
                                type="text"
                                value={signUpInfoObj.brandCode}
                                readOnly={signUpInfoObj.isAuthBrandCode}
                                ref={brandCodeInput}
                                onKeyUp={onKeyUpBrandCode}
                                onChange={onChangeBrandCodeInput}
                            />
                        </div>
                        <div className="guide-msg">
                            <a href="https://www.notion.so/acon3d/36480214ce794e61a649504589d6970b" target="_blank" rel="noopener noreferrer">
                                {t("PennameDescription1.label")}❓
                            </a>
                        </div>
                    </div>
                    {/* 이메일 */}
                    <div className="signup__field">
                        <span className="signup__field__title">
                            {t("EmailFieldTitle")}
                        </span>
                        <div className="signup__field__userId">
                            <div>
                                <input name="userId"
                                    className={"underline " + (signUpInfoObj.isEmail === false ? "error" : "")}
                                    value={signUpInfoObj.userId}
                                    onChange={onChangeEmailId}
                                    onBlur={onBlurValidEmail}
                                    ref={emailInput}
                                    style={{ width: "150px" }}
                                    type="text"
                                    autoFocus
                                />
                                <span className="emailAt">@</span>
                                <input name="emailDomain"
                                    className="emailDomain"
                                    value={signUpInfoObj.emailDomain}
                                    onChange={onChangeEmailDomain}
                                    ref={emailDomainInput}
                                    placeholder={t("Email.label")}
                                    type="text"
                                    list="data"
                                />
                                <datalist id="data">
                                    <option value="naver.com" readOnly />
                                    <option value="yahoo.co.kr" readOnly />
                                    <option value="daum.net" readOnly />
                                    <option value="gmail.com" readOnly />
                                </datalist>
                                <button type="button"
                                    ref={emailAuthButton}
                                    className="vivid-button min-btn"
                                    onClick={onClickDuplicateCheckBtn}
                                >
                                    {t("CheckDuplicate.label")}
                                </button>
                            </div>
                            <div className="guide-msg">
                                {signUpInfoObj.userIdValidationText}
                            </div>
                        </div>
                    </div>

                    {/* 비밀번호 */}
                    <div className="signup__field">
                        <span className="signup__field__title">{t("Password.label")}</span>
                        <input name="password"
                            type="password"
                            ref={passwordInput}
                            className={"underline " + (signUpInfoObj.isFirstPassword === false ? "error" : "")}
                            value={signUpInfoObj.password}
                            onChange={handleChange}
                            onBlur={onBlurValidPw}
                        />
                        <span className="guide-msg">
                            {signUpInfoObj.passwordValidationText}
                        </span>
                    </div>

                    {/* 비밀번호 확인 */}
                    <div className="signup__field">
                        <span className="signup__field__title">{t("ConfirmPassword.label")}</span>
                        <input name="rePassword" value={signUpInfoObj.rePassword}
                            type="password" onChange={handleChange}
                            onBlur={onBlurValidRePw}
                            ref={rePasswordInput}
                            className={"underline " + (signUpInfoObj.isRepassword === false ? "error" : "")}
                        />
                        <span className="guide-msg">
                            {signUpInfoObj.rePasswordValidationText}
                        </span>
                    </div>

                    {/* 휴대폰 번호 */}
                    <SignUpInput
                        childRef={phoneInput}
                        title={t("Phone.label")}
                        value={signUpInfoObj.phone}
                        name="phone"
                        validationMsg={signUpInfoObj.phoneValidationText}
                        className={signUpInfoObj.isPhone === false ? "error" : ""}
                        onChange={handleFieldChange}
                        onBlur={onBlurPhone}
                    />

                    {/* 창작자 본명 */}
                    <SignUpInput
                        childRef={usernameInput}
                        title={t("AuthorRealName.label")}
                        value={signUpInfoObj.username}
                        name="username"
                        validationMsg={signUpInfoObj.usernameValidationText}
                        className={signUpInfoObj.isUsername === false ? "error" : ""}
                        onChange={handleFieldChange}
                        onBlur={onBlurUsername}
                    />

                    {/* 모국어  */}
                    <div className="signup__field">
                        <span className="signup__field__title">{t("LanguageFieldTitle")}</span>
                        <select>
                            <option value="ko">{t("SouthKorea.label")}</option>
                            <option value="en">U.S</option>
                        </select>
                    </div>

                    {/* 이용 약관 */}
                    <div className="signup__field">
                        <span className="signup__field__title">{t("TermsOfUse.label")}</span>
                        <button type="button"
                            className={"mid-btn " + (signUpInfoObj.isAgreeTermsOfUseModal ? "vivid-button" : "white-button")}
                            onClick={openTermsOfUseModal}
                            ref={termsOfUseButton}
                        >
                            {signUpInfoObj.isAgreeTermsOfUseModal ? t("Completed.label") : t("Terms.label")}
                        </button>
                    </div>

                    {/* 개인정보 수집 및 이용  */}
                    <div className="signup__field">
                        <span className="signup__field__title">{t("CollectingAndUsingPersonalInformation.label")}</span>
                        <button type="button"
                            className={"mid-btn " + (signUpInfoObj.isAgreeCollectionUseOfPersonalInformation ? "vivid-button" : "white-button")}
                            onClick={openCollectionUseOfPersonalInformationModal}
                            ref={collectionUseOfPersonalInformationButton}
                        >
                            {signUpInfoObj.isAgreeCollectionUseOfPersonalInformation ? t("Completed.label") : t("Terms.label")}
                        </button>
                    </div>

                    <div className="btn-line">
                        <button className="white-button"
                            onClick={onClickCancelButton}
                        >
                            {t("cancel")}
                        </button>
                        <button className="vivid-button join"
                            onClick={onClickJoinButton}
                        >
                            {t("signup.signup")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
});