import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Divider,
  FlexColumn,
  FlexRow,
  SectionTitle,
  Text,
  CreatorDivider,
} from "../style";
import countryData from "../assets/iso_3166";
import { useRecoilValue } from "recoil";
import { signinState, userTypeState } from "signup/SignUp.atoms";
import { Stack } from "@mui/material";
import Navigator from "signup/Navigator";
import { ConfirmModalComponent as ConfirmModal } from "../components/confirmModal";
import { LanguageCodeEnum } from "generated/graphql";
import { AppContext } from "app";
import { UserTypeEnum } from "signup/type";
import { resolve } from "path";

export default function ConfirmInformationForm() {
  // 번역 도구
  const { t } = useTranslation();
  const { showConfirmMessage } = useContext(AppContext);

  const state = useRecoilValue(signinState);
  const {
    name,
    userEmail,
    brandNames: { koBrandName, enBrandName, zhBrandName, jaBrandName },
    documents,
    snsUrl,
    country,
  } = state;

  const handleNext: () => Promise<string> = async () => {
    return new Promise((resolve, reject) => {
      showConfirmMessage(
        "",
        t("signupPage.onFinishConfirmMessage"),
        async () => {
          resolve("");
        }
      );
    });
  };

  return (
    <>
      <Stack>
        <SectionTitle style={{ marginBottom: 80 }}>
          {t("signupPage.confirmInfo")}
        </SectionTitle>
        <FlexColumn>
          <FlexColumn marginBottom="60">
            <Text fontWeight="500" color="#7A7A7A" marginBottom="12">
              Account Infomation
            </Text>
            <Divider color="#CDCDCD" marginBottom="20" />
            <FlexRow height="40" marginBottom="20">
              <Text width="200" fontWeight="700">
                ID (E-Mail)
              </Text>
              <Text>{userEmail}</Text>
            </FlexRow>
            <Divider color="#CDCDCD" />
          </FlexColumn>
          <FlexColumn marginBottom="60">
            <Text fontWeight="500" color="#7A7A7A" marginBottom="12">
              Creator Information
            </Text>
            <Divider color="#CDCDCD" marginBottom="20" />
            <FlexRow height="40" marginBottom="20">
              <Text width="200" fontWeight="700">
                Creator Name
              </Text>
              {koBrandName && <Text>{koBrandName}</Text>}
              {enBrandName && (
                <>
                  <CreatorDivider />
                  <Text>{enBrandName}</Text>
                </>
              )}
              {zhBrandName && (
                <>
                  <CreatorDivider />
                  <Text>{zhBrandName}</Text>
                </>
              )}
              {jaBrandName && (
                <>
                  <CreatorDivider />
                  <Text>{jaBrandName}</Text>
                </>
              )}
            </FlexRow>
            <Divider color="#CDCDCD" marginBottom="20" />
            <FlexRow
              minHeight="40"
              marginBottom="20"
              style={{ alignItems: "center" }}
            >
              <Text width="200" fontWeight="700">
                Project Reference Link
              </Text>
              <FlexColumn
                style={{
                  listStyleType: "decimal",
                  listStylePosition: "inside",
                }}
              >
                {documents.map((doc, idx) => {
                  return (
                    <FlexRow
                      key={idx}
                      style={{
                        alignItems: "baseline",
                        marginBottom:
                          documents.length === idx + 1 ? "0px" : "20px",
                        wordBreak: "break-all",
                        display: "list-item",
                        fontSize: 14,
                      }}
                    >
                      <a
                        key={idx}
                        href={doc}
                        target="_blank"
                        style={{
                          color: "#333333",
                          textDecoration: "underline",
                          fontSize: 14,
                        }}
                      >
                        {doc}
                      </a>
                    </FlexRow>
                  );
                })}
              </FlexColumn>
            </FlexRow>
            <Divider color="#CDCDCD" marginBottom="20" />
            <FlexRow height="40" marginBottom="20">
              <Text width="200" fontWeight="700">
                SNS Account (Optional)
              </Text>
              <Text>{snsUrl}</Text>
            </FlexRow>
            <Divider color="#CDCDCD" marginBottom="20" />
          </FlexColumn>
          <FlexColumn>
            <Text fontWeight="500" color="#7A7A7A" marginBottom="12">
              Contract Information
            </Text>
            <Divider color="#CDCDCD" marginBottom="20" />
            <FlexRow height="40" marginBottom="20">
              <Text width="200" fontWeight="700">
                Name
              </Text>
              <Text>{name}</Text>
            </FlexRow>
            <Divider color="#CDCDCD" marginBottom="20" />
            <FlexRow height="40" marginBottom="20">
              <Text width="200" fontWeight="700">
                Country
              </Text>
              <Text>{countryData.find((c) => c.Code === country)?.Name}</Text>
            </FlexRow>
            <Divider color="#CDCDCD" marginBottom="20" />
          </FlexColumn>
        </FlexColumn>
      </Stack>
      <Navigator onNext={handleNext} />
    </>
  );
}
