import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ACON from 'lib/global';
import { useTranslation } from 'react-i18next';
import Cropper from '../../write/image';
import { Flex, Container, FieldTitle, Field, Value, Input, Title, WhiteButton, BlackButton, InputMessage,  CommonInputCheckbox, LabelText, Table, Thead, Th, Tbody, Td, BenefitInputBox } from '../../write/style';
import DatePicker from '../../../../components/datepicker';
import dateFormat from 'dateformat';
import { GET_GODO_COMET_DEAL_LIST, GET_IS_EXIST_PROMOTION, GET_COMET_PROMOTION } from 'api/quries';
import { SAVE_PROMOTION } from 'api/mutations';
import { AppContext } from 'app';
import { useHistory, useParams } from 'react-router-dom';
import AconHelper from '../../../../lib/global';
import { CheckboxGroup, Checkbox } from 'rsuite';
import { Loading } from 'boards/DetailBoardWrite/style';

export default ((props) => {
    // 모달 표시 메소드 불러오기
    const { userInfo, showAlertMessage } = useContext(AppContext);
    // URL 파생정보 
    const { id } = useParams();
    // history
    const history = useHistory();
    // 현재 페이지 타입을 가져옵니다.
    const promotionDisplayLocation = props.match.params.displayLocation || "cosmic-deal";

    // 언어 
    const [languages, setLanguages] = useState(['ko', 'en', 'cn', 'jp']);
    // 해당 문서에 대한 상태 object
    const [item, setItem] = useState({
        // 고도몰 상품번호 
        godoGoodsNo: '',
        // 혜택 자동 지급 여부
        isAutoBenefit: false,
        // 예약일자 
        reserveDate: '',
        // 예약 이미지 크롭 정보 { cropX, cropY, cropWidth, cropHeight, key }
        reserveImgCropInfo: '',
        // 예약 원본 이미지 업로드 key 
        reserveOriginImgKey: '',
        // 예약 원본 이미지 URL
        reserveOriginImgUrl: '',

        // 아래 정보들에 대하여, index:0 => 1차 / index:1 => 2차 / index:2 => 3차 입니다. 
        // 이미지 크롭 정보들 
        imgCropInfos: [],
        // 원본 이미지 키들 
        originImgKeys: [],
        // 원본 이미지 url들
        originImgUrls: [],
        // 혜택들 { name, amount, startDate, endDate, period }
        benefits: [],
        // 포인트 혜택 뱃지
        pointBenefit: ['', '', '', ''],
        // 쿠폰 혜택 뱃지
        couponBenefit: ['', '', '', ''],
    });
    // 고도몰 상품번호 유효성검사 메세지 
    const [godoGoodsNoValidationMessage, setGodoGoodsNoValidationMessage] = useState('');
    // 패칭 여부 
    const [isFetch, setIsFetch] = useState(false);
    // 프로모션 저장하기 메소드
    const [savePromotion, { loading: isLoadingSavePromotion }] = useMutation(SAVE_PROMOTION);
    // 번역 도구 
    const { t } = useTranslation();

    /* --- 조회 쿼리들 시작 --- */
    // 혜성 특가 중복 여부 가져오기 메소드
    // 혜성 특가 리스트 가져오기 메소드
    const getGodoCometDealList = useQuery(GET_GODO_COMET_DEAL_LIST, {
        skip: true
    });
    // 혜성 특가 중복 여부 가져오기 메소드
    const getIsExistCometDealItem = useQuery(GET_IS_EXIST_PROMOTION, {
        skip: true
    });
    // 프로모션 항목 가져오기 
    const { data: promotionItem, loading: isLoadingGetPromotionItem } = useQuery(GET_COMET_PROMOTION, {
        variables: { id: Number(id) },
        skip: !id
    });
    /* --- 조회 쿼리들 끝 --- */

    // 초기 문서 진입 시에 대한 useEffect
    useEffect(() => {
        // 사용자가 해당 경로에 접근했을 경우
        if (!userInfo.isAdmin)
            history.push('/');

        // url 에 ID가 존재하지 않을경우 
        if (!id)
            // 종료 
            return;

        // 이미 패칭됌. 혜택 정보를 수정할 수 없음 
        setIsFetch(true);

        // 프로모션 항목 
        const data = promotionItem?.getPromotion;

        // 로딩중인 경우 
        if (isLoadingGetPromotionItem)
            // 종료 
            return;

        // 가져온 항목이 존재하지 않고, 프로모션 항목 가져오기가 로딩중이 아닐경우 
        if (!data && !isLoadingGetPromotionItem) {
            // 작성 페이지로 이동 
            history.push(`/manager/promotion/${promotionDisplayLocation}/write`);
            // 종료 
            return;
        }

        // 우주최저가 n차에 대한 이미지 정보들
        const cosmicDealImgItems = data.imageList.filter((x, i) => i > 0);
        // 언어 설정하기 
        setLanguages(data.languageList.map(x => x.language.code));

        // 항목을 설정한다. 
        setItem({
            // 고도몰 상품번호 
            godoGoodsNo: data.godoGoodsNo,

            //예약일자 
            reserveDate: data.reserveDate,

            //예약 이미지 크롭 정보 { cropX, cropY, cropWidth, cropHeight, key }
            reserveImgCropInfo: {
                imageOriginX: data.imageList[0].cropX,
                imageOriginY: data.imageList[0].cropY,
                imageWidth: data.imageList[0].cropWidth,
                imageHeight: data.imageList[0].cropHeight,
                key: null
            },

            // 예약 원본 이미지 업로드 key 
            reserveOriginImgKey: '',

            // 예약 원본 이미지 URL
            reserveOriginImgUrl: data.imageList[0].originUploadFile.fullName,

            // 이미지 크롭 정보들 
            imgCropInfos: cosmicDealImgItems.map(x => {
                return {
                    imageOriginX: x.cropX,
                    imageOriginY: x.cropY,
                    imageWidth: x.cropWidth,
                    imageHeight: x.cropHeight,
                    key: null
                }
            }),

            // 원본 이미지 키들 
            originImgKeys: [],

            // 원본 이미지 url들
            originImgUrls: cosmicDealImgItems.map(x => x.originUploadFile.fullName),

            // 혜택들
            benefits: data.benefitList.map(x => {
                return {
                    // 혜택 명 
                    name: x.name,
                    // 상품 가격 
                    amount: x.amount,
                    // 시작일자 
                    startDate: x.startDate,
                    // 종료일자 
                    endDate: x.endDate,
                    // 기간 
                    period: `${dateFormat(x.startDate, 'yyyy-mm-dd HH:MM')} ~ ${dateFormat(x.endDate, 'yyyy-mm-dd HH:MM')}`
                }
            }),
            pointBenefit: item.pointBenefit.map((x, k) => {
                const pointBenefit = data.badgeList.find(x => x.type === 'point')?.item;
                return pointBenefit ? pointBenefit.find(x => x.language.id - 1 === k)?.amount || '' : '';
            }),
            couponBenefit: item.couponBenefit.map((x, k) => {
                const couponBenefit = data.badgeList.find(x => x.type === 'coupon')?.item;
                return couponBenefit ? couponBenefit.find(x => x.language.id - 1 === k)?.amount || '' : '';
            }),
            isAutoBenefit: data.isAutoCreateReviewBenefit
        });

        // 정상적인 항목에 대한 페이지 접근인 경우
    }, [promotionItem, isLoadingGetPromotionItem]);

    // 고도몰 상품번호 변경 이벤트 처리기 메소드
    const onChangeGodoGoodsNo = e => {
        // 입력한 값 
        const { value } = e.target;

        // 항목 조립 
        item.godoGoodsNo = ACON.OnlyNumber(value);
        // 항목 설정 
        setItem({
            ...item
        })
    };

    // 목록 버튼 클릭 이벤트 처리기 메소드
    const onClickListButton = () => {
        // 목록 페이지로 이동 
        history.push(`/manager/promotion/${promotionDisplayLocation}`);
    };

    // 저장 버튼 클릭 이벤트 처리기 메소드
    const onClickSaveButton = async () => {
        try {
            const currentDate = new Date();

            // 언어가 한개도 설정되어있지 않은경우
            if (languages.length === 0)
                throw new ACON.ValidationError(t('pleaseSelectLanguage'));

            // 고도몰 상품번호가 입력되지 않았을 경우
            if (!item.godoGoodsNo)
                throw new ACON.ValidationError(t('pleaseEnterGodoGoodsNo'));

            // 불러오기가 적용되지 않은경우 
            if (!isFetch)
                throw new ACON.ValidationError(t('pleaseClickFetch'));

            // 노출예약 일자가 존재하고, 노출 예약 일자가 혜택일자보다 큰 경우 
            if (item.benefits.filter(x => new Date(x.startDate) < new Date(item.reserveDate ? item.reserveDate : currentDate)).length > 0)
                throw new ACON.ValidationError(t('pleaseCheckReserveDate'));

            // 노출예약 이미지가 업로드 되지 않았을 경우 
            if (!item.reserveImgCropInfo) {
                throw new ACON.ValidationError(t('pleaseEnterReserveImg'));
            }
            // 혜택 이미지가 크롭되지 않았을 경우 
            if (item.imgCropInfos.filter(x => x === '').length > 0) {
                throw new ACON.ValidationError(t('pleaseEnterBenefitImg'));
            }
            // 혜택 이미지가 업로드 되지 않았을 경우 
            if (item.benefits.length !== item.imgCropInfos.length) {
                throw new ACON.ValidationError(t('pleaseEnterBenefitImg'));
            }

            // 프로모션 항목 존재여부 
            const isExistPromotionItem = await (async () => {
                const { data } = await getIsExistCometDealItem.refetch({
                    godoGoodsNo: item.godoGoodsNo,
                    id : Number(id),
                    type: 'comet'
                });

                // 프로모션 항목 존재여부 반환 
                return data.isExistPromotion
            })();

            // 프로모션 항목이 이미 존재하는 경우
            if (isExistPromotionItem) {
                throw new ACON.ValidationError(t('alreadyExistCosmicDealItem'));
            }
        
            const badgeList = [
                {
                    type: 'point',
                    koAmount: item.pointBenefit[0],
                    enAmount: item.pointBenefit[1],
                    zhAmount: item.pointBenefit[2],
                    jaAmount: item.pointBenefit[3],
                },
                {
                    type: 'coupon',
                    koAmount: item.couponBenefit[0],
                    enAmount: item.couponBenefit[1],
                    zhAmount: item.couponBenefit[2],
                    jaAmount: item.couponBenefit[3],
                }
            ];

            // 프로모션 항목 저장하기 
            const { data } = await savePromotion({
                variables: {
                    id: Number(id),
                    // 프로모션 타입
                    type: 'comet',
                    // 고도몰 상품번호 
                    godoGoodsNo: item.godoGoodsNo,
                    // 혜택 자동 생성 여부
                    isAutoCreateReviewBenefit: item.isAutoBenefit,
                    // 언어
                    languages,
                    // 예약일자 
                    reserveDate: item.reserveDate ? item.reserveDate : currentDate,
                    // 예약일자 이미지 키
                    reserveOriginImgKey: item.reserveOriginImgKey,
                    // 예약일자 크롭 정보
                    reserveImgCropInfo: {
                        cropX: item.reserveImgCropInfo.imageOriginX,
                        cropY: item.reserveImgCropInfo.imageOriginY,
                        cropWidth: item.reserveImgCropInfo.imageWidth,
                        cropHeight: item.reserveImgCropInfo.imageHeight,
                    },
                    // 원본 이미지 키 
                    originImgKeys: item.imgCropInfos.map((x, i) => {
                        return item.originImgKeys[i] ? item.originImgKeys[i] : ''
                    }),
                    //  크롭된 정보들 
                    imgCropInfos: item.imgCropInfos.map(x => {
                        return {
                            cropX: x.imageOriginX,
                            cropY: x.imageOriginY,
                            cropWidth: x.imageWidth,
                            cropHeight: x.imageHeight,
                        };
                    }),
                    // 뱃지 리스트
                    badgeList: badgeList
                }
            });

            // url id 조립
            history.push(`/manager/promotion/${promotionDisplayLocation}/write/${data.savePromotion.id}`);

            // 안내 메세지 표시 
            showAlertMessage(
                t('completeSave'),
                t('completePromotionSaveContents')
            );
        }
        catch (errObj) {
            // 에러가 유효성검사 실패 에러일 경우
            if (errObj instanceof ACON.ValidationError) {
                showAlertMessage(
                    t("UnableToCompleteSave.label"),
                    errObj.message
                );
                // 종료
                return;
            }
        }
    };

    // 고도몰 우주최저가 리스트 가져오기 버튼 클릭 이벤트 처리기 메소드 
    const onClickGetGodoCometDealListButton = async () => {
        // 이미 불러오기 인 상태인 경우 
        if (isFetch) {
            // 불러오기 상태 해제 
            setIsFetch(false);
            // 상태 초기화 
            setItem({
                ...item,
                // 고도몰 상품번호 초기화 
                godoGoodsNo: '',
                // 이미지 크롭 정보들 
                imgCropInfos: [],
                // 원본 이미지 키들 
                originImgKeys: [],
                // 원본 이미지 url들
                originImgUrls: [],
                // 혜택들
                benefits: []
            });
            // 종료 
            return;
        }

        // 프로모션 항목 존재여부 
        const isExistPromotionItem = await (async () => {
            const { data } = await getIsExistCometDealItem.refetch({
                godoGoodsNo: item.godoGoodsNo,
                id,
                type: 'comet'
            });

            // 프로모션 항목 존재여부 반환 
            return data.isExistPromotion
        })();

        // 프로모션 항목이 이미 존재하는 경우
        if (isExistPromotionItem) {
            // 메세지 설정 
            setGodoGoodsNoValidationMessage(t('alreadyExistItem'));
            // 종료 
            return;
        }

        // 가져온 데이터 
        const { data } = await getGodoCometDealList.refetch({
            godoGoodsNo: item.godoGoodsNo
        });

        // 데이터가 존재하지 않을경우 
        if (!data)
            // 종료한다.
            return;

        // // 혜택이 존재하지 않을경우
        if (data.godoCometDealList.length === 0) {
            // 메세지 설정 
            setGodoGoodsNoValidationMessage(t('goodsNotBenefit'));
            // 종료
            return;
        }
        
        // 메세지 초기화 
        setGodoGoodsNoValidationMessage('');

        const dealList = data.godoCometDealList.filter((x, i) => i === 0);

        // 항목을 설정한다. 
        setItem({
            ...item,
            // 이미지 크롭 정보들 
            imgCropInfos: [],
            // 원본 이미지 키들 
            originImgKeys: [],
            // 원본 이미지 url들
            originImgUrls: [],
            // 혜택들
            benefits: dealList.map(x => {
                return {
                    // 혜택 명 
                    name: x.benefitNm,
                    // 할인금액
                    amount: x.goodsPrice,
                    // 시작일자 
                    startDate: x.periodDiscountStart,
                    // 종료일자 
                    endDate: x.periodDiscountEnd,
                    // 기간 
                    period: `${dateFormat(x.periodDiscountStart, 'yyyy-mm-dd HH:MM')} ~ ${dateFormat(x.periodDiscountEnd, 'yyyy-mm-dd HH:MM')}`
                }
            })
        });

        // 패칭 여부 확인 
        setIsFetch(true);
    };

    // 혜택 자동 생성 체크 이벤트
    const onChangeAutoBenefit = e => {
        // 입력한 값
        const isChecked = e.target.checked;
        
        // 항목 조립 
        item.isAutoBenefit = isChecked;
        // 항목 설정 
        setItem({
            ...item
        })
    };
    
    return (
        <>
            <Loading show={isLoadingSavePromotion} />
            <Container>
                { /* 언어 설정 및 목록, 저장 버튼 */ }
                <Flex justifyContent="space-between" style={{ marginBottom: "42px" }}>
                    <div>
                        <CheckboxGroup
                            inline
                            value={languages}
                            onChange={value => setLanguages(value)}
                        >
                            <Checkbox value="ko">KO</Checkbox>
                            <Checkbox value="en">EN</Checkbox>
                            <Checkbox value="cn">ZH</Checkbox>
                            <Checkbox value="jp">JA</Checkbox>
                        </CheckboxGroup>
                    </div>
                    <div>
                        <WhiteButton onClick={onClickListButton}>{t('list')}</WhiteButton>
                        <BlackButton disabled={isLoadingSavePromotion} onClick={onClickSaveButton}>{t('save')}</BlackButton>
                    </div>
                </Flex>

                { /* 고도몰 상품번호로 상품정보 불러오기 Form */ }
                <Field>
                    <Title>{t('cosmic.godoGoodsNo')}</Title>
                    <Value>
                        <Flex>
                            <Input
                                placeholder={t('cosmic.godoGoodsNoPlaceholder')}
                                maxLength={10}
                                disabled={id || isFetch}
                                value={item.godoGoodsNo}
                                onChange={onChangeGodoGoodsNo}
                            />
                            {/* ID가 존재할 경우, 불러오기 기능을 막음 */}
                            {!id &&
                                <BlackButton disabled={item.godoGoodsNo.toString().length !== 10} onClick={onClickGetGodoCometDealListButton}>
                                    {!isFetch ? '불러오기' : '삭제하기'}
                                </BlackButton>}
                        </Flex>
                    </Value>
                    <Flex>
                        <CommonInputCheckbox disabled={id} onChange={onChangeAutoBenefit} id='autoBenefit' style={{ marginLeft: '0px' }} />
                        <LabelText for='autoBenefit'>리뷰 작성 시 포인트 지급 혜택을 자동으로 생성합니다. (저장 후 수정 불가)</LabelText>
                    </Flex>
                    {<InputMessage>{godoGoodsNoValidationMessage}</InputMessage>}
                </Field>

                { /* 혜성 특가 예고 */ }
                <Field>
                    <Title>{t('comet.expected')}</Title>
                    <Value>
                        <FieldTitle>{t('cosmic.registImage')}</FieldTitle>
                        <Cropper
                            url={item.reserveOriginImgUrl}
                            cropInfo={item.reserveImgCropInfo}
                            setCropKey={value => {
                                item.reserveOriginImgKey = value;
                                setItem({
                                    ...item
                                })
                            }}
                            setCropInfo={value => {
                                item.reserveImgCropInfo = value;
                                setItem({
                                    ...item
                                })
                            }}
                        />
                    </Value>
                    <Value>
                        <FieldTitle>{t('cosmic.reserve')}</FieldTitle>
                        <DatePicker
                            date={item.reserveDate}
                            placeholder={t('reserveDatePlaceholder')}
                            setDate={value => {
                                item.reserveDate = value;
                                setItem({
                                    ...item
                                })
                            }}
                        />
                    </Value>
                </Field>
                
                { /* 혜성 특가 */ }
                {item.benefits.map((x, i) =>
                    <Field>
                        <Title>{t('comet.comet')}</Title>
                        <Value>
                            <FieldTitle>{t('cosmic.registImage')}</FieldTitle>
                            <Cropper
                                url={item.originImgUrls[i]}
                                cropInfo={item.imgCropInfos[i]}
                                setCropKey={value => {
                                    item.originImgKeys[i] = value;
                                    setItem({
                                        ...item
                                    })
                                }}
                                setCropInfo={value => {
                                    item.imgCropInfos[i] = value;
                                    setItem({
                                        ...item
                                    })
                                }}
                            />
                        </Value>
                        <Value>
                            <FieldTitle>{t('cosmic.godoBenefit')}</FieldTitle>
                            <Input disabled={true} value={x.name} />
                        </Value>
                        <Flex justifyContent={'space-between'}>
                            <Value style={{ width: 'calc(50% - 10px)' }}>
                                <FieldTitle>{t('cosmic.salePrice')}</FieldTitle>
                                <Input disabled={true} value={AconHelper.AddCommas(x.amount)} />
                            </Value>
                            <Value style={{ width: 'calc(50% - 10px)' }}>
                                <FieldTitle>{t('cosmic.period')}</FieldTitle>
                                <Input
                                    disabled={true}
                                    value={`${dateFormat(x.startDate, 'yyyy-mm-dd HH:MM')} ~ ${dateFormat(x.endDate, 'yyyy-mm-dd HH:MM')}`} />
                            </Value>
                        </Flex>
                        <Value>
                            <FieldTitle>혜택 배지 정보 (선택)</FieldTitle>
                            <Table>
                                {(() => {
                                    const languageArr = ['KO', 'EN', 'ZH', 'JA'];
                                    return (
                                        <>
                                            <Thead>
                                                <Th>혜택 종류</Th>
                                                {languageArr.map(x => 
                                                    <Th>{x}</Th>
                                                )}
                                            </Thead>
                                            <Tbody>
                                                <tr>
                                                    <Td>포인트</Td>
                                                    {languageArr.map((o, k) => 
                                                        <Td>
                                                            <BenefitInputBox value={item.pointBenefit[k]} onChange={(e) => { 
                                                                item.pointBenefit[k] = e.target.value;
                                                                setItem({
                                                                    ...item
                                                                })
                                                            }} />
                                                        </Td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <Td>쿠폰</Td>
                                                    {languageArr.map((o, k) => 
                                                        <Td>
                                                            <BenefitInputBox value={item.couponBenefit[k]} onChange={(e) => { 
                                                                item.couponBenefit[k] = e.target.value;
                                                                setItem({
                                                                    ...item
                                                                })
                                                            }} />
                                                        </Td>
                                                    )}
                                                </tr>
                                            </Tbody>
                                        </>
                                    )
                                })()}
                            </Table>
                        </Value>
                    </Field>
                )}
            </Container>
        </>
    )
});