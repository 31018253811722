import React from "react";
import { Stack, Typography } from "@mui/material";
import { LabelFieldType } from "user/type";

export default function LabelField({ label, value }: LabelFieldType) {
  return (
    <Stack direction={"column"} spacing={0.5}>
      <Typography variant={"body2"} color={"grey.600"}>
        {label}
      </Typography>

      <Typography variant={"body2"} color={"grey.800"}>
        {value}
      </Typography>
    </Stack>
  );
}
