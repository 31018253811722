import React, { useMemo, useState } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Field, InformationCard, Title } from 'acon-mui/components/Board';
import { Container } from 'acon-mui/components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { categoriesState, contentBodyState, contentHeadState, displayState, originalDocumentData, selectedFieldsState, statusState } from '../recoil';
import { STATUS_DRAFT_ON_OPEN } from '../constants';
import Contents from './contents';
import Category from './category';

export default function ProductDetails() {
  const { t } = useTranslation();
  const display = useRecoilValue(displayState);
  const originalData = useRecoilValue(originalDocumentData);
  const [selectedFields, setSelectedFields] = useRecoilState(selectedFieldsState);
  const setContentHead = useSetRecoilState(contentHeadState);
  const setContentBody = useSetRecoilState(contentBodyState);
  const setCategories = useSetRecoilState(categoriesState);
  const status = useRecoilValue(statusState);
  const [isChecked, setIsChecked] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || display.isDisp, [isCheckboxVisible, display.isDisp, isChecked]);

  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setContentHead(originalData?.contentHead);
      setContentBody(originalData?.contentBody);
      setCategories(originalData?.categories);
      setSelectedFields(selectedFields.filter(item => item !== val));
    }
};
  return (
    <Field 
      className="hubWrite_field_content"
      informationCards={
        <>
          <InformationCard type="notice">
            {parse(t('document.content.notice'))}
          </InformationCard>
          <InformationCard marginTop="20px">
            {t('document.content.information1')}
          </InformationCard>
          <InformationCard marginTop="20px">
            {t('document.content.information2')}
          </InformationCard>

          <InformationCard type="notice" marginTop="auto" marginBottom="40px">
            {t('document.categories.notice')}
          </InformationCard>
        </>
      }
    >
      <Title
        checkboxVisible={isCheckboxVisible}
        isChecked={isChecked}
        onClick={(value, checked) => handleChecked(checked, t("document.productDetails"))}

      >
        {t("document.productDetails")}
      </Title>
      <Container marginTop="12px" {...disabled && { pointerEvents: 'none' }}>
        {/* 본문 */}
        <Contents isChecked={!isCheckboxVisible || isChecked} />
        <Category />
      </Container>
    </Field> 
  );
}