import { Flex } from 'signup/style';
import styled from 'styled-components';

export const Label = styled.strong`
    display: block;
    margin-bottom: 16px;
    font-size: 14px;
`;

export const Title = styled(Flex)`
    align-items: center;
    a {
        margin-left: 20px;
        color: #FA0070;
    }
`;

export const User = styled.div`
    color: #4A4A4A;
    background: white;
    padding: 90px 0;

    hr {
        margin: 18px 0;
        border: 0;
    }

    section {
        width: 588px;
        margin: 50px auto;

        .box {
            padding: 80px 60px;
            border: 1px solid #E0E0E0;
            box-sizing: border-box;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }

        textarea {
            width: 100%;
            height: 78px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 3px;
        }

        .download {
            width: 120px;
            height: 28px;
            border-radius: 3px;
            border: 1px solid #7C7C7C;
            background: white;
        }

        .flex {
            display: flex;

            button {
                width: 65px;
                height: 38px;
                margin-left: 12px;
                color: white;
                background: #333;
                border-radius: 3px;
            }
        }
    }

    > button {
        display: block;
        width: 216px;
        height: 40px;
        margin: 0 auto;
        color: white;
        background: #333;
        border-radius: 3px;
    }

`;

export * from './input';