import { useLazyQuery } from '@apollo/client';
import { GET_USER_SETTLE } from 'api/quries';
import React, { useState } from 'react';
import { Flex, FlexItem } from 'signup/style';
import { Field } from '../common/field';
import { CustomButton, Input } from '../common/input';
import UserEdit from './userEdit';
import User from './user';
import Company from './company';
import CompanyEdit from './companyEdit';

const Component = (props) => {
    const { id, userInfo } = props;
    const { isAdmin, isAccept } = userInfo;

    const [ getUserSettle, { loading, error, data }] = useLazyQuery(GET_USER_SETTLE);
    const [ password, setPassword ] = useState('');

    const signIn = () => {
        getUserSettle({variables: { id, password }});
    };

    if (!props.data) {
        return null;
    }

    if (isAdmin && !data) {
        // 관리자이고 정산정보 없을때
        return (
            <>
                <Field label="비밀번호 인증">
                    <Flex>
                        <FlexItem>
                            <Input type="password" placeholder="비밀번호를 입력 후 확인 가능합니다." onChange={(e) => setPassword(e.target.value)} />
                        </FlexItem>
                        <CustomButton onClick={signIn}>인증</CustomButton>
                    </Flex>
                </Field>
            </>
        )
    }

    const type = props.changeData?.type || props.data.user.type;

    if (type === '0') {
        // 구분 - 개인
        if (isAdmin) {
            // 로그인 - 관리자
            return <UserEdit data={data} isAdmin={isAdmin} onChangeData={props.onChangeData} />
        }

        if (props.data.user.accept.status === -1) {
            // 상태 - 반려
            return <UserEdit data={props.data} isAdmin={isAdmin} onChangeData={props.onChangeData} />
        }

        return <User data={props.data} isAccept={isAccept}/>
    } else if (type === '1') {
        // 구분 - 사업자
        if (isAdmin) {
            // 로그인 - 관리자
            return <CompanyEdit data={data} isAdmin={isAdmin} onChangeData={props.onChangeData} />
        }

        if (props.data.user.accept.status === -1) {
            // 상태 - 반려
            return <CompanyEdit data={props.data} isAdmin={isAdmin} onChangeData={props.onChangeData} />
        }

        return <Company data={props.data} isAccept={isAccept}/>
    }

    // 이곳을 통과하는 경우는 에러이므로 팝업창과 에러로그 수집이 추가되어야함

    return null;
};

export default Component;